import { ReactComponent as ReliableServiceIcon } from "../../assets/icons/ReliableService.svg";
import { ReactComponent as VerifiedAdsIcon } from "../../assets/icons/VerifiedAds.svg";
import { ReactComponent as SecurePaymentIcon } from "../../assets/icons/SecurePayment.svg";
import cows from "../../assets/images/cows.webp";

export default function WhyFarmGhar() {
  const items = [
    {
      icon: <ReliableServiceIcon />,
      title: "Reliable Service",
      background: "bg-[#EFA922]",
      description:
        "farmGhar is the most trusted online platform for all its customers. All our vendors are verified through NADRA so that none of our customers face any fraudulent activity. ",
    },
    {
      icon: <VerifiedAdsIcon />,
      title: "Verified Ads",
      background: "bg-[#39342F]",
      description:
        "Use the farmGhar verified ad service to verify your animals and let your ad stay on top of our ad portal for seven consecutive days.",
    },
    {
      icon: <SecurePaymentIcon />,
      title: "100% Payment Security",
      background: "bg-[#EFA922]",
      description:
        "Your payments are safe and secure with us, and you will not have to face any fraudulent activity whatsoever. We ensure our customers get their deliveries and the vendors are paid timely.",
    },
  ];
  return (
    <div className=" rounded-[15px] w-[95%] mx-auto mt-[50px]  text-white h-[1218px] lg-[926px]:h-[508px]">
      <img src={cows} alt="cows" className="h-[406px] " />
      <div className="relative bottom-[418px]">
        <h3 className="text-center font-PoppinsBold text-[22px] pt-[47px]">
          Why Choose farmGhar?
        </h3>

        <p className="font-PoppinsRegular mx-[5%] text-center mt-[32px] text-[14px]">
          farmGhar is the largest online livestock and pet market where you can
          buy and sell anything related to farming. It is a one-stop shop for
          all pet and animal lovers. Our farmGhar app is easy to use and has
          access to vendors all around the globe. We feel it is our
          responsibility to provide the most outstanding web experience for pet
          owners and farmers to foster a better farming experience in Pakistan
          and worldwide.
        </p>

        <div className="mt-[-14px] py-[67px]  flex flex-col  lg-[926px]:flex-row sm:justify-center items-center relative top-[2px] space-y-4 lg-[926px]:space-y-0 ">
          {items.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  " w-[275px] sm-[500px]:w-[450px] lg-[926px]:w-[279px] mx-auto mt-[27px] sm:mt-0 h-[263px] flex items-center rounded-[15px] text-white " +
                  item.background
                }
              >
                <div className=" flex  flex-col pl-[24.34px]">
                  <div>{item.icon}</div>
                  <div className="pt-[13.91px] font-PoppinsBold text-[18px]">
                    {item.title}
                  </div>
                  <div className="pt-[14.91px] font-PoppinsRegular text-[14px] text-justify pr-6">
                    {item.description}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
