import { useNavigate } from "react-router-dom";

export default function RenderServicesCard({ articles }) {
  const navigate = useNavigate();
  const getShortName = (name) => {
    let str;

    if (name?.length > 14) {
      str = name?.substring(0, 14) + "...";
    } else {
      str = name ?? "";
    }
    return str;
  };

  return articles.map((item, index) => (
    <button
      key={index}
      onClick={() => {
        navigate(item?.slug ?? item?.slug, {
          state: {
            ad_id: item?.service_id,
          },
        });
      }}
    >
      <div
        // commented because we are not using ads for now
        //       className={`
        // rounded-[13px] border border-[#DBDED9] md:w-[214px]  lg:w-[260px] h-[310px]
        // `}
        className={`
rounded-[13px] border border-[#DBDED9]  w-[260px] h-[410px]
`}
      >
        <div className={"rounded-[8px] mt-[6.77px] mx-[5.95px] "}>
          <div className={"relative h-[360.49px]"}>
            <img
              src={item?.detail_image && item?.detail_image}
              className={`  rounded-[9px] w-full h-full
               `}
              alt={item?.name && item?.name}
            />
          </div>
        </div>
        <div className="m-[12.51px] flex justify-between">
          {/* for Blog Card  */}

          <h2 className="text-[16px] font-PoppinsBold">
            {getShortName(item?.name)}
          </h2>

          <p className=" text-[12px]  text-Primary sm:text-[14px] lg:text-[16px] font-PoppinsBold underline ">
            More Info
          </p>
        </div>
      </div>
    </button>
  ));
}
