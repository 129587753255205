import { Outlet, Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../Context/AuthContext";

const PublicRoute = () => {
  const { isAuth } = useContext(AuthContext);
  if (isAuth) return <Navigate to="/account/profile" />;
  return <Outlet />;
};
export default PublicRoute;
