import { Link } from "react-router-dom";
export default function LatestPosts({ BlogsPosts, CurrentPostId }) {
  const getShortName = (name) => {
    let str;

    if (name?.length > 14) {
      str = name?.substring(0, 25) + "...";
    } else {
      str = name ?? "";
    }
    return str;
  };
  //get the latest 5 posts excluding the current post

  const latestPosts = BlogsPosts.filter(
    (post) => String(post?.id) !== String(CurrentPostId)
  ).slice(0, 5);

  return (
    latestPosts.length > 0 && (
      <div className="w-[249px] max-h-[407px]  block mx-auto mb-[24px]">
        <p className="font-PoppinsBold pb-4 text-[18px]">Latest Posts</p>
        <div className="space-y-3">
          {latestPosts.map((post) => (
            <Link key={post?.id} to={`/blogs/${post?.slug}`}>
              <div className="flex space-x-2 items-end my-4">
                <div className="w-16 h-16 ">
                  <img
                    src={post?.image}
                    className="rounded-[6px] w-full h-full"
                    alt={post?.image_alt_tag && post?.image_alt_tag}
                    title={post?.image_title && post?.image_title}
                  />
                </div>

                <h3>{getShortName(post?.title)} </h3>
              </div>
            </Link>
          ))}
        </div>
      </div>
    )
  );
}
