import { useState } from "react";

export default function QuantitySelector({
  id,
  quantity,
  updateQuantity,
  max,
  setToastMessage,
  handleShowToast,
}) {
  const [value, setValue] = useState(quantity);

  const handleMinusClick = () => {
    if (value > 1) {
      const newValue = value - 1;
      setValue(newValue);
      updateQuantity(id, parseInt(newValue));
    }
  };

  const handlePlusClick = () => {
    if (value < max) {
      const newValue = value + 1;
      setValue(newValue);
      updateQuantity(id, parseInt(newValue));
    } else {
      setToastMessage("Maximum quantity is " + max + ".");
      handleShowToast();
    }
  };

  return (
    <div className="flex items-center justify-center">
      <button
        type="button"
        onClick={handleMinusClick}
        title="Remove Item"
        className="rounded-full w-6 h-6  bg-gray-200 text-gray-500 hover:bg-gray-300 hover:text-gray-600 mr-2"
      >
        -
      </button>
      {quantity}
      <button
        type="button"
        title="Add Item"
        onClick={handlePlusClick}
        className="rounded-full w-6 h-6 bg-gray-200 text-gray-500 hover:bg-gray-300 hover:text-gray-600 ml-2"
      >
        +
      </button>
    </div>
  );
}
