import { ReactComponent as AdCallIcon } from "../../assets/icons/AdCall.svg";
import { ReactComponent as AdMessageIcon } from "../../assets/icons/AdMessage.svg";
import { ReactComponent as AdWhatsappIcon } from "../../assets/icons/AdWhatsapp.svg";

export default function BuyStarAdsModalMobile({ data, isLoading }) {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }
  return (
    <div className="-mt-6">
      <img
        src={data?.data?.image}
        alt="buy with payment guarantee"
        className="w-[250px] sm:w-[400px] max-w-[600px] h-[390px] rounded-md"
      />
      <div>
        <h3 className="mt-2">Contact Us</h3>

        <div className="flex space-x-2 items-center  mt-2">
          <a
            href={`https://api.whatsapp.com/send?phone=${
              data?.data?.whatsapp_number && "92" + data?.data?.whatsapp_number
            }`}
            target={"_blank"}
            rel="noreferrer"
            className=" h-[36px] rounded-[8px] border border-[#39342F] flex items-center  p-[2px]"
          >
            <AdWhatsappIcon />
          </a>
          <a
            href={`sms://${
              data?.data?.sms_number && data?.data?.sms_number
            };?&body=I want to buy star Ads, please provide more information.`}
            className="h-[36px] rounded-[8px] border border-[#39342F] flex items-center p-[2px]"
          >
            <AdMessageIcon />
          </a>
          <a
            href={`tel:${data?.data?.phone_number && data?.data?.phone_number}`}
            className=" h-[36px] rounded-[8px] border border-[#39342F] flex items-center p-[2px]"
          >
            <AdCallIcon />
          </a>{" "}
        </div>
      </div>
    </div>
  );
}
