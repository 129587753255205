import BannerTop from "../Components/HomePageComponents/BannerTop";
import CallToActionBottom from "../Components/HomePageComponents/CallToActionBottom";
import UpdateMetaTags from "../Utils/UpdateMetaTags";
import ViewAllAds from "../Components/MyAdsComponents/ViewAllAds";
import RejectedAds from "../Components/MyAdsComponents/RejectedAds";
import ActiveAds from "../Components/MyAdsComponents/ActiveAds";
import DraftAds from "../Components/MyAdsComponents/DraftAds";
import { ReactComponent as StarIcon } from "../assets/icons/star.svg";
import { ReactComponent as VerifiedIcon } from "../assets/icons/verified.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BuyPaidAdsIcon } from "../assets/icons/BuyPaidAds.svg";
import {
  useFetchMyAdsAdsList,
  useFetchMyAdsStats,
  useFetchMyAdsPaidAdBanner,
  useMutateDeleteAd,
  useFetchHomePageCategories,
  useMutateMyAdCreateDone,
} from "../Middlewares/Api";
import AuthContext from "../Context/AuthContext";
import { useContext, useEffect } from "react";
import Modal from "../Components/Modal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import BuyPaidAdsModal from "../Components/MyAdsComponents/BuyPaidAdsModal";
import BuyPaidAdsModalMobile from "../Components/MyAdsComponents/BuyPaidAdsModalMobile";
import AdSuccessfullyPosted from "../Components/PostAdComponents/AdSuccessfullyPosted";
export default function MyAds() {
  const { setIsAuth, setUser } = useContext(AuthContext);
  const [IsAdSuccessfullyPosted, setIsAdSuccessfullyPosted] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const navigate = useNavigate();
  const {
    mutateAsync: createDone,
    isLoading: CreateDoneLoading,
    data: createDoneData,
  } = useMutateMyAdCreateDone();
  const { data: CategoriesData } = useFetchHomePageCategories();
  const [activeTab, setActiveTab] = useState("all");
  const [ShowPaidBanner, setShowPaidBanner] = useState(false);
  const [ShowPaidBannerMobile, setShowPaidBannerMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [DeleteAdId, setDeleteAdId] = useState("");
  const { mutateAsync: deleteAd } = useMutateDeleteAd();
  const { data, isLoading, refetch } = useFetchMyAdsAdsList({
    key: localStorage.getItem("key") ?? "",
  });
  const { data: StatsData, refetch: dataRefetch } = useFetchMyAdsStats({
    key: localStorage.getItem("key") ?? "",
  });
  const { data: PaidBannerdata, isLoading: PaidBannerLoading } =
    useFetchMyAdsPaidAdBanner();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  let tabContent;
  const PostAdSuccessfully = (payl) => {
    const res = createDone(payl);
    Promise.resolve(res).then((res) => {
      if (
        res.web_status_code === 101 ||
        res.web_status_code === 102 ||
        res.web_status_code === 103
      ) {
        localStorage.removeItem("key");
        setIsAuth(false);
        setUser(null);
        navigate("/auth/login");
      }
      if (res.code === 200) {
        setIsAdSuccessfullyPosted(true);
        refetch();
        dataRefetch();
      } else {
        // alert(res.message);
        setShowAlertModal(true);
      }
    });
  };

  if (activeTab === "active") {
    tabContent = (
      <ActiveAds
        data={data?.data ?? []}
        setDeleteAdId={setDeleteAdId}
        setShowModal={setShowModal}
        navigate={navigate}
        CategoriesData={CategoriesData}
        StarIcon={StarIcon}
        VerifiedIcon={VerifiedIcon}
      />
    );
  } else if (activeTab === "draft") {
    tabContent = (
      <DraftAds
        data={data?.data ?? []}
        setDeleteAdId={setDeleteAdId}
        setShowModal={setShowModal}
        navigate={navigate}
        CategoriesData={CategoriesData}
        PostAdSuccessfully={PostAdSuccessfully}
        StarIcon={StarIcon}
        VerifiedIcon={VerifiedIcon}
      />
    );
  } else if (activeTab === "rejected") {
    tabContent = (
      <RejectedAds
        data={data?.data ?? []}
        setDeleteAdId={setDeleteAdId}
        setShowModal={setShowModal}
        navigate={navigate}
        CategoriesData={CategoriesData}
        StarIcon={StarIcon}
        VerifiedIcon={VerifiedIcon}
      />
    );
  } else if (activeTab === "all") {
    tabContent = (
      <ViewAllAds
        data={data?.data ?? []}
        setDeleteAdId={setDeleteAdId}
        setShowModal={setShowModal}
        navigate={navigate}
        CategoriesData={CategoriesData}
        PostAdSuccessfully={PostAdSuccessfully}
        StarIcon={StarIcon}
        VerifiedIcon={VerifiedIcon}
      />
    );
  }

  useEffect(() => {
    if (
      data?.web_status_code === 101 ||
      data?.web_status_code === 102 ||
      data?.web_status_code === 103 ||
      StatsData?.web_status_code === 101 ||
      StatsData?.web_status_code === 102 ||
      StatsData?.web_status_code === 103
    ) {
      localStorage.removeItem("key");
      setIsAuth(false);
      setUser(null);
      navigate("/auth/login");
    }
    refetch();
    dataRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (IsAdSuccessfullyPosted) {
    return (
      <AdSuccessfullyPosted
        FromMyAdsPage={true}
        ad_id={sessionStorage?.getItem("ad_post_id")}
      />
    );
  }
  return (
    <>
      {showAlertModal && (
        <Modal>
          <div id="modalInner">
            <div className="w-full h-[480px] max-w-[600px] bg-[#fff] p-3 md:p-[20px] rounded-[13px] my-0 mx-auto mt-2 md:mt-4 relative">
              <div className=" absolute top-[-13px] right-[4px] mt-3">
                <button
                  className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                  onClick={() => {
                    setShowAlertModal(false);
                  }}
                >
                  <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                </button>
              </div>
              <div className="flex flex-col items-center gap-2 mt-28">
                <p className="mb-8 text-center">{createDoneData?.message}</p>

                <button
                  className="bg-Primary text-white font-PoppinsMedium text-[14px] max-w-[300] w-full p-3 rounded-lg"
                  type="button"
                  onClick={() => {
                    navigate("/services/paid-ads", {
                      state: {
                        fromMyAds: true,
                      },
                    });
                  }}
                >
                  Buy Paid Ads
                </button>

                <button
                  className="bg-Primary text-white font-PoppinsMedium text-[14px] max-w-[300] w-full p-3 rounded-lg"
                  type="button"
                  onClick={() => {
                    navigate("/account/my-ads");
                  }}
                >
                  Go to My Ads Page
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showModal ? (
        <Modal>
          <div id="modalInner">
            <div
              id="modalContent"
              style={{ height: "auto" }}
              className="font-PoppinsRegular relative "
            >
              <div className="w-[250px] sm:w-[500px] max-w-[600px] h-[230px] mx-auto flex items-center mt-2 flex-col space-y-3">
                <p className="text-[18px] font-PoppinsBold text-center md:text-left">
                  Delete Ad
                </p>
                <p className="pt-8 text-center">
                  Are you sure you want to delete your ad?
                </p>
                <div className="flex  justify-center md:justify-start space-x-6">
                  {/* delete confirm button */}
                  <button
                    className="bg-[#FF2020] text-white font-PoppinsBold text-[14px] px-4 py-2 rounded-md mr-2"
                    onClick={(e) => {
                      e.target.innerText = "Deleting...";
                      e.target.disabled = true;
                      deleteAd({
                        key: localStorage.getItem("key") ?? "",
                        ad_id: DeleteAdId,
                        ad_status: "delete",
                      }).then((res) => {
                        if (
                          res.web_status_code === 101 ||
                          res.web_status_code === 102 ||
                          res.web_status_code === 103
                        ) {
                          localStorage.removeItem("key");
                          setIsAuth(false);
                          setUser(null);
                          navigate("/auth/login");
                        }
                        if (res?.code === 200) {
                          refetch();
                          dataRefetch();
                          setShowModal(false);
                        }
                      });
                    }}
                  >
                    Delete
                  </button>
                  {/* cancel button */}
                  <button
                    className="bg-gray-100 text-gray-900 font-PoppinsBold text-[14px] px-4 py-2 rounded-md"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className=" absolute top-[-13px] right-[4px] mt-3">
                <button
                  className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                  onClick={() => setShowModal(false)}
                >
                  <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}

      {ShowPaidBanner ? (
        <Modal>
          <div id="modalInner">
            <div className="w-full h-[480px] bg-[#fff] p-[20px] rounded-[13px] my-0 mx-auto -mt-[120px]  relative">
              <div className="w-[270px]  sm:w-[500px] max-w-[600px] h-[350px] mx-auto flex items-center flex-col mt-2 font-PoppinsRegular ">
                <BuyPaidAdsModal
                  data={PaidBannerdata}
                  isLoading={PaidBannerLoading}
                />
              </div>
              <div className=" absolute top-[-13px] right-[4px] mt-3">
                <button
                  className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                  onClick={() => {
                    setShowPaidBanner(false);
                  }}
                >
                  <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      {ShowPaidBannerMobile ? (
        <Modal>
          <div id="modalInner">
            <div className="w-full h-[480px] bg-[#fff] p-[20px] rounded-[13px] my-0 mx-auto -mt-[120px]  relative">
              <div className="w-[270px]  sm:w-[500px] max-w-[600px] h-[350px] mx-auto flex items-center flex-col mt-2 font-PoppinsRegular ">
                <BuyPaidAdsModalMobile
                  data={PaidBannerdata}
                  isLoading={PaidBannerLoading}
                />
              </div>
              <div className=" absolute top-[-13px] right-[4px] mt-3">
                <button
                  className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                  onClick={() => {
                    setShowPaidBannerMobile(false);
                  }}
                >
                  <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <UpdateMetaTags data={{ title: "My Ads" }} />
        <div className="pt-3 ">
          <BannerTop />
        </div>
        <h3 className="font-PoppinsBold">My Ads</h3>
        <div className="flex items-center space-x-4 sm:space-x-10 sm:text-[16px] text-[12px]">
          <div>
            Free Ads: {StatsData?.data?.total_free_ads_used}/
            {StatsData?.data?.total_free_ads}
          </div>
          <div className="ml-4">
            Paid Ads: {StatsData?.data?.total_paid_ads_used}/
            {StatsData?.data?.total_paid_ads}
          </div>
          <button
            className="ml-4 text-Primary font-PoppinsBold block sm:hidden"
            type="button"
            onClick={() => {
              // setShowPaidBanner(false);
              // setShowPaidBannerMobile(true);
              navigate("/services/paid-ads");
            }}
          >
            Buy Paid Ads
          </button>
          <button
            className="ml-4 text-Primary font-PoppinsBold hidden sm:block"
            type="button"
            onClick={() => {
              // setShowPaidBanner(true);
              // setShowPaidBannerMobile(false);
              navigate("/services/paid-ads", {
                state: {
                  fromMyAds: true,
                },
              });
            }}
          >
            Buy Paid Ads
          </button>
        </div>
        {/* Create Tabs for Active Ads, Draft Ads, Rejected Ads and View All Ads */}
        <div className="flex border-b-2 pb-2 border-[#B6B6B6] text-[8px] sm:text-[16px] ">
          <button
            className={`px-4 py-2  rounded-l-md ${
              activeTab === "all"
                ? "text-Primary border-b-2 font-PoppinsBold border-Primary pb-2"
                : ""
            }`}
            onClick={() => handleTabClick("all")}
          >
            View All ({data?.data?.length ?? 0})
          </button>
          <button
            className={`px-4 py-2 ${
              activeTab === "active"
                ? "text-Primary border-b-2 font-PoppinsBold border-Primary pb-2"
                : ""
            }`}
            onClick={() => handleTabClick("active")}
          >
            Active (
            {data?.data?.filter((item) => item.review_status === "success")
              .length ?? 0}
            )
          </button>

          <button
            className={`px-4 py-2 ${
              activeTab === "rejected"
                ? "text-Primary border-b-2 font-PoppinsBold border-Primary pb-2"
                : ""
            }`}
            onClick={() => handleTabClick("rejected")}
          >
            Rejected Ads (
            {data?.data?.filter((item) => item.review_status === "rejected")
              .length ?? 0}
            )
          </button>
          <button
            className={`px-4 py-2 rounded-r-md ${
              activeTab === "draft"
                ? "text-Primary border-b-2 font-PoppinsBold border-Primary pb-2"
                : ""
            }`}
            onClick={() => handleTabClick("draft")}
          >
            Draft Ads (
            {data?.data?.filter((item) => item.ad_status === "draft").length ??
              0}
            )
          </button>
        </div>
        {isLoading || CreateDoneLoading ? (
          <div className="flex items-center justify-center mt-20">
            <div className="spinner"></div>
          </div>
        ) : (
          <div className="flex justify-center">
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
              <div className="h-[340px] w-[280px] sm:w-[344px] sm:h-[390px] bg-[#EFA922] bg-opacity-[0.15] rounded-[8px] py-4">
                <div className="flex flex-col items-center justify-center h-full space-y-4">
                  <BuyPaidAdsIcon className="w-[100px] h-[100px] " />
                  <p className="py-4">Heavy discount on Packages</p>
                  <button
                    className="bg-[#EFA922] text-white rounded-[8px] px-4 py-2 hidden sm:block"
                    type="button"
                    onClick={() => {
                      setShowPaidBanner(true);
                      setShowPaidBannerMobile(false);
                    }}
                  >
                    Buy Paid Ads Subscription
                  </button>
                  <button
                    className="bg-[#EFA922] text-white rounded-[8px] px-4 py-2 block sm:hidden"
                    type="button"
                    onClick={() => {
                      setShowPaidBanner(false);
                      setShowPaidBannerMobile(true);
                    }}
                  >
                    Buy Paid Ads Subscription
                  </button>
                </div>
              </div>
              {tabContent}
            </div>
          </div>
        )}

        <CallToActionBottom />
      </div>
    </>
  );
}
