import { ReactComponent as LocationIcon } from "../../../assets/icons/location.svg";
import { getCurrentLocation, getAddress } from "../../../Utils/GoogleMap";
import { useContext } from "react";
import LocationContext from "../../../Context/LocationContext";
import FilterAdsContext from "../../../Context/FilterAdsContext";
import PreserveFormikFilterContext from "../../../Context/PreserveFormikFilterContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export const getLocality = async (latitude, longitude, setLocationStates) => {
  if (latitude && longitude) {
    const FromHeader = true;
    const addressComponents = await getAddress(
      parseFloat(latitude),
      parseFloat(longitude),
      FromHeader
    );
    let locality = null;
    for (let i = 0; i < addressComponents.length; i++) {
      if (addressComponents[i].types[0] === "locality") {
        locality = addressComponents[i].long_name;
        break;
      }
    }

    setLocationStates((prev) => ({
      ...prev,
      selectedPlace: null,
      currentLocation: null,
      locality: locality,
      latitude: latitude,
      longitude: longitude,
      lat2: latitude,
      lng2: longitude,
    }));
  }
};
export default function LocationFilter() {
  const { locationStates, setLocationStates } = useContext(LocationContext);
  const { setFormikInitialValues } = useContext(PreserveFormikFilterContext);
  const { setFilterAds } = useContext(FilterAdsContext);
  const navigate = useNavigate();
  const getLatLongNavigate = async () => {
    const position = await getCurrentLocation();
    const { latitude, longitude } = position.coords;
    if (latitude !== "" && longitude !== "") {
      setFilterAds((prev) => ({
        ...prev,
        page: 1,
        Province: undefined,
        district: undefined,
        Tehsil: undefined,
      }));
      setFormikInitialValues((prev) => ({
        ...prev,
        Province: undefined,
        District: undefined,
        Tehsil: undefined,
      }));
      setLocationStates((prev) => ({
        ...prev,
        address: "",
        selectedPlace: null,
        currentLocation: null,
        locality: locationStates.locality ?? null,
        latitude: latitude,
        longitude: longitude,
        lat2: latitude,
        lng2: longitude,
        isViewNearby: true,
      }));
      navigate(`/all-categories`);
    }
  };
  useEffect(() => {
    let tout = null;
    if (locationStates.latitude && locationStates.longitude) {
      tout = setTimeout(() => {
        getLocality(
          locationStates.latitude,
          locationStates.longitude,
          setLocationStates
        );
      }, 1000);
    } else {
      setLocationStates((prev) => ({
        ...prev,
        selectedPlace: null,
        currentLocation: null,
        locality: null,
        // isViewNearby: false,
      }));
    }
    return () => {
      clearTimeout(tout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationStates.lat2, locationStates.lng2]);
  return (
    <button onClick={() => getLatLongNavigate()}>
      <div className="flex items-center space-x-2 justify-center">
        {" "}
        <LocationIcon />
        <span className="text-[14px] text-TextColor font-PoppinsBold">
          {locationStates.locality ?? "View Nearby Ads"}
        </span>
      </div>
    </button>
  );
}
