import Slider from "react-slick";
const settings = {
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: false,

  infinite: true,
  className: "slider variable-width",
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1215,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        centerMode: false,
      },
    },
    {
      breakpoint: 1214,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
        centerMode: false,
      },
    },
    {
      breakpoint: 920,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
        centerMode: false,
      },
    },
    {
      breakpoint: 615,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: false,
        infinite: true,

        centerMode: false,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: true,

        centerMode: false,
      },
    },
  ],
};

export default function BlogsLoader() {
  return (
    <div className="pt-3 relative animate-pulse">
      <div className="flex justify-between mt-[21px] mb-[28px] ">
        <p className="font-PoppinsBold md:text-[22px] w-6 h-2 bg-gray-200"></p>
        <p className="font-PoppinsBold text-[18px] text-Primary mr-[15px]  w-6 h-2 bg-gray-200"></p>
      </div>
      <Slider {...settings}>
        {[1, 2, 3, 4, 5, 6].map((index) => {
          return (
            <div key={index} style={{ width: 280 }}>
              <div
                className="
                        rounded-[13px] border border-[#DBDED9]  bg-white   w-[270px]  xsm:w-[270px] h-[325px]
                        
                        "
              >
                <div className="inline-block w-full h-full">
                  <div className="rounded-[8px] mt-[6.77px] mx-[5.95px] xsm:w-[259.48px] h-[180.49px]">
                    <div className="relative xsm:w-[259.48px] h-[180.49px]">
                      <p className=" rounded-[9px] w-full h-full object-cover   bg-gray-200"></p>
                    </div>
                  </div>
                  <div className="m-[12.51px]">
                    <div className="flex justify-between">
                      <p className="text-[18px] font-PoppinsBold bg-gray-200 w-[260px] h-2"></p>
                    </div>
                    <div className="text-[12px] text-justify">
                      <p className=" bg-gray-200  w-[240px] h-10 mt-1"></p>
                    </div>
                    <div className="flex justify-between mt-[10px]">
                      <p className="text-Primary font-[18px] font-PoppinsBold  w-8 h-2 bg-gray-200"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
