import { ReactComponent as ImpPostAdTick } from "../../assets/icons/ImpPostAdTick.svg";
export function SelectCategory({
  dataCategories,
  formik,
  setSelectedFiles,
  setPreviewImages,
  dataCategoriesLoading,
  RemoveAllFilesFromImageUploader,
}) {
  return (
    <div className="flex flex-col  px-5  py-5">
      <h3 className="text-left font-PoppinsBold">Select Category</h3>
      {formik.touched.SelectedCategory && formik.errors.SelectedCategory ? (
        <span className="text-red-600  text-[11px] ">
          {formik.errors.SelectedCategory}
        </span>
      ) : null}
      {dataCategoriesLoading ? (
        <div className="flex justify-center items-center h-[500px]">
          <div className="flex flex-col items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-green-500"></div>
            <p className="font-PoppinsBold text-[22px] text-center mt-5">
              Loading...
            </p>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 py-2">
          {dataCategories?.map((item) => {
            return String(item.id) === "1" ||
              String(item.id) === "9999" ||
              String(item.id) === "9991" ? null : (
              <button
                key={item.id}
                onClick={(e) => {
                  e.preventDefault();
                  formik.resetForm({
                    values: {
                      ...formik.values,
                      SelectedCategory: "",
                      //Livestock
                      Category: "",
                      Breed: "",
                      Sex: "",
                      Quantity: 1,
                      AgeYears: "",
                      AgeMonths: "",
                      LiveWeight: "",
                      AnimalDescription: "",
                      Price: "",
                      PriceType: "",
                      Teeth: "",
                      DailyMilkQuantity: "",
                      Pregnant: "",
                      PregnancyCount: "",
                      Castrated: "",
                      Pictures: [],
                      MinLiveWeight: "",
                      MaxLiveWeight: "",
                      MaxAgeYears: "",
                      MaxAgeMonths: "",
                      MinAgeYears: "",
                      MinAgeMonths: "",
                      MaxTeeth: "",
                      MinTeeth: "",
                      //HenBirdPetOther
                      AdTitle: "",
                      BreedName: "",

                      // ProductsServicesEquiqPetFood
                      QuantityUnit: "",
                      PriceType2: "",
                    },
                  });
                  RemoveAllFilesFromImageUploader();
                  setSelectedFiles([]);
                  setPreviewImages([]);
                  formik.setFieldValue("SelectedCategory", item.id);
                }}
                className={`border ${
                  String(formik.values.SelectedCategory) === String(item.id)
                    ? "border-Primary"
                    : "border-gray-200"
                } bg-white flex justify-between items-center p-4 rounded-lg`}
              >
                <div className="flex items-center">
                  <img
                    src={`${item.icon}`}
                    alt="Livestock"
                    className="rounded-lg w-16 h-16"
                  />
                  <p className="ml-2 text-[14px] sm:text-[16px]">{item.name}</p>
                </div>
                {String(formik.values.SelectedCategory) === String(item.id) && (
                  <ImpPostAdTick />
                )}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}
