import LogoCut from "../../assets/icons/LogoCut.svg";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import BannerTop from "../../Components/HomePageComponents/BannerTop";
import { ReactComponent as AdSuccessfullyPostedIcon } from "../../assets/icons/AdSuccessfullyPostedIcon.svg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { ReactComponent as BankIcon } from "../../assets/icons/Bank.svg";
export default function QurbaniOrderConfirmed() {
  const location = useLocation();
  const { data, totalPrice, formik, pickUpAddress, tracking_number } =
    location.state;
  let BookingAmount = 0;
  let DeliveryPrice = 0;

  useEffect(() => {
    if (process.env.REACT_APP_SERVER_TYPE === "production") {
      // Create a script element
      const script = document.createElement("script");
      script.innerHTML = `gtag('event', 'conversion', { 'send_to': 'AW-469522917/_XuPCIOEkKUYEOWz8d8B', 'transaction_id': ${
        tracking_number ?? ""
      } });`;
      script.setAttribute("id", "conversion-script");

      // Append the script to the head of the document
      document.head.appendChild(script);
    }
    !data && window.location.replace("/qurbani");
    // Clean up by removing the script when the component unmounts
    return () => {
      const scriptToRemove = document.getElementById("conversion-script");
      if (scriptToRemove) {
        document.head.removeChild(scriptToRemove);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative min-w-screen min-h-screen bg-[#F4F4F4] font-PoppinsRegular">
      <img
        src={LogoCut}
        alt="Logo"
        className="absolute bottom-0 right-0 z-0 "
      />
      <div className="bg-white">
        <div className="font-PoppinsRegular max-w-[1280px] mx-auto">
          <div className="flex justify-between items-center  px-1 py-3">
            <Link to="/" className=" h-8 w-28">
              <Logo />
            </Link>
            <Link
              to="/"
              className="w-[110px]  text-center sm:w-[203px] flex-shrink h-[42px] rounded-[50px] bg-[#EFA922] flex justify-center items-center text-white text-[14px]"
            >
              Go Back To homepage
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-[#F4F4F4] relative font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8  mx-auto mt-[54px] md:mt-0 space-y-4">
        <h2 className="font-PoppinsBold text-center mt-4">
          Book Your Qurbani Package
        </h2>
        <div className="pt-3 ">
          <BannerTop />
        </div>
        <section className=" pb-10 w-full mr-[25px] flex items-center flex-col  bg-white rounded-[15px] p-2 pt-7 space-y-4">
          <AdSuccessfullyPostedIcon />
          <h3 className="font-PoppinsBold text-center">
            Thank You For Placing Order.
          </h3>
          <p className="text-center text-[14px] text-TextColor pt-2">
            Your order will be confirmed after payment.
          </p>
          <p className="text-center text-[14px] text-TextColor pt-2">
            Order details has been sent to your mobile number.
          </p>
          <p>
            Tracking ID{" "}
            <span className="text-Primary font-PoppinsBold">
              {tracking_number}
            </span>
          </p>
          <div className="border-2 border-gray-200 w-[95%] sm:w-[80%] h-full rounded-[13px] p-4">
            {data?.map((item, index) => {
              if (
                pickUpAddress?.data2[0]?.qurbani_advance_payment === "100" ||
                formik.values.PaymentOption === "FullPayment"
              ) {
                BookingAmount += item.price * item.quantity;
                // AmountLeft += item.price * item.quantity;
              } else {
                BookingAmount += (item.price * item.quantity) / 5;
                // AmountLeft += item.price * item.quantity - BookingAmount;
              }

              if (
                pickUpAddress?.data2[0]?.is_delivery === "1" &&
                formik.values.DeliveryOption === "HomeDelivery"
              ) {
                DeliveryPrice += item.deliveryPrice * item.quantity;
              }
              return (
                <div key={index} className="flex justify-between  my-4">
                  <div className="flex space-x-4 items-center">
                    <img
                      className={`w-[75px] h-[75px] rounded-[10px]`}
                      src={item?.image}
                      alt={""}
                    />
                    <div className="space-y-4">
                      <p className="text-sm">{item.name}</p>
                      <p className="font-PoppinsBold">
                        Rs {item.price}{" "}
                        <span className="text-[#B6B6B6] text-xs pl-2">
                          x{item.quantity}
                        </span>
                      </p>
                    </div>
                  </div>
                  <p className="font-PoppinsBold text-Primary pt-4">
                    Rs {item.price}
                  </p>
                </div>
              );
            })}
            <div className="space-y-4">
              <section className="flex justify-between items-center">
                <div className="text-base">Total Order Value</div>
                <div className="font-PoppinsBold text-base">
                  RS {totalPrice}
                </div>
              </section>
              {/* <section className="flex justify-between items-center">
        <div className="text-base">Delivery Charges</div>
        <div className="font-PoppinsBold text-base">RS {item.deliveryPrice}</div>
      </section> */}
              <section className="flex justify-between items-center">
                <div className="text-base">Booking Amount</div>
                <div className="font-PoppinsBold text-base">
                  RS {BookingAmount}
                </div>
              </section>
              {pickUpAddress?.data2[0]?.is_delivery === "1" && (
                <section className="flex justify-between items-center">
                  <div className="text-base">Delivery Charges</div>
                  <div className="font-PoppinsBold text-base">
                    RS {DeliveryPrice}
                  </div>
                </section>
              )}
              {/* <section className="flex justify-between items-center">
                <div className="text-base">Balance Amount</div>
                <div className="font-PoppinsBold text-base">
                  RS{" "}
                  {formik?.values?.PaymentOption === "FullPayment"
                    ? 0
                    : totalPrice - BookingAmount + DeliveryPrice}
                </div>
              </section> */}
            </div>
            <section className="pb-4 flex justify-between items-center mt-4 border-t-2 border-gray-200 pt-2">
              <div className="text-base font-PoppinsBold">Total Payable</div>
              <div className="font-PoppinsBold text-base">
                RS{" "}
                {pickUpAddress?.data2[0]?.is_delivery === "1" &&
                formik?.values?.DeliveryOption === "HomeDelivery" &&
                (pickUpAddress?.data2[0]?.qurbani_advance_payment === "100" ||
                  formik?.values?.PaymentOption === "FullPayment")
                  ? BookingAmount + DeliveryPrice
                  : BookingAmount}
              </div>
            </section>
            <div className="col-span-1 mt-6">
              <div className="flex items-center mb-4">
                <BankIcon className="" />
                <h3 className="font-PoppinsBold text-lg mt-2 pl-4 text-center">
                  Bank Account
                </h3>
              </div>
              <div className="flex  md:pr-4 items-center justify-between">
                <h3 className="font-PoppinsBold text-[13px]">Bank Name</h3>
                <p className="font-PoppinsRegular text-[12px]">MCB Bank</p>
              </div>
              <div className="flex md:pr-4 items-center justify-between">
                <h3 className="font-PoppinsBold text-[13px]">Account Title</h3>
                <p className="font-PoppinsRegular text-[12px]">
                  Farmghar Private Limited
                </p>
              </div>
              <div className="flex  md:pr-4 items-center justify-between">
                <h3 className="font-PoppinsBold text-[13px]">Account IBAN</h3>
                <p className="font-PoppinsRegular text-[12px]">
                  PK66MUCB1206098601010134
                </p>
              </div>
              <div className="flex  md:pr-4 items-center justify-between">
                <h3 className="font-PoppinsBold text-[13px]">Account Number</h3>
                <p className="font-PoppinsRegular text-[12px]">
                  1206098601010134
                </p>
              </div>
              <div className="flex  md:pr-4 items-center justify-between">
                <h3 className="font-PoppinsBold text-[13px]">Branch Code</h3>
                <p className="font-PoppinsRegular text-[12px]">0477</p>
              </div>
            </div>
          </div>
        </section>
        <p className="text-center text-[14px] text-TextColor pt-2">
          Copyright {new Date().getFullYear()} farmGhar. All Rights Reserved
        </p>
      </div>
    </div>
  );
}
