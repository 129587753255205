export const Style = {
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? "#3ed400"
        : isFocused
        ? "rgb(241 245 249)"
        : null,
    };
  },

  control: (base) => ({
    ...base,

    border: "2px solid rgba(0, 0, 0, 0.16)",
    borderRadius: "10px",
    boxShadow: "#007ec5",

    // This line disable the blue border

    "&:hover": {
      border: "2px solid rgb(209 213 219)",
    },
  }),
};
