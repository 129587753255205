import { createContext } from "react";
import { useState, useEffect } from "react";
const BlogsContext = createContext();

export default BlogsContext;

export const BlogsContextProvider = ({ children }) => {
  const [BlogsStates, setBlogsStates] = useState({
    page: 1,
    isLatestPost: false,
  });
  useEffect(() => {}, [BlogsStates]);

  return (
    <BlogsContext.Provider value={{ BlogsStates, setBlogsStates }}>
      {children}
    </BlogsContext.Provider>
  );
};
