import UpdateMetaTags from "../Utils/UpdateMetaTags";
import image1 from "../assets/images/image1.webp";
import image3 from "../assets/images/image3.webp";
import image4 from "../assets/images/image4.webp";
import image5 from "../assets/images/image5.webp";
import image6 from "../assets/images/image6.webp";
import SquareGoogleAd from "../Utils/SquareGoogleAd";
import { useFetchQurbaniSupportInfo } from "../Middlewares/Api";

import { ReactComponent as AdCallIcon } from "../assets/icons/AdCall.svg";
import { ReactComponent as AdWhatsappIcon } from "../assets/icons/AdWhatsapp.svg";
import { useEffect, useState } from "react";
import BannerTop from "../Components/HomePageComponents/BannerTop";
import CallToActionBottom from "../Components/HomePageComponents/CallToActionBottom";
import Toast from "../Utils/Toast";
import { useFetchQurbaniSEOContent } from "../Middlewares/Api";

import { useContext } from "react";
import { CartContext } from "../Context/CartContext";
const data = [
  {
    slug: "/products/cow-for-sale-in-pakistan",
    image: `${image1}`,
    heading:
      "Get the Best Cow Price in Pakistan and Around the World Through farmGhar",
    content: `
        <div>
<p>farmGhar is the leading online livestock market for <b>Pakistani cow sale </b>. Our website has categories for all species of animals, including cattle, cows, goats, and sheep.</p>
<p>We simplify for customers to identify the best animals for sale and purchase. Whether you're looking for commercial cattle, seed stock, Pakistani cows, grazing goats, or nearly any other livestock species, our free farmGhar app is sure to have what you're looking for.</p>
<p><br></p>
<p> <b>Best Cows for Sale in Pakistan  </b> <b>and Globally </b></p>
<p>The advantages of online cattle sales are numerous for both the vendor and the buyer. farmGhar now offers this viewing and bidding option to make the purchase more user-friendly and to broaden its customer base.</p>
<p>You can get the best <b>cow price in Pakistan </b>while living anywhere in the world only through farmGhar as it is the largest online maweshi mandi in Pakistan.</p>
<p><br></p>
<p> <b>Advertise with Us and Expand your BusinessGlobally </b></p>
<p>Marketing and selling cattle were never so simple before the internet, but it is now easier than ever. Everyone is always connected to the internet and likes to purchase online. As a result, all vendors are flocking to the digital realm to grow their businesses and earn more money. Are you interested in the <b>Pakistan cow sale </b>? farmGhar encourages all livestock owners to visit and publish cattle classified advertising through their free farmGhar app.</p>
<p>We make it extremely simple to advertise your <b>Cows for Sale in Pakistan and globally </b>and post their classifieds for free. We can connect you with many cow buyers all around Pakistan and even internationally as well, increasing your earnings.</p>
<p><br></p>
<p> <b>The Ultimate farmGhar Services for Cow Sale in Pakistan and Around the World </b></p>
<p>farmGhar is all about customer service; our staff serves consumers throughout Pakistan and international consumers as well 24 hours a day, seven days a week. Our services range from animal verification to placing advertisements on our app and ensuring that our customers get the best <b>cow for sale in Pakistan </b>at the best suitable price anywhere in the world.</p>
<p><br></p>
<p>Our employees are highly competent, well-trained, and respectful. We guarantee that we will not sit silently until our customer's problem is resolved since we feel that a customer's problem is our problem.</p>
<p>Some of our top-notch services include:</p>
<ul>
<li >Biggest online maweshi mandi in Pakistan</li>
<li >Verification of your cow by our veterinary doctor</li>
<li >Posting of Ads free of cost</li>
<li >Best quality cattle in Pakistan</li>
<li >Hassle-free service</li>
<li >Best Fed cows in Pakistan</li>
<li >Option of getting your ads featured for quick selling and buying of cows</li>
<li >100% guarantee of services</li>
<li >Best customer service in Pakistan and around the world</li>
</ul>
<p><br>So, what are you waiting for? Want to put up your <b>cow for sale in Pakistan and globally </b>? No worries! farmGhar is the only online platform which can make the sale and purchase of your cows extremely easy. Grab your phone, download our free app, sell your animal at the best <b>cow price in Pakistan </b>, or visit our website for more details.<br></p></div>
        `,
    title:
      "Get the Best Cow Price in Pakistan and Around the World Through farmGh – farmGhar",
    description:
      "We make it extremely simple to advertise your Cows for Sale in Pakistan and globally and post their classifieds for free.",
    keywords:
      "Get the Best Cow Price in Pakistan and Around the World Through farmGhar,farmGhar,farmghar.com",
  },
  {
    slug: "/collections/cow-farmghar-onlinequrbani-cow",
    image: `${image4}`,
    heading: "farmGhar Online Qurbani of Cow",
    content: `
        <div><p >You don't need to face the hassle of going to  <strong >cow mandis </strong>anymore because farmGhar is bringing the biggest mawehshi mandi to your doorstep. farmGhar specializes in the best quality Qurbani cows available in the market, verified by our exceptional veterinary doctors.<br></p>
<p >We handle  <strong >the qurbani of cows </strong>in the most hygienic way possible, utilizing only sterilized stainless steel equipment on meat. The most skilled and experienced butchers chop the meat. The specialists are appointed from various places to provide you with the most incredible experience you deserve.</p>
<h2 >Say Goodbye to Inconvenience and be Hassle-Free with farmGhar</h2>
<p >  <strong >Qurbani of cow </strong>was never this easy before! Our team ensures that you get the best quality animals at your doorstep. Convenience is given fresh through an efficient and hygienic method. All farmGhar animals are obtained, produced, sold, and maintained under vendor representation and warranties. Our primary goal is to relieve the consumer of the trouble of wandering and annoyance caused in the cattle market by various factors. You don't have to struggle and walk around the animal market anymore.</p>
<p >The services offered by farmGhar include:</p>
<p >A picture of the sacrificial animal and information such as colour, age, weight, and price.</p>
<ul >
<li  >A picture of the sacrificial animal and information such as colour, age, weight, and price. Verification of animals in the  <strong >cow mandi. </strong>Online purchase is possible via online bank transfers.</li>
<li  >Animals are brought to your door for free.</li>
<li  >Most reliable service of  <strong >cow qurbani in Pakistan. </strong>
</li>
<li  >Meat can be donated to charity or delivered to your home.</li>
<li  >Sacrificial procedures that are Shariah-compliant.</li>
</ul>
<h2 >Download the farmGhar App and Get Hygienic Meat at Your Doorstep!</h2>
<p >We also provide in-house  <strong >qurbani of cow </strong>services keeping in mind all the hygiene protocols. farmGhar has the best butchers in town who will make sure to satisfy your needs. We are subject to the same severe veterinary and sanitary standards as the factories, even though we operate on a far smaller scale. As a result, we can ensure that the slaughter procedure follows all applicable hygienic requirements. We endeavour to perform to the most excellent standards to provide our clients with high-quality, traceable services.</p>
<p >We provide verified animals on Eid al-Adha who are well taken care of. We also offer transportation of the animals straight to your house, and if you desire, we can also get the animal sacrificed and send the meat to your home. We take each stage of the Qurbani process very seriously and make every effort to guarantee that it is carried out following the Quran and Sunnah. Our team ensures that the customers don't have to face the hassle of going to mandis and that the meat delivered to their doorstep is fresh and hygienically packed.</p>
<p >Hurry up and download the farmGhar app for the best online qurbani services in Pakistan!</p></div>`,
    title: "farmGhar Online Qurbani of Cow | farmGhar Cow Mandi Lahore",
    description:
      "farmGhar is the biggest online livestock market in Pakistan. It was conceptualized to provide farmers with the skills they need while buying and selling animals for qurbani of cow in Pakistan. Contact Us At 0304-111-0434",
    keywords:
      "Your One-Stop Shop for Online Qurbani of Cow in Pakistan!,farmGhar,farmghar.com",
  },
  {
    slug: "/products/qurbani-full-cow-farmghar",
    image: `${image3}`,
    heading: "Cow Hissa Package",
    content: `
    <div>
    <p>Performing Qurbani has always been challenging. Experience the convenience and spirituality of Qurbani with farmGhar cow hissa package. farmGhar offers competitive prices at only Rs: 27,000 and Rs: 24,900 on pre-booking cow hissa package. Moreover, farmGhar provides the guaranteed weight of cow hissa package, which is 14 - 16 kg.</p>
<p>
Furthermore, we invite everyone to participate in this sacred tradition by owning shares of cows and performing their Qurbani with ease. However, each qurbani cow is carefully chosen based on factors including its breed, age, health, and suitability. The Qurbani is performed according to the Shariah.</p>
<p>
farmGhar is committed to transparency, quality farming, hygiene and customer satisfaction. farmGhar offers a trusted and convenient platform for individuals looking to invest in cows. Book your Cow Hissa Package Now!!!</p>
    </div>
       `,
    title: "Cow Hissa Package – farmGhar",
    description:
      "farmGhar is offering Qurbani of cow services at the most competitive rates. We provide online Qurbani services all around the world.",
    keywords:
      "Qurbani of Cow Made Easily Accessible with farmGhar , farmGhar, farmghar.com",
  },
  {
    slug: "/products/cholistani-cow",
    image: `${image4}`,
    heading: "Cholistani Cow",
    content: `
    <div ><h1><strong>Details of Cholistani Cow: </strong></h1>
<p><span>Under local climatic conditions, a study was done to examine the performance of </span> <b>cholistani cow </b><span  > or </span><span  >Cholistani-breed calves fed fattening feeds. Twenty male calves of comparable weight, age, and size participated in the trial. All of the calves were between eight and twelve months of age (8-12 months) and averaged 133 lb (105-165 lbs.)</span></p></div>`,
    title: "Cholistani Cow – farmGhar",
    description:
      "Under local climatic conditions, a study was done to examine the performance of cholistani cow or Cholistani-breed calves fed fattening feeds.",
    keywords: "Cholistani Cow,farmGhar, farmghar.com",
  },
  {
    slug: "/collections/live-cow/products/gulabi-bakra",
    image: `${image5}`,
    heading: "Gulabi Bakra",
    content: `
    <div><p >Breed: Gulabi</p>
<p >Age: 9 Months</p></div>`,
    title: "Gulabi Bakra – farmGhar",
    description:
      "Biggest Cow Qurbani 2022 | Breed: Sahiwal Live Weight: 250 kg plus Age: 2 Dant",
    keywords: "Gulabi Bakra,farmGhar,farmghar.com",
  },
  {
    slug: "/products/bakra-mandi-1",
    image: `${image6}`,
    heading: "Goat Qurbani Package 2",
    content: `
    <div>
<p>
farmGhar is introducing the Goat Qurbani Package to bring joy and blessing to you. This Eid ul Azha experience the seamless and hassle-free Qurbani with our high-quality meat, all conveniently packaged for your convenience. The Goat Qurbani Package ensures a meat weight of 14-16 kg for just Rs: 55,500.</p>
<p>
Moreover, our experienced team and veterinary doctors ensure that the goats are raised in a healthy environment and are provided with optimal living conditions, proper nutrition, and regular veterinary care. The Qurbani is performed according to the Shariah rules and follows the hygiene protocol.</p>
<p>
However, don't miss the opportunity to secure the Goat Qurbani Package at the discounted price of Rs 52,300 through our Pre-booking offer. </p>
</div>
    `,
    title: "Goat Qurbani Package 2 – farmGhar",
    description:
      "Live goats ( bakra mandi lahore ) which are fed organically and raised naturally to offer the safest and highest quality meat will be delivered to your doorstep. Price: 1000 Rs (Per Kg live animal weight)",
    keywords: "Live Goat ( Bakra Mandi Lahore ),farmGhar,farmghar.com",
  },
];

export default function MigratedContent() {
  const { data: supportInfo } = useFetchQurbaniSupportInfo();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const slug =
    item?.slug === "/products/bakra-mandi-1" ||
    item?.slug === "/collections/live-cow/products/gulabi-bakra"
      ? "goat-qurbani-package-2"
      : "cow-hissa-package";
  const { data: data1, isLoading: isLoading1 } = useFetchQurbaniSEOContent({
    QurbaniSlug: slug,
  });
  const QurbaniPackages = data1?.data ?? {};

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "Item added to cart successfully."
  );
  const handleCloseToast = () => {
    setShowToast(false);
  };
  const handleShowToast = () => {
    setShowToast(true);
  };

  const { addToCart, cartItems } = useContext(CartContext);

  const handleAddToCart = (item) => {
    addToCart(
      {
        id: parseInt(item.package_id),
        name: item.package_name,
        price: parseInt(item?.price?.replace(/,/g, "")),
        catId: parseInt(item?.cat_id),
        totalQuantity: parseInt(item?.total_quantity),
        availableQuantity: parseInt(item?.available_quantity),
        packageCatName: item?.package_cat_name,
        deliveryPrice: parseInt(item?.delivery_price?.replace(/,/g, "")),
        description: item?.description,
        image: item?.image,
        SelectedEidDay: item.SelectedEidDay,
      },
      1
    );
    let cartsI = 0;
    const found = cartItems?.find(
      (element) => element.id === parseInt(item.package_id)
    );
    if (found) {
      cartsI = 0;
    } else {
      cartsI = 1;
    }

    cartItems?.forEach((element) => {
      cartsI = cartsI + element.quantity;
    });
    // setToastMessage(
    //   `${cartsI} ${cartsI > 1 ? "items" : "item"} added to cart successfully.`
    // );
    // handleShowToast();
    window.location.href = "/qurbani/checkout";
  };

  useEffect(() => {
    const slug = window.location.pathname;
    const foundItem = data.find((item) => item.slug === slug);
    setItem(foundItem);
    setLoading(false);
  }, []);
  if (loading || isLoading1) {
    return (
      <div className="flex items-center justify-center mt-20">
        <div className="spinner"></div>
      </div>
    );
  }
  return (
    <>
      {showToast && (
        <Toast
          message={toastMessage}
          duration={3000}
          onClose={handleCloseToast}
        />
      )}
      <UpdateMetaTags data={item ?? {}} />
      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>
        <h2 className="font-PoppinsBold text-[22px]">{item?.heading ?? ""}</h2>
        <div className="flex  flex-col md:flex-row pt-4">
          <div className="w-full md:w-[67%] lg:w-[70%]  mr-[25px]">
            <div className=" flex flex-col    rounded-[13px] bg-gray-50  p-4 sm:pr-4 lg:mr-[-30px]">
              <img
                src={item?.image ?? ""}
                alt={item?.heading ?? ""}
                title={item?.heading ?? ""}
                className="mx-auto"
              />
            </div>
            <h4 className="font-PoppinsBold  pt-2 pb-2">
              {item?.heading ?? ""}
            </h4>
            {item?.slug === "/products/bakra-mandi-1" ||
            item?.slug === "/collections/live-cow/products/gulabi-bakra" ? (
              <div className="space-y-1">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    const found = cartItems.find((cartItem) => {
                      if (
                        parseInt(cartItem.id) ===
                        parseInt(QurbaniPackages.package_id)
                      ) {
                        return cartItem;
                      }
                    });
                    if (
                      parseInt(QurbaniPackages.f_d_available_quantity) -
                        parseInt(found?.quantity ?? 0) >=
                      1
                    ) {
                      if (cartItems[0]?.SelectedEidDay === "1") {
                        QurbaniPackages.SelectedEidDay = "1";
                        handleAddToCart(QurbaniPackages);
                      } else if (cartItems[0]?.SelectedEidDay === "2") {
                        setToastMessage(
                          "Please remove Eid Day 2 items to add Eid Day 1 items."
                        );
                        handleShowToast();
                      }

                      if (cartItems.length === 0) {
                        QurbaniPackages.SelectedEidDay = "1";
                        handleAddToCart(QurbaniPackages);
                      }
                    } else {
                      setToastMessage("Out of stock.");
                      handleShowToast();
                    }
                  }}
                  className="bg-Primary w-[90%] py-2 flex mx-auto text-white font-PoppinsBold rounded-[50px]"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    className="inline-block ml-4"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.01672 13.3333L9.26672 14.5833L11.9751 12.0833"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.34162 1.66667L4.32495 4.69167"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.6583 1.66667L15.675 4.69167"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.66663 6.54167C1.66663 5 2.49163 4.875 3.51663 4.875H16.4833C17.5083 4.875 18.3333 5 18.3333 6.54167C18.3333 8.33333 17.5083 8.20833 16.4833 8.20833H3.51663C2.49163 8.20833 1.66663 8.33333 1.66663 6.54167Z"
                      stroke="white"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M2.91663 8.33333L4.09163 15.5333C4.35829 17.15 4.99996 18.3333 7.38329 18.3333H12.4083C15 18.3333 15.3833 17.2 15.6833 15.6333L17.0833 8.33333"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span className="text-center w-full mr-4">Buy Now</span>
                </button>
                {/* <button
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    const found = cartItems.find((cartItem) => {
                      if (
                        parseInt(cartItem.id) ===
                        parseInt(QurbaniPackages.package_id)
                      ) {
                        return cartItem;
                      }
                    });
                    if (
                      parseInt(QurbaniPackages.s_d_available_quantity) -
                        parseInt(found?.quantity ?? 0) >=
                      1
                    ) {
                      if (cartItems[0]?.SelectedEidDay === "2") {
                        QurbaniPackages.SelectedEidDay = "2";
                        handleAddToCart(QurbaniPackages);
                      } else if (cartItems[0]?.SelectedEidDay === "1") {
                        setToastMessage(
                          "Please remove Eid Day 1 items to add Eid Day 2 items."
                        );
                        handleShowToast();
                      }

                      if (cartItems.length === 0) {
                        QurbaniPackages.SelectedEidDay = "2";
                        handleAddToCart(QurbaniPackages);
                      }
                    } else {
                      setToastMessage("Out of stock.");
                      handleShowToast();
                    }
                  }}
                  className="bg-Primary w-[90%] py-2 flex mx-auto text-white font-PoppinsBold rounded-[50px]"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    className="inline-block ml-4"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.01672 13.3333L9.26672 14.5833L11.9751 12.0833"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.34162 1.66667L4.32495 4.69167"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.6583 1.66667L15.675 4.69167"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.66663 6.54167C1.66663 5 2.49163 4.875 3.51663 4.875H16.4833C17.5083 4.875 18.3333 5 18.3333 6.54167C18.3333 8.33333 17.5083 8.20833 16.4833 8.20833H3.51663C2.49163 8.20833 1.66663 8.33333 1.66663 6.54167Z"
                      stroke="white"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M2.91663 8.33333L4.09163 15.5333C4.35829 17.15 4.99996 18.3333 7.38329 18.3333H12.4083C15 18.3333 15.3833 17.2 15.6833 15.6333L17.0833 8.33333"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span className="text-center w-full mr-4">
                    Buy Now Eid Day 2
                  </span>
                </button> */}
              </div>
            ) : (
              <>
                <p className="py-2">
                  Are you looking for affordable Online Qurbani Packages? Click
                  the link below to see the Cow and Goat Qurbani Packages
                  according to your budget and preferences.
                </p>
                <p className="py-2">
                  قربانی پیکجز دیکھنے کے لیے نیچے دیے گئے لنک پر کلک کریں۔
                </p>
                <div className="space-y-1">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      const found = cartItems.find((cartItem) => {
                        if (
                          parseInt(cartItem.id) ===
                          parseInt(QurbaniPackages.package_id)
                        ) {
                          return cartItem;
                        }
                      });
                      if (
                        parseInt(QurbaniPackages.f_d_available_quantity) -
                          parseInt(found?.quantity ?? 0) >=
                        1
                      ) {
                        if (cartItems[0]?.SelectedEidDay === "1") {
                          QurbaniPackages.SelectedEidDay = "1";
                          handleAddToCart(QurbaniPackages);
                        } else if (cartItems[0]?.SelectedEidDay === "2") {
                          setToastMessage(
                            "Please remove Eid Day 2 items to add Eid Day 1 items."
                          );
                          handleShowToast();
                        }

                        if (cartItems.length === 0) {
                          QurbaniPackages.SelectedEidDay = "1";
                          handleAddToCart(QurbaniPackages);
                        }
                      } else {
                        setToastMessage("Out of stock.");
                        handleShowToast();
                      }
                    }}
                    className="bg-Primary w-[90%] py-2 flex mx-auto text-white font-PoppinsBold rounded-[50px]"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="inline-block ml-4"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.01672 13.3333L9.26672 14.5833L11.9751 12.0833"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.34162 1.66667L4.32495 4.69167"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.6583 1.66667L15.675 4.69167"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.66663 6.54167C1.66663 5 2.49163 4.875 3.51663 4.875H16.4833C17.5083 4.875 18.3333 5 18.3333 6.54167C18.3333 8.33333 17.5083 8.20833 16.4833 8.20833H3.51663C2.49163 8.20833 1.66663 8.33333 1.66663 6.54167Z"
                        stroke="white"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M2.91663 8.33333L4.09163 15.5333C4.35829 17.15 4.99996 18.3333 7.38329 18.3333H12.4083C15 18.3333 15.3833 17.2 15.6833 15.6333L17.0833 8.33333"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                    <span className="text-center w-full mr-4">Buy Now</span>
                  </button>
                  {/* <button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      const found = cartItems.find((cartItem) => {
                        if (
                          parseInt(cartItem.id) ===
                          parseInt(QurbaniPackages.package_id)
                        ) {
                          return cartItem;
                        }
                      });
                      if (
                        parseInt(QurbaniPackages.s_d_available_quantity) -
                          parseInt(found?.quantity ?? 0) >=
                        1
                      ) {
                        if (cartItems[0]?.SelectedEidDay === "2") {
                          QurbaniPackages.SelectedEidDay = "2";
                          handleAddToCart(QurbaniPackages);
                        } else if (cartItems[0]?.SelectedEidDay === "1") {
                          setToastMessage(
                            "Please remove Eid Day 1 items to add Eid Day 2 items."
                          );
                          handleShowToast();
                        }

                        if (cartItems.length === 0) {
                          QurbaniPackages.SelectedEidDay = "2";
                          handleAddToCart(QurbaniPackages);
                        }
                      } else {
                        setToastMessage("Out of stock.");
                        handleShowToast();
                      }
                    }}
                    className="bg-Primary w-[90%] py-2 flex mx-auto text-white font-PoppinsBold rounded-[50px]"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="inline-block ml-4"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.01672 13.3333L9.26672 14.5833L11.9751 12.0833"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.34162 1.66667L4.32495 4.69167"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.6583 1.66667L15.675 4.69167"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.66663 6.54167C1.66663 5 2.49163 4.875 3.51663 4.875H16.4833C17.5083 4.875 18.3333 5 18.3333 6.54167C18.3333 8.33333 17.5083 8.20833 16.4833 8.20833H3.51663C2.49163 8.20833 1.66663 8.33333 1.66663 6.54167Z"
                        stroke="white"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M2.91663 8.33333L4.09163 15.5333C4.35829 17.15 4.99996 18.3333 7.38329 18.3333H12.4083C15 18.3333 15.3833 17.2 15.6833 15.6333L17.0833 8.33333"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                    <span className="text-center w-full mr-4">
                      Buy Now Eid Day 2
                    </span>
                  </button> */}
                </div>
              </>
            )}
            <div
              className="mt-10 StylesCategorySeoContent font-PoppinsRegular space-y-2 overflow-hidden"
              dangerouslySetInnerHTML={{
                __html: item?.content ? item?.content : ``,
              }}
            />
          </div>
          <div className="w-full md:w-[40%] lg:w-[30%] space-y-4 pt-10 md:pt-0 flex items-center flex-col">
            <div className="w-[249px] h-[157px] border border-[rgba(12, 35, 2, 0.15)] rounded-[13px]  mx-auto block">
              <h4 className="font-PoppinsBold pl-4 pt-2">Contact Us</h4>
              <div className="flex flex-col items-center mt-[15px] space-y-2">
                <a
                  href={`https://wa.me/92${supportInfo?.data?.whatsapp_number.replace(
                    /-/g,
                    ""
                  )}`}
                  target={"_blank"}
                  rel="noreferrer"
                  className="w-[230px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
                >
                  <AdWhatsappIcon />
                  <span className="ml-10">Whatsapp</span>
                </a>
                <div>
                  <a
                    href={`tel:${supportInfo?.data?.phone_number.replace(
                      /-/g,
                      ""
                    )}`}
                    className="w-[230px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
                  >
                    <AdCallIcon />
                    <span className="ml-12">Call Now</span>
                  </a>
                </div>
              </div>
            </div>
            <SquareGoogleAd />
          </div>
        </div>
        <CallToActionBottom />
      </div>
    </>
  );
}
