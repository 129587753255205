import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Provinces } from "../../StaticData/Provinces";
import Select, { components } from "react-select";
import { Style } from "../../Styles/ReactSelectStyles";
import { ReactComponent as CaretDownIcon } from "../../assets/icons/CaretDown.svg";
import MySelect from "../MySelect";
import {
  Option,
  ValueContainer,
  MultiValue,
  animatedComponents,
} from "../MySelectFunc.js";

import { useFormik } from "formik";
import { useState } from "react";
import {
  useFetchFilterDistricts,
  useFetchFilterTehsils,
  useFetchChildCategories,
  useFetchFilterBreeds,
  useMutateLatLongdata,
  useMutateFilterDistricts,
} from "../../Middlewares/Api";

import AgeRangeSlider from "./AgeRangeSlider";
import WeightRangeSlider from "./WeightRangeSlider";
import { useContext } from "react";
import PreserveFormikFilterContext from "../../Context/PreserveFormikFilterContext";
import { useEffect } from "react";
import GoogleMap, { getAddress } from "../../Utils/GoogleMap";

export default function Filter({
  open,
  setOpen,
  filterAds,
  setFilterAds,
  searchParams,
  setSearchParams,
  setLocationStates,
  locationStates,
}) {
  const { formikInitialValues, setFormikInitialValues } = useContext(
    PreserveFormikFilterContext
  );

  const { mutateAsync } = useMutateLatLongdata();
  const { mutateAsync: MutateDistricts } = useMutateFilterDistricts();
  const [autoComplete2, setAutoComplete2] = useState(null);

  const formik = useFormik({
    initialValues: {
      Province: [],
      District: [],
      Tehsil: "",
      Category: "",
      MaxPrice: "",
      Breed: "",
      IsDelivery: false,
      IsVerified: false,
      IsPregnant: false,
      MinWeight: "",
      MinAge: 0,
      MaxAge: 10,
      Castrated: false,
    },

    onSubmit: (values) => {
      setLocationStates((prev) => ({
        ...prev,
        lat2: prev.latitude,
        lng2: prev.longitude,
        isViewNearby: false,
      }));
      searchParams.delete("query");
      setSearchParams(searchParams);
      setFormikInitialValues(() => ({
        Province: values.Province,
        District: values.District,
        Tehsil: values.Tehsil,
        Category: values.Category,
        MaxPrice: values.MaxPrice,
        Breed: values.Breed,
        IsDelivery: values.IsDelivery,
        IsVerified: values.IsVerified,
        IsPregnant: values.IsPregnant,
        MinWeight: values.MinWeight,
        MinAge: values.MinAge,
        MaxAge: values.MaxAge,
        Castrated: values.Castrated,
      }));

      setFilterAds((prev) => ({
        ...prev,
        Province: values.Province && values.Province.value,
        district:
          values.District && values.District.length === 1
            ? values.District[0].value
            : makeCommaSeperatedIds(values.District),
        Tehsil: values.Tehsil && values.Tehsil.value,
        Category: values.Category && values.Category.value,
        MaxPrice: values.MaxPrice && values.MaxPrice,
        Breed:
          values.Breed && values.Breed?.length === 1
            ? values?.Breed[0].value
            : makeCommaSeperatedIds(values.Breed),
        //sending payload undefined if value is 0 mean false
        IsDelivery: values.IsDelivery ? 1 : undefined,
        IsVerified: values.IsVerified ? 1 : undefined,
        IsPregnant: values.IsPregnant ? 1 : undefined,
        MinWeight: values.MinWeight && values.MinWeight,
        MinAge:
          values.MinAge && values.MinAge === 0 ? undefined : values.MinAge,
        MaxAge:
          values.MaxAge && values.MaxAge === 10 ? undefined : values.MaxAge,
        Castrated: values.Castrated ? 1 : undefined,
      }));
      setOpen(false);
    },
  });

  const { values, handleSubmit, setFieldValue, resetForm } = formik;
  const [value, setValue] = useState({
    min: formikInitialValues.MinAge,
    max: formikInitialValues.MaxAge,
  });
  const { data: DistrictsData } = useFetchFilterDistricts({
    ProvinceId: values.Province && values.Province.value,
  });

  function makeCommaSeperatedIds(array) {
    var str = "";
    for (var i = 0; i < array?.length; i++) {
      str += array[i].value;
      if (i < array.length - 1) {
        str += ",";
      }
    }
    return str;
  }

  const DistrictsOptons =
    DistrictsData &&
    DistrictsData.data.map((item) => {
      return { value: item.id, label: item.name };
    });

  const { data: TehsilsData } = useFetchFilterTehsils({
    DistrictId: values.District?.length === 1 && values.District[0].value,
  });

  const TehsilsOptons =
    TehsilsData &&
    TehsilsData.data.map((item) => {
      return { value: item.id, label: item.name };
    });

  // const { data: AllCategories } = useFetchFilterAllCategoriesAnimals();
  const { data: ChildCategoriesData } = useFetchChildCategories({
    CatId: filterAds.parent_category,
  });

  const ShowHidePregnant = () => {
    if (
      (values.Category && values.Category?.value === 1008) ||
      values.Category?.value === 1006 ||
      values.Category?.value === 1002 ||
      values.Category?.value === 1004
    ) {
      return (
        <div className="relative flex items-start pt-[10px]">
          <div className="flex h-5 items-center ">
            <input
              id="Pregnant"
              aria-describedby="Filter by breed"
              name="Pregnant"
              type="checkbox"
              checked={values.IsPregnant ? values.IsPregnant : false}
              onChange={(e) => {
                setFieldValue("IsPregnant", e.target.checked);
              }}
              className="h-4 w-4 rounded border-gray-300  accent-[#3ED400]"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="Pregnant" className="font-medium text-TextColor">
              Pregnant
            </label>
          </div>
        </div>
      );
    }
  };

  const ShowHideWeight = () => {
    return (
      <div className="pt-[10px]">
        <h3 className="block text-sm font-medium text-TextColor pb-[50px]">
          {" "}
          Select Weight
        </h3>
        <WeightRangeSlider setFieldValue={setFieldValue} values={values} />
      </div>
    );
  };

  const ShowHideCastrated = () => {
    if (values.Category && values.Category.value === 1001) {
      return (
        <div className="relative flex items-start pt-[10px]">
          <div className="flex h-5 items-center ">
            <input
              id="Castrated"
              aria-describedby="Filter by breed"
              name="Castrated"
              type="checkbox"
              checked={values.Castrated ? values.Castrated : false}
              onChange={(e) => {
                setFieldValue("Castrated", e.target.checked);
              }}
              className="h-4 w-4 rounded border-gray-300  accent-[#3ED400]"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="Castrated" className="font-medium text-TextColor">
              Khassi
            </label>
          </div>
        </div>
      );
    }
  };

  const getChildCategories = () => {
    if (String(filterAds.parent_category) === "1") {
      let datass = [];
      for (let i = 0; i < ChildCategoriesData?.data?.length; i++) {
        for (let j = 0; j < ChildCategoriesData?.data[i]?.child?.length; j++) {
          datass.push(ChildCategoriesData?.data[i]?.child[j]);
        }
      }
      return datass?.map((item) => {
        return { value: item.id, label: item.name };
      });
    }
    return ChildCategoriesData?.data?.map((item) => {
      return { value: item.id, label: item.name };
    });
  };

  const ChildCategoriesOptions = ChildCategoriesData && getChildCategories();

  // const AllCategoriesAnimals =
  // AllCategories &&
  // AllCategories.map(({ data }) => {
  //   const each = data?.data.map((item) => {
  //     return { value: item.id, label: item.name };
  //   });
  //   return each;
  // });

  // const AllCategoriesAnimalsOptons = AllCategoriesAnimals?.flat();
  // const LiveStock = AllCategoriesAnimals?.[0];
  // const FarmServices = AllCategoriesAnimals?.[1];
  // const FarmEquipments = AllCategoriesAnimals?.[2];
  // const FarmProducts = AllCategoriesAnimals?.[3];
  // const OtherAnimals = AllCategoriesAnimals?.[4];

  const { data: BreedsData } = useFetchFilterBreeds({
    SubCateId: values.Category && values.Category.value,
  });

  const BreedsOptons = BreedsData?.data.map((item) => {
    return { value: item.id, label: item.name };
  });

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const ShowHideAge = () => {
    if (
      String(filterAds.parent_category) === "6" ||
      String(filterAds.parent_category) === "21" ||
      String(filterAds.parent_category) === "1"
    ) {
      return (
        <div className="pt-[10px]">
          <h3 className="block text-sm font-medium text-TextColor">
            Select age
          </h3>
          <div className="flex">
            <div>
              <span className="font-[22px] pr-2">{value.min}</span>
              <span className="font-[12px] text-Primary">Years</span>
            </div>
            <p className="px-2">-</p>
            <div>
              <span className="font-[12px] pr-2">{value.max}</span>
              <span className="font-[12px] text-Primary">Years</span>
            </div>
          </div>
          <AgeRangeSlider
            min={0}
            max={10}
            step={1}
            value={value}
            onChange={setValue}
            setFieldValue={setFieldValue}
            values={values}
          />
        </div>
      );
    }
  };

  const HandleChangeLatLong = async (latitude, longitude) => {
    let dataLatLong = null;
    await mutateAsync({
      latitude,
      longitude,
    }).then((res) => {
      dataLatLong = res;
    });
    const Province = Provinces.find(
      (item) =>
        parseInt(item.value) === parseInt(dataLatLong?.data?.province_id)
    );
    let District = null;

    await MutateDistricts({ province: Province?.value }).then(
      (res) =>
        (District = res?.data?.find(
          (item) => parseInt(item.id) === parseInt(dataLatLong?.data?.id)
        ))
    );
    formik.setValues((prev) => ({
      ...prev,
      Province: {
        value: Province?.value,
        label: Province?.label,
      },
      District: [
        {
          value: District?.id,
          label: District?.name,
        },
      ],
      Tehsil: "",
    }));
  };
  useEffect(() => {
    formik.setValues({
      Province: formikInitialValues.Province,
      District: formikInitialValues.District,
      Tehsil: formikInitialValues.Tehsil,
      Category: formikInitialValues.Category,
      MaxPrice: formikInitialValues.MaxPrice,
      Breed: formikInitialValues.Breed,
      IsDelivery: formikInitialValues.IsDelivery,
      IsVerified: formikInitialValues.IsVerified,
      IsPregnant: formikInitialValues.IsPregnant,
      MinWeight: formikInitialValues.MinWeight,
      MinAge: formikInitialValues.MinAge,
      MaxAge: formikInitialValues.MaxAge,
      Castrated: formikInitialValues.Castrated,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikInitialValues]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 font-PoppinsRegular"
        onClose={setOpen}
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full ">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md bg-white shadow-xl">
                  <form onSubmit={handleSubmit} className="h-full">
                    <div className="px-4 sm:px-6 h-[60px] py-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-[18px] font-medium text-black font-PoppinsBold">
                          Filter by
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none  focus:ring-0"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex h-[calc(100%-140px)] flex-col overflow-y-scroll ">
                      <div className="relative mt-2 flex-1 px-4 sm:px-6 ">
                        <div className="divide-[#DBDED9] divide-y space-y-3">
                          <div>
                            <GoogleMap
                              autoComplete2={autoComplete2}
                              setAutoComplete2={setAutoComplete2}
                              HandleChangeLatLong={HandleChangeLatLong}
                              locationStates={locationStates}
                              setLocationStates={setLocationStates}
                              formikInitialValues={formikInitialValues}
                            />

                            <div>
                              <label
                                htmlFor="provinces"
                                className="block text-sm font-medium text-TextColor"
                              >
                                Province
                              </label>
                              <Select
                                styles={Style}
                                id="provinces"
                                name="provinces"
                                maxMenuHeight={140}
                                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                                options={Provinces}
                                value={values.Province ? values.Province : []}
                                label="provinces"
                                components={{
                                  IndicatorSeparator: () => null,
                                  DropdownIndicator,
                                }}
                                onChange={(value) => {
                                  setFieldValue("Province", value);
                                  setFieldValue("District", []);
                                  setFieldValue("Tehsil", null);
                                  setLocationStates((prev) => ({
                                    ...prev,
                                    address: "",
                                    latitude: null,
                                    longitude: null,
                                  }));
                                }}
                              />
                            </div>

                            <div className="mt-1">
                              <label
                                htmlFor="district"
                                className="block text-sm font-medium text-TextColor"
                              >
                                District
                              </label>
                              <MySelect
                                styles={Style}
                                id="district"
                                name="district"
                                maxMenuHeight={140}
                                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                                options={DistrictsOptons}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                  Option,
                                  MultiValue,
                                  ValueContainer,
                                  animatedComponents,
                                  IndicatorSeparator: () => null,
                                  DropdownIndicator,
                                }}
                                allowSelectAll={false}
                                value={values.District ? values.District : []}
                                label="district"
                                onChange={async (value) => {
                                  setFieldValue("District", value);
                                  if (values.District?.length >= 1) {
                                    setFieldValue("Tehsil", null);
                                    setLocationStates((prev) => ({
                                      ...prev,
                                      address: "",
                                      latitude: null,
                                      longitude: null,
                                    }));
                                  }
                                  if (value.length === 1) {
                                    const District = DistrictsData?.data?.find(
                                      (item) =>
                                        parseInt(item.id) ===
                                        parseInt(value[0].value)
                                    );
                                    const address = await getAddress(
                                      parseFloat(District?.latitude),
                                      parseFloat(District?.longitude)
                                    );
                                    setLocationStates((prev) => ({
                                      ...prev,
                                      address: address,
                                      latitude: parseFloat(District?.latitude),
                                      longitude: parseFloat(
                                        District?.longitude
                                      ),
                                    }));
                                  }
                                }}
                              />
                            </div>
                            <div className="mt-1">
                              <label
                                htmlFor="tehsil"
                                className="block text-sm font-medium text-TextColor"
                              >
                                Tehsil
                              </label>
                              <Select
                                styles={Style}
                                id="tehsil"
                                name="tehsil"
                                maxMenuHeight={140}
                                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                                options={TehsilsOptons}
                                value={values.Tehsil ? values.Tehsil : ""}
                                label="tehsil"
                                components={{
                                  IndicatorSeparator: () => null,
                                  DropdownIndicator,
                                }}
                                onChange={async (value) => {
                                  setFieldValue("Tehsil", value);
                                  // const tehsil = TehsilsData?.data?.find(
                                  //   (item) =>
                                  //     parseInt(item.id) ===
                                  //     parseInt(value?.value)
                                  // );
                                  // const address = await getAddress(
                                  //   parseFloat(tehsil?.latitude),
                                  //   parseFloat(tehsil?.longitude)
                                  // );
                                  // setLocationStates((prev) => ({
                                  //   ...prev,
                                  //   address: address,
                                  //   latitude: parseFloat(tehsil?.latitude),
                                  //   longitude: parseFloat(tehsil?.longitude),
                                  // }));
                                }}
                              />
                            </div>
                          </div>
                          <div className="pt-[10px]">
                            <label
                              htmlFor="Category"
                              className="block text-sm font-medium text-TextColor"
                            >
                              Category
                            </label>
                            <Select
                              styles={Style}
                              id="Category"
                              name="Category"
                              maxMenuHeight={140}
                              className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                              options={
                                ChildCategoriesOptions
                                // String(filterAds.parent_category) === "1"
                                //   ? AllCategoriesAnimalsOptons
                                //   : String(filterAds.parent_category) === "6"
                                //   ? LiveStock
                                //   : String(filterAds.parent_category) === "21"
                                //   ? OtherAnimals
                                //   : String(filterAds.parent_category) === "100"
                                //   ? FarmProducts
                                //   : String(filterAds.parent_category) === "101"
                                //   ? FarmEquipments
                                //   : String(filterAds.parent_category) === "102"
                                //   ? FarmServices
                                //   : []
                              }
                              value={values.Category ? values.Category : []}
                              label="Category"
                              components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator,
                              }}
                              onChange={(value) => {
                                setFieldValue("Category", value);
                                setFieldValue("Castrated", false);
                                setFieldValue("IsPregnant", false);
                                setFieldValue("Breed", []);
                              }}
                            />
                          </div>

                          {ShowHidePregnant()}
                          {ShowHideCastrated()}
                          <div className="pt-[10px]">
                            <label
                              htmlFor="Breed"
                              className="block text-sm font-medium text-TextColor"
                            >
                              breed
                            </label>
                            <MySelect
                              styles={Style}
                              id="Breed"
                              name="Breed"
                              maxMenuHeight={140}
                              className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                              options={BreedsOptons}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option,
                                MultiValue,
                                ValueContainer,
                                animatedComponents,
                                IndicatorSeparator: () => null,
                                DropdownIndicator,
                              }}
                              allowSelectAll={false}
                              value={values.Breed ? values.Breed : ""}
                              label="breed"
                              onChange={(value) => {
                                setFieldValue("Breed", value);
                              }}
                            />
                          </div>
                          <div className="pt-[10px]">
                            <label
                              htmlFor="price"
                              className="block text-sm font-medium text-TextColor"
                            >
                              Max Price (PKR)
                            </label>
                            <input
                              onChange={(e) => {
                                setFieldValue("MaxPrice", e.target.value);
                              }}
                              id="price"
                              name="price"
                              value={values.MaxPrice ? values.MaxPrice : ""}
                              className="mt-1 block w-full PKR border-[rgba(0, 0, 0, 0.16)] py-2 pl-3 pr-10 text-base  sm:text-sm border-2 rounded-[10px] focus:outline-none"
                            />
                          </div>
                          {ShowHideAge()}

                          {ShowHideWeight()}
                          <fieldset className="space-y-3 pt-[10px]">
                            <h3 className="block text-sm font-medium text-TextColor">
                              Other Option
                            </h3>
                            <div className="relative flex items-start">
                              <div className="flex h-5 items-center ">
                                <input
                                  id="IsVerified"
                                  aria-describedby="Filter by verified"
                                  name="IsVerified"
                                  type="checkbox"
                                  checked={
                                    values.IsVerified
                                      ? values.IsVerified
                                      : false
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      "IsVerified",
                                      e.target.checked
                                    );
                                  }}
                                  className="h-4 w-4 rounded border-gray-300  accent-[#3ED400]"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="IsVerified"
                                  className="font-medium text-TextColor"
                                >
                                  FarmGhar Veirfied
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex h-5 items-center ">
                                <input
                                  id="IsDelivery"
                                  aria-describedby="Filter by delivery"
                                  name="IsDelivery"
                                  type="checkbox"
                                  checked={
                                    values.IsDelivery
                                      ? values.IsDelivery
                                      : false
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      "IsDelivery",
                                      e.target.checked
                                    );
                                  }}
                                  className="h-4 w-4 rounded border-gray-300  accent-[#3ED400]"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="IsDelivery"
                                  className="font-medium text-TextColor"
                                >
                                  Only Show Animals with delivery Guarantee
                                </label>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        {/* /End replace */}
                      </div>
                    </div>
                    <div className="flex h-[80px] px-4 sm:px-6 justify-between pt-4 font-PoppinsRegular font-[14px]">
                      <button
                        type="reset"
                        onClick={() => {
                          searchParams.delete("query");
                          setSearchParams(searchParams);
                          setFilterAds((prev) => ({
                            ...prev,
                            Province: undefined,
                            district: undefined,
                            Tehsil: undefined,
                            Category: undefined,
                            Breed: undefined,
                            IsDelivery: undefined,
                            IsVerified: undefined,
                            IsPregnant: undefined,
                            Castrated: undefined,
                            MinWeight: undefined,
                            MinAge: undefined,
                            MaxAge: undefined,
                            MaxPrice: undefined,
                          }));
                          setFormikInitialValues(() => ({
                            Province: undefined,
                            District: undefined,
                            Tehsil: undefined,
                            Category: undefined,
                            MaxPrice: undefined,
                            Breed: undefined,
                            IsDelivery: undefined,
                            IsVerified: undefined,
                            IsPregnant: undefined,
                            MinWeight: undefined,
                            MinAge: 0,
                            MaxAge: 10,
                            Castrated: undefined,
                          }));
                          resetForm();
                          setOpen(false);
                          setValue({ min: 0, max: 10 });
                        }}
                        className="w-[141px] h-[42px] rounded-[8px] bg-white border-2 border-[#B6B6B6] text-[#B6B6B6]"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        className="w-[141px] h-[42px] rounded-[8px] bg-Primary border-1 border-[#B6B6B6] text-white"
                      >
                        Apply
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
