// import { ReactComponent as PakistanIcon } from "../../../assets/icons/pakistan.svg";
// import pakistan from "../../../assets/icons/pakistan.png";
import usa from "../../../assets/icons/usa.png";
export default function LanguageSwitcher() {
  return (
    <>
      <div className="flex justify-center space-x-2 ">
        <div className="">
          <img src={usa} alt="flag" width="24" height="24" />
        </div>
        <select
          id="countries"
          className="focus:outline-none text-sm text-TextColor  pr-[22px] LangSelect cursor-pointer"
          defaultChecked="EN"
        >
          {/* <option defaultValue="UR" className="font-PoppinsRegular">
            Urdu
          </option> */}
          <option value="EN" className="font-PoppinsRegular">
            English
          </option>
        </select>
      </div>
    </>
  );
}
