import { FormWrapper } from "../../Components/RegisterComponents/FormWrapper";
import { ReactComponent as JustLogoWithoutName } from "../../assets/icons/JustLogoWithoutName.svg";
import { ReactComponent as InputPasswordIcon } from "../../assets/icons/InputPassword.svg";

export default function ChangePassword({ formik }) {
  return (
    <FormWrapper title="User Details">
      <div className="flex flex-col items-center mt-[40px] w-full h-full ">
        <JustLogoWithoutName />

        <h1 className="text-TextColor  mt-[40px] ">Change Password</h1>
        <div className="space-y-4 mt-[29px]  w-full h-full max-w-[340px] text-left">
          <div className="relative text-gray-600 focus-within:text-gray-400">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <InputPasswordIcon />
            </span>
            <label htmlFor="Password" className="sr-only">
              Password
            </label>
            <input
              type="password"
              value={formik.values.Password}
              name="Password"
              id="Password"
              placeholder="Password"
              className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
              onChange={(e) => {
                formik.setFieldValue("Password", e.target.value);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.touched.Password && formik.errors.Password ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Password}
            </span>
          ) : null}
          <div className="relative text-gray-600 focus-within:text-gray-400">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <InputPasswordIcon />
            </span>
            <label htmlFor="ConfirmPassword" className="sr-only">
              Confirm Password
            </label>
            <input
              type="password"
              value={formik.values.ConfirmPassword}
              name="ConfirmPassword"
              id="ConfirmPassword"
              placeholder="Confirm Password"
              className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
              onChange={(e) => {
                formik.setFieldValue("ConfirmPassword", e.target.value);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.ConfirmPassword}
            </span>
          ) : null}
        </div>
      </div>
    </FormWrapper>
  );
}
