import { useState, useEffect, useRef } from "react";

export default function WeightRangeSlider({ setFieldValue, values }) {
  const [step, setStep] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const rangeLinePadding = 16;
    const calcStep =
      (ref.current.offsetWidth - rangeLinePadding) / ref.current.max;
    setStep(calcStep);
  }, []);

  return (
    <div className="relative weight-wrapper">
      <input
        onChange={(e) => {
          setFieldValue("MinWeight", e.target.value);
        }}
        type="range"
        min="0"
        max="5000"
        title={values?.MinWeight}
        value={values.MinWeight ? values.MinWeight : 0}
        className="mt-1  weight"
        ref={ref}
      />

      <label
        htmlFor="weight"
        style={{
          transform: `translateX(${values.MinWeight * step}px)`,
        }}
      >
        <span className="text-white font-PoppinsBold">
          {" "}
          {values.MinWeight} KG
        </span>
        <svg
          width="39"
          height="65"
          viewBox="0 0 75 65"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 0C1.79086 0 0 4.15735 0 9.28572V44.1072C0 49.2354 1.79086 53.3929 4 53.3929H34.1225L37.4331 62.7995C37.8333 63.9366 38.5818 63.9366 38.982 62.7995L42.2926 53.3929H71C73.2091 53.3929 75 49.2354 75 44.1072V9.28572C75 4.15735 73.2091 0 71 0H4Z"
            fill="#3ED400"
          />
        </svg>
      </label>
    </div>
  );
}
