import { useState } from "react";
import { ReactComponent as AdCallIcon } from "../../assets/icons/AdCall.svg";
import { ReactComponent as AdMessageIcon } from "../../assets/icons/AdMessage.svg";
import { ReactComponent as AdWhatsappIcon } from "../../assets/icons/AdWhatsapp.svg";

export default function BuyStarAdsModal({ data, isLoading }) {
  const [showNumberSMS, setShowNumberSMS] = useState(false);
  const [showNumberCall, setShowNumberCall] = useState(false);
  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }
  return (
    <div className="-mt-6">
      <img
        src={data?.data?.image}
        alt="Buy with payment guarantee"
        className="w-[250px] sm:w-[400px] max-w-[600px] h-[390px] rounded-md"
      />
      <div>
        <h3 className="mt-2">Contact Us</h3>
        <div className="grid grid-cols-2">
          <div className="flex space-x-2 items-center">
            <a
              href={`https://api.whatsapp.com/send?phone=${
                data?.data?.whatsapp_number &&
                "92" + data?.data?.whatsapp_number
              }`}
              target={"_blank"}
              rel="noreferrer"
              className=" h-[36px] rounded-[8px] border border-[#39342F] flex items-center  p-[2px]"
            >
              <AdWhatsappIcon />
            </a>
            <button
              onClick={() => {
                setShowNumberCall(false);
                setShowNumberSMS(!showNumberSMS);
              }}
              className="h-[36px] rounded-[8px] border border-[#39342F] flex items-center p-[2px]"
            >
              <AdMessageIcon />
            </button>
            <button
              onClick={() => {
                setShowNumberSMS(false);
                setShowNumberCall(!showNumberCall);
              }}
              className=" h-[36px] rounded-[8px] border border-[#39342F] flex items-center p-[2px]"
            >
              <AdCallIcon />
            </button>{" "}
          </div>

          {showNumberCall && (
            <div className="w-full h-[42px] rounded-[8px] border border-[#39342F] flex items-center p-[4px]">
              <p className="text-center">
                <span>{showNumberCall && "Call No: "}</span>
                {showNumberCall && `${data?.data?.phone_number}`}
              </p>
            </div>
          )}
          {showNumberSMS && (
            <div className="w-full h-[42px] rounded-[8px] border border-[#39342F] flex items-center p-[4px]">
              <p className="text-center">
                <span>{showNumberSMS && "Send SMS: "}</span>

                {showNumberSMS && `${data?.data?.sms_number}`}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
