import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import HeaderSearchBox from "../HomePageComponents/HeaderComponents/HeaderSearchBox";
import LanguageSwitcher from "../HomePageComponents/HeaderComponents/LanguageSwitcher";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";

import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/icons/whatsapp.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/icons/youtube.svg";

import { useNavigate, useLocation } from "react-router-dom";
// import Modal from "../Modal";
// import { useState } from "react";
// import { ReactComponent as PlayStoreIcon } from "../../assets/slider/PlayStore.svg";
// import { ReactComponent as AppStoreIcon } from "../../assets/slider/AppStore.svg";
import { useContext } from "react";
import FilterAdsContext from "../../Context/FilterAdsContext";
import AuthContext from "../../Context/AuthContext";
import LocationFilter from "../HomePageComponents/HeaderComponents/LocationFilter";

const Navigation = {
  menu: [
    { name: "Home", href: "/" },
    { name: "Our Services", href: "/services" },
    { name: "All Categories", href: "/all-categories" },
    // { name: "About Us", href: "/about-us" },
    // { name: "Blogs", href: "/blogs" },
    { name: "Qurbani 2023", href: "/qurbani" },
    // { name: "news", href: "#news" },
    // { name: "Contact Us", href: "/contact-us" },
  ],
};

export default function Header() {
  const { setFilterAds } = useContext(FilterAdsContext);
  const { isAuth, logoutUser } = useContext(AuthContext);
  // const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const handleSearch = (e) => {
    e.preventDefault();
    const data = e.target[0].value;
    if (typeof window.fbq === "function") {
      window.fbq("track", "Search event", { Search_string: data });
    } else {
      console.warn("Facebook Pixel function not yet available");
    }
    const SearchField = document.getElementById("Query1");
    SearchField.value = "";
    setFilterAds((prev) => ({
      ...prev,
      page: 1,
    }));

    navigate(`/all-categories?query=${data}`);
  };
  if (
    location.pathname.startsWith("/account/post-ad") ||
    location.pathname.startsWith("/qurbani/checkout")
  ) {
    return null;
  }
  return (
    <>
      {/* {showModal ? (
        <Modal>
          <div id="modalInner">
            <div id="modalContent" className="font-PoppinsRegular relative ">
              <div className="w-[250px] sm:w-[500px] max-w-[600px] h-[350px] mx-auto flex items-center mt-2 flex-col space-y-3">
                <p className="pt-14 text-center">
                  Buy and sell animals at a faster rate! Download the farmGhar
                  app and post your ad online from the comfort of your home.{" "}
                </p>
                <p className="text-[18px] font-PoppinsBold text-center md:text-left">
                  Get The App Now!
                </p>
                <div className="flex  justify-center md:justify-start">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.farmghar.app"
                    className="mr-[10.53px]"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <PlayStoreIcon />
                  </a>
                  <a
                    href="https://apps.apple.com/pk/app/farmghar-%D8%A2%D9%86-%D9%84%D8%A7%D8%A6%D9%86-%D9%85%D9%88%DB%8C%D8%B4%DB%8C-%D9%85%D9%86%DA%88%DB%8C/id1624237338"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <AppStoreIcon />
                  </a>
                </div>
              </div>
              <div className=" absolute top-[-13px] right-[4px] mt-3">
                <button
                  className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                  onClick={() => setShowModal(false)}
                >
                  <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null} */}

      <Disclosure
        as="nav"
        className="bg-white xl:px-20 pb-6 lg:px-16 md:px-8 sm:px-5 py-1"
      >
        {({ open }) => (
          <>
            <div className="">
              <div className="relative flex justify-between h-16">
                <div className="flex-1 flex items-center ml-[19px] justify-start sm:items-stretch md:justify-start md:ml-0 font-PoppinsBold">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to="/" className="block lg:hidden h-8 w-28">
                      <Logo />
                    </Link>
                    <Link to="/" className="hidden lg:block h-8 w-28 ">
                      <Logo />
                    </Link>
                  </div>
                  <div className="">
                    <HeaderSearchBox />
                  </div>

                  <div className="hidden relative top-[70px] right-[113px] md:flex sm:space-x-8 w-full mx-auto ">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <div className="flex justify-between w-full mx-auto ">
                      <div className="space-x-[10px]  xl:space-x-[20px]">
                        {Navigation.menu.map((item) => (
                          <div
                            className={
                              "inline-block " +
                              (window.location.pathname === item.href &&
                                "check")
                            }
                            key={item.name}
                          >
                            {item.href === "/all-categories" ? (
                              <Link
                                to={item.href}
                                onClick={() =>
                                  setFilterAds((prev) => ({
                                    ...prev,
                                    page: 1,
                                  }))
                                }
                                className={
                                  "text-[10px] lg:text-[12px] " +
                                  (window.location.pathname === item.href &&
                                    "text-Primary")
                                }
                              >
                                {item.name}
                              </Link>
                            ) : (
                              <Link
                                to={item.href}
                                className={
                                  "text-[10px] lg:text-[12px] " +
                                  (window.location.pathname === item.href &&
                                    "text-Primary")
                                }
                              >
                                {item.name}
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="relative left-[100px] space-x-[10px] xl:space-x-[20px] ">
                        {isAuth ? (
                          <>
                            <div
                              className={
                                "inline-block " +
                                (window.location.pathname ===
                                  "/account/transaction-history" && "check")
                              }
                            >
                              <Link
                                to="/account/transaction-history"
                                className={
                                  "text-[10px] lg:text-[12px]  " +
                                  (window.location.pathname ===
                                    "/account/transaction-history" &&
                                    "text-Primary")
                                }
                              >
                                Transaction History
                              </Link>
                            </div>
                            <div
                              className={
                                "inline-block " +
                                (window.location.pathname ===
                                  "/track-your-qurbani" && "check")
                              }
                            >
                              <Link
                                to="/track-your-qurbani"
                                className={
                                  "text-[10px] lg:text-[12px]  " +
                                  (window.location.pathname ===
                                    "/track-your-qurbani" && "text-Primary")
                                }
                              >
                                Track Qurbani
                              </Link>
                            </div>
                            <div
                              className={
                                "inline-block " +
                                (window.location.pathname ===
                                  "/account/my-ads" && "check")
                              }
                            >
                              <Link
                                to="/account/my-ads"
                                className={
                                  "text-[10px] lg:text-[12px] " +
                                  (window.location.pathname ===
                                    "/account/my-ads" && "text-Primary")
                                }
                              >
                                My Ads
                              </Link>
                            </div>
                            <div
                              className={
                                "inline-block " +
                                (window.location.pathname ===
                                  "/account/favourites" && "check")
                              }
                            >
                              <Link
                                to="/account/favourites"
                                className={
                                  "text-[10px] lg:text-[12px] " +
                                  (window.location.pathname ===
                                    "/account/favourites" && "text-Primary")
                                }
                              >
                                Favourites
                              </Link>
                            </div>

                            <Link
                              to="/account/profile"
                              className="text-[10px] lg:text-[12px]"
                            >
                              My Account
                            </Link>
                            <button
                              onClick={(e) => {
                                e.target.innerText = "Logging out...";
                                setTimeout(() => {
                                  logoutUser();
                                }, 2000);
                              }}
                              className="text-[10px] lg:text-[12px] text-Primary"
                            >
                              Logout
                            </button>
                          </>
                        ) : (
                          <>
                            <div
                              className={
                                "inline-block " +
                                (window.location.pathname ===
                                  "/track-your-qurbani" && "check")
                              }
                            >
                              <Link
                                to="/track-your-qurbani"
                                className={
                                  "text-[10px] lg:text-[12px]  " +
                                  (window.location.pathname ===
                                    "/track-your-qurbani" && "text-Primary")
                                }
                              >
                                Track Qurbani
                              </Link>
                            </div>
                            <Link
                              to="/auth/register"
                              className="text-[10px] lg:text-[12px]"
                            >
                              Create Account
                            </Link>
                            <Link
                              to="/auth/login"
                              className="text-[10px] lg:text-[12px] text-Primary"
                            >
                              Login
                            </Link>
                          </>
                        )}
                        {/* <button
                          onClick={() => setShowModal(true)}
                          className="text-[10px] lg:text-[12px] text-TextColor"
                        >
                          Create Account
                        </button>
                        <button
                          onClick={() => setShowModal(true)}
                          className="text-[10px] lg:text-[12px] text-Primary"
                        >
                          Login
                        </button> */}
                      </div>
                    </div>
                  </div>
                  <p className="relative border-"></p>
                </div>
                {/* <a
                href="/candidate/auth/login"
                className="md:hidden flex border-transparent darkBlueColor borderBlueHover darkGrayHoverText  items-center justify-end px-1 pt-1 border-b-4 text-sm font-medium"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                  />
                </svg>
              </a> */}
                <div className="absolute inset-y-0 right-0 top-[9px] flex items-center md:hidden">
                  <div className="">
                    <LanguageSwitcher />
                  </div>
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-0 ">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <div className="border-b border-gray-100 absolute left-0 w-full pt-1"></div>

            <div className="flex justify-center flex-col-reverse md:hidden pt-4 items-center bg-[#F4F4F4] py-[12px] px-[19px] w-full absolute left-0">
              <form
                onSubmit={handleSearch}
                className="mt-1  rounded-md shadow-sm  w-full flex mx-auto"
              >
                <div className="relative flex flex-grow items-stretch focus:outline-none focus:border-none">
                  <input
                    type="text"
                    name="search"
                    id="Query1"
                    className="block w-full text-TextColor pl-4 font-PoppinsRegular text-sm border-[0.5px] border-[#39342F] rounded-[8px] border-r-none focus:outline-none focus:ring-0 SearchInput "
                    placeholder="Search"
                  />
                </div>
                <button
                  type="submit"
                  name="search"
                  className="relative -ml-px inline-flex items-center space-x-2 rounded-r-[8px] w-[34px] p-[7px] text-sm font-medium bg-Primary"
                >
                  <SearchIcon />
                </button>
              </form>
              <div className=" flex mb-2">
                <LocationFilter />
              </div>
            </div>
            <div className="border-b border-gray-100 absolute left-0 w-full pt-1 top-[100px] hidden md:block"></div>

            <Disclosure.Panel className="md:hidden mt-24 divide-y-2 divide-gray-300 font-PoppinsBold">
              <div className="pt-2 pb-4 space-y-2 flex flex-col ml-5">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}

                {Navigation.menu.map((item) => (
                  <div
                    className={
                      "inline-block " +
                      (window.location.pathname === item.href && "check")
                    }
                    key={item.name}
                  >
                    {item.href === "/all-categories" ? (
                      <Disclosure.Button
                        as={Link}
                        to={item.href}
                        onClick={() =>
                          setFilterAds((prev) => ({
                            ...prev,
                            page: 1,
                          }))
                        }
                        className={
                          "text-[14px] " +
                          (window.location.pathname === item.href &&
                            "text-Primary")
                        }
                      >
                        {item.name}
                      </Disclosure.Button>
                    ) : (
                      <Disclosure.Button
                        as={Link}
                        to={item.href}
                        className={
                          "text-[14px] " +
                          (window.location.pathname === item.href &&
                            "text-Primary")
                        }
                      >
                        {item.name}
                      </Disclosure.Button>
                    )}
                  </div>
                ))}
              </div>
              {/* <div className="space-y-2 flex flex-col ml-5  py-2"> */}
              <div className="space-y-2 flex flex-col items-start ml-5  py-2">
                {isAuth ? (
                  <>
                    <div
                      className={
                        "inline-block " +
                        (window.location.pathname ===
                          "/account/transaction-history" && "check")
                      }
                    >
                      <Disclosure.Button
                        as={Link}
                        to={"/account/transaction-history"}
                        className={
                          "text-[14px] " +
                          (window.location.pathname ===
                            "/account/transaction-history" && "text-Primary")
                        }
                      >
                        Transaction History
                      </Disclosure.Button>
                    </div>
                    <div
                      className={
                        "inline-block " +
                        (window.location.pathname === "/track-your-qurbani" &&
                          "check")
                      }
                    >
                      <Disclosure.Button
                        as={Link}
                        to={"/track-your-qurbani"}
                        className={
                          "text-[14px]  " +
                          (window.location.pathname === "/track-your-qurbani" &&
                            "text-Primary")
                        }
                      >
                        Track Qurbani
                      </Disclosure.Button>
                    </div>
                    <div
                      className={
                        "inline-block " +
                        (window.location.pathname === "/account/my-ads" &&
                          "check")
                      }
                    >
                      <Disclosure.Button
                        as={Link}
                        to={"/account/my-ads"}
                        className={
                          "text-[14px] " +
                          (window.location.pathname === "/account/my-ads" &&
                            "text-Primary")
                        }
                      >
                        My Ads
                      </Disclosure.Button>
                    </div>
                    <div
                      className={
                        "inline-block " +
                        (window.location.pathname === "/account/favourites" &&
                          "check")
                      }
                    >
                      <Disclosure.Button
                        as={Link}
                        to={"/account/favourites"}
                        className={
                          "text-[14px] " +
                          (window.location.pathname === "/account/favourites" &&
                            "text-Primary")
                        }
                      >
                        Favourites
                      </Disclosure.Button>
                    </div>

                    <Disclosure.Button
                      as={Link}
                      to={"/account/profile"}
                      className="text-[14px]"
                    >
                      My Account
                    </Disclosure.Button>

                    <Disclosure.Button
                      as="button"
                      onClick={() => {
                        logoutUser();
                      }}
                      className="text-[14px] text-Primary"
                    >
                      Logout
                    </Disclosure.Button>
                  </>
                ) : (
                  <>
                    <div
                      className={
                        "inline-block " +
                        (window.location.pathname === "/track-your-qurbani" &&
                          "check")
                      }
                    >
                      <Disclosure.Button
                        as={Link}
                        to={"/track-your-qurbani"}
                        className={
                          "text-[14px]  " +
                          (window.location.pathname === "/track-your-qurbani" &&
                            "text-Primary")
                        }
                      >
                        Track Qurbani
                      </Disclosure.Button>
                    </div>
                    <Disclosure.Button
                      as={Link}
                      to={"/auth/register"}
                      className="text-[14px]"
                    >
                      Create Account
                    </Disclosure.Button>

                    <Disclosure.Button
                      as={Link}
                      to={"/auth/login"}
                      className="text-[14px] text-Primary"
                    >
                      Login
                    </Disclosure.Button>
                  </>
                )}

                {/* <Disclosure.Button
                  onClick={() => setShowModal(true)}
                  className="text-[14px] text-TextColor"
                >
                  Create Account
                </Disclosure.Button>
                <Disclosure.Button
                  onClick={() => setShowModal(true)}
                  className="text-[14px] text-Primary"
                >
                  Login
                </Disclosure.Button> */}
              </div>
              <div className="space-y-2 flex flex-col ml-5 py-2">
                <h4 className="font-bold text-[14px] font-PoppinsBold">
                  Follow US
                </h4>
                <div className="flex pb-6 space-x-2 items-center">
                  <a
                    href="https://www.facebook.com/official.farmGhar/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UC0Km80c5qBFojTcG9SMRoZg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <YoutubeIcon />
                  </a>
                  <a
                    href="https://api.whatsapp.com/send?phone=923019681454"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <WhatsappIcon />
                  </a>
                  <a
                    href="https://instagram.com/farmghar?igshid=YmMyMTA2M2Y="
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
