import FeauresAd from "../../assets/images/FeaturesAd.png";

export default function Features({ data }) {
  return (
    <div className=" mx-[10px] mt-[12px] ">
      <div
        className="w-full rounded-[15px] pb-[10px]"
        style={{
          backgroundImage: `url(${FeauresAd})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <h4 className="pl-[23px] pt-[21px] font-PoppinsBold text-[16px]">
          Features
        </h4>

        <div className="grid grid-cols-1 sm:grid-cols-2  gap-6 lg:grid-cols-3  pt-2">
          {data?.details?.map((item, index) => (
            <div key={index} className="ml-[20px]">
              <div className="flex items-center space-x-2 ">
                <div className="w-[52px] h-[52px] rounded-[10px] bg-[#F2F2F2] flex items-center justify-center">
                  <img
                    src={item?.icon}
                    alt={item?.title}
                    width="24.38"
                    height="25"
                  />
                </div>
                <div>
                  <p>{item?.title}</p>
                  <p>{item?.value}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
