import Breadcrumbs from "../Components/BreadCrumbs";
import BannerTop from "../Components/HomePageComponents/BannerTop";
import CallToActionBottom from "../Components/HomePageComponents/CallToActionBottom";
// import AdImage from "../assets/images/ad.png";
import LatestPosts from "../Components/BlogsComponents/BlogDetails/LatestPosts";
import SingleArticle from "../Components/BlogsComponents/BlogDetails/SingleArticle";
import { useFetchBlogPost, useFetchAllBlogsPosts } from "../Middlewares/Api";
import { useParams } from "react-router-dom";

import UpdateMetaTags from "../Utils/UpdateMetaTags";
import SquareGoogleAd from "../Utils/SquareGoogleAd";

export default function BlogDetails() {
  const { BlogSlug } = useParams();
  const { data: BlogPost, isLoading: BlogPostLoading } = useFetchBlogPost({
    BlogPostSlug: BlogSlug,
  });

  const { data: BlogsPosts, isLoading: BlogsPostsLoading } =
    useFetchAllBlogsPosts({ page: 1 });
  if (BlogsPostsLoading || BlogPostLoading) {
    return (
      <div className="flex items-center justify-center mt-20">
        <div className="spinner"></div>
      </div>
    );
  }
  if (BlogPost?.data?.length === 0) {
    return (
      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>
        <div className="flex items-center justify-center mt-20">
          <div className="text-2xl font-PoppinsRegular">No Article Found</div>
        </div>
      </div>
    );
  }

  return (
    <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
      <div className="pt-3 ">
        <BannerTop />
      </div>
      <div>
        <Breadcrumbs />
      </div>
      <UpdateMetaTags data={BlogPost.data} />
      <div className="pt-4 font-PoppinsRegular">
        <div className="flex  flex-col md:flex-row">
          <div className="w-full md:w-[67%] lg:w-[70%]  mr-[25px]">
            <SingleArticle postdata={BlogPost.data} />
          </div>
          <div className="w-full md:w-[40%] lg:w-[30%] space-y-4 pt-10 md:pt-0 flex items-center flex-col ">
            <LatestPosts
              BlogsPosts={BlogsPosts.data}
              CurrentPostId={BlogPost?.data?.id}
            />
            <div className="pt-4">
              <SquareGoogleAd />
            </div>
          </div>
        </div>
      </div>
      <CallToActionBottom />
    </div>
  );
}
