import {
  useFetchSingleCategoryDetails,
  useFetchGetIdFromSlugCategory,
  useFetchHomePageCategories,
  useFetchCategorySeoContent,
} from "../../Middlewares/Api";

import { useState, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ReactComponent as StarIcon } from "../../assets/icons/star.svg";
import { ReactComponent as VerifiedIcon } from "../../assets/icons/verified.svg";
import { ReactComponent as PackageIcon } from "../../assets/icons/package.svg";
import { ReactComponent as DeliveryIcon } from "../../assets/icons/delivery.svg";
import { ReactComponent as LocationAdIcon } from "../../assets/icons/locationAd.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import CategorySeoContent from "./CategorySeoContent";
import { ReactComponent as SoldOutIcon } from "../../assets/icons/SoldOut.svg";

import BannerTop from "../HomePageComponents/BannerTop";
// import AdImage from "../../assets/images/ad.png";
import SquareGoogleAd from "../../Utils/SquareGoogleAd";

import ReactPaginate from "react-paginate";
import { XMarkIcon } from "@heroicons/react/24/outline";
// import BannerTop from "../HomePageComponents/BannerTop";
import { useContext } from "react";
import FilterAdsContext from "../../Context/FilterAdsContext";
import LocationContext from "../../Context/LocationContext";
import Filter from "./Filter";
import GetSelectedFilters from "./GetSelectedFilters";
import PreserveFormikFilterContext from "../../Context/PreserveFormikFilterContext";

export default function SingleCategoryDetails() {
  const { CatSlug } = useParams();
  const CatId = useFetchGetIdFromSlugCategory({ CatSlug: CatSlug }).data
    ?.category_id;
  const [searchParams, setSearchParams] = useSearchParams();
  const Query = searchParams.get("query");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { filterAds, setFilterAds } = useContext(FilterAdsContext);
  const { formikInitialValues, setFormikInitialValues } = useContext(
    PreserveFormikFilterContext
  );
  const [open, setOpen] = useState(false);
  const { locationStates, setLocationStates } = useContext(LocationContext);
  const { data: SingleCategoryDetails, isLoading: CategoryDetailsLoading } =
    useFetchSingleCategoryDetails({
      CatId: filterAds.parent_category ? filterAds.parent_category : CatId,
      Query: Query ? Query : null,
      page: filterAds.page,
      sortBy: filterAds.sortBy,
      Province: filterAds.Province,
      District: filterAds.district,
      Tehsil: filterAds.Tehsil,
      Category: filterAds.Category,
      MaxPrice: filterAds.MaxPrice,
      Breed: filterAds.Breed,
      IsDelivery: filterAds.IsDelivery,
      IsVerified: filterAds.IsVerified,
      IsPregnant: filterAds.IsPregnant,
      MinWeight: filterAds.MinWeight,
      MinAge: filterAds.MinAge,
      MaxAge: filterAds.MaxAge,
      Castrated: filterAds.Castrated,
      latitude: locationStates.isViewNearby ? locationStates.lat2 : null,
      longitude: locationStates.isViewNearby ? locationStates.lng2 : null,
    });
  const { data: DataCategorySeoContent, isLoading: CategorySeoContentLoading } =
    useFetchCategorySeoContent({
      CatId: filterAds.parent_category ? filterAds.parent_category : CatId,
    });

  const { data: HomePageCategories, isLoading: CategoriesLoading } =
    useFetchHomePageCategories();
  useEffect(() => {
    searchParams.delete("filter");
    setSearchParams(searchParams);

    if (
      filterAds.SelectedPaginationCategory !== undefined &&
      CatId !== filterAds.SelectedPaginationCategory
    ) {
      setFilterAds((prev) => ({
        ...prev,
        page: 1,
      }));
    }

    if (HomePageCategories) {
      const Category = HomePageCategories.find(
        (category) => category.category_slug === CatSlug
      );

      setSelectedCategory(Category);

      setFilterAds((prev) => ({
        ...prev,
        sortBy: "",
        parent_category: Category?.id,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HomePageCategories, CatSlug]);

  if (
    CategoryDetailsLoading ||
    CategoriesLoading ||
    CategorySeoContentLoading
  ) {
    return (
      <div className="flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  const handlePageClick = (d) => {
    setFilterAds((prev) => ({
      ...prev,
      page: d.selected + 1,
      SelectedPaginationCategory: CatId,
    }));
    window.scrollTo(0, 0);
  };

  const handleSortBy = (e) => {
    setFilterAds((prev) => ({
      ...prev,
      sortBy: e.target.value,
    }));
  };

  const DisplaySoldOut = () => {
    return alert("This item has been sold and no longer available.");
  };

  const getShortName = (name) => {
    let str;

    if (name.length > 14) {
      str = name.substring(0, 20) + "...";
    } else {
      str = name;
    }
    return str;
  };
  const RemoveFilterIsNearbyAds = () => {
    setLocationStates((prev) => ({
      ...prev,
      isViewNearby: false,
      locality: null,
    }));
  };
  return (
    <>
      <div>
        <Filter
          open={open}
          setOpen={setOpen}
          filterAds={filterAds}
          setFilterAds={setFilterAds}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          setLocationStates={setLocationStates}
          locationStates={locationStates}
        />
        <div className="font-PoppinsBold text-[22px] ">
          {selectedCategory && <h2>{selectedCategory.name}</h2>}
        </div>

        <div className="pt-2 md:pt-4">
          <div className="flex  flex-col-reverse xl:flex-row">
            <div className="w-full xl:w-[30%] space-y-4 pt-10 xl:pt-0 flex items-center flex-col xl:block">
              <SquareGoogleAd />
            </div>
            <div className="w-full xl:w-[70%]">
              <div className="text-[16px]  md:-mt-8 pt-2 ">
                {locationStates.isViewNearby && (
                  <div className="bg-gray-200 p-1 rounded-md text-TextColor border border-[#3ED400] font-PoppinsRegular text-[12px] mr-2 mt-2 inline-flex items-center">
                    View nearby ads{" "}
                    <button
                      onClick={() => RemoveFilterIsNearbyAds()}
                      className="font-PoppinsBold text-Primary"
                    >
                      <XMarkIcon className="block h-4 w-4" />
                    </button>
                  </div>
                )}
                <GetSelectedFilters
                  filters={formikInitialValues}
                  setFilters={setFormikInitialValues}
                  filterAds={filterAds}
                  setFilterAds={setFilterAds}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  setLocationStates={setLocationStates}
                />
              </div>

              <div className="mt-4 flex justify-end space-x-2 text-[12px] ">
                <div className="rounded-[14px] bg-gray-200 w-auto p-2 h-auto flex justify-center items-center text-center">
                  {SingleCategoryDetails && (
                    <span className="text-[#0C2302] ">
                      {SingleCategoryDetails.records}{" "}
                      {SingleCategoryDetails.records === "1" ? "Ad" : "Ads"}
                    </span>
                  )}
                </div>
                {/* <div className="space-x-2 lg-[800px]:space-x-5  items-center font-bold hidden md:flex text-[10px] lg-[800px]:text-[12px] w-[60%] whitespace-nowrap overflow-x-auto scrollbar">
                  {HomePageCategories &&
                    HomePageCategories.map(
                      (category) =>
                        category.id !== 1 && (
                          <div
                            key={category.id}
                            className={
                              "inline-block  " +
                              (category.category_slug === CatSlug && "check")
                            }
                          >
                            <Link
                              onClick={() => {
                                setFilterAds((prev) => ({
                                  ...prev,
                                  page: 1,
                                }));
                              }}
                              to={`/${category.category_slug}`}
                              className={
                                "text-TextColor " +
                                (category.category_slug === CatSlug &&
                                  "text-Primary")
                              }
                            >
                              {category.name}
                            </Link>
                          </div>
                        )
                    )}
                </div> */}
                <div className="flex space-x-2  items-center font-PoppinsBold">
                  <p>Filter</p>
                  <button onClick={() => setOpen(true)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
                      />
                    </svg>
                  </button>
                </div>
                <div className="border-l-2 border-[#B6B6B6] inline-block"></div>
                <div className="flex space-x-2  items-center">
                  <span className="text-TextColor font-PoppinsBold">
                    Sort by:
                  </span>
                  <select
                    className="ml-2 font-PoppinsRegular"
                    onChange={handleSortBy}
                    defaultValue={filterAds.sortBy}
                  >
                    <option value="">Low price</option>
                    <option value="d">High price</option>
                  </select>
                </div>
              </div>

              {SingleCategoryDetails &&
              SingleCategoryDetails?.data?.length > 0 ? (
                <ul className="flex items-center justify-center">
                  <div className="grid grid-cols-1 sm:grid-cols-2  gap-6 lg:grid-cols-3  pt-2">
                    {SingleCategoryDetails &&
                      SingleCategoryDetails.data.map((item, index) => (
                        <li key={index}>
                          <div
                            className={`rounded-[13px] border border-[#DBDED9] ${
                              item?.managed_by_farmghar === "1"
                                ? "bg-Primary"
                                : "bg-white"
                            } w-[255px] h-[312px]`}
                          >
                            <Link
                              to={
                                /sold/i.test(item.ad_status)
                                  ? "#sold"
                                  : `/${selectedCategory?.category_slug}/${item?.ad_slug}`
                              }
                              onClick={
                                /sold/i.test(item.ad_status)
                                  ? DisplaySoldOut
                                  : null
                              }
                              className="w-full h-full flex flex-col justify-center"
                            >
                              <div className="rounded-[8px] mt-[6.77px] mr-[5.95px] ml-[5px] w-[244.48px] h-[180.49px] ">
                                <div className="relative w-[244.48px] h-[180.49px]">
                                  <figure className="w-full h-full">
                                    <img
                                      src={
                                        item.img_thumbnail && item.img_thumbnail
                                      }
                                      className=" rounded-[9px] w-full h-full object-cover"
                                      alt={item.ad_title && item.ad_title}
                                    />
                                  </figure>

                                  {item?.is_star === "1" &&
                                    item?.priority === "11" && (
                                      <div className="absolute top-[-15px] left-[-15px]">
                                        <StarIcon />
                                      </div>
                                    )}
                                  {item.ad_status &&
                                    /sold/i.test(item.ad_status) && (
                                      <div className="absolute right-[85px] top-[86px]">
                                        <SoldOutIcon />
                                      </div>
                                    )}

                                  {/* <div className="absolute top-[11.49px]  right-[9.84px] bg-gray-50 h-9 w-9 rounded-full flex justify-center items-center bg-opacity-25">
                                    {" "}
                                    <svg
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (!isAuth) {
                                          navigate("/auth/login");
                                        }
                                        const target = e.target;
                                        const response =
                                          isAuth &&
                                          mutateAddtoFavList({
                                            ad_id: item?.id,
                                            key: localStorage.getItem("key"),
                                          });

                                        Promise.resolve(response).then(
                                          (res) => {
                                            if (
                                              res?.code === 200 ||
                                              res?.code === 300
                                            ) {
                                              target.classList.add(
                                                "animate-pulse"
                                              );
                                              target.classList.toggle(
                                                "fill-[#FE251B]"
                                              );
                                              target.classList.toggle(
                                                "stroke-[#FE251B]"
                                              );
                                              setTimeout(() => {
                                                target.classList.remove(
                                                  "animate-pulse"
                                                );
                                              }, 1000);
                                            }
                                            if (res?.code === 201) {
                                              alert(res?.message);
                                            }
                                          }
                                        );
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      strokeWidth="2"
                                      stroke={"black"}
                                      className={`w-6 h-6 cursor-pointer ${
                                        String(item?.is_fav) === "1" &&
                                        "fill-[#FE251B] stroke-[#FE251B]"
                                      }  `}
                                      fill="none"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                                      />
                                    </svg>
                                  </div> */}

                                  {item.is_verified === "1" && (
                                    <div className="absolute bottom-[7.62px] right-[6.43px]">
                                      <VerifiedIcon />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className={` ${
                                  item?.managed_by_farmghar === "1"
                                    ? "bg-white rounded-md ml-0 mr-0 px-3 m-[3.51px]"
                                    : "m-[12.51px]"
                                }`}
                              >
                                <div
                                  className={`flex ${
                                    item?.managed_by_farmghar === "1"
                                      ? "mt-[3px] pt-[3px]"
                                      : null
                                  }`}
                                >
                                  {item.is_delivery === "1" && (
                                    <div>
                                      <DeliveryIcon />
                                    </div>
                                  )}
                                  {item.is_delivery_ad === "1" && (
                                    <div>
                                      <PackageIcon />
                                    </div>
                                  )}
                                </div>

                                {/* for Ad */}
                                {item.ad_title && (
                                  <div>
                                    <h3 className="font-PoppinsBold text-[16px]">
                                      {getShortName(item.ad_title)}
                                    </h3>
                                  </div>
                                )}

                                <div className="flex justify-between">
                                  {item.posted_text && (
                                    <p className="font-[10px] text-[#39342F] opacity-50">
                                      {item.posted_text}
                                    </p>
                                  )}
                                  {item.price && (
                                    <p className="text-black font-[18px]">
                                      RS {item.price}
                                    </p>
                                  )}
                                </div>

                                <div className="flex justify-between">
                                  {item.district && (
                                    <div className="flex items-center">
                                      <div className="mr-[5.85px]">
                                        <LocationAdIcon />
                                      </div>
                                      <p>{item.district}</p>{" "}
                                    </div>
                                  )}
                                  {item.detailviews && (
                                    <div className="flex items-center">
                                      <div className="opacity-50 mr-[5.85px]">
                                        <EyeIcon />
                                      </div>
                                      <p className="text-Primary">
                                        {item.detailviews}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {item?.managed_by_farmghar === "1" && (
                                <div className="flex justify-center text-white font-bold text-[14px]">
                                  farmGhar Self-stock
                                </div>
                              )}
                            </Link>
                          </div>
                        </li>
                      ))}
                  </div>
                </ul>
              ) : (
                <div className="flex justify-center items-center h-[300px]">
                  <div className="flex flex-col items-center">
                    <p className="text-[#39342F] font-PoppinsBold text-[18px] mt-[20px]">
                      No Ads Found
                    </p>
                  </div>
                </div>
              )}

              {SingleCategoryDetails?.pages === 1 ||
              SingleCategoryDetails?.pages === 0 ? null : (
                <ReactPaginate
                  previousLabel={
                    <button
                      title="previous"
                      type="button"
                      className="inline-flex items-center justify-center  py-0  "
                    >
                      <svg
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4"
                      >
                        <polyline points="15 18 9 12 15 6"></polyline>
                      </svg>
                    </button>
                  }
                  nextLabel={
                    <button
                      title="next"
                      type="button"
                      className="inline-flex items-center justify-center  py-0  "
                    >
                      <svg
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </button>
                  }
                  breakLabel={"..."}
                  forcePage={filterAds.page - 1}
                  pageCount={Math.ceil(SingleCategoryDetails?.pages)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={
                    "flex justify-center items-center space-x-1   my-10 "
                  }
                  pageClassName={
                    "inline-flex items-center justify-center w-8 h-8 text-sm  "
                  }
                  pageLinkClassName={"page-link "}
                  previousClassName={
                    "inline-flex items-center justify-center py-0 "
                  }
                  previousLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={
                    "inline-flex items-center justify-center w-8 h-8 text-sm font-semibold border rounded-full shadow-md bg-[#3ED400] text-white"
                  }
                />
              )}

              <BannerTop />
              {/* adding category seo content */}
              <div className="mt-10">
                <CategorySeoContent data={DataCategorySeoContent?.data} />
              </div>
              {/* <div className="pt-3 ">
                <BannerTop />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
