import { useContext, useEffect } from "react";
import { CartContext } from "../../Context/CartContext";
export default function CheckoutButton() {
  const { cartItems } = useContext(CartContext);

  useEffect(() => {}, [cartItems]);
  return (
    <a
      href="/qurbani/checkout"
      className="p-2 flex justify-around font-PoppinsBold text-white w-full max-w-[270px] items-center bg-Primary rounded-[15px]"
    >
      <div className="relative w-[44.67px] h-[44.67px] border-2 border-white rounded-[14px] flex justify-center items-center">
        <div className="absolute bg-white max-w-full w-[18px] h-[18px] rounded-full top-[-8px] right-[-4px] flex justify-center items-center">
          <span className="text-[12px] text-TextColor">
            {cartItems?.length}
          </span>
        </div>
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.4861 24.0834C18.5063 24.0834 19.3333 23.2563 19.3333 22.2361C19.3333 21.2159 18.5063 20.3889 17.4861 20.3889C16.4659 20.3889 15.6389 21.2159 15.6389 22.2361C15.6389 23.2563 16.4659 24.0834 17.4861 24.0834Z"
            fill="white"
          />
          <path
            d="M9.04162 24.0834C10.0618 24.0834 10.8888 23.2563 10.8888 22.2361C10.8888 21.2159 10.0618 20.3889 9.04162 20.3889C8.02143 20.3889 7.1944 21.2159 7.1944 22.2361C7.1944 23.2563 8.02143 24.0834 9.04162 24.0834Z"
            fill="white"
          />
          <path
            d="M5.44222 4.49228L5.2311 7.07839C5.18888 7.5745 5.57944 7.98617 6.07555 7.98617H22.2361C22.6794 7.98617 23.0489 7.64839 23.0805 7.20505C23.2178 5.33672 21.7928 3.81672 19.9244 3.81672H6.95166C6.8461 3.35228 6.63499 2.90894 6.30777 2.5395C5.77999 1.98005 5.0411 1.65283 4.2811 1.65283H2.44444C2.01166 1.65283 1.65277 2.01172 1.65277 2.4445C1.65277 2.87728 2.01166 3.23617 2.44444 3.23617H4.2811C4.60833 3.23617 4.91444 3.37339 5.1361 3.60561C5.35777 3.84839 5.46333 4.16505 5.44222 4.49228Z"
            fill="white"
          />
          <path
            d="M21.9828 9.56949H5.79054C5.3472 9.56949 4.98831 9.90727 4.94609 10.34L4.56609 14.9317C4.41831 16.7367 5.83276 18.2778 7.63776 18.2778H19.3755C20.9589 18.2778 22.3522 16.9795 22.4683 15.3962L22.8166 10.4667C22.8589 9.98116 22.4789 9.56949 21.9828 9.56949Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="sm:block hidden ml-1">Proceed to Checkout</div>
      <div className="sm:block hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="white"
          className="w-6 h-6 inline-block"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </div>
    </a>
  );
}
