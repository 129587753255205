import Slider from "react-slick";
import BannerTopLoader from "./BannerTopLoader";
const settings = {
  ViewAll: "View All",
  Width: "xsm:w-[240px]",
  Height: "h-[312px]",
  ImageWidth: "xsm:w-[229.48px]",
  ImageHeight: "h-[180.49px]",
  hiddenOnOneSlide: "lg:block",
  className: "slider variable-width",
  variableWidth: true,
  infinite: false,
  // spaceing vertical bug fixed we have to use slidetoshow card less than breakpoint width
  responsive: [
    {
      breakpoint: 1227,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        infinite: false,
        arrows: false,
      },
    },
    {
      breakpoint: 920,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        infinite: false,
        arrows: false,
      },
    },
    {
      breakpoint: 631,
      settings: {
        sldesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false,
        infinite: false,
        arrows: false,
      },
    },
    {
      breakpoint: 630,
      settings: {
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false,
        slidesToShow: 1,
        infinite: false,
        arrows: false,
      },
    },
  ],
};

export default function AdsByCategoryLoader() {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => {
    let ShowBanner = false;
    let indexx = index + 1;
    if (indexx % 2 === 0) {
      ShowBanner = true;
    }
    return (
      <div key={index} className="pt-3 relative animate-pulse">
        <div className="flex justify-between mt-[21px] mb-[28px] ">
          <p className="font-PoppinsBold md:text-[22px]  w-20 h-2 bg-gray-200"></p>
          <p className="font-PoppinsBold text-[18px] text-Primary mr-[15px] w-10 h-2 bg-gray-200"></p>
        </div>
        <Slider {...settings}>
          {[1, 2, 3, 4, 5, 6, 7].map((index) => (
            <div key={index} style={{ width: 270 }}>
              <div
                className="
                        rounded-[13px] border border-[#DBDED9]  bg-white   w-[240px]  xsm:w-[240px] h-[312px]
                        "
              >
                <div>
                  <div className="rounded-[8px] mt-[6.77px] mx-[5.95px] w-[230px] xsm:w-[229.48px] h-[180.49px]">
                    <div className="relative w-[230px] xsm:w-[229.48px] h-[180.49px]">
                      <div className=" rounded-[9px] w-full h-full object-cover bg-gray-100 "></div>
                      <div className="absolute top-[14px] left-[6px]">
                        <p className="w-[50px] h-[10px] bg-gray-200"></p>
                      </div>
                      <div className="absolute bottom-[7.62px] right-[6.43px]">
                        <p className="w-[76px] h-[28px] bg-gray-200"></p>
                      </div>
                    </div>
                  </div>
                  <div className="m-[12.51px]">
                    <div className="flex"></div>
                    <div>
                      <p className="font-PoppinsBold  text-[16px] w-10 h-2 bg-gray-200"></p>
                    </div>
                    <div className="flex justify-between mt-[20px]">
                      <p className=" w-6 h-2 bg-gray-200"></p>
                      <p className="font-[18px] w-6 h-2 bg-gray-200"></p>
                    </div>
                    <div className="flex justify-between mt-[10px]">
                      <div className="flex items-center">
                        <div className="mr-[5.85px]">
                          <p className="h-[14px] w-[11px] bg-gray-100"></p>
                        </div>
                        <p className="w-6 h-2 bg-gray-200"></p>
                      </div>
                      <div className="flex items-center">
                        <div className="opacity-50 mr-[5.85px]">
                          <p className="h-[16px] w-[16px]  bg-gray-200"></p>
                        </div>
                        <p className="text-Primary w-6 h-2 bg-gray-200"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {ShowBanner && <BannerTopLoader />}
      </div>
    );
  });
}
