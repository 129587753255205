import OwnerInformation from "../../Components/AdDetailsPageComponents/OwnerInformation";
import AdSlider from "../../Components/AdDetailsPageComponents/AdSlider";
import AdInformation from "../../Components/AdDetailsPageComponents/AdInformation";
import Features from "../../Components/AdDetailsPageComponents/Features";
import { ReactComponent as PriceSideIcon } from "../../assets/icons/PriceSideIcon.svg";
export default function AdPreview({
  data,
  makeAdDraft,
  PostAdSuccessfully,
  edit,
}) {
  return (
    <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
      <div className="pt-4 font-PoppinsRegular">
        <div className="flex  flex-col md:flex-row">
          <div className="w-full md:w-[58%] lg:w-[70%] rounded-[13px] border border-[rgba(103, 103, 103, 0.1)] mr-[25px]">
            <AdSlider data={data ?? {}} />
            <AdInformation data={data ?? {}} />
            <div className=" justify-between mx-2 mt-[20px] flex">
              <div className="space-x-2 items-center flex">
                <PriceSideIcon />
                <div>
                  <p>Price</p>
                  <p className="font-PoppinsBold text-Primary">
                    RS {data?.price && data.price}{" "}
                    <span>{data?.price_type && data.price_type}</span>
                  </p>
                </div>
              </div>
            </div>
            <Features data={data ?? {}} />
            <div className="mx-[10px] divide-y divide-gray-300">
              <div className="pb-[5px] pt-[20px]">
                <h4 className="font-PoppinsBold ">Description</h4>
                <p className="text-[#848484] text-justify">
                  {data?.description}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-[40%] lg:w-[30%] space-y-4 pt-10 md:pt-0 flex items-center flex-col pb-10 md:pb-0">
            <div className="md:flex justify-between flex-col w-full space-y-4 hidden">
              <button
                className="bg-Primary text-white  text-[14px] py-[10px] px-[20px] rounded-[13px]"
                type="button"
                onClick={PostAdSuccessfully}
              >
                Publish Ad
              </button>
              {!edit && (
                <button
                  className="bg-[#EFA922] text-white font-PoppinsMedium text-[14px] py-[10px] px-[20px] rounded-[13px]"
                  type="button"
                  onClick={makeAdDraft}
                >
                  Make Ad Draft
                </button>
              )}
            </div>
            <OwnerInformation data={data ?? {}} isAdPreview={true} />
          </div>
          {/* show fixed make draft and publish button on buttom  */}
          <div className="z-50 md:hidden block fixed bottom-0 left-0 w-full bg-white border-t border-[rgba(103, 103, 103, 0.1)] py-4 px-4 md:px-8 lg:px-16 xl:px-20">
            <div className="flex justify-between flex-col w-full space-y-4">
              <button
                className="bg-Primary text-white  text-[14px] py-[10px] px-[20px] rounded-[13px]"
                type="button"
                onClick={PostAdSuccessfully}
              >
                Publish Ad
              </button>
              {!edit && (
                <button
                  className="bg-[#EFA922] text-white font-PoppinsMedium text-[14px] py-[10px] px-[20px] rounded-[13px]"
                  type="button"
                  onClick={makeAdDraft}
                >
                  Make Ad Draft
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
