import { useNavigate } from "react-router-dom";
export default function OwnerInformation({ data, isAdPreview }) {
  const navigate = useNavigate();
  return (
    <div className="w-[313px] h-[296px] block rounded-[13px] border border-[rgba(103, 103, 103, 0.1)] pt-[17px]">
      <h3 className="pl-[15px]">Owner Information</h3>
      <div className="flex justify-center">
        <div className="w-[286px] h-[235px] back flex items-center flex-col space-y-2">
          <div className="mt-[25px]">
            <img
              className="w-24 h-24 rounded-full mx-auto object-cover"
              src={data?.profile_image && `${data.profile_image}`}
              alt="owner"
              width="384"
              height="512"
            />
          </div>
          <p className="">{data?.ad_user_name && data.ad_user_name}</p>
          <button
            onClick={() =>
              navigate(`/view-all-user-ads`, { state: data?.ad_user_id })
            }
            disabled={isAdPreview}
            className="text-[#EFA922] underline mt-[-4px]"
          >
            View All Ads
          </button>
          <div className="pt-[4px]">
            <div className="w-[106px] h-[34px] bg-white text-TextColor p-2 rounded-[12px] text-[13px] text-center">
              {data?.total_user_ad
                ? data.total_user_ad > 1
                  ? data.total_user_ad + " " + "Ads Live"
                  : data.total_user_ad + " " + "Ad Live"
                : "0 Ad Live"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
