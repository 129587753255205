import { ReactComponent as VideoTopBarIcon } from "../../assets/icons/VideoTopBar.svg";
import { ReactComponent as VideoBottomBarIcon } from "../../assets/icons/VideoBottomBar.svg";

export default function VideoComponent() {
  return (
    //adding youtube video
    <div className="video-container my-16 mb-40 w-[90%] h-[350px] sm:h-[500.91px] mx-auto">
      <VideoTopBarIcon />

      <iframe
        loading="lazy"
        className="max-w-[932px] mx-auto"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/9_wkeMvuv8w?rel=0&controls=0&showinfo=0&loop=1"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        rel="0"
        allowFullScreen
      ></iframe>

      <VideoBottomBarIcon />
    </div>
  );
}
