import { Outlet, Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../Context/AuthContext";

const PrivateRoute = () => {
  const { isAuth } = useContext(AuthContext);
  if (!isAuth) return <Navigate to="/auth/login" />;
  return <Outlet />;
};
export default PrivateRoute;
