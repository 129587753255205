import { ReactComponent as InputPasswordIcon } from "../../assets/icons/InputPassword.svg";
import { useOutletContext } from "react-router-dom";
import { useEffect } from "react";
export default function ChangePassword() {
  const { formik, handleUserChangePassword, PassResetChangePasswordLoading } =
    useOutletContext();
  useEffect(() => {
    formik.values.error && formik.setFieldValue("error", false);
    formik.values.success && formik.setFieldValue("success", false);
    formik.setFieldValue("isPasswordScreen", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="max-w-full border border-[rgba(12, 35, 2, 0.15)] rounded-[13px]  mx-auto block p-4">
        <div className="divide-y-2">
          <h2 className="font-PoppinsBold pb-2">Change Password</h2>

          <div className="py-4">
            <div className="pt-[10px] pb-[1px]">
              <label htmlFor="Password" className="text-sm">
                Password
              </label>
            </div>
            <div className="relative text-gray-600 focus-within:text-gray-400">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <InputPasswordIcon />
              </span>

              <input
                type="password"
                value={formik.values.Password}
                name="Password"
                id="Password"
                placeholder="Password"
                className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
                onChange={(e) => {
                  formik.setFieldValue("Password", e.target.value);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.touched.Password && formik.errors.Password ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.Password}
              </span>
            ) : null}

            <div className="pt-[10px] pb-[1px]">
              <label htmlFor="ConfirmPassword" className="text-sm">
                Confirm Password
              </label>
            </div>
            <div className="relative text-gray-600 focus-within:text-gray-400">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <InputPasswordIcon />
              </span>

              <input
                type="password"
                value={formik.values.ConfirmPassword}
                name="ConfirmPassword"
                id="ConfirmPassword"
                placeholder="Confirm Password"
                className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
                onChange={(e) => {
                  formik.setFieldValue("ConfirmPassword", e.target.value);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.ConfirmPassword}
              </span>
            ) : null}
          </div>
        </div>
      </div>

      {PassResetChangePasswordLoading ? (
        <div className="bg-Primary text-white text-sm  bg-opacity-50 w-[117px] h-[36px] rounded-[10px] mt-4 flex justify-center items-center ">
          <svg
            className="h-5 w-5 text-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            ></path>
          </svg>
        </div>
      ) : (
        <div className="">
          <button
            type="button"
            name="button"
            onClick={() => {
              handleUserChangePassword();
            }}
            className="bg-Primary text-white text-sm  py-2 px-2 rounded-[10px] mt-4"
          >
            Save Changes
          </button>
        </div>
      )}
    </>
  );
}
