import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import LocationFilter from "./LocationFilter";
import { Link } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import FilterAdsContext from "../../../Context/FilterAdsContext";
export default function HeaderSearchBox() {
  const { setFilterAds } = useContext(FilterAdsContext);
  const navigate = useNavigate();
  const handleSearch = (e) => {
    e.preventDefault();
    const data = e.target[0].value;
    if (typeof window.fbq === "function") {
      window.fbq("track", "Search event", { value: data });
    } else {
      console.warn("Facebook Pixel function not yet available");
    }
    const SearchField = document.getElementById("Query");
    SearchField.value = "";
    setFilterAds((prev) => ({
      ...prev,
      page: 1,
    }));
    navigate(`/all-categories?query=${data}`);
  };

  return (
    <div className="absolute left-[222px] top-[18px] hidden md:block">
      <div className="flex space-x-2 items-center">
        <form
          onSubmit={handleSearch}
          className="mt-1  rounded-md flex-1 shadow-sm w-[250px] lg:w-[350px] xl:w-[470px] flex -ml-11 lg:-ml-0"
        >
          <div className="relative flex flex-grow items-stretch focus:outline-none focus:border-none">
            <input
              type="text"
              name="search"
              id="Query"
              className="block w-full text-TextColor pl-4 font-PoppinsRegular text-sm border-[0.5px] border-[#39342F] rounded-[8px] border-r-none focus:outline-none focus:ring-0 SearchInput "
              placeholder="Search"
            />
          </div>
          <button
            type="submit"
            name="search"
            className="relative -ml-px inline-flex items-center space-x-2 rounded-r-[8px] w-[34px] p-[7px] text-sm font-medium bg-Primary "
          >
            <SearchIcon />
          </button>
        </form>
        <div className="xl:pl-5">
          <LocationFilter />
        </div>
        <div className="xl:pl-5">
          <Link
            className="block bg-Primary py-[10px] px-[14px] rounded-[50px] w-[108px] HeaderShadowButton"
            to="/account/post-ad"
          >
            <div className="flex items-center space-x-2 justify-center">
              {" "}
              <span className="text-[14px] text-white">+</span>
              <span className="text-[14px] text-white">Post Ad</span>
            </div>
          </Link>
        </div>
        <div className="xl:pl-5">
          <LanguageSwitcher />
        </div>
      </div>
    </div>
  );
}
