import { ReactComponent as JustLogoWithoutName } from "../assets/icons/JustLogoWithoutName.svg";
import { ReactComponent as InputPasswordIcon } from "../assets/icons/InputPassword.svg";
import { ReactComponent as InputPhoneIcon } from "../assets/icons/InputPhone.svg";
import LogoCut from "../assets/icons/LogoCut.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useLoginUser } from "../Middlewares/Api";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AuthContext from "../Context/AuthContext";
import { useContext, useState } from "react";
import UpdateMetaTags from "../Utils/UpdateMetaTags";
export default function Login() {
  const { mutateAsync, isLoading } = useLoginUser();
  const { isAuth, setIsAuth, setUser } = useContext(AuthContext);
  const [loggedInSuccessfully, setLoggedInSuccessfully] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      Phone: "",
      Password: "",
      errors: {},
    },

    validationSchema: yup.object({
      Phone: yup.string().required("Mobile number is Required"),
      Password: yup.string().required("Password is Required"),
    }),
    onSubmit: (values) => {
      const data = mutateAsync({ phone: values.Phone, pass: values.Password });
      Promise.resolve(data).then((res) => {
        if (res.code === 200) {
          window.localStorage.setItem("key", res?.data?.key);
          setUser(res?.data);

          setLoggedInSuccessfully(true);
          setTimeout(() => {
            setIsAuth(true);
            navigate("/");
          }, 2000);
        } else {
          formik.setErrors({ errors: res.message });
        }
      });
    },
  });

  useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  if (isLoading || loggedInSuccessfully) {
    return (
      <div className="font-PoppinsRegular mt-10 md:mt-3">
        <div className="relative w-full h-full bg-[#F4F4F4] flex justify-center items-center py-10">
          <div className="max-w-[600px] px-10 w-full h-[501px] rounded-[15px] bg-[#FFFFFF] z-10 ">
            <div className="flex flex-col items-center mt-[40px] w-full h-full ">
              <div className="space-y-4 mt-[29px]  w-full h-full max-w-[340px] text-left">
                {loggedInSuccessfully ? (
                  <div className="ml-auto mr-auto mt-44 font-PoppinsBold text-center text-[18px] text-Primary">
                    Successfully logged in <br />
                    Redirecting to home page
                  </div>
                ) : (
                  <div className="spinner ml-auto mr-auto mt-44"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <UpdateMetaTags data={{ title: "Login" }} />

      <div className="font-PoppinsRegular mt-10 md:mt-3">
        <div className="relative w-full h-full bg-[#F4F4F4] flex justify-center items-center py-10">
          <div className="max-w-[600px] px-10 w-full h-[500px] rounded-[15px] bg-[#FFFFFF] z-10 pb-6 ">
            <form
              className="w-full h-full relative"
              onSubmit={formik.handleSubmit}
            >
              <div className="text-center sm:text-[16px] text-[14px] mt-[100px]">
                <div className="flex flex-col items-center mt-[40px] w-full h-full ">
                  <JustLogoWithoutName />

                  <div className="space-y-4 mt-[29px]  w-full h-full max-w-[340px] text-left">
                    {formik.errors.errors && (
                      <p className="text-red-600  text-[14px] pt-2">
                        {formik.errors.errors}
                      </p>
                    )}
                    <div className="relative text-gray-600 focus-within:text-gray-400">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <InputPhoneIcon />
                      </span>

                      <label htmlFor="Phone" className="sr-only">
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        value={formik.values.Phone}
                        name="Phone"
                        id="Phone"
                        placeholder="Mobile number"
                        className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
                        onChange={(e) => {
                          formik.setFieldValue("Phone", e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </div>

                    {formik.touched.Phone && formik.errors.Phone ? (
                      <span className="text-red-600  text-[11px] ">
                        {formik.errors.Phone}
                      </span>
                    ) : null}

                    <div className="relative text-gray-600 focus-within:text-gray-400">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <InputPasswordIcon />
                      </span>
                      <label htmlFor="Password" className="sr-only">
                        Password
                      </label>
                      <input
                        type="password"
                        value={formik.values.Password}
                        name="Password"
                        id="Password"
                        placeholder="Password"
                        className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
                        onChange={(e) => {
                          formik.setFieldValue("Password", e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.Password && formik.errors.Password ? (
                      <span className="text-red-600  text-[11px] ">
                        {formik.errors.Password}
                      </span>
                    ) : null}

                    <div className="flex justify-end">
                      <Link
                        to="/auth/password/reset"
                        className="text-Primary text-[14px]"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="space-x-2">
                  <button
                    type="submit"
                    className="max-w-[340px]  w-full h-[42px] rounded-[10px] bg-Primary text-white mt-4 inline-block"
                  >
                    Sign In
                  </button>
                </div>

                <div className="mt-2">
                  Don&apos;t have an account?{" "}
                  <Link to="/auth/register" className="text-Primary">
                    Create Account
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <img
            src={LogoCut}
            alt="Logo"
            className="absolute bottom-0 right-0 "
          />
        </div>
      </div>
    </>
  );
}
