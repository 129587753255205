import useBreadcrumbs from "use-react-router-breadcrumbs";

import { Link, useParams } from "react-router-dom";

export default function Breadcrumbs() {
  const { CatSlug, AdSlug, BlogSlug, ServiceSlug, QurbaniSlug } = useParams();

  const routes = [
    { path: ":CatSlug", breadcrumb: `${CatSlug}` },
    { path: ":CatSlug/:AdSlug", breadcrumb: `${AdSlug}` },
    { path: "/blogs", breadcrumb: "blogs" },
    { path: "/blogs/:BlogSlug", breadcrumb: `${BlogSlug}` },
    { path: "/services/:ServiceSlug", breadcrumb: `${ServiceSlug}` },
    { path: "/services", breadcrumb: "services" },
    { path: "/qurbani", breadcrumb: "qurbani" },
    { path: "/qurbani/:QurbaniSlug", breadcrumb: `${QurbaniSlug}` },
    { path: "/qurbani/order", breadcrumb: "order" },
  ];

  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });
  return (
    <nav
      className="flex font-[12px] overflow-x-auto pb-2 whitespace-nowrap "
      aria-label="Breadcrumb"
    >
      <ol className="flex items-center space-x-1">
        <li>
          <div>
            <Link to="/" className="text-TextColor hover:text-gray-500">
              <span className="">Home</span>
            </Link>
          </div>
        </li>
        {breadcrumbs.map(({ match, breadcrumb }, index) => (
          <li key={match.pathname}>
            <div className="flex items-center text-[#B6B6B6]">
              <span
                className=" flex-shrink-0 text-TextColor"
                aria-hidden="true"
              >
                /
              </span>
              {index === breadcrumbs.length - 1 ? (
                breadcrumb
              ) : (
                <Link
                  to={match.pathname}
                  className="ml-2 text-sm font-medium  hover:text-[#B6B6B6] text-TextColor"
                  aria-current={
                    index === breadcrumbs.length - 1 ? "page" : undefined
                  }
                >
                  {breadcrumb}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
