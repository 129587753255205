export const PriceUnits = [
  { value: "total", label: "Total" },
  { value: "permun", label: "Per Mun" },
  { value: "perkg", label: "Per KG" },
  { value: "perliter", label: "Per Liter" },
  { value: "perkanal", label: "Per Kanal" },
  { value: "perbag", label: "Per Bag" },
  { value: "perunit", label: "Per Unit" },
  { value: "other", label: "Other" },
];
