import { ReactComponent as AdSuccessfullyPostedIcon } from "../../assets/icons/AdSuccessfullyPostedIcon.svg";
import { useNavigate } from "react-router-dom";
import BuyStarAdsModal from "./BuyStarAdsModal";
import BuyStarAdsModalMobile from "./BuyStarAdsModalMobile";
import Modal from "../Modal";
import { useState, useEffect } from "react";
import { useFetchBuyStarAdBanner } from "../../Middlewares/Api";
import { XMarkIcon } from "@heroicons/react/24/outline";
import PaymentConfirmation from "../PaymobComponents/PaymentConfirmation";
export default function AdSuccessfullyPosted({ ad_id, FromMyAdsPage }) {
  const [ShowStarBanner, setShowPaidBanner] = useState(false);
  const [ShowStarBannerMobile, setShowPaidBannerMobile] = useState(false);
  const { data: StarBannerdata, isLoading: StarBannerLoading } =
    useFetchBuyStarAdBanner();

  const navigate = useNavigate();
  useEffect(() => {}, []);

  return (
    <>
      {ShowStarBanner ? (
        <Modal>
          <div id="modalInner">
            <div className="w-full h-[480px] bg-[#fff] p-[20px] rounded-[13px] my-0 mx-auto -mt-[120px]  relative">
              <div className="w-[270px]  sm:w-[500px] max-w-[600px] h-[350px] mx-auto flex items-center flex-col mt-2 font-PoppinsRegular ">
                <BuyStarAdsModal
                  data={StarBannerdata}
                  isLoading={StarBannerLoading}
                />
              </div>
              <div className=" absolute top-[-13px] right-[4px] mt-3">
                <button
                  className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                  onClick={() => {
                    setShowPaidBanner(false);
                  }}
                >
                  <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      {ShowStarBannerMobile ? (
        <Modal>
          <div id="modalInner">
            <div className="w-full h-[480px] bg-[#fff] p-[20px] rounded-[13px] my-0 mx-auto -mt-[120px]  relative">
              <div className="w-[270px]  sm:w-[500px] max-w-[600px] h-[350px] mx-auto flex items-center flex-col mt-2 font-PoppinsRegular ">
                <BuyStarAdsModalMobile
                  data={StarBannerdata}
                  isLoading={StarBannerLoading}
                />
              </div>
              <div className=" absolute top-[-13px] right-[4px] mt-3">
                <button
                  className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                  onClick={() => {
                    setShowPaidBannerMobile(false);
                  }}
                >
                  <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      <div className="flex flex-col items-center space-y-4 max-w-[500px]  p-3 h-[600px] justify-center mx-auto">
        <AdSuccessfullyPostedIcon />
        <p className="text-[#2A2A2A] font-PoppinsMedium max-w-[300] max-h-[150px] bg-gray-100 p-4 rounded-xl text-center">
          Your Ad has been successfully uploaded. It will go live shortly after
          the review. You can check its status in My Ads Section
        </p>

        {/* <button
          className="bg-[#EFA922] text-white font-PoppinsMedium text-[14px] max-w-[300] w-full p-3 rounded-[13px] block sm:hidden"
          type="button"
          onClick={() => {
            setShowPaidBannerMobile(true);
            setShowPaidBanner(false);
          }}
        >
          Want To Sell Quickly? Make it a Star Ad
        </button> */}

        <div className="block sm:hidden w-full">
          <PaymentConfirmation ad_id={ad_id} slug={"post-ad"} />
        </div>

        {/* <button
          className="bg-[#EFA922] text-white font-PoppinsMedium text-[14px] max-w-[300] w-full p-3 rounded-[13px] hidden sm:block"
          type="button"
          onClick={() => {
            setShowPaidBanner(true);
            setShowPaidBannerMobile(false);
          }}
        >
          Want To Sell Quickly? Make it a Star Adaaa
        </button> */}

        <div className="hidden sm:block w-full">
          <PaymentConfirmation ad_id={ad_id} slug={"post-ad"} />
        </div>

        <button
          className="bg-Primary text-white font-PoppinsMedium text-[14px] max-w-[300] w-full p-3 rounded-[13px]"
          type="button"
          onClick={() => {
            FromMyAdsPage
              ? window.location.reload()
              : navigate("/account/my-ads");
          }}
        >
          Go to My Ads Page
        </button>
        <button
          className="bg-transparent text-Primary border-2 border-Primary font-PoppinsMedium text-[14px] max-w-[300] w-full p-3 rounded-[13px]"
          type="button"
          onClick={() => {
            navigate(`/account/post-ad`, {
              state: null,
            });
            window.location.reload();
          }}
        >
          Post New Ad
        </button>
      </div>
    </>
  );
}
