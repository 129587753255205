import BannerTop from "../Components/HomePageComponents/BannerTop";
import CallToActionBottom from "../Components/HomePageComponents/CallToActionBottom";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { Provinces } from "../StaticData/Provinces";
import Modal from "../Components/Modal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  useFetchFilterDistricts,
  useFetchFilterTehsils,
  useAccountGetUserDetails,
  useAccountGetUserProfilePhotoUpdate,
  useAccountGetUserProfilePhotoUpload,
  useAccountGetUserProfileEdit,
  usePassResetChangePassword,
  useAccountDeleteUser,
} from "../Middlewares/Api";
import { useEffect } from "react";
import AuthContext from "../Context/AuthContext";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import UpdateMetaTags from "../Utils/UpdateMetaTags";
export default function MyAccount() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const { isAuth, setIsAuth, setUser, user } = useContext(AuthContext);

  const { mutateAsync: UserDetailsData, isLoading: UserDetailsDataLoading } =
    useAccountGetUserDetails();
  const {
    mutateAsync: UserProfilePhotoUpdate,
    isLoading: UserProfilePhotoUpdateLoading,
  } = useAccountGetUserProfilePhotoUpdate();
  const {
    mutateAsync: UserProfilePhotoUpload,
    isLoading: UserProfilePhotoUploadLoading,
  } = useAccountGetUserProfilePhotoUpload();
  const { mutateAsync: UserProfileEdit, isLoading: ProfileEditLoading } =
    useAccountGetUserProfileEdit();
  const {
    mutateAsync: PassResetChangePassword,
    isLoading: PassResetChangePasswordLoading,
  } = usePassResetChangePassword();
  const { mutateAsync: AccountDeleteUser } = useAccountDeleteUser();

  const formik = useFormik({
    initialValues: {
      Phone: "",
      Name: "",
      FarmName: "",
      Province: [],
      District: [],
      Tehsil: "",
      Village: "",
      ProfilePhoto: "",
      Password: "",
      ConfirmPassword: "",
      isPasswordScreen: false,
      error: false,
      success: false,
    },

    validationSchema: yup.object({
      Name: yup.string().required("Name is Required").max(40, "Max 40 chars"),
      FarmName: yup.string().max(40, "Max 40 chars"),
      Province: yup.object().nullable().required("Province is Required"),
      District: yup.object().nullable().required("District is Required"),
      Tehsil: yup.object().nullable().required("Tehsil is Required"),
      Password: yup.string().when("isPasswordScreen", {
        is: true,
        then: yup
          .string()
          .required("Password is Required")
          .min(4, "Password must be at least 4 characters"),
      }),

      ConfirmPassword: yup
        .string()
        .oneOf([yup.ref("Password"), null], "Passwords must match")
        .when("isPasswordScreen", {
          is: true,
          then: yup.string().required("Confirm Password is Required"),
        }),
      Village: yup.string().max(50, "Max 50 chars"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    const payl = {
      key: localStorage.getItem("key"),
      name: values.Name,
      business: values.FarmName,
      province: values.Province.value,
      district: values.District.value,
      tehsil: values.Tehsil.value,
      town_en: values.Village,
    };
    const data = UserProfileEdit(payl);
    Promise.resolve(data).then((data) => {
      if (
        data.web_status_code === 101 ||
        data.web_status_code === 102 ||
        data.web_status_code === 103
      ) {
        localStorage.removeItem("key");
        setIsAuth(false);
        setUser(null);
        navigate("/auth/login");
      } else if (data.code === 200) {
        formik.setFieldValue("error", false);
        formik.setFieldValue("success", true);
      } else {
        formik.setFieldValue("success", false);
        formik.setFieldValue("error", true);
      }
    });
  };

  const deleteAccount = () => {
    const data = isAuth && AccountDeleteUser({ phone: formik.values.Phone });
    Promise.resolve(data).then((data) => {
      if (data.code === 200) {
        localStorage.removeItem("key");
        setIsAuth(false);
        setUser(null);
        setShowModal(false);
        navigate("/");
      } else {
        formik.setFieldValue("success", false);
        formik.setFieldValue("error", true);
        setShowModal(false);
      }
    });
  };

  const { data: DistrictsData } = useFetchFilterDistricts({
    ProvinceId: formik.values.Province && formik.values.Province.value,
  });

  const DistrictsOptons =
    DistrictsData &&
    DistrictsData.data.map((item) => {
      return { value: item.id, label: item.name };
    });

  const { data: TehsilsData } = useFetchFilterTehsils({
    DistrictId: formik.values.District && formik.values.District.value,
  });

  const TehsilsOptons =
    TehsilsData &&
    TehsilsData.data.map((item) => {
      return { value: item.id, label: item.name };
    });

  useEffect(() => {
    const data = isAuth && UserDetailsData(localStorage.getItem("key"));
    Promise.resolve(data).then((data) => {
      if (
        data.web_status_code === 101 ||
        data.web_status_code === 102 ||
        data.web_status_code === 103
      ) {
        localStorage.removeItem("key");
        setIsAuth(false);
        setUser(null);
        navigate("/auth/login");
      }
      if (data.code === 200) {
        setUser(data.data[0]);
        formik.setFieldValue("Name", data.data[0].name);
        formik.setFieldValue("Phone", data.data[0].phone);
        formik.setFieldValue("FarmName", data.data[0].business_name);
        Provinces.find((item) => {
          if (String(item.value) === String(data.data[0].province_id)) {
            formik.setFieldValue("Province", item);
          }
        });
        formik.setFieldValue("District", {
          value: data.data[0].district_id,
          label: data.data[0].district,
        });
        formik.setFieldValue("Tehsil", {
          value: data.data[0].tehsil_id,
          label: data.data[0].tehsil,
        });
        formik.setFieldValue("Village", data.data[0].town_en);
        formik.setFieldValue("ProfilePhoto", data.data[0].profile_image);
      } else {
        formik.setFieldValue("error", true);
        formik.setFieldValue("success", false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (UserDetailsDataLoading || ProfileEditLoading) {
    return (
      <div className="flex items-center justify-center mt-20">
        <div className="spinner"></div>
      </div>
    );
  }

  const handleUserProfilePhoto = (e) => {
    let fdat = new FormData();
    fdat.append("fileToUpload", e.target.files[0]);
    const data = isAuth && UserProfilePhotoUpload(fdat);
    formik.setFieldValue("success", false);
    Promise.resolve(data).then((data) => {
      if (data.code === 200) {
        Promise.resolve(data).then((data) => {
          if (data.code === 200) {
            const { file_url } = data.data;
            const data2 =
              isAuth &&
              UserProfilePhotoUpdate({
                key: localStorage.getItem("key"),
                img_url: file_url,
              });
            Promise.resolve(data2).then((data2) => {
              if (data2.code === 200) {
                formik.setFieldValue("ProfilePhoto", data.data.file_url);
              } else if (
                data2.web_status_code === 101 ||
                data2.web_status_code === 102 ||
                data2.web_status_code === 103
              ) {
                localStorage.removeItem("key");
                setIsAuth(false);
                setUser(null);
                navigate("/auth/login");
              } else {
                formik.setErrors({ ProfilePhoto: data.message });
                formik.setFieldTouched("ProfilePhoto", true);
              }
            });
          }
        });
      } else {
        formik.setFieldError("ProfilePhoto", data.message);
      }
    });
  };

  const handleUserChangePassword = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length > 0) {
        formik.setTouched(res);
      }
      // if res object is empty then submit form
      if (Object.keys(res).length === 0) {
        const data =
          isAuth &&
          PassResetChangePassword({
            key: localStorage.getItem("key"),
            password: formik.values.Password,
          });
        Promise.resolve(data).then((data) => {
          if (
            data.web_status_code === 101 ||
            data.web_status_code === 102 ||
            data.web_status_code === 103
          ) {
            localStorage.removeItem("key");
            setIsAuth(false);
            setUser(null);
            navigate("/auth/login");
          } else if (data.code === 200) {
            formik.setFieldValue("success", true);
            formik.setFieldValue("error", false);
            formik.setFieldValue("ConfirmPassword", "");
            formik.setFieldValue("Password", "");
            formik.setFieldTouched("ConfirmPassword", false);
            formik.setFieldTouched("Password", false);
          } else {
            formik.setFieldValue("success", false);
            formik.setFieldValue("error", true);
          }
        });
      }
    });
  };

  return (
    <>
      {showModal ? (
        <Modal>
          <div id="modalInner">
            <div
              id="modalContent"
              style={{ height: "auto" }}
              className="font-PoppinsRegular relative "
            >
              <div className="w-[250px] sm:w-[500px] max-w-[600px] h-[230px] mx-auto flex items-center mt-2 flex-col space-y-3">
                <p className="text-[18px] font-PoppinsBold text-center md:text-left">
                  Delete Account
                </p>
                <p className="pt-8 text-center">
                  Are you sure you want to delete your account?
                </p>
                <div className="flex  justify-center md:justify-start space-x-6">
                  {/* delete confirm button */}
                  <button
                    className="bg-[#FF2020] text-white font-PoppinsBold text-[14px] px-4 py-2 rounded-md mr-2"
                    onClick={(e) => {
                      e.target.innerText = "Deleting...";
                      e.target.disabled = true;
                      deleteAccount();
                    }}
                  >
                    Delete
                  </button>
                  {/* cancel button */}
                  <button
                    className="bg-gray-100 text-gray-900 font-PoppinsBold text-[14px] px-4 py-2 rounded-md"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className=" absolute top-[-13px] right-[4px] mt-3">
                <button
                  className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                  onClick={() => setShowModal(false)}
                >
                  <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      <UpdateMetaTags data={{ title: "My account" }} />

      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>

        <div className=" font-PoppinsRegular">
          <div className="flex  flex-col md:flex-row pt-4">
            <div className="w-full md:w-[40%] lg:w-[30%] space-y-4 pt-10 md:pt-0 flex items-center flex-col">
              <div className="w-full flex md:flex-col md:space-x-0 md:py-0 flex-row space-x-4 py-4">
                <Link
                  to="/account/profile"
                  className={
                    window.location.pathname === "/account/profile"
                      ? "text-Primary font-PoppinsBold text-[18px] w-fit"
                      : "text-[#000000] font-PoppinsRegular text-[18px] w-fit"
                  }
                >
                  Edit Profile
                </Link>
                <Link
                  to="/account/password/change"
                  className={
                    window.location.pathname === "/account/password/change"
                      ? "text-Primary font-PoppinsBold text-[18px] w-fit"
                      : "text-[#000000] font-PoppinsRegular text-[18px] w-fit"
                  }
                >
                  Change Password
                </Link>
              </div>

              {/* <div className="w-[249px] h-[207px] block mx-auto">
              <img src={AdImage} alt="" width="249px" height="207px" />
            </div>
            <div className="w-[249px] h-[207px]  block mx-auto">
              <img src={AdImage} alt="" width="249px" height="207px" />
            </div> */}
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="w-full md:w-[67%] lg:w-[70%]  mr-[25px]"
            >
              <div>
                {formik.errors.ProfilePhoto ? (
                  <span className="text-red-600  text-[16px] ">
                    {formik.errors.ProfilePhoto}
                  </span>
                ) : null}
              </div>
              <div>
                {formik.values.success ? (
                  <span className="text-Primary  text-[16px] py-2 font-PoppinsBold">
                    All Changes Saved Successfully
                  </span>
                ) : null}
              </div>
              <div>
                {formik.values.error ? (
                  <span className="text-red-600  text-[16px] py-2 font-PoppinsBold">
                    Something went wrong
                  </span>
                ) : null}
              </div>

              <Outlet
                context={{
                  formik,
                  user,
                  handleUserProfilePhoto,
                  Provinces,
                  DistrictsOptons,
                  TehsilsOptons,
                  setShowModal,
                  handleUserChangePassword,
                  UserProfilePhotoUpdateLoading,
                  UserProfilePhotoUploadLoading,
                  PassResetChangePasswordLoading,
                }}
              />
            </form>
          </div>
        </div>
        <CallToActionBottom />
      </div>
    </>
  );
}
