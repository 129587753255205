// import Banner from "../../assets/images/Banner.png";
import { useFetchAdBanners } from "../../Middlewares/Api";
import Slider from "react-slick";
import { useRef } from "react";

import BannerTopLoader from "../../Loaders/BannerTopLoader";

export default function BannerTop() {
  // const { data, isLoading } = useFetchAdBanners();
  const { data, isLoading } = useFetchAdBanners();
  const slider = useRef(null);

  // if (isLoading) {
  //   return (
  //     <div className="flex items-center justify-center mt-10">
  //       <div className="spinner"></div>
  //     </div>
  //   );
  // }
  const infiniteLength = data?.data?.length;

  const settings = {
    dots: false,
    arrows: false,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    infinite: infiniteLength > 1 ? true : false,
    slidesToScroll: 1,
  };

  return (
    <>
      {isLoading ? (
        <BannerTopLoader />
      ) : (
        <div className="mt-4 relative ">
          <Slider ref={slider} {...settings}>
            {data?.data.map((banner, index) => {
              return (
                <div key={index}>
                  <img
                    src={banner.web_banner_image ?? ""}
                    alt={banner.banner_ad_title ?? ""}
                    title={banner.banner_ad_title ?? ""}
                    className="w-full h-full"
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      )}
    </>
  );
}
