import Breadcrumbs from "../Components/BreadCrumbs";
import BannerTop from "../Components/HomePageComponents/BannerTop";
import CallToActionBottom from "../Components/HomePageComponents/CallToActionBottom";
import SquareGoogleAd from "../Utils/SquareGoogleAd";
import {
  useFetchQurbaniPackageCategory,
  useFetchQurbaniPackages,
  useMutateOrderQurbaniPackage,
  useFetchQurbaniSupportInfo,
} from "../Middlewares/Api";
import { ReactComponent as AdCallIcon } from "../assets/icons/AdCall.svg";
import { ReactComponent as AdWhatsappIcon } from "../assets/icons/AdWhatsapp.svg";
import UpdateMetaTags from "../Utils/UpdateMetaTags";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { useContext } from "react";
import { CartContext } from "../Context/CartContext";
import CheckoutButton from "../Components/QurbaniComponents/CheckoutButton";
import Toast from "../Utils/Toast";
export default function Qurbani() {
  const [CatId, setCatId] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "Item added to cart successfully."
  );
  const { data: supportInfo } = useFetchQurbaniSupportInfo();
  const navigate = useNavigate();

  const { addToCart, cartItems } = useContext(CartContext);

  const handleAddToCart = (item) => {
    addToCart(
      {
        id: parseInt(item.package_id),
        name: item.package_name,
        price: parseInt(item?.price?.replace(/,/g, "")),
        catId: parseInt(item?.cat_id),
        totalQuantity: parseInt(item?.total_quantity),
        availableQuantity: parseInt(item?.available_quantity),
        packageCatName: item?.package_cat_name,
        deliveryPrice: parseInt(item?.delivery_price?.replace(/,/g, "")),
        description: item?.description,
        image: item?.image,
        SelectedEidDay: item.SelectedEidDay,
      },
      1
    );
    let cartsI = 0;
    const found = cartItems?.find(
      (element) => element.id === parseInt(item.package_id)
    );
    if (found) {
      cartsI = 0;
    } else {
      cartsI = 1;
    }

    cartItems?.forEach((element) => {
      cartsI = cartsI + element.quantity;
    });
    // setToastMessage(
    //   `${cartsI} ${cartsI > 1 ? "items" : "item"} added to cart successfully.`
    // );
    // handleShowToast();
    window.location.href = "/qurbani/checkout";
  };

  const { data: QurbaniPackages, isLoading: GettingPackages } =
    useFetchQurbaniPackages({ package_category_id: CatId });
  const { data: qurbaniPackageCategory, isLoading: QurbaniPackageLoading } =
    useFetchQurbaniPackageCategory();
  const {
    mutateAsync: OrderQurbaniPackage,
    isLoading: OrderingQurbani,
    isSuccess,
  } = useMutateOrderQurbaniPackage();

  const getShortName = (name) => {
    let str;

    if (name?.length > 14) {
      str = name?.substring(0, 14) + "...";
    } else {
      str = name ?? "";
    }
    return str;
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };
  const handleShowToast = () => {
    setShowToast(true);
  };
  if (QurbaniPackageLoading || GettingPackages) {
    return (
      <div className="flex items-center justify-center mt-20">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <>
      {showToast && (
        <Toast
          message={toastMessage}
          duration={3000}
          onClose={handleCloseToast}
        />
      )}
      <div className="relative font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>
        <div>
          <Breadcrumbs />
        </div>
        <UpdateMetaTags data={{ title: "Qurbani services" }} />
        <div className=" font-PoppinsRegular">
          <h2 className="font-PoppinsBold text-[22px]">Qurbani services</h2>
          <div className="fixed z-50 bottom-[134px] right-[10px]">
            <CheckoutButton />
          </div>

          <div className="flex  flex-col xl:flex-row-reverse pt-4">
            {/* commented because we are not using ads for now */}
            <div className="w-full xl:w-[70%]  mr-[25px] flex items-center flex-col xl:block">
              {/* <div className="w-full flex justify-center"> */}
              <Outlet
                context={{
                  getShortName,
                  navigate,
                  QurbaniPackages,
                  qurbaniPackageCategory,
                  CatId,
                  setCatId,
                  OrderingQurbani,
                  OrderQurbaniPackage,
                  isSuccess,
                  handleAddToCart,
                  cartItems,
                  handleShowToast,
                  setToastMessage,
                  AdCallIcon,
                  AdWhatsappIcon,
                  supportInfo,
                }}
              />
            </div>

            <div className="w-full xl:w-[30%] space-y-4 pt-10 xl:pt-0 flex items-center flex-col xl:block">
              <div className="w-[249px] hidden xl:block h-[157px] border border-[rgba(12, 35, 2, 0.15)] rounded-[13px]  mx-auto block">
                <h4 className="font-PoppinsBold pl-4 pt-2">Contact Us</h4>
                <div className="flex flex-col items-center mt-[15px] space-y-2">
                  <a
                    href={`https://wa.me/92${supportInfo?.data?.whatsapp_number.replace(
                      /-/g,
                      ""
                    )}`}
                    target={"_blank"}
                    rel="noreferrer"
                    className="w-[230px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
                  >
                    <AdWhatsappIcon />
                    <span className="ml-10">Whatsapp</span>
                  </a>
                  <div>
                    <a
                      href={`tel:${supportInfo?.data?.phone_number.replace(
                        /-/g,
                        ""
                      )}`}
                      className="w-[230px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
                    >
                      <AdCallIcon />
                      <span className="ml-12">Call Now</span>
                    </a>
                  </div>
                </div>
              </div>
              <SquareGoogleAd />
            </div>
          </div>
        </div>
        <CallToActionBottom />
      </div>
    </>
  );
}
