import { ReactComponent as InputFarmIcon } from "../../assets/icons/InputFarm.svg";
import { ReactComponent as InputPersonIcon } from "../..//assets/icons/InputPerson.svg";
import { ReactComponent as InputPhoneIcon } from "../../assets/icons/InputPhone.svg";
import Select, { components } from "react-select";
import { Style } from "../../Styles/ReactSelectStyles";
import { ReactComponent as CaretDownIcon } from "../../assets/icons/CaretDown.svg";
import { useOutletContext } from "react-router-dom";
import { useEffect } from "react";
export default function Profile() {
  const {
    formik,
    handleUserProfilePhoto,
    Provinces,
    DistrictsOptons,
    TehsilsOptons,
    setShowModal,
    UserProfilePhotoUpdateLoading,
    UserProfilePhotoUploadLoading,
  } = useOutletContext();
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };
  useEffect(() => {
    formik.values.error && formik.setFieldValue("error", false);
    formik.values.success && formik.setFieldValue("success", false);
    formik.setFieldValue("isPasswordScreen", false);
    formik.setFieldValue("Password", "");
    formik.setFieldValue("ConfirmPassword", "");

    formik.setFieldTouched("Password", false);
    formik.setFieldTouched("ConfirmPassword", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="max-w-full border border-[rgba(12, 35, 2, 0.15)] rounded-[13px]  mx-auto block p-4">
        <div className="divide-y-2">
          <h2 className="font-PoppinsBold pb-2">Edit Profile</h2>

          <div className="py-4">
            <label
              htmlFor="photo"
              className="block text-sm font-medium text-blue-gray-900 sr-only"
            >
              User Photo
            </label>
            <div className="mt-1 fontRegular flex flex-col sm:flex-row items-center ">
              <div className="flex items-center justify-center">
                <img
                  className="inline-block h-20 w-20 rounded-full mt-4 sm:mt-0 object-cover"
                  src={
                    formik.values.ProfilePhoto ? formik.values.ProfilePhoto : ""
                  }
                  alt={formik.values.Name}
                />

                <div className="ml-4 flex flex-col">
                  <p className="py-1">Profile Photo</p>
                  {UserProfilePhotoUploadLoading ||
                  UserProfilePhotoUpdateLoading ? (
                    <div className="relative bg-Primary bg-opacity-50 w-[120px] h-[36px]  rounded-md shadow-sm flex items-center  ">
                      <div className="absolute inset-0 flex items-center justify-center ">
                        <svg
                          className="h-5 w-5 text-white animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v8H4z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <div className="relative bg-Primary py-2 px-3  rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 ">
                      <label
                        htmlFor="user-photo"
                        className="relative text-sm font-medium text-white pointer-events-none font-PoppinsBold"
                      >
                        <span>Upload Photo</span>
                        <span className="sr-only"> user photo</span>
                      </label>

                      <input
                        id="user-photo"
                        name="user-photo"
                        type="file"
                        onChange={(e) => handleUserProfilePhoto(e)}
                        accept=".jpeg, .jpg, .png, .webp,.gif"
                        className="absolute inset-0 w-full h-full opacity-0 border-gray-300 rounded-md"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="py-4">
            <div className="pt-[10px] pb-[1px]">
              <label htmlFor="Phone" className=" text-sm">
                Mobile Number
              </label>
            </div>

            <div className="relative text-gray-600 focus-within:text-gray-400">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <InputPhoneIcon />
              </span>

              <input
                type="text"
                value={formik.values.Phone}
                name="Phone"
                id="Phone"
                disabled
                className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
              />
            </div>

            <div className="pt-[10px] pb-[1px]">
              <label htmlFor="Name" className="text-sm">
                Name
              </label>
            </div>
            <div className="relative text-gray-600 focus-within:text-gray-400">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <InputPersonIcon />
              </span>

              <input
                type="text"
                value={formik.values.Name ?? ""}
                name="Name"
                id="Name"
                placeholder="John Doe"
                className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
                onChange={(e) => {
                  formik.setFieldValue("Name", e.target.value);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.touched.Name && formik.errors.Name ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.Name}
              </span>
            ) : null}
            <div className="pt-[10px] pb-[1px]">
              <label htmlFor="FarmName" className="text-sm">
                Farm Name
              </label>
            </div>
            <div className="relative text-gray-600 focus-within:text-gray-400">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <InputFarmIcon />
              </span>

              <input
                type="text"
                value={formik.values.FarmName ?? ""}
                name="FarmName"
                id="FarmName"
                placeholder="Khan Farm"
                className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
                onChange={(e) => {
                  formik.setFieldValue("FarmName", e.target.value);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.touched.FarmName && formik.errors.FarmName ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.FarmName}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="max-w-full border border-[rgba(12, 35, 2, 0.15)] rounded-[13px]  mx-auto block p-4 mt-4">
        <div className="divide-y-2">
          <h2 className="font-PoppinsBold pb-2">Location</h2>

          <div className="py-4 space-y-4">
            <div>
              <label
                htmlFor="provinces"
                className="block text-sm font-medium text-TextColor"
              >
                Province
              </label>
              <Select
                styles={Style}
                id="provinces"
                name="provinces"
                maxMenuHeight={140}
                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                options={Provinces}
                value={formik.values.Province ? formik.values.Province : []}
                label="provinces"
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                onChange={(value) => {
                  formik.setFieldValue("Province", value);

                  formik.setFieldValue("District", []);
                  formik.setFieldValue("Tehsil", []);
                  formik.setFieldTouched("District", false);
                  formik.setFieldTouched("Tehsil", false);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.touched.Province && formik.errors.Province ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.Province}
              </span>
            ) : null}
            <div className="mt-1">
              <label
                htmlFor="district"
                className="block text-sm font-medium text-TextColor"
              >
                District
              </label>
              <Select
                styles={Style}
                id="district"
                name="district"
                maxMenuHeight={140}
                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                options={DistrictsOptons}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                allowSelectAll={false}
                value={formik.values.District ? formik.values.District : []}
                label="district"
                onChange={(value) => {
                  formik.setFieldValue("District", value);
                  formik.setFieldValue("Tehsil", []);
                  formik.setFieldTouched("Tehsil", false);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.touched.District && formik.errors.District ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.District}
              </span>
            ) : null}
            <div className="mt-1">
              <label
                htmlFor="tehsil"
                className="block text-sm font-medium text-TextColor"
              >
                Tehsil
              </label>
              <Select
                styles={Style}
                id="tehsil"
                name="tehsil"
                maxMenuHeight={140}
                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                options={TehsilsOptons}
                value={formik.values.Tehsil ? formik.values.Tehsil : ""}
                label="tehsil"
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                onChange={(value) => {
                  formik.setFieldValue("Tehsil", value);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.touched.Tehsil && formik.errors.Tehsil ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.Tehsil}
              </span>
            ) : null}
            <div className="mt-1">
              <textarea
                className="block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
       border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      border-2
      focus:text-gray-700 focus:bg-white focus:outline-none "
                placeholder="Area/Village/Town"
                value={formik.values.Village ?? ""}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue("Village", e.target.value);
                }}
              />
            </div>
            {formik.touched.Village && formik.errors.Village ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.Village}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="flex sm:justify-start justify-center">
        <button
          type="submit"
          name="submit"
          className="bg-Primary text-white text-sm  py-2 px-2 rounded-[10px] mt-4"
        >
          Save Changes
        </button>
        <button
          type="button"
          name="button"
          onClick={() => {
            setShowModal(true);
          }}
          className="bg-[#FF2020] text-white text-sm  py-2 px-2 rounded-[10px] mt-4 ml-4"
        >
          Delete Account
        </button>
      </div>
    </>
  );
}
