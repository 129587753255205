import BannerTop from "../Components/HomePageComponents/BannerTop";
import { useMutateTrackQurbani } from "../Middlewares/Api";
import { useState } from "react";
import UpdateMetaTags from "../Utils/UpdateMetaTags";
export default function TrackQurbani() {
  const { mutateAsync: Track, isLoading: Tracking } = useMutateTrackQurbani();
  const [data, setData] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const TrackData = {
      tracking_number: data.get("Track"),
    };
    await Track(TrackData).then((res) => {
      res.code === 200 && setData(res.data);
    });
  };

  return (
    <>
      <UpdateMetaTags data={{ title: "Track Your Qurbani Order" }} />
      <div className="relative font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>
        <form onSubmit={handleSubmit}>
          <h3 className="max-w-[600px] w-full mx-auto font-PoppinsBold text-TextColor text-[20px]">
            Track Your Qurbani
          </h3>
          <div className="flex justify-center items-center mt-1 max-w-[600px] w-full mx-auto">
            <label
              htmlFor="Track"
              className="block text-sm  text-TextColor sr-only"
            >
              Track Qurbani
            </label>
            <input
              required
              title="Track Qurbani"
              id="Track"
              name="Track"
              type="text"
              className="placeholder:text-[10px] sm:placeholder:text-[16px] w-full h-[37.5px]  text-[#39342F]  border border-[#8a8989] focus:outline-none pl-3"
              label="Track"
              placeholder="Enter your Tracking Number"
            />
            <button
              type="submit"
              className="max-w-[200px] w-full h-[37.5px]  text-white  border border-Primary focus:outline-none pl-3  bg-Primary"
            >
              Track Qurbani
            </button>
          </div>
        </form>

        <div>
          {Tracking && (
            <div className="flex items-center justify-center mt-5">
              <div className="spinner" style={{ width: "80px" }}></div>
              <svg
                className="absolute"
                width="59"
                height="58"
                viewBox="0 0 59 58"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_227_20)">
                  <path
                    d="M29.4133 58C45.4296 58 58.4133 45.0163 58.4133 29C58.4133 12.9837 45.4296 0 29.4133 0C13.3971 0 0.41333 12.9837 0.41333 29C0.41333 45.0163 13.3971 58 29.4133 58Z"
                    fill="#3ED400"
                  />
                  <mask
                    id="mask0_227_20"
                    style={{ maskType: "luminance" }}
                    maskUnits="userSpaceOnUse"
                    x="13"
                    y="12"
                    width="25"
                    height="32"
                  >
                    <path
                      d="M37.3993 12H13.4133V43.8182H37.3993V12Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_227_20)">
                    <path
                      d="M37.0954 17.7479C36.7394 16.478 36.0904 15.3108 35.2012 14.3412C34.664 13.7745 34.0547 13.2819 33.3891 12.8761C32.956 12.607 32.5229 12.2853 32.0219 12.1619C31.2299 11.9606 30.4021 11.9484 29.6045 12.1265C28.784 12.3181 28.0625 12.7671 27.3776 13.2476L27.105 13.4432C26.3524 13.9824 25.6711 14.6159 25.0776 15.3284C23.3295 17.4577 22.0733 19.9512 21.4 22.6288C21.1391 23.6331 20.7621 23.7079 19.9206 23.3075C17.8033 22.298 15.8582 21.1283 14.8928 18.8204C14.7206 18.4095 14.4779 17.9881 13.9117 18.2257C13.3025 18.4817 13.3325 18.9556 13.5764 19.4571C13.7778 19.9096 14.0088 20.3482 14.2679 20.7699C15.5725 22.726 17.4759 23.9049 19.5906 24.7398C20.5207 25.1061 20.7882 25.5787 20.7034 26.5777C20.509 28.8843 20.5533 31.204 20.9865 33.4896C21.6061 36.7584 22.4137 39.9695 24.9485 42.3601C27.5068 44.773 31.0461 44.114 32.6168 40.9607C33.2026 39.7792 33.6605 38.5425 34.228 37.3531C34.498 36.7847 34.6285 36.232 33.9762 35.9563C33.35 35.6938 33.1504 36.3436 32.9234 36.744C32.1406 38.129 31.6358 39.6663 30.6586 40.9449C29.4845 42.4848 27.9334 42.7211 26.3339 41.6407C25.4442 41.0381 24.8193 40.2045 24.3666 39.2488C22.6707 35.6544 21.9388 31.8446 22.0562 27.8826C22.1201 25.7231 22.1723 25.7664 24.3484 26.1537C28.103 26.8232 31.7845 26.8757 35.0016 24.3499C37.1828 22.638 37.8638 20.4194 37.0954 17.7479ZM33.7414 23.4204C31.9632 24.6518 29.9581 25.0798 27.7833 24.98C26.3926 25.0628 25.0046 24.7503 23.6087 24.4851C22.8664 24.3433 22.4763 24.1031 22.745 23.1723C23.6269 20.103 24.9994 17.337 27.3502 15.1433C29.8537 12.8078 32.3076 13.0599 34.4902 15.8181C36.6727 18.5762 36.3649 21.6009 33.7414 23.4204Z"
                      fill="white"
                    />
                    <path
                      d="M27.5811 30.7441C27.5354 31.1799 27.3202 31.4805 26.8649 31.4635C26.7832 31.4642 26.7021 31.4486 26.6264 31.4177C26.5507 31.3867 26.4818 31.341 26.4238 31.2832C26.3657 31.2253 26.3196 31.1565 26.288 31.0807C26.2565 31.0048 26.2401 30.9234 26.24 30.8412C26.2047 30.387 26.4461 30.0811 26.8662 30.0391C27.3136 29.9879 27.5459 30.2925 27.5811 30.7441Z"
                      fill="white"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_227_20">
                    <rect width="59" height="58" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          )}
          {data && data?.[0] && (
            <div className="container max-w-7xl mx-auto mt-8">
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                  <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                            Date
                          </th>
                          <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                            Time
                          </th>
                          <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                            Order Status
                          </th>
                        </tr>
                      </thead>

                      <tbody className="bg-white">
                        {data.map((item, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                              <div className="text-sm leading-5 text-gray-900">
                                {item.date}
                              </div>
                            </td>

                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                              <p>{item.time}</p>
                            </td>

                            <td className="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200">
                              <p>{item.order_status}</p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {data === "" && (
            <div className="flex justify-center items-center">
              <p>No Order Found</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
