import * as yup from "yup";
import { useFormik } from "formik";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState, useContext } from "react";
import JazzCash from "./../../assets/icons/jazz-cash.png";
import EasyPaisa from "./../../assets/icons/easy-paisa.png";
import DebitCredit from "./../../assets/icons/credit-debit.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as LocationAdIcon } from "../../assets/icons/locationAd.svg";

import {
  useAccountGetUserDetails,
  useFetchMyAdsAdsList,
  usePaymob_auth_token,
  usePaymob_card_payment_key,
  usePaymob_order_registration,
  useStarAdPackage,
} from "../../Middlewares/Api";

import Modal from "../Modal";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import Toast from "../../Utils/Toast";

const PaymentConfirmation = ({ ad_id, slug, ad_detail_list }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fromMyAds, fromPrviewAd } = location.state || {};
  const { isAuth } = useContext(AuthContext);

  const [step, setStep] = useState(1);
  const [payment_method, setPayment_method] = useState("");
  const [packageData, setPackageData] = useState();
  const [showModalBuyNow, setShowModalBuyNow] = useState(false);
  const [adsData, setAdsData] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [choseAdNotFound, setChoseAdNotFound] = useState(false);
  useEffect(() => {
    if (fromMyAds === true) {
      setStep(4);
      setShowModalBuyNow(true);
      setFieldValue("isPaid_ads", true);
      localStorage.setItem("service_type", "paid_ad");
      localStorage.setItem("ad_id", 10);
    }

    // else if (afterPostAd === true) {
    //   setStep(1);
    //   setShowModalBuyNow(true);
    //   localStorage.setItem("service_type", "star_ad");
    //   localStorage.setItem("ad_id", ad_id);
    // }
  }, [fromMyAds]);

  const integration_id =
    payment_method === "card"
      ? process.env.REACT_APP_PAYMOB_CARD_ID
      : payment_method === "jazz-cash"
      ? process.env.REACT_APP_PAYMOB_JAZZCASH_ID
      : payment_method === "easy-paisa"
      ? process.env.REACT_APP_PAYMOB_EASYPAISA_ID
      : process.env.REACT_APP_PAYMOB_CARD_ID;

  const { mutateAsync: UserDetailsMutate, data: UserDetailsData } =
    useAccountGetUserDetails();

  const {
    data: ad_package_data,
    mutate: ad_package_mutate,
    isLoading: ad_package_isLoading,
  } = useStarAdPackage();

  const { data: myAds_list_data, isLoading: myAds_list_isLoading } =
    useFetchMyAdsAdsList({
      key: localStorage.getItem("key") ?? "",
    });

  const {
    data: paymob_auth_token_data,
    mutateAsync: paymob_auth_token_mutate,
    isLoading: paymob_auth_token_isLoading,
  } = usePaymob_auth_token();
  const {
    mutateAsync: order_registration_mutate,
    isLoading: order_registration_isLoading,
    data: order_registration_data,
  } = usePaymob_order_registration();
  const {
    mutateAsync: card_payment_key_mutate,
    isLoading: card_payment_key_isLoading,
  } = usePaymob_card_payment_key();

  const handleNext = () =>
    new Promise(() => {
      if (step === 4) {
        setStep(step - 2);
      } else {
        setStep(step + 1);
      }
    });

  useEffect(() => {
    UserDetailsMutate(localStorage.getItem("key"));
    if (
      UserDetailsData?.web_status_code === 101 ||
      UserDetailsData?.web_status_code === 102 ||
      UserDetailsData?.web_status_code === 103
    ) {
      localStorage.clear();
      navigate("/auth/login");
    }
  }, [order_registration_data]);

  const handle_auth = () => {
    const auth_payload = {
      api_key: process.env.REACT_APP_PAYMOB_API_KEY,
    };
    paymob_auth_token_mutate(auth_payload).then((res) => {
      const payload = {
        auth_token: res?.token,
        delivery_needed: "false",
        amount_cents: packageData?.package_price * 100,
        currency: "PKR",
        // merchant_order_id: "",
        items: [
          {
            name: packageData?.package_name,
            amount_cents: packageData?.package_price * 100,
            description: `service_id${packageData?.package_id}`,
            quantity: "1",
          },
        ],
      };
      order_registration_mutate(payload);
    });
  };

  const initialValues = {
    package: "",
    payment_field: "",
    step_no: 1,
    paid_ads: "",
    isPaid_ads: false,
  };

  const Formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      payment_field: yup.string().when("step_no", {
        is: (value) => (value === 2 ? true : false),
        then: yup.string().required("Please Select the Payment Term"),
        otherwise: yup.string(),
      }),

      package: yup
        .string()
        .required("Please Select the Package")
        .when("step_no", {
          is: (value) => (value === 1 ? true : false),
          then: yup.string().required("Please Select the Package"),
          otherwise: yup.string(),
        }),
      paid_ads: yup
        .string()
        .matches(/^[1-9]+$/, "Enter correct quantity")
        .when(["isPaid_ads"], {
          is: (value) => value === true,
          then: yup.string().required("Please add quantity"),
          otherwise: yup.string(),
        }),
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async () => {
      if (step === 1) {
        handleNext();
        handle_auth();
        setFieldValue("step_no", step + 1);
        setFieldError("payment_field", "");
        setFieldTouched("payment_field", false);
      } else if (step === 4) {
        Promise.resolve(values?.paid_ads).then(() => {
          handle_auth();
          handleNext();
        });

        setFieldValue("step_no", 2);
        setFieldError("payment_field", "");
        setFieldTouched("payment_field", false);
      } else {
        const payload = {
          auth_token: paymob_auth_token_data?.token,
          amount_cents: order_registration_data?.amount_cents,
          expiration: 3600,
          order_id: paymob_auth_token_data?.id,
          billing_data: {
            apartment: 195,
            email: "finance@farmghar.com",
            floor: 0,
            first_name: UserDetailsData?.data?.[0]?.name,
            street: "Street 4",
            building: "195 G3",
            phone_number: paymob_auth_token_data?.profile?.primary_phone_number,
            shipping_method: "PKG",
            postal_code: 51000,
            city: "Lahore",
            country: "PK",
            last_name: UserDetailsData?.data?.[0]?.name,
            state: "Punjab",
          },
          currency: "PKR",
          integration_id: integration_id,
        };
        card_payment_key_mutate(payload).then((res) => {
          if (res.token) {
            if (payment_method === "card") {
              window.location.href = `https://pakistan.paymob.com/api/acceptance/iframes/131624?payment_token==${res.token}`;
            } else {
              window.location.href = `https://pakistan.paymob.com/iframe/${res.token}`;
            }
          }
        });
      }
    },
  });

  const {
    values,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = Formik;

  const handleCloseToast = () => {
    setShowToast(false);
  };
  const handleShowToast = () => {
    setShowToast(true);
  };

  useEffect(() => {
    if (packageData?.normal_ads === true) {
      setShowModalBuyNow(true);
      setStep(2);
      handle_auth();
    }
  }, [packageData]);

  const hasChoseAdNotFound = myAds_list_data?.data?.some(
    (item) =>
      item?.review_status === "success" &&
      item?.is_star === "0" &&
      item?.priority === "0"
  );

  useEffect(() => {
    setChoseAdNotFound(hasChoseAdNotFound);
  }, [hasChoseAdNotFound]);

  const handleStepTwo = () => {
    setShowModalBuyNow(true);
    setFieldValue("step_no", 2);
    setFieldError("payment_field", "");
    setFieldTouched("payment_field", true);

    setFieldValue("package", ad_detail_list?.price);

    setPackageData({
      package_id: ad_detail_list?.id,
      package_name: ad_detail_list?.ad_title,
      package_price: ad_detail_list?.price,
      normal_ads: true,
    });
    localStorage.setItem("package_id", ad_detail_list?.id);
  };

  const handleStepOne = () => {
    setShowModalBuyNow(true);
    const payload = {
      ad_id: ad_id,
    };
    ad_package_mutate(payload);
    localStorage.setItem("ad_id", ad_id);
    localStorage.setItem("service_type", "star_ad");
  };

  const handleStepThird = () => {
    setStep(3);
    setShowModalBuyNow(true);
    localStorage.setItem("service_type", "star_ad");
    localStorage.setItem("ad_id", ad_id);
  };

  return (
    <>
      {showToast && (
        <Toast
          message={toastMessage}
          duration={3000}
          onClose={handleCloseToast}
        />
      )}
      <div className="w-full mx-auto">
        {(fromPrviewAd === true && ad_detail_list?.is_star === "1") ||
        ad_detail_list?.review_status === "reviewpending" ? null : (
          <button
            className={`w-full text-center h-[42px] rounded-[8px] text-white  ${
              slug === "post-ad"
                ? "bg-[#EFA922]"
                : slug === "star-ads"
                ? "bg-[#EFA922]"
                : ad_detail_list?.review_status === "reviewsuccessful" &&
                  ad_detail_list?.managed_by_farmghar === "0" &&
                  fromPrviewAd === true
                ? "bg-[#EFA922]"
                : ad_detail_list?.review_status === "reviewpending" &&
                  ad_detail_list?.managed_by_farmghar === "0"
                ? "bg-[#EFA922]"
                : ad_detail_list?.managed_by_farmghar === "1"
                ? "bg-Primary"
                : ad_detail_list?.review_status === "reviewsuccessful" &&
                  ad_detail_list?.managed_by_farmghar === "0" &&
                  fromPrviewAd === undefined
                ? "bg-Primary"
                : "bg-Primary"
            }`}
            onClick={() => {
              if (!isAuth) {
                navigate("/auth/login");
              } else if (slug === "star-ads") {
                handleStepThird();
              } else if (slug === "paid-ads") {
                setStep(4);
                setShowModalBuyNow(true);

                setFieldValue("isPaid_ads", true);
                localStorage.setItem("service_type", "paid_ad");
                localStorage.setItem("ad_id", 10);
              } else if (slug === "my-ads" || slug === "post-ad") {
                handleStepOne();
              } else if (
                ad_detail_list?.managed_by_farmghar === "0" &&
                ad_detail_list?.review_status === "reviewsuccessful" &&
                fromPrviewAd === true
              ) {
                handleStepOne();
              } else if (
                ad_detail_list?.managed_by_farmghar === "0" &&
                ad_detail_list?.review_status === "reviewsuccessful" &&
                fromPrviewAd === false
              ) {
                localStorage.setItem("service_type", "payment_guarantee");
                localStorage.setItem("ad_id", ad_detail_list?.id);
                handleStepTwo();
              }
              // in case of buy with payment guaranteed and self stock
              else if (
                ad_detail_list?.managed_by_farmghar === "1" &&
                ad_detail_list?.review_status === "reviewsuccessful"
              ) {
                localStorage.setItem("service_type", "self-stock");
                localStorage.setItem("ad_id", ad_detail_list?.id);
                handleStepTwo();
              } else if (ad_detail_list?.review_status === "reviewpending") {
                handleStepOne();
              } else {
                handleStepTwo();
                localStorage.setItem("service_type", "payment_guarantee");
              }
            }}
          >
            {slug === "post-ad" ? (
              <p className="font-PoppinsMedium text-[14px]">
                Want to sell Quickly? Make it a Star Ad
              </p>
            ) : slug === "star-ads" ? (
              <p className="font-PoppinsMedium text-[12px]">
                Want to sell Quickly? Make it a Star Ad
              </p>
            ) : ad_detail_list?.review_status === "reviewpending" ? (
              <p className="font-PoppinsMedium text-[12px]">
                Want to sell Quickly? Make it a Star Ad
              </p>
            ) : ad_detail_list?.review_status === "reviewsuccessful" &&
              ad_detail_list?.managed_by_farmghar === "1" ? (
              <p className="text-[12px]">Buy Now</p>
            ) : ad_detail_list?.review_status === "reviewsuccessful" &&
              ad_detail_list?.managed_by_farmghar === "0" &&
              ad_detail_list?.is_star === "0" &&
              fromPrviewAd === true ? (
              // <p className="text-[12px]">Buy with Payment Guarantee</p>
              <p className="font-PoppinsMedium text-[12px]">
                Want to sell Quickly? Make it a Star Ad
              </p>
            ) : ad_detail_list?.review_status === "reviewsuccessful" &&
              ad_detail_list?.managed_by_farmghar === "0" &&
              fromPrviewAd === undefined ? (
              <p className="text-[12px]">Buy with Payment Guarantee</p>
            ) : ad_detail_list?.managed_by_farmghar === "1" ? (
              "Buy Now"
            ) : slug === "my-ads" ? (
              <p>Make it star ad</p>
            ) : slug === "paid-ads" ? (
              <p>Buy Paid Ads</p>
            ) : (
              <p className="text-[12px]">Buy with Payment Guarantee</p>
            )}
          </button>
        )}
      </div>

      <form onSubmit={handleSubmit}>
        {showModalBuyNow ? (
          <Modal>
            <div id="modalInner">
              <div className="w-full  overflow-y-auto bg-[#fff] p-3 md:p-[20px] rounded-[13px] my-0 mx-auto mt-2 md:mt-4 relative">
                <div className=" absolute top-[-13px] right-[4px] mt-3">
                  <button
                    className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                    onClick={() => {
                      setShowModalBuyNow(false);
                      setStep(1);
                      Formik.resetForm();
                      setPayment_method(null);
                      setPackageData(null);
                      setAdsData(null);
                    }}
                  >
                    <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                  </button>
                </div>
                <div className="w-[280px] md:w-[600px] max-w-[600px] mx-auto flex items-center flex-col mt-3 font-PoppinsRegular "></div>
                {ad_package_isLoading ||
                paymob_auth_token_isLoading ||
                order_registration_isLoading ? (
                  <div className="flex items-center justify-center mt-20 mb-52">
                    <div className="spinner"></div>
                  </div>
                ) : (
                  <>
                    {/* --------------------- Packages Details --------------------- */}

                    {step === 1 && (
                      <div>
                        {ad_package_data?.data?.length > 0 ? (
                          <div className="overflow-x-auto shadow-md border rounded-lg my-4">
                            <table className="w-full text-sm">
                              <thead className="text-xs">
                                <tr className="text-lg ">
                                  <th
                                    scope="col"
                                    className="font-semibold md:font-bold text-center px-2 md:px-6 py-3"
                                  >
                                    Star Ad Package Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="font-semibold md:font-bold text-center px-2 md:px-6 py-3 border-x"
                                  >
                                    Price
                                  </th>
                                  <th
                                    scope="col"
                                    className="font-semibold md:font-bold text-center px-2 md:px-6 py-3"
                                  >
                                    No of Days
                                  </th>
                                </tr>
                              </thead>
                              {ad_package_data?.data?.map((item, index) => (
                                <tbody key={index}>
                                  <tr className="border-b border-gray-200">
                                    <th
                                      scope="row"
                                      className="px-2 md:px-6 py-4 font-normal md:font-medium"
                                    >
                                      <label className="flex items-center gap-2 PaymobFromRadioBtnLabel">
                                        <input
                                          className="h-3 md:h-5 w-3 md:w-5 text-gray-600 PaymobFromRadioBtn"
                                          type="radio"
                                          id="starAd"
                                          name="card"
                                          value={item?.package_id}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "package",
                                              e.target.value
                                            );
                                            setPackageData({
                                              package_id: item?.package_id,
                                              package_name: item?.package_name,
                                              package_price: item?.price,
                                            });
                                            localStorage.setItem(
                                              "package_id",
                                              item?.package_id
                                            );
                                          }}
                                          onBlur={handleBlur}
                                        />

                                        {item?.package_name}
                                      </label>
                                    </th>
                                    <td className="px-6 py-4 font-normal md:font-medium border-x">
                                      {item?.price}
                                    </td>
                                    <td className="px-6 py-4 font-normal md:font-medium">
                                      {item?.no_of_days}
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </table>

                            {errors.package && touched.package ? (
                              <p className="md:pl-5 py-2 font-semibold text-xs md:text-sm text-red-600">
                                {errors.package}
                              </p>
                            ) : null}
                          </div>
                        ) : (
                          <div className="flex items-center justify-center mt-20">
                            <div className="text-2xl font-PoppinsRegular">
                              No Ad Package Found
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {step === 2 && (
                      <div className="w-full max-w-[700px]">
                        <h2 className="font-semibold text-[18px] leading-[21.6px] mb-6 md:my-6">
                          Mode of Payments
                        </h2>
                        <div className="w-full space-y-2 mt-4 h-[350px] overflow-y-auto">
                          <label
                            className={`flex items-center justify-between  font-normal text-[12px] leading-[14.4px] border rounded-lg p-3 w-full h-[97.88px] ${
                              payment_method === "jazz-cash"
                                ? "bg-Primary bg-opacity-[0.1] text-Primary"
                                : "bg-white"
                            }`}
                          >
                            <span className="w-full flex gap-3 items-center">
                              <img
                                src={JazzCash}
                                alt=""
                                className="object-cover h-full w-full max-h-[92px] max-w-[92px]"
                              />
                              Jazz Cash
                            </span>

                            <input
                              className="h-5 w-5 text-gray-600 PaymobFromRadioBtn"
                              type="radio"
                              id="jazz-cash"
                              name="card"
                              value="jazz-cash"
                              checked={values.payment_field === "jazz-cash"}
                              onChange={(e) => {
                                setFieldValue("payment_field", e.target.value);
                                setPayment_method("jazz-cash");
                              }}
                              onBlur={handleBlur}
                            />
                          </label>

                          <label
                            className={`flex items-center justify-between  font-normal text-[12px] leading-[14.4px] border rounded-lg p-3 w-full ${
                              payment_method === "easy-paisa"
                                ? "bg-Primary bg-opacity-[0.1] text-Primary"
                                : "bg-white"
                            }`}
                          >
                            <span className="w-full flex gap-3 items-center">
                              <img
                                src={EasyPaisa}
                                alt=""
                                className="object-cover h-auto max-h-[58px] max-w-[58px]"
                              />
                              Easy Paisa
                            </span>

                            <input
                              className="h-5 w-5 text-gray-600 PaymobFromRadioBtn"
                              type="radio"
                              id="easy-paisa"
                              name="card"
                              value="easy-paisa"
                              checked={values.payment_field === "easy-paisa"}
                              onChange={(e) => {
                                setFieldValue("payment_field", e.target.value);
                                setPayment_method("easy-paisa");
                              }}
                              onBlur={handleBlur}
                            />
                          </label>
                          <label
                            className={`flex items-center justify-between  font-normal text-[12px] leading-[14.4px] border rounded-lg p-3 w-full ${
                              payment_method === "card"
                                ? "bg-Primary bg-opacity-[0.1] text-Primary"
                                : "bg-white"
                            }`}
                          >
                            <span className="w-full flex gap-3 items-center">
                              <img
                                src={DebitCredit}
                                alt=""
                                className="object-cover h-auto max-h-[72px] max-w-[72px]"
                              />
                              Debit/ Credit
                            </span>

                            <input
                              className="h-5 w-5 text-gray-600 PaymobFromRadioBtn"
                              type="radio"
                              id="card"
                              name="card"
                              value="card"
                              checked={values.payment_field === "card"}
                              onChange={(e) => {
                                setFieldValue("payment_field", e.target.value);
                                setPayment_method("card");
                              }}
                              onBlur={handleBlur}
                            />
                          </label>

                          {errors.payment_field && touched.payment_field ? (
                            <p className="md:pl-5 py-2 font-semibold text-xs md:text-sm text-red-600">
                              {errors.payment_field}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    )}

                    {/* --------------------- For Star Ads --------------------- */}
                    {step === 3 && (
                      <div className="w-full max-w-[700px]">
                        <h2 className="font-semibold text-[18px] leading-[21.6px] mb-6 md:my-6">
                          Choose Ad
                        </h2>
                        <div className="flex flex-col gap-1 w-full space-y-2 mt-4 h-[350px] overflow-y-auto">
                          {myAds_list_data?.data?.map((item, index) => (
                            <label
                              key={index}
                              className="PaymobFromRadioBtnLabel"
                            >
                              <div
                                className={`flex justify-between items-center border border-[#DBDED9] rounded-lg shadow-lg p-4 w-full ${
                                  item?.review_status === "success" &&
                                  item?.is_star === "0" &&
                                  item?.priority === "0"
                                    ? " block"
                                    : "hidden"
                                }`}
                              >
                                <div className="grid md:flex gap-3 items-center w-full">
                                  <div>
                                    <img
                                      src={item?.img_thumbnail}
                                      alt={item?.ad_title}
                                      className="max-w-[100px] max-h-[100px] rounded-lg object-contain"
                                    />
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <h2 className="font-semibold text-base">
                                      {item?.ad_title}
                                    </h2>
                                    <span className="font-normal text-xs">
                                      {item?.ad_summary}
                                    </span>

                                    <span className="flex items-center gap-1 font-normal text-xs opacity-50">
                                      <EyeIcon />
                                      {item?.detailviews}
                                    </span>
                                    <div className="flex gap-3 items-center">
                                      <span className="flex gap-1 font-normal text-xs">
                                        <LocationAdIcon />
                                        {item?.district}
                                      </span>
                                      <span className="font-bold text-base text-[#3ED400]">
                                        Rs.
                                        {item?.price}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <input
                                  className="PaymobFromRadioBtn h-5 w-5 text-gray-600"
                                  type="radio"
                                  id="my-ads"
                                  name="myAds"
                                  onChange={() => {
                                    setAdsData({
                                      ad_id: item?.id,
                                    });
                                    localStorage.setItem("ad_id", item?.id);
                                  }}
                                />
                              </div>
                            </label>
                          ))}

                          <div
                            className={`flex items-center justify-center mt-20 ${
                              choseAdNotFound ? "hidden" : "block"
                            }`}
                          >
                            <div className="text-2xl font-PoppinsRegular">
                              No Ad Package Found
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* --------------------- For Paid Ads --------------------- */}
                    {step === 4 && (
                      <div className="w-full max-w-[700px] px-3">
                        <h2 className="font-bold text-xl my-6">
                          Please add quantity
                        </h2>
                        <p>
                          Enter the quantity for the paid ads you want to buy
                        </p>
                        <p className="font-normal text-sm text-[#848484] pb-6">
                          Price per ad : Rs.
                          {!packageData?.package_price
                            ? "200"
                            : packageData?.package_price}
                        </p>

                        <input
                          className=" outline-none  border-b-[0.5px] border-[#DFDFDF]  h-[46px] w-full py-4 pl-5 text-black   placeholder:font-normal placeholder:text-sm "
                          type="name"
                          autoComplete="off"
                          placeholder="Add here Ads Quantity"
                          name="paid_ads"
                          id="name"
                          value={values?.paid_ads}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue("package", e.target.value);
                            setFieldValue("paid_ads", e.target.value);

                            setPackageData({
                              package_id: 10,
                              package_name: "paid-ads",
                              package_price: e.target.value * 200,
                            });
                            localStorage.setItem("package_id", 10);
                            localStorage.setItem(
                              "package_quantity",
                              e.target.value
                            );
                          }}
                        />

                        {errors.paid_ads && touched.paid_ads ? (
                          <p className="md:pl-5 py-2 font-semibold text-xs md:text-sm text-red-600">
                            {errors.paid_ads}
                          </p>
                        ) : null}
                      </div>
                    )}

                    <div className="w-full flex justify-center my-6 ">
                      {step === 4 ? (
                        <button
                          type="submit"
                          disabled={packageData === undefined}
                          onClick={() => {
                            handleSubmit();
                          }}
                          className={`font- text-[12px] leading-[14.4px] text-white bg-[#3ED400] rounded-lg p-3 w-full max-w-[140px] 
                        
                        `}
                        >
                          Next
                        </button>
                      ) : null}
                      {step === 3 ? (
                        <button
                          type="button"
                          onClick={() => {
                            if (!adsData?.ad_id) {
                              setToastMessage("Please Select an Ad");
                              handleShowToast();
                            } else {
                              setStep(1);
                              const payload = {
                                ad_id: adsData?.ad_id,
                              };
                              ad_package_mutate(payload);
                              // localStorage.setItem("ad_id", adsData?.id);
                            }
                          }}
                          className={`font- text-[12px] leading-[14.4px] text-white bg-[#3ED400] rounded-lg p-3 w-full max-w-[140px] 
                        
                        `}
                        >
                          {myAds_list_isLoading ? (
                            <p>please wait...</p>
                          ) : (
                            "Next"
                          )}
                        </button>
                      ) : (
                        <button
                          type="submit"
                          onClick={() => {
                            handleSubmit();
                          }}
                          className={`font- text-[12px] leading-[14.4px] text-white bg-[#3ED400] rounded-lg p-3 w-full max-w-[140px] 
                        ${
                          step === 4 ||
                          ad_package_isLoading ||
                          (ad_package_data?.data === undefined && step === 4)
                            ? "hidden"
                            : "block"
                        }
                        `}
                        >
                          {card_payment_key_isLoading ? (
                            <p>please wait...</p>
                          ) : (
                            <>{step === 1 ? "Next" : "Pay Now"}</>
                          )}
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Modal>
        ) : null}
      </form>
    </>
  );
};

export default PaymentConfirmation;
