import { Disclosure } from "@headlessui/react";
import { useFetchQurbaniFAQs } from "../Middlewares/Api";
export default function QurbaniFaqs() {
  const { data: faqs } = useFetchQurbaniFAQs();

  return (
    <div className="space-y-2">
      {faqs?.data?.map((faq) => (
        <Disclosure key={faq.question}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex justify-between items-center w-full px-4 py-2  text-sm text-left text-TextColor ${
                  open
                    ? "bg-Primary bg-opacity-[0.15]"
                    : "bg-white border-2 border-gray"
                } rounded-lg  focus:outline-none `}
              >
                <span className="font-PoppinsBold">{faq.question}</span>
                <span>
                  {open ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 4.34295C12.0634 4.34295 11.6325 4.42007 11.2324 4.56858C10.8324 4.71708 10.4732 4.93319 10.1737 5.20151C9.87446 5.4697 9.64136 5.78419 9.48388 6.1249C9.42129 6.2603 9.37098 6.3991 9.33308 6.54003H15.6668C15.6289 6.3991 15.5786 6.2603 15.516 6.1249C15.3586 5.78419 15.1255 5.4697 14.8262 5.20151C14.5268 4.93319 14.1675 4.71708 13.7675 4.56858C13.3674 4.42007 12.9365 4.34295 12.5 4.34295ZM16.9982 6.54003C16.9407 6.20991 16.8397 5.88716 16.6972 5.57892C16.4624 5.07074 16.1203 4.61392 15.6946 4.23241C15.269 3.85102 14.7675 3.55175 14.2203 3.34864C13.6731 3.14553 13.0887 3.04167 12.5 3.04167C11.9112 3.04167 11.3268 3.14553 10.7796 3.34863C10.2324 3.55175 9.7309 3.85102 9.3053 4.23241C8.87957 4.61392 8.53757 5.07074 8.30267 5.57892C8.1602 5.88716 8.05919 6.20991 8.00173 6.54003H4.69227C4.33293 6.54003 4.04163 6.83134 4.04163 7.19068C4.04163 7.55001 4.33293 7.84132 4.69227 7.84132H5.77667V11.6526C5.77667 13.08 5.97748 14.5002 6.37315 15.8708C6.96411 17.918 8.66695 19.4471 10.7591 19.8006L10.896 19.8238C11.9579 20.0032 13.042 20.0032 14.1039 19.8238L14.2408 19.8006C16.3329 19.4471 18.0358 17.918 18.6267 15.8709C19.0224 14.5002 19.2232 13.0799 19.2232 11.6525V7.84132H20.3077C20.667 7.84132 20.9583 7.55001 20.9583 7.19068C20.9583 6.83134 20.667 6.54003 20.3077 6.54003H16.9982ZM17.922 7.84132H7.07795V11.6526C7.07795 12.9579 7.2616 14.2567 7.62338 15.5099C8.07696 17.0812 9.38142 18.2481 10.9759 18.5175L11.1128 18.5407C12.0312 18.6959 12.9687 18.6959 13.8871 18.5407L14.024 18.5175C15.6185 18.2481 16.9229 17.0812 17.3765 15.5099C17.7383 14.2567 17.922 12.9579 17.922 11.6525V7.84132Z"
                        fill="#FF3737"
                      />
                      <path
                        d="M7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.82C22 4.17 19.83 2 16.19 2H7.82C4.18 2 2.01 4.17 2.01 7.81V16.19C2 19.83 4.17 22 7.81 22ZM7.94 12.83L11.47 9.3C11.62 9.15 11.81 9.08 12 9.08C12.19 9.08 12.38 9.15 12.53 9.3L16.06 12.83C16.35 13.12 16.35 13.6 16.06 13.89C15.77 14.18 15.29 14.18 15 13.89L12 10.89L9 13.89C8.71 14.18 8.23 14.18 7.94 13.89C7.65 13.6 7.65 13.13 7.94 12.83Z"
                        fill="#3ED400"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.81 2H16.19C19.83 2 22 4.17 22 7.81V16.18C22 19.83 19.83 22 16.19 22H7.82C4.18 22 2.01 19.83 2.01 16.19V7.81C2 4.17 4.17 2 7.81 2ZM7.94 11.17L11.47 14.7C11.62 14.85 11.81 14.92 12 14.92C12.19 14.92 12.38 14.85 12.53 14.7L16.06 11.17C16.35 10.88 16.35 10.4 16.06 10.11C15.77 9.82 15.29 9.82 15 10.11L12 13.11L9 10.11C8.71 9.82 8.23 9.82 7.94 10.11C7.65 10.4 7.65 10.87 7.94 11.17Z"
                        fill="#B6B6B6"
                      />
                    </svg>
                  )}
                </span>
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                {faq.answer}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
}
