// import {useParams} from 'react-router-dom';
import Breadcrumbs from "../Components/BreadCrumbs";
import BannerTop from "../Components/HomePageComponents/BannerTop";
import CallToActionBottom from "../Components/HomePageComponents/CallToActionBottom";
import SelectCategory from "../Components/HomePageComponents/SelectCategory";
import SingleCategoryDetails from "../Components/SingleCategoryPageComponents/SingleCategoryDetails";
import SelectCategoryLoader from "../Loaders/SelectCategoryLoader";
import { useFetchHomePageCategories } from "../Middlewares/Api";
export default function SingleCategoryPage() {
  const { data: HomePageCategories, isLoading: CategoriesLoading } =
    useFetchHomePageCategories();
  return (
    <>
      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>
        <div className="w-full mx-auto">
          {CategoriesLoading ? (
            <SelectCategoryLoader />
          ) : (
            <SelectCategory data={HomePageCategories} />
          )}
        </div>
        <div>
          <Breadcrumbs />
        </div>
        <SingleCategoryDetails />
        <CallToActionBottom />
      </div>
    </>
  );
}
