import Modal from "../Modal";
import { useState } from "react";

import { ReactComponent as ViewStepsIcon } from "../../assets/icons/ViewSteps.svg";
import { ReactComponent as WatchVideoIcon } from "../../assets/icons/WatchVideo.svg";
import { XMarkIcon } from "@heroicons/react/24/outline";
export default function VerificationSteps({ data }) {
  const [showModalViewSteps, setShowModalViewSteps] = useState(false);
  const [showModalWatchVideo, setShowModalWatchVideo] = useState(false);
  return (
    <>
      {data?.is_verified === "1" && (
        <>
          <div className="flex  items-center space-x-2 mt-[24px] mx-[10px]">
            <button
              onClick={() => setShowModalViewSteps(true)}
              className="w-[230px] h-[42px] rounded-[8px]   text-TextColor  bg-Primary flex  items-center bg-opacity-[0.15]"
            >
              <div className="ml-[10px] mr-[8px]">
                <ViewStepsIcon />
              </div>
              <div className="text-center w-full font-PoppinsBold  -ml-[10px] text-[10px] sm:text-[12px]">
                View Verification Checklist
              </div>
            </button>
            <button
              onClick={() => setShowModalWatchVideo(true)}
              className="w-[192px] h-[42px]  text-TextColor  rounded-[8px]  bg-Primary flex  items-center bg-opacity-[0.15]"
            >
              <div className="ml-[10px]">
                <WatchVideoIcon />
              </div>
              <div className="text-center w-full font-PoppinsBold  -ml-[10px] text-[10px] sm:text-[12px]">
                Watch Video
              </div>
            </button>
          </div>

          {showModalViewSteps ? (
            <Modal>
              <div id="modalInner">
                <div id="modalContent" className="relative">
                  <div className="w-[250px] sm:w-[500px] max-w-[600px] h-[350px] mx-auto flex items-center mt-4">
                    <img
                      src={data?.verify_step_image}
                      alt=""
                      className="w-[250px] sm:w-[500px] max-w-[600px] h-[350px] rounded-md"
                    />
                  </div>
                  <div className=" absolute top-[-13px] right-[4px] mt-3">
                    <button
                      className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                      onClick={() => setShowModalViewSteps(false)}
                    >
                      <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          ) : null}

          {showModalWatchVideo ? (
            <Modal>
              <div id="modalInner">
                <div id="modalContent" className="relative">
                  <div className="w-[250px] sm:w-[500px] max-w-[600px] h-[350px] mx-auto flex items-center mt-4 ">
                    <iframe
                      className="w-[250px] sm:w-[500px] max-w-[600px] h-[350px] rounded-md"
                      src={data?.verify_video_id}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className=" absolute top-[-13px] right-[4px] mt-3">
                    <button
                      className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                      onClick={() => setShowModalWatchVideo(false)}
                    >
                      <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          ) : null}
        </>
      )}
    </>
  );
}
