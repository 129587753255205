import axios from "axios";

const baseURL = `${process.env.REACT_APP_PAYMOB_API_URL}`;

const UsePaymob = () => {
  const axiosInstance = axios.create({
    baseURL,
  });

  axiosInstance.interceptors.request.use(async (req) => {
    return req;
  });

  return axiosInstance;
};

export default UsePaymob;
