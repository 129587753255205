import CardSlider from "../CardSlider";

export default function OurServices({ data }) {
  const data1 =
    data &&
    data.map((item) => {
      return {
        ServiceMoreInfoLink: `/services/${item?.slug}`,
        id: item.service_id,
        Image: item.web_icon,
        IsService: true,
        ServiceName: item.name,
        ServiceMoreInfo: "More Info",

        WhatsappNumber: item.whatsapp_number,
        PhoneNumber: item.phone_number,
        SMSNumber: item.phone_number,
        DetailImage: item.detail_image,
      };
    });

  const settings = {
    SectionLink: `/services`,
    SectionName: "Our Services",
    ViewAll: "View All",
    IsService: true,
    // Width: "xsm:w-[340.93px]",
    // Height: "h-[399.67px]",
    // ImageWidth: "xsm:w-[328.34px]",
    // ImageHeight: "h-[341.98px]",
    Width: "xsm:w-[255px]",
    Height: "h-[112px]",
    ImageWidth: "w-[90.48px]",
    ImageHeight: "h-[100px]",
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    hiddenOnOneSlide: "lg:block",
    data: data1,
    autoplay: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1215,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
          autoplay: true,
        },
      },
      {
        breakpoint: 1214,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          centerMode: false,
          autoplay: true,
          // centerMode: true,
          // centerPadding: "20px",
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          centerMode: false,
          autoplay: true,
          // centerMode: true,
          // centerPadding: "20px",
        },
      },
      {
        breakpoint: 615,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          infinite: true,
          autoplay: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          autoplay: true,
          centerMode: false,
        },
      },
    ],
  };

  return <CardSlider settings={settings} />;
}
