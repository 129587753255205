import Slider from "react-slick";

import { ReactComponent as StarIcon } from "../../assets/icons/star.svg";
import { ReactComponent as VerifiedIcon } from "../../assets/icons/verified.svg";
import { ReactComponent as CameraIcon } from "../../assets/icons/Camera.svg";

import { ReactComponent as PrevIcon } from "../../assets/slider/left.svg";
import { ReactComponent as NextIcon } from "../../assets/slider/right.svg";
import { ReactComponent as SoldOutIcon } from "../../assets/icons/SoldOut.svg";
import ImagePreviewModal from "./ImagePreviewModal";
// import AdSliderGoogleAd from "../../Utils/AdSliderGoogleAd";
import { useRef, useState } from "react";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    //eslint-disable-next-line
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "red",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      NEXT
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    //eslint-disable-next-line
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "green",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      BACK
    </div>
  );
}

export default function AdSlider({ data }) {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClick = (item, index) => {
    setCurrentIndex(index);
    setClickedImg(item);
    document.body.style.overflow = "hidden";
  };

  const handelRotationRight = () => {
    const totalLength = data?.img?.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = data?.img[0];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = data?.img?.filter((item) => {
      return data?.img?.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0];
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = data?.img?.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = data?.img[totalLength - 1];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = data?.img?.filter((item) => {
      return data?.img?.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0];
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const slider = useRef(null);
  const settings = {
    customPaging: function (i) {
      if (data?.img?.length === i) {
        return <div className="hidden"></div>;
      }
      return (
        //eslint-disable-next-line
        <a className=" w-[100px] h-[85px] rounded-[13px]">
          <img
            src={`${data?.img[i]}`}
            alt=""
            className="rounded-[13px] w-[100px] h-[85px]"
          />
        </a>
      );
    },
    infinite: true,
    dotsClass: "slick-custom-dots",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="">
      <div
        className={`rounded-[9px] mt-[6.77px]  w-full ${
          data?.managed_by_farmghar === "1" ? "h-[509px] " : "h-[471px]"
        }`}
      >
        <div className="relative w-full h-[471px] ">
          {data?.img?.length > 1 && (
            <div className={"z-10 absolute top-[206px] left-[23px] "}>
              <button
                onClick={() => slider?.current?.slickPrev()}
                type="button"
              >
                <PrevIcon />
              </button>
            </div>
          )}
          <div className="AdSliderStyle ">
            <Slider ref={slider} {...settings}>
              {data &&
                data?.img?.map((image, index) => (
                  <div
                    key={index}
                    className={`
                   ${
                     data?.managed_by_farmghar === "1"
                       ? "border-2 border-Primary"
                       : null
                   }
                  `}
                  >
                    {/* eslint-disable-next-line  */}
                    <img
                      src={image && `${image}`}
                      onClick={() => handleClick(image, index)}
                      className="mx-auto rounded-[9px]  h-[471px] py-2"
                      alt={data?.ad_title && data?.ad_title}
                    />
                  </div>
                ))}

              {/* <div>
                <AdSliderGoogleAd />
              </div> */}
            </Slider>
          </div>

          {data?.is_star === "1" && data?.priority === "11" && (
            <div className="absolute top-[-15px] left-[5px]">
              <StarIcon />
            </div>
          )}
          {data?.ad_status && /sold/i.test(data?.ad_status) && (
            <div className="absolute right-[85px] top-[86px]">
              <SoldOutIcon />
            </div>
          )}
          {data?.is_verified === "1" && (
            <div className="absolute top-[7.62px] right-[26px]">
              <VerifiedIcon />
            </div>
          )}
          {data?.img?.length && (
            <div className="absolute bottom-[7.62px] right-[26px] flex bg-TextColor rounded-[4px] w-[48px] h-[26px] justify-center items-center bg-opacity-[0.5]">
              <CameraIcon />
              <span className="ml-2 text-white">{data?.img?.length}</span>
            </div>
          )}
          {data?.img?.length > 1 && (
            <div className={"z-10 absolute top-[206px] right-[23px]  "}>
              <button
                onClick={() => slider?.current?.slickNext()}
                type="button"
              >
                <NextIcon />
              </button>
            </div>
          )}
        </div>
        {data?.managed_by_farmghar === "1" && (
          <div className="flex justify-center font-PoppinsBold text-white bg-Primary text-[20px] pt-1 mx-[21px]">
            farmGhar Self-stock
          </div>
        )}
      </div>
      <div>
        {clickedImg && (
          <ImagePreviewModal
            isOneImage={data?.img?.length === 1}
            clickedImg={clickedImg}
            handelRotationRight={handelRotationRight}
            setClickedImg={setClickedImg}
            handelRotationLeft={handelRotationLeft}
          />
        )}
      </div>
    </div>
  );
}
