import { ReactComponent as ExploreDeliveryGuranteeIcon } from "../../assets/icons/ExploreDeliveryGurantee.svg";
import { ReactComponent as ExploreHomeDeliveryIcon } from "../../assets/icons/ExploreHomeDelivery.svg";
import { ReactComponent as ExploreVerifiedIcon } from "../../assets/icons/ExploreVerified.svg";

export default function ExploreProducts() {
  const Products = [
    {
      icon: <ExploreVerifiedIcon />,
      websiteName: "farmGhar",
      ProductName: "Verified",
    },
    {
      icon: <ExploreHomeDeliveryIcon />,
      websiteName: "farmGhar",
      ProductName: "Home Delivery",
    },
    {
      icon: <ExploreDeliveryGuranteeIcon />,
      websiteName: "farmGhar",
      ProductName: "Delivery Gurantee",
    },
  ];
  return (
    <div className=" bg-gray-50 rounded-[15px] w-[95%] mx-auto my-[50px]">
      <h3 className="text-center font-PoppinsBold text-[22px] pt-[47px]">
        Explore Products by farmGhar
      </h3>
      <div className="mt-[-14px] py-[67px] flex flex-col sm:flex-row sm:justify-center items-center">
        {Products.map((item, index) => {
          return (
            <div
              key={index}
              className="w-[90%] sm:w-[25%] mx-auto mt-[27px] sm:mt-0 h-[175px] bg-white  flex items-center rounded-[15px]"
            >
              <div className=" flex  flex-col pl-[24.34px]">
                <div>{item.icon}</div>
                <div className="pt-[13.91px] font-PoppinsBold">
                  {item.websiteName}
                </div>
                <div className="pt-[14.91px] font-PoppinsBold">
                  {item.ProductName}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
