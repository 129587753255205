import MainComponent from "./Components/MainComponent";
import { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactComponent as WA } from "./assets/icons/WA.svg";
import Header from "./Components/BasicComponents/Header";
import Footer from "./Components/BasicComponents/Footer";
import { FilterAdsContextProvider } from "./Context/FilterAdsContext";
import { PreserveFormikFilterContextProvider } from "./Context/PreserveFormikFilterContext";
import { BlogsContextProvider } from "./Context/BlogsContext";
import { AuthContextProvider } from "./Context/AuthContext";
import { LocationContextProvider } from "./Context/LocationContext";
import ScrollToTop from "./Components/ScrollToTop";
import SideAd from "./Components/HomePageComponents/SideAd";
import { CartProvider } from "./Context/CartContext";
class App extends Component {
  componentDidMount() {
    if (process.env.REACT_APP_SERVER_TYPE === "production") {
      if (!window.adsScriptAdded) {
        // Include Google tag (gtag.js) script tags here
        // const gtagScript = document.createElement("script");
        // gtagScript.src =
        //   "https://www.googletagmanager.com/gtag/js?id=AW-469522917";
        // gtagScript.async = true;

        // const gtagConfigScript = document.createElement("script");
        // gtagConfigScript.innerHTML = `
        //     window.dataLayer = window.dataLayer || [];
        //     function gtag(){dataLayer.push(arguments);}
        //     gtag('js', new Date());

        //     gtag('config', 'AW-469522917');
        //   `;
        // // document.head.appendChild(gtagScript);
        // document.head.appendChild(gtagConfigScript);

        //Google Tag Manager
        const gtmScript = document.createElement("script");
        gtmScript.innerHTML = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MX4MJP8N');
              `;
        document.head.appendChild(gtmScript);

        //Meta Pixel Code

        // const metaPixelScript = document.createElement("script");
        // metaPixelScript.innerHTML = `
        //   !function (f, b, e, v, n, t, s) {
        //     if (f.fbq) return; n = f.fbq = function () {
        //       n.callMethod ?
        //       n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        //     };
        //     if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        //     n.queue = []; t = b.createElement(e); t.async = !0;
        //     t.src = v; s = b.getElementsByTagName(e)[0];
        //     s.parentNode.insertBefore(t, s)
        //   }(window, document, 'script',
        //     'https://connect.facebook.net/en_US/fbevents.js');
        //   fbq('init', '687753315656728');
        //   fbq('track', 'PageView');
        //   `;
        // document.head.appendChild(metaPixelScript);
        // // Include Facebook Pixel noscript tag here
        // const noscript = document.createElement("noscript");
        // const img = document.createElement("img");
        // img.height = "1";
        // img.width = "1";
        // img.style.display = "none";
        // img.src =
        //   "https://www.facebook.com/tr?id=687753315656728&ev=PageView&noscript=1";
        // noscript.appendChild(img);
        // document.head.appendChild(noscript);

        //Google Tag Manager (noscript)
        const gtmIframe = document.createElement("iframe");
        gtmIframe.src =
          "https://www.googletagmanager.com/ns.html?id=GTM-MX4MJP8N";
        gtmIframe.height = "0";
        gtmIframe.width = "0";
        gtmIframe.style.display = "none";
        gtmIframe.style.visibility = "hidden";
        const noscriptTag = document.createElement("noscript");
        noscriptTag.appendChild(gtmIframe);
        document.body.prepend(noscriptTag);

        // Include Google AdSense script tag here
        // const script = document.createElement("script");
        // script.src =
        //   "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9191039315895871";
        // script.async = true;
        // script.setAttribute("crossorigin", "anonymous");
        // document.head.appendChild(script);
        // window.adsScriptAdded = true;
      }
    }
  }
  render() {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: Infinity,
          cacheTime: Infinity,
        },
      },
    });
    const handleWhatsAppClick = () => {
      const number = "9203011018207";
      const message = "I have questions.";
      const encodedMessage = encodeURIComponent(message);
      window.open(`https://wa.me/${number}?text=${encodedMessage}`, "_blank");
    };
    return (
      <BrowserRouter>
        <ScrollToTop />
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <FilterAdsContextProvider>
              <PreserveFormikFilterContextProvider>
                <BlogsContextProvider>
                  <CartProvider>
                    <LocationContextProvider>
                      <div className="max-w-[1280px] mx-auto font-PoppinsRegular">
                        <Header />
                      </div>

                      <div className="max-w-[1280px] md:mx-auto px-[5%] xxl:px-[2%] relative hidden 2xl:block">
                        <SideAd />
                      </div>
                      {/* eslint-disable-next-line */}
                      <div
                        className="fixed bottom-4 right-4 z-10 cursor-pointer"
                        onClick={handleWhatsAppClick}
                      >
                        <div className="relative w-16 h-16">
                          <button
                            type="button"
                            className="bg-green-500 hover:bg-green-600 rounded-full p-2 shadow-lg focus:outline-none"
                          >
                            <WA />
                          </button>
                          <div className="absolute bottom-[-2px] right-[38px] transform translate-x-1/2 -translate-y-1/2">
                            <div className="bg-green-500 w-[40px] h-[40px] rounded-full animate-ping"></div>
                          </div>
                        </div>
                      </div>

                      <MainComponent />

                      <div className="max-w-[1280px] mx-auto font-PoppinsRegular">
                        <Footer />
                      </div>
                    </LocationContextProvider>
                  </CartProvider>
                </BlogsContextProvider>
              </PreserveFormikFilterContextProvider>
            </FilterAdsContextProvider>
          </AuthContextProvider>
        </QueryClientProvider>
      </BrowserRouter>
    );
  }
}

export default App;
