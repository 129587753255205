import { Link, useSearchParams } from "react-router-dom";
import {
  useFetchGetIdFromSlugCategory,
  useFetchSingleCategoryDetails,
} from "../../../Middlewares/Api";
import { useContext, useEffect } from "react";
import FilterAdsContext from "../../../Context/FilterAdsContext";
import PreserveFormikFilterContext from "../../../Context/PreserveFormikFilterContext";
import LocationContext from "../../../Context/LocationContext";
import GetSelectedFilters from "../../SingleCategoryPageComponents/GetSelectedFilters";

import { ReactComponent as StarIcon } from "./../../../assets/icons/star.svg";
import { ReactComponent as VerifiedIcon } from "./../../../assets/icons/verified.svg";
import { ReactComponent as PackageIcon } from "./../../../assets/icons/package.svg";
import { ReactComponent as DeliveryIcon } from "./../../../assets/icons/delivery.svg";
import { ReactComponent as LocationAdIcon } from "./../../../assets/icons/locationAd.svg";
import { ReactComponent as EyeIcon } from "./../../../assets/icons/eye.svg";
import { ReactComponent as SoldOutIcon } from "./../../../assets/icons/SoldOut.svg";

import { XMarkIcon } from "@heroicons/react/24/outline";

export default function AllCategories() {
  const CatSlug = "all-categories";
  const CatId = useFetchGetIdFromSlugCategory({ CatSlug: CatSlug }).data
    ?.category_id;
  const [searchParams, setSearchParams] = useSearchParams();
  const Query = searchParams.get("query");

  const { filterAds, setFilterAds } = useContext(FilterAdsContext);
  const { formikInitialValues, setFormikInitialValues } = useContext(
    PreserveFormikFilterContext
  );

  const { locationStates, setLocationStates } = useContext(LocationContext);
  const { data: SingleCategoryDetails, isLoading: CategoryDetailsLoading } =
    useFetchSingleCategoryDetails({
      CatId: 1,
      Query: Query ? Query : null,
      page: filterAds.page,
      sortBy: filterAds.sortBy,
      Province: filterAds.Province,
      District: filterAds.district,
      Tehsil: filterAds.Tehsil,
      Category: filterAds.Category,
      MaxPrice: filterAds.MaxPrice,
      Breed: filterAds.Breed,
      IsDelivery: filterAds.IsDelivery,
      IsVerified: filterAds.IsVerified,
      IsPregnant: filterAds.IsPregnant,
      MinWeight: filterAds.MinWeight,
      MinAge: 0,
      MaxAge: filterAds.MaxAge,
      Castrated: filterAds.Castrated,
      latitude: locationStates.isViewNearby ? locationStates.lat2 : null,
      longitude: locationStates.isViewNearby ? locationStates.lng2 : null,
    });

  useEffect(() => {
    searchParams.delete("filter");
    setSearchParams(searchParams);

    if (
      filterAds.SelectedPaginationCategory !== undefined &&
      CatId !== filterAds.SelectedPaginationCategory
    ) {
      setFilterAds((prev) => ({
        ...prev,
        page: 1,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CatSlug]);

  if (CategoryDetailsLoading) {
    return (
      <div className="flex gap-4 items-center mt-8">
        {Array(4)
          .fill()
          .map((_, index) => (
            <div key={index} style={{ width: 270 }}>
              <div
                className="
                        rounded-[13px] border border-[#DBDED9]  bg-white   w-[240px]  xsm:w-[240px] h-[312px]
                        "
              >
                <div>
                  <div className="rounded-[8px] mt-[6.77px] mx-[5.95px] w-[230px] xsm:w-[229.48px] h-[180.49px]">
                    <div className="relative w-[230px] xsm:w-[229.48px] h-[180.49px]">
                      <div className=" rounded-[9px] w-full h-full object-cover bg-gray-100 "></div>
                      <div className="absolute top-[14px] left-[6px]">
                        <p className="w-[50px] h-[10px] bg-gray-200"></p>
                      </div>
                      <div className="absolute bottom-[7.62px] right-[6.43px]">
                        <p className="w-[76px] h-[28px] bg-gray-200"></p>
                      </div>
                    </div>
                  </div>
                  <div className="m-[12.51px]">
                    <div className="flex"></div>
                    <div>
                      <p className="font-PoppinsBold  text-[16px] w-10 h-2 bg-gray-200"></p>
                    </div>
                    <div className="flex justify-between mt-[20px]">
                      <p className=" w-6 h-2 bg-gray-200"></p>
                      <p className="font-[18px] w-6 h-2 bg-gray-200"></p>
                    </div>
                    <div className="flex justify-between mt-[10px]">
                      <div className="flex items-center">
                        <div className="mr-[5.85px]">
                          <p className="h-[14px] w-[11px] bg-gray-100"></p>
                        </div>
                        <p className="w-6 h-2 bg-gray-200"></p>
                      </div>
                      <div className="flex items-center">
                        <div className="opacity-50 mr-[5.85px]">
                          <p className="h-[16px] w-[16px]  bg-gray-200"></p>
                        </div>
                        <p className="text-Primary w-6 h-2 bg-gray-200"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }

  const DisplaySoldOut = () => {
    return alert("This item has been sold and no longer available.");
  };

  const getShortName = (name) => {
    let str;

    if (name.length > 14) {
      str = name.substring(0, 20) + "...";
    } else {
      str = name;
    }
    return str;
  };
  const RemoveFilterIsNearbyAds = () => {
    setLocationStates((prev) => ({
      ...prev,
      isViewNearby: false,
      locality: null,
    }));
  };
  return (
    <>
      <div>
        <div className="pt-2 md:pt-4">
          <div className="flex  flex-col-reverse xl:flex-row">
            <div className="w-full mx-auto">
              <div className="text-[16px]  md:-mt-8 pt-2 ">
                {locationStates.isViewNearby && (
                  <div className="bg-gray-200 p-1 rounded-md text-TextColor border border-[#3ED400] font-PoppinsRegular text-[12px] mr-2 mt-2 inline-flex items-center">
                    View nearby ads{" "}
                    <button
                      onClick={() => RemoveFilterIsNearbyAds()}
                      className="font-PoppinsBold text-Primary"
                    >
                      <XMarkIcon className="block h-4 w-4" />
                    </button>
                  </div>
                )}
                <GetSelectedFilters
                  filters={formikInitialValues}
                  setFilters={setFormikInitialValues}
                  filterAds={filterAds}
                  setFilterAds={setFilterAds}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  setLocationStates={setLocationStates}
                />
              </div>

              <div className="w-full  flex justify-end space-x-2 text-[12px] ">
                <div className="rounded-[14px] bg-gray-200 w-fit p-2 h-auto flex justify-end items-center text-center mt-4">
                  {SingleCategoryDetails && (
                    <span className="text-[#0C2302] ">
                      {SingleCategoryDetails.records}{" "}
                      {SingleCategoryDetails.records === "1" ? "Ad" : "Ads"}
                    </span>
                  )}
                </div>
              </div>

              {SingleCategoryDetails &&
              SingleCategoryDetails?.data?.length > 0 ? (
                <ul className="flex items-center justify-center">
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  pt-2">
                    {SingleCategoryDetails &&
                      SingleCategoryDetails.data.map((item, index) => (
                        <li key={index}>
                          <div
                            className={`rounded-[13px] border border-[#DBDED9] ${
                              item?.managed_by_farmghar === "1"
                                ? "bg-Primary"
                                : "bg-white"
                            } w-[255px] h-[312px]`}
                          >
                            <Link
                              to={
                                /sold/i.test(item.ad_status)
                                  ? "#sold"
                                  : `/${CatSlug}/${item?.ad_slug}`
                              }
                              onClick={
                                /sold/i.test(item.ad_status)
                                  ? DisplaySoldOut
                                  : null
                              }
                              className="w-full h-full flex flex-col justify-center"
                            >
                              <div className="rounded-[8px] mt-[6.77px] mr-[5.95px] ml-[5px] w-[244.48px] h-[180.49px] ">
                                <div className="relative w-[244.48px] h-[180.49px]">
                                  <figure className="w-full h-full">
                                    <img
                                      src={
                                        item.img_thumbnail && item.img_thumbnail
                                      }
                                      className=" rounded-[9px] w-full h-full object-cover"
                                      alt={item.ad_title && item.ad_title}
                                    />
                                  </figure>

                                  {item?.is_star === "1" &&
                                    item?.priority === "11" && (
                                      <div className="absolute top-[-15px] left-[-15px]">
                                        <StarIcon />
                                      </div>
                                    )}
                                  {item.ad_status &&
                                    /sold/i.test(item.ad_status) && (
                                      <div className="absolute right-[85px] top-[86px]">
                                        <SoldOutIcon />
                                      </div>
                                    )}

                                  {item.is_verified === "1" && (
                                    <div className="absolute bottom-[7.62px] right-[6.43px]">
                                      <VerifiedIcon />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className={` ${
                                  item?.managed_by_farmghar === "1"
                                    ? "bg-white rounded-md ml-0 mr-0 px-3 m-[3.51px]"
                                    : "m-[12.51px]"
                                }`}
                              >
                                <div
                                  className={`flex ${
                                    item?.managed_by_farmghar === "1"
                                      ? "mt-[3px] pt-[3px]"
                                      : null
                                  }`}
                                >
                                  {item.is_delivery === "1" && (
                                    <div>
                                      <DeliveryIcon />
                                    </div>
                                  )}
                                  {item.is_delivery_ad === "1" && (
                                    <div>
                                      <PackageIcon />
                                    </div>
                                  )}
                                </div>

                                {/* for Ad */}
                                {item.ad_title && (
                                  <div>
                                    <h3 className="font-PoppinsBold text-[16px]">
                                      {getShortName(item.ad_title)}
                                    </h3>
                                  </div>
                                )}

                                <div className="flex justify-between">
                                  {item.posted_text && (
                                    <p className="font-[10px] text-[#39342F] opacity-50">
                                      {item.posted_text}
                                    </p>
                                  )}
                                  {item.price && (
                                    <p className="text-black font-[18px]">
                                      RS {item.price}
                                    </p>
                                  )}
                                </div>

                                <div className="flex justify-between">
                                  {item.district && (
                                    <div className="flex items-center">
                                      <div className="mr-[5.85px]">
                                        <LocationAdIcon />
                                      </div>
                                      <p>{item.district}</p>{" "}
                                    </div>
                                  )}
                                  {item.detailviews && (
                                    <div className="flex items-center">
                                      <div className="opacity-50 mr-[5.85px]">
                                        <EyeIcon />
                                      </div>
                                      <p className="text-Primary">
                                        {item.detailviews}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {item?.managed_by_farmghar === "1" && (
                                <div className="flex justify-center text-white font-bold text-[14px]">
                                  farmGhar Self-stock
                                </div>
                              )}
                            </Link>
                          </div>
                        </li>
                      ))}

                    {/* ------------------------- For View All Ads ------------------------- */}
                    {/* <div>
                      <Link
                        to={`/${CatSlug}`}
                        className="w-full h-full flex flex-col justify-center rounded-[13px] border border-[#DBDED9] bg-[#F3F4F6] font-PoppinsBold  text-center text-Primary text-[20px]"
                      >
                        View All
                        <br />
                        {SingleCategoryDetails.records}{" "}
                        {SingleCategoryDetails.records === "1" ? "Ad" : "Ads"}
                      </Link>
                    </div> */}
                  </div>
                </ul>
              ) : (
                <div className="flex justify-center it  ems-center h-[300px]">
                  <div className="flex flex-col items-center">
                    <p className="text-[#39342F] font-PoppinsBold text-[18px] mt-[20px]">
                      No Ads Found
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
