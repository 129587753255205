import CardSlider from "../CardSlider";

export default function AdsByCategory({ CategoriesAndAdsHome }) {
  CategoriesAndAdsHome;
  const trackAds = {};
  return (
    CategoriesAndAdsHome &&
    CategoriesAndAdsHome?.data?.map((item, index) => {
      const catSlug = CategoriesAndAdsHome?.data2?.find(
        (cat) => cat.id === item.parent_cat_id
      )?.category_slug;
      const data = [];
      item?.ads_data?.map((itemm) => {
        data.push({
          AdLink: `${catSlug}/${itemm.ad_slug}`,
          Image: itemm.images && itemm.images.img_thumbnail,
          IsDelivery: itemm.is_delivery,
          IsDeliveryAd: itemm.is_delivery_ad,
          IsStar: itemm.is_star,
          IsHeartIcon: true,
          IsVerified: itemm.is_verified,
          AdTitle: itemm.ad_title,
          PostedText: itemm.posted_text,
          AdPrice: itemm.price,
          AdStatus: itemm.ad_status,
          IsAd: true,
          IsManagedByFarmGhar: itemm.managed_by_farmghar,
          AdLocation: itemm.district,
          AdViewsCount: itemm.impressions,
        });
      });
      //after every 2 category  add banner ad
      let ShowBanner = false;
      let ShowGoogleAd = false;
      let indexx = index + 1;

      if (indexx % 2 === 0) {
        if (trackAds.ShowBanner) {
          ShowGoogleAd = true;
          trackAds.ShowGoogleAd = true;
          trackAds.ShowBanner = false;
        } else {
          ShowBanner = true;
          trackAds.ShowBanner = true;
          trackAds.ShowGoogleAd = false;
        }
      }
      item?.ads_data?.length > 0 &&
        data.push({
          isViewAllLastCardLink: `${catSlug}`,
          Image: "",
          IsDelivery: false,
          IsDeliveryAd: false,
          IsStar: false,
          IsHeartIcon: false,
          isViewAllLastCard: true,
          IsVerified: false,
          AdTitle: `View All ${
            CategoriesAndAdsHome?.data2?.filter(
              (cat) => cat.id === item.parent_cat_id
            )[0]?.ads_count
          } ${item.parent_cat_name} Ads`,
          PostedText: "",
          AdPrice: "",
          IsAd: false,
          AdLocation: "",
          AdViewsCount: "",
        });
      const infiniteLength = item?.ads_data?.length
        ? item?.ads_data?.length + 1
        : 1;
      const settings = {
        SectionName: item.parent_cat_name,
        ShowBanner: ShowBanner,
        ShowGoogleAd: ShowGoogleAd,
        SectionLink: `${catSlug}`,
        ViewAll: "View All",
        Width: "xsm:w-[240px]",
        Height: "h-[312px]",
        ImageWidth: "xsm:w-[229.48px]",
        ImageHeight: "h-[180.49px]",
        hiddenOnOneSlide: "lg:block",
        data: data,
        slidesToShow: infiniteLength > 4 ? 4 : infiniteLength,
        slidesToScroll: 1,
        infinite: false,
        // spaceing vertical bug fixed we have to use slidetoshow card less than breakpoint width
        responsive: [
          {
            breakpoint: 1227,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              centerMode: false,
              infinite: false,
              arrows: false,
            },
          },
          {
            breakpoint: 920,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              centerMode: false,
              infinite: false,
              arrows: false,
            },
          },
          {
            breakpoint: 631,
            settings: {
              sldesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              centerMode: false,
              infinite: false,
              arrows: false,
            },
          },
          {
            breakpoint: 630,
            settings: {
              slidesToScroll: 1,
              initialSlide: 1,
              centerMode: false,
              slidesToShow: 1,
              infinite: false,
              arrows: false,
            },
          },
          // {
          //   breakpoint: 448,
          //   settings: {
          //     slidesToScroll: 1,
          //     initialSlide: 2,
          //     centerMode: false,
          //     slidesToShow: 1,
          //     infinite: infiniteLength > 1 ? true : false,
          //   },
          // },
        ],
      };

      return <CardSlider key={index} settings={settings} />;
    })
  );
}
