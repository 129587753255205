import { FormWrapper } from "./FormWrapper";
import { useState } from "react";
import OtpInput from "./otp/OtpInput";
import { ReactComponent as JustLogoWithoutName } from "../../assets/icons/JustLogoWithoutName.svg";
export function OtpForm({ formik }) {
  const [otp, setOtp] = useState("");
  const onChange = (value) => {
    formik.setFieldValue("OTP", value);
    setOtp(value);
  };
  return (
    <FormWrapper>
      <div className="flex flex-col items-center w-full h-full">
        <div className="flex justify-center items-center mt-[80px] ">
          <JustLogoWithoutName />
        </div>

        <h2 className="text-TextColor font-bold  mt-[20px] ">
          Confirmation Required
        </h2>
        <p>Please Enter 4 Digit code sent to your mobile number.</p>
        {formik.touched.OTP && formik.errors.OTP ? (
          <span className="text-red-600  text-left text-[16px] ">
            {formik.errors.OTP}
          </span>
        ) : null}
        <div className="space-y-4 mt-[29px]  w-full h-full max-w-[340px] text-left ">
          <div className="container">
            <OtpInput value={otp} valueLength={4} onChange={onChange} />
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}
