// that commented code is for generating regex of words if you want to add more words in future
// const words = [
//   "slut",
//   "ass",
//   "balls",
//   "cocksucker",
//   "cunt",
//   "fuck",
//   "motherfucker",
//   "piss",
//   "shit",
//   "bastard",
//   "escort",
//   "fight",
//   "fighter",
//   "blood",
//   "beautifullgirl",
//   "night",
//   "massagecenter",
//   "callgirl",
//   "spa",
//   "massagespa",
//   "callboy",
//   "horny",
//   "bottom",
//   "salon",
//   "lesbian",
//   "gay",
//   "shot",
//   "roomservice",
//   "bodymassage",
//   "hooker",
//   "staff",
//   "club",
//   "nightclub",
//   "party",
//   "meetgirl",
//   "kill",
//   "bdsm",
//   "candom",
//   "dildo",
//   "aunty",
//   "aunti",
//   "lun",
//   "lund",
//   "boob",
//   "tits",
//   "vagina",
//   "butts",
//   "sex",
//   "sexy",
// ];
// const aa = [];
// words.forEach((word) => {
//   let regex = `\\b((\\w*_)?`;
//   for (let i = 0; i < word.length; i++) {
//     if (i === word.length - 1) regex += word[i];
//     else regex += word[i] + "[^A-Za-z]*";
//   }
//   regex += `(s|es)?[^A-Za-z]*(_\\w*)?)\\b`;
//   aa.push(regex);
// });

const regexOfWords = [
  "\\b((\\w*_)?s[^A-Za-z]*l[^A-Za-z]*u[^A-Za-z]*t(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?a[^A-Za-z]*s[^A-Za-z]*s(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?b[^A-Za-z]*a[^A-Za-z]*l[^A-Za-z]*l[^A-Za-z]*s(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?c[^A-Za-z]*o[^A-Za-z]*c[^A-Za-z]*k[^A-Za-z]*s[^A-Za-z]*u[^A-Za-z]*c[^A-Za-z]*k[^A-Za-z]*e[^A-Za-z]*r(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?c[^A-Za-z]*u[^A-Za-z]*n[^A-Za-z]*t(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?f[^A-Za-z]*u[^A-Za-z]*c[^A-Za-z]*k(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?m[^A-Za-z]*o[^A-Za-z]*t[^A-Za-z]*h[^A-Za-z]*e[^A-Za-z]*r[^A-Za-z]*f[^A-Za-z]*u[^A-Za-z]*c[^A-Za-z]*k[^A-Za-z]*e[^A-Za-z]*r(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?p[^A-Za-z]*i[^A-Za-z]*s[^A-Za-z]*s(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?s[^A-Za-z]*h[^A-Za-z]*i[^A-Za-z]*t(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?b[^A-Za-z]*a[^A-Za-z]*s[^A-Za-z]*t[^A-Za-z]*a[^A-Za-z]*r[^A-Za-z]*d(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?e[^A-Za-z]*s[^A-Za-z]*c[^A-Za-z]*o[^A-Za-z]*r[^A-Za-z]*t(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?f[^A-Za-z]*i[^A-Za-z]*g[^A-Za-z]*h[^A-Za-z]*t(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?f[^A-Za-z]*i[^A-Za-z]*g[^A-Za-z]*h[^A-Za-z]*t[^A-Za-z]*e[^A-Za-z]*r(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?b[^A-Za-z]*l[^A-Za-z]*o[^A-Za-z]*o[^A-Za-z]*d(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?b[^A-Za-z]*e[^A-Za-z]*a[^A-Za-z]*u[^A-Za-z]*t[^A-Za-z]*i[^A-Za-z]*f[^A-Za-z]*u[^A-Za-z]*l[^A-Za-z]*l[^A-Za-z]*g[^A-Za-z]*i[^A-Za-z]*r[^A-Za-z]*l(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?n[^A-Za-z]*i[^A-Za-z]*g[^A-Za-z]*h[^A-Za-z]*t(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?m[^A-Za-z]*a[^A-Za-z]*s[^A-Za-z]*s[^A-Za-z]*a[^A-Za-z]*g[^A-Za-z]*e[^A-Za-z]*c[^A-Za-z]*e[^A-Za-z]*n[^A-Za-z]*t[^A-Za-z]*e[^A-Za-z]*r(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?c[^A-Za-z]*a[^A-Za-z]*l[^A-Za-z]*l[^A-Za-z]*g[^A-Za-z]*i[^A-Za-z]*r[^A-Za-z]*l(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?s[^A-Za-z]*p[^A-Za-z]*a(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?m[^A-Za-z]*a[^A-Za-z]*s[^A-Za-z]*s[^A-Za-z]*a[^A-Za-z]*g[^A-Za-z]*e[^A-Za-z]*s[^A-Za-z]*p[^A-Za-z]*a(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?c[^A-Za-z]*a[^A-Za-z]*l[^A-Za-z]*l[^A-Za-z]*b[^A-Za-z]*o[^A-Za-z]*y(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?h[^A-Za-z]*o[^A-Za-z]*r[^A-Za-z]*n[^A-Za-z]*y(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?b[^A-Za-z]*o[^A-Za-z]*t[^A-Za-z]*t[^A-Za-z]*o[^A-Za-z]*m(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?s[^A-Za-z]*a[^A-Za-z]*l[^A-Za-z]*o[^A-Za-z]*n(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?l[^A-Za-z]*e[^A-Za-z]*s[^A-Za-z]*b[^A-Za-z]*i[^A-Za-z]*a[^A-Za-z]*n(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?g[^A-Za-z]*a[^A-Za-z]*y(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?s[^A-Za-z]*h[^A-Za-z]*o[^A-Za-z]*t(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?r[^A-Za-z]*o[^A-Za-z]*o[^A-Za-z]*m[^A-Za-z]*s[^A-Za-z]*e[^A-Za-z]*r[^A-Za-z]*v[^A-Za-z]*i[^A-Za-z]*c[^A-Za-z]*e(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?b[^A-Za-z]*o[^A-Za-z]*d[^A-Za-z]*y[^A-Za-z]*m[^A-Za-z]*a[^A-Za-z]*s[^A-Za-z]*s[^A-Za-z]*a[^A-Za-z]*g[^A-Za-z]*e(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?h[^A-Za-z]*o[^A-Za-z]*o[^A-Za-z]*k[^A-Za-z]*e[^A-Za-z]*r(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?s[^A-Za-z]*t[^A-Za-z]*a[^A-Za-z]*f[^A-Za-z]*f(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?c[^A-Za-z]*l[^A-Za-z]*u[^A-Za-z]*b(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?n[^A-Za-z]*i[^A-Za-z]*g[^A-Za-z]*h[^A-Za-z]*t[^A-Za-z]*c[^A-Za-z]*l[^A-Za-z]*u[^A-Za-z]*b(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?p[^A-Za-z]*a[^A-Za-z]*r[^A-Za-z]*t[^A-Za-z]*y(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?m[^A-Za-z]*e[^A-Za-z]*e[^A-Za-z]*t[^A-Za-z]*g[^A-Za-z]*i[^A-Za-z]*r[^A-Za-z]*l(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?k[^A-Za-z]*i[^A-Za-z]*l[^A-Za-z]*l(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?b[^A-Za-z]*d[^A-Za-z]*s[^A-Za-z]*m(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?c[^A-Za-z]*a[^A-Za-z]*n[^A-Za-z]*d[^A-Za-z]*o[^A-Za-z]*m(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?d[^A-Za-z]*i[^A-Za-z]*l[^A-Za-z]*d[^A-Za-z]*o(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?a[^A-Za-z]*u[^A-Za-z]*n[^A-Za-z]*t[^A-Za-z]*y(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?a[^A-Za-z]*u[^A-Za-z]*n[^A-Za-z]*t[^A-Za-z]*i(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?l[^A-Za-z]*u[^A-Za-z]*n(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?l[^A-Za-z]*u[^A-Za-z]*n[^A-Za-z]*d(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?b[^A-Za-z]*o[^A-Za-z]*o[^A-Za-z]*b(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?t[^A-Za-z]*i[^A-Za-z]*t[^A-Za-z]*s(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?v[^A-Za-z]*a[^A-Za-z]*g[^A-Za-z]*i[^A-Za-z]*n[^A-Za-z]*a(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?b[^A-Za-z]*u[^A-Za-z]*t[^A-Za-z]*t[^A-Za-z]*s(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?s[^A-Za-z]*e[^A-Za-z]*x(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
  "\\b((\\w*_)?s[^A-Za-z]*e[^A-Za-z]*x[^A-Za-z]*y(s|es)?[^A-Za-z]*(_\\w*)?)\\b",
];

const IsContentDoesNotContainsBadWords = (description) => {
  // Creating a regex pattern to match any of the words in the array with word boundaries
  const regexPattern = new RegExp(regexOfWords.join("|"), "gi");
  // Using the regex test method to check if the description contains any of the words
  const isMatch = regexPattern.test(description);
  // Return the result
  return !isMatch;
};

export default IsContentDoesNotContainsBadWords;
