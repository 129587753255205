import { useNavigate } from "react-router-dom";

const AddMorePackages = ({
  setShowAddMorePackages,
  handleAddToCart,
  cartItems,
  QurbaniPackages,
  GettingPackages,
  setToastMessage,
  handleShowToast,
}) => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setShowAddMorePackages(false);
      document.body.style.overflow = "unset";
    }
  };

  if (GettingPackages) {
    return (
      <div className="flex items-center justify-center mt-20">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <>
      {/* eslint-disable-next-line  */}
      <div
        className="dismiss py-14 fixed top-0 left-0 right-0 w-full h-full bg-black bg-opacity-80 flex items-center z-[51] p-4 justify-center"
        onClick={handleClick}
      >
        <div
          className="h-full sm:h-[474px] flex-col flex justify-center items-center bg-white overflow-y-auto p-4 rounded-lg"
          // className={`max-w-full max-h-full flex justify-center  m-auto `}
        >
          <div className="flex  w-full justify-between pt-8 pb-2">
            <h4 className="font-PoppinsBold text-lg">Add Qurbani Package</h4>
            <button
              className="font-PoppinsBold text-[#0C2302] dismiss"
              onClick={handleClick}
            >
              Close
            </button>
          </div>

          <div className="h-full grid grid-cols-1 sm:grid-cols-2  gap-6 lg:grid-cols-3  pt-2">
            {Array.isArray(QurbaniPackages?.data)
              ? QurbaniPackages?.data?.map((item, index) => (
                  //eslint-disable-next-line
                  <div
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/qurbani/${item.slug}`);
                      // navigate("/qurbani/order", {
                      //   state: { item: item },
                      // });
                    }}
                  >
                    <div
                      // commented because we are not using ads for now
                      //       className={`
                      // rounded-[13px] border border-[#DBDED9] md:w-[214px]  lg:w-[260px] h-[310px]
                      // `}
                      className={`
rounded-[13px] border border-[#DBDED9]  w-[260px] h-[370px] relative BtnShowWhenHoverCard cursor-pointer
`}
                    >
                      <div className={"rounded-[8px] mt-[6.77px] mx-[5.95px] "}>
                        <div className={" h-[200px]"}>
                          <img
                            src={item?.image && item?.image}
                            className={`  rounded-[9px] w-full h-full
     `}
                            alt={item?.package_name && item?.package_name}
                          />
                        </div>
                      </div>
                      {/* <p className="text-[12px] font-PoppinsBold absolute top-[39px] left-[6px] p-1 bg-black text-white rounded-r-xl">
                        {getShortName(item?.package_name)}
                      </p> */}
                      <h2 className="text-[16px] font-PoppinsBold m-[12.51px]">
                        {item?.package_name}
                      </h2>
                      <div className=" m-[12.51px]  flex justify-between">
                        <div className="flex justify-between flex-col">
                          <p className=" text-[12px]  text-[#39342F] text-opacity-50 lg:text-[13px] font-PoppinsBold ">
                            Price
                          </p>
                          <p className=" text-[12px]  text-[#39342F] lg:text-[13px] font-PoppinsBold ">
                            PKR {item?.price}
                          </p>
                        </div>
                        <div className="flex justify-between flex-col">
                          <p className=" text-[12px]  text-[#39342F] text-opacity-50 lg:text-[13px] font-PoppinsBold ">
                            Weight
                          </p>
                          <p className=" text-[12px]  text-[#39342F] lg:text-[13px] font-PoppinsBold  ">
                            {item?.description}
                          </p>
                        </div>
                      </div>
                      <div className="space-y-1">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            // navigate("/qurbani/order", {
                            //   state: { item: item },
                            // });
                            const found = cartItems.find((cartItem) => {
                              if (
                                parseInt(cartItem.id) ===
                                parseInt(item.package_id)
                              ) {
                                return cartItem;
                              }
                            });
                            if (
                              parseInt(item.f_d_available_quantity) -
                                parseInt(found?.quantity ?? 0) >=
                              1
                            ) {
                              if (cartItems[0]?.SelectedEidDay === "1") {
                                item.SelectedEidDay = "1";
                                handleAddToCart(item);
                                setShowAddMorePackages(false);
                                document.body.style.overflow = "unset";
                              } else if (cartItems[0]?.SelectedEidDay === "2") {
                                setToastMessage(
                                  "Please remove Eid Day 2 items to add Eid Day 1 items."
                                );
                                handleShowToast();
                              }

                              if (cartItems.length === 0) {
                                item.SelectedEidDay = "1";
                                handleAddToCart(item);
                                setShowAddMorePackages(false);
                                document.body.style.overflow = "unset";
                              }
                            } else {
                              setToastMessage("Out of stock.");
                              handleShowToast();
                            }
                          }}
                          className="bg-Primary w-[90%] py-2 flex mx-auto text-white font-PoppinsBold rounded-[50px]"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            className="inline-block ml-4"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.01672 13.3333L9.26672 14.5833L11.9751 12.0833"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.34162 1.66667L4.32495 4.69167"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.6583 1.66667L15.675 4.69167"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M1.66663 6.54167C1.66663 5 2.49163 4.875 3.51663 4.875H16.4833C17.5083 4.875 18.3333 5 18.3333 6.54167C18.3333 8.33333 17.5083 8.20833 16.4833 8.20833H3.51663C2.49163 8.20833 1.66663 8.33333 1.66663 6.54167Z"
                              stroke="white"
                              strokeWidth="1.5"
                            />
                            <path
                              d="M2.91663 8.33333L4.09163 15.5333C4.35829 17.15 4.99996 18.3333 7.38329 18.3333H12.4083C15 18.3333 15.3833 17.2 15.6833 15.6333L17.0833 8.33333"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            />
                          </svg>
                          <span className="text-center w-full mr-4">
                            Buy Now
                          </span>
                        </button>
                        {/* <button
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            // navigate("/qurbani/order", {
                            //   state: { item: item },
                            // });
                            const found = cartItems.find((cartItem) => {
                              if (
                                parseInt(cartItem.id) ===
                                parseInt(item.package_id)
                              ) {
                                return cartItem;
                              }
                            });
                            if (
                              parseInt(item.s_d_available_quantity) -
                                parseInt(found?.quantity ?? 0) >=
                              1
                            ) {
                              if (cartItems[0]?.SelectedEidDay === "2") {
                                item.SelectedEidDay = "2";
                                handleAddToCart(item);
                                setShowAddMorePackages(false);
                                document.body.style.overflow = "unset";
                              } else if (cartItems[0]?.SelectedEidDay === "1") {
                                setToastMessage(
                                  "Please remove Eid Day 1 items to add Eid Day 2 items."
                                );
                                handleShowToast();
                              }

                              if (cartItems.length === 0) {
                                item.SelectedEidDay = "2";
                                handleAddToCart(item);
                                setShowAddMorePackages(false);
                                document.body.style.overflow = "unset";
                              }
                            } else {
                              setToastMessage("Out of stock.");
                              handleShowToast();
                            }
                          }}
                          className="bg-Primary w-[90%] py-2 flex mx-auto text-white font-PoppinsBold rounded-[50px]"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            className="inline-block ml-4"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.01672 13.3333L9.26672 14.5833L11.9751 12.0833"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.34162 1.66667L4.32495 4.69167"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.6583 1.66667L15.675 4.69167"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M1.66663 6.54167C1.66663 5 2.49163 4.875 3.51663 4.875H16.4833C17.5083 4.875 18.3333 5 18.3333 6.54167C18.3333 8.33333 17.5083 8.20833 16.4833 8.20833H3.51663C2.49163 8.20833 1.66663 8.33333 1.66663 6.54167Z"
                              stroke="white"
                              strokeWidth="1.5"
                            />
                            <path
                              d="M2.91663 8.33333L4.09163 15.5333C4.35829 17.15 4.99996 18.3333 7.38329 18.3333H12.4083C15 18.3333 15.3833 17.2 15.6833 15.6333L17.0833 8.33333"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            />
                          </svg>
                          <span className="text-center w-full mr-4">
                            Buy Now Eid Day 2
                          </span>
                        </button> */}
                      </div>
                    </div>
                  </div>
                ))
              : "No Packages Found"}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMorePackages;
