import { useEffect } from "react";
const PregnancyCountOptions = [
  { value: "1st", label: "1st" },
  { value: "2nd", label: "2nd" },
  { value: "3rd", label: "3rd" },
  { value: "4th", label: "4th" },
  { value: "5th", label: "5th" },
  { value: "6th", label: "6th" },
  { value: "7th", label: "7th" },
];

export function LivestockForm({
  formik,
  ChildCategoriesOptions,
  BreedsOptons,
  DropdownIndicator,
  Style,
  Select,
  AgeMonthsOptions,
  AgeYearsOptions,
  Provinces,
  DistrictsOptons,
  TehsilsOptons,
  FileUploader,
  DailyMilkQuantity,
  selectedFiles,
  setSelectedFiles,
  previewImages,
  setPreviewImages,
  edit,
  inputRef,
  RemoveAllFilesFromImageUploader,
  GoogleMap,
  getAddress,
  HandleChangeLatLong,
  locationStates,
  setLocationStates,
  autoComplete2,
  setAutoComplete2,
  DistrictsData,
  // TehsilsData,
  adstatus,
}) {
  useEffect(() => {}, [formik.values.Category]);

  return (
    <div className="flex flex-col items-center mt-[40px] w-full h-full ">
      <div className="grid grid-cols-2 gap-4 w-full h-full p-10">
        <div className="">
          <label htmlFor="Category" className="block text-sm  text-TextColor">
            Category
          </label>
          <Select
            styles={Style}
            id="Category"
            name="Category"
            maxMenuHeight={140}
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={ChildCategoriesOptions}
            value={formik.values.Category ? formik.values.Category : ""}
            label="Category"
            isDisabled={edit}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onChange={(value) => {
              formik.resetForm({
                values: {
                  ...formik.values,
                  Breed: "",
                  Sex: "",
                  Quantity: "",
                  AgeYears: "",
                  AgeMonths: "",
                  LiveWeight: "",
                  AnimalDescription: "",
                  Price: "",
                  PriceType: "",
                  Teeth: "",
                  DailyMilkQuantity: "",
                  Pregnant: "",
                  PregnancyCount: "",
                  Castrated: "",
                  Pictures: [],
                  MinLiveWeight: "",
                  MaxLiveWeight: "",
                  MaxAgeYears: "",
                  MaxAgeMonths: "",
                  MinAgeYears: "",
                  MinAgeMonths: "",
                  MaxTeeth: "",
                  MinTeeth: "",
                },
              });
              RemoveAllFilesFromImageUploader();
              setSelectedFiles([]);
              setPreviewImages([]);
              formik.setFieldValue("Category", value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.Category && formik.errors.Category ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Category}
            </span>
          ) : null}
        </div>
        <div className="">
          <label htmlFor="Breed" className="block text-sm  text-TextColor">
            Breed
          </label>
          <Select
            styles={Style}
            id="Breed"
            name="Breed"
            maxMenuHeight={140}
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={BreedsOptons}
            value={formik.values.Breed ? formik.values.Breed : ""}
            label="Category"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onChange={(value) => {
              formik.setFieldValue("Breed", value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.Breed && formik.errors.Breed ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Breed}
            </span>
          ) : null}
        </div>
        <div className="col-span-2">
          <label
            htmlFor="AnimalDescription"
            className="block text-sm  text-TextColor"
          >
            Description
          </label>
          <textarea
            className="block
            
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
       border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      border-2
      focus:text-gray-700 focus:bg-white focus:outline-none resize-none"
            placeholder="Description"
            id="AnimalDescription"
            name="AnimalDescription"
            value={formik.values.AnimalDescription ?? ""}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.setFieldValue("AnimalDescription", e.target.value);
            }}
          />
          {formik.touched.AnimalDescription &&
          formik.errors.AnimalDescription ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.AnimalDescription}
            </span>
          ) : null}
        </div>
        {String(formik.values.Category?.value) === "10" ||
        String(formik.values.Category?.value) === "15" ||
        String(formik.values.Category?.value) === "8" ||
        String(formik.values.Category?.value) === "14" ? (
          <>
            <div className="">
              <label htmlFor="Sex" className="block text-sm  text-TextColor">
                Sex
              </label>
              <Select
                styles={Style}
                id="Sex"
                name="Sex"
                isDisabled={edit}
                maxMenuHeight={140}
                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                options={[
                  {
                    value: "male",
                    label: "Male",
                  },
                  {
                    value: "female",
                    label: "Female",
                  },
                ]}
                value={formik.values.Sex ? formik.values.Sex : ""}
                label="Sex"
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                onChange={(value) => {
                  formik.setFieldValue("Sex", value);
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.Sex && formik.errors.Sex ? (
                <span className="text-red-600  text-[11px] ">
                  {formik.errors.Sex}
                </span>
              ) : null}
            </div>
            <div className="flex flex-col justify-center mt-1">
              <label
                htmlFor="Quantity"
                className="block text-sm  text-TextColor"
              >
                Quantity
              </label>
              <input
                id="Quantity"
                name="Quantity"
                disabled={edit}
                type="text"
                className="w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
                value={formik.values.Quantity ? formik.values.Quantity : ""}
                label="Quantity"
                onChange={(e) => {
                  formik.setValues({
                    ...formik.values,
                    Quantity: e.target.value,
                    AgeYears: "",
                    AgeMonths: "",
                    MinLiveWeight: "",
                    MaxLiveWeight: "",
                    MaxAgeYears: "",
                    MaxAgeMonths: "",
                    MinAgeYears: "",
                    MinAgeMonths: "",
                    MaxTeeth: "",
                    MinTeeth: "",
                  });
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.Quantity && formik.errors.Quantity ? (
                <span className="text-red-600  text-[11px] ">
                  {formik.errors.Quantity}
                </span>
              ) : null}
            </div>
          </>
        ) : null}
        {/* show these fields if quantity is greater than 1 and id are these show null */}
        {(String(formik.values.Category?.value) === "10" ||
          String(formik.values.Category?.value) === "15" ||
          String(formik.values.Category?.value) === "8" ||
          String(formik.values.Category?.value) === "14") &&
        parseInt(formik.values.Quantity) > 1 ? null : (
          <>
            <div className="">
              <label
                htmlFor="AgeYears"
                className="block text-sm  text-TextColor"
              >
                Age In Years
              </label>
              <Select
                styles={Style}
                id="AgeYears"
                name="AgeYears"
                maxMenuHeight={140}
                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                options={AgeYearsOptions}
                value={formik.values.AgeYears ? formik.values.AgeYears : ""}
                label="AgeYears"
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                onChange={(value) => {
                  formik.setFieldValue("AgeYears", value);
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.AgeYears && formik.errors.AgeYears ? (
                <span className="text-red-600  text-[11px] ">
                  {formik.errors.AgeYears}
                </span>
              ) : null}
            </div>
            <div className="">
              <label
                htmlFor="AgeMonths"
                className="block text-sm  text-TextColor"
              >
                Age In Months
              </label>
              <Select
                styles={Style}
                id="AgeMonths"
                name="AgeMonths"
                maxMenuHeight={140}
                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                options={AgeMonthsOptions}
                value={formik.values.AgeMonths ? formik.values.AgeMonths : ""}
                label="AgeMonths"
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                onChange={(value) => {
                  formik.setFieldValue("AgeMonths", value);
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.AgeMonths && formik.errors.AgeMonths ? (
                <span className="text-red-600  text-[11px] ">
                  {formik.errors.AgeMonths}
                </span>
              ) : null}
            </div>
          </>
        )}

        {String(formik.values.Category?.value) === "9" ||
        String(formik.values.Category?.value) === "7" ? (
          <div className="">
            <label
              htmlFor="DailyMilkQuantity"
              className="block text-sm  text-TextColor"
            >
              Daily Milk Quantity (In Litres)
            </label>
            <Select
              styles={Style}
              id="DailyMilkQuantity"
              name="DailyMilkQuantity"
              maxMenuHeight={140}
              className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
              options={DailyMilkQuantity}
              value={
                formik.values.DailyMilkQuantity
                  ? formik.values.DailyMilkQuantity
                  : ""
              }
              label="DailyMilkQuantity"
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
              onChange={(value) => {
                formik.setFieldValue("DailyMilkQuantity", value);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.DailyMilkQuantity &&
            formik.errors.DailyMilkQuantity ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.DailyMilkQuantity}
              </span>
            ) : null}
          </div>
        ) : null}

        <div className="flex flex-col justify-center mt-1">
          <label htmlFor="Price" className="block text-sm  text-TextColor">
            Price (PKR)
          </label>
          <input
            id="Price"
            name="Price"
            type="text"
            className="w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
            value={formik.values.Price ? formik.values.Price : ""}
            label="Price"
            disabled={adstatus?.is_star === "1"}
            onChange={(e) => {
              formik.setFieldValue("Price", e.target.value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.Price && formik.errors.Price ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Price}
            </span>
          ) : null}
        </div>
        {String(formik.values.Category?.value) === "10" ||
        String(formik.values.Category?.value) === "15" ||
        String(formik.values.Category?.value) === "8" ||
        String(formik.values.Category?.value) === "14" ? (
          <div className="">
            <label
              htmlFor="PriceType"
              className="block text-sm  text-TextColor"
            >
              Price Type
            </label>
            <Select
              styles={Style}
              id="PriceType"
              name="PriceType"
              maxMenuHeight={140}
              className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
              options={
                parseInt(formik.values.Quantity) > 1
                  ? [
                      {
                        value: "perhead",
                        label: "Per Head",
                      },
                      {
                        value: "perkg",
                        label: "Per Kg",
                      },
                      {
                        value: "total",
                        label: "Total",
                      },
                    ]
                  : [
                      {
                        value: "perkg",
                        label: "Per Kg",
                      },
                      {
                        value: "total",
                        label: "Total",
                      },
                    ]
              }
              value={formik.values.PriceType ? formik.values.PriceType : ""}
              label="PriceType"
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
              onChange={(value) => {
                formik.setFieldValue("PriceType", value);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.PriceType && formik.errors.PriceType ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.PriceType}
              </span>
            ) : null}
          </div>
        ) : null}
        {(String(formik.values.Category?.value) === "10" ||
          String(formik.values.Category?.value) === "8") &&
        parseInt(formik.values.Quantity) === 1 ? (
          <div className="flex flex-col justify-center mt-1">
            <label
              htmlFor="LiveWeight"
              className="block text-sm  text-TextColor"
            >
              Live Weight (Kg)
            </label>
            <input
              id="LiveWeight"
              name="LiveWeight"
              type="text"
              className="w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
              value={formik.values.LiveWeight ? formik.values.LiveWeight : ""}
              label="LiveWeight"
              onChange={(e) => {
                formik.setFieldValue("LiveWeight", e.target.value);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.LiveWeight && formik.errors.LiveWeight ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.LiveWeight}
              </span>
            ) : null}
          </div>
        ) : null}

        {String(formik.values.Category?.value) === "8" &&
        String(formik.values.Sex?.value) === "male" ? (
          <div className="">
            <label
              htmlFor="Castrated"
              className="block text-sm  text-TextColor"
            >
              Castrated
            </label>
            <Select
              styles={Style}
              id="Castrated"
              name="Castrated"
              maxMenuHeight={140}
              className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
              options={[
                {
                  value: "yes",
                  label: "Yes",
                },
                {
                  value: "no",
                  label: "No",
                },
              ]}
              value={formik.values.Castrated ? formik.values.Castrated : ""}
              label="Castrated"
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
              onChange={(value) => {
                formik.setFieldValue("Castrated", value);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.Castrated && formik.errors.Castrated ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.Castrated}
              </span>
            ) : null}
          </div>
        ) : null}

        {formik.values.Category?.value === "7" ||
        formik.values.Category?.value === "9" ||
        (formik.values.Category?.value === "8" &&
          formik.values.Sex?.value === "female") ? (
          <div className="">
            <label htmlFor="Pregnant" className="block text-sm  text-TextColor">
              Pregnant
            </label>
            <Select
              styles={Style}
              id="Pregnant"
              name="Pregnant"
              maxMenuHeight={140}
              className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
              options={[
                {
                  value: true,
                  label: "Yes",
                },
                {
                  value: false,
                  label: "No",
                },
              ]}
              value={formik.values.Pregnant ? formik.values.Pregnant : ""}
              label="Pregnant"
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
              onChange={(value) => {
                formik.setFieldValue("Pregnant", value);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.Pregnant && formik.errors.Pregnant ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.Pregnant}
              </span>
            ) : null}
          </div>
        ) : null}

        {(String(formik.values.Category?.value) === "9" ||
          String(formik.values.Category?.value) === "7") &&
        String(formik.values.Pregnant?.label) === "Yes" ? (
          <div className="">
            <label
              htmlFor="PregnancyCount"
              className="block text-sm  text-TextColor"
            >
              Pregnancy Count
            </label>
            <Select
              styles={Style}
              id="PregnancyCount"
              name="PregnancyCount"
              maxMenuHeight={140}
              className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
              options={PregnancyCountOptions}
              value={
                formik.values.PregnancyCount ? formik.values.PregnancyCount : ""
              }
              label="PregnancyCount"
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
              onChange={(value) => {
                formik.setFieldValue("PregnancyCount", value);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.PregnancyCount && formik.errors.PregnancyCount ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.PregnancyCount}
              </span>
            ) : null}
          </div>
        ) : null}

        {String(formik.values.Category?.value) === "12" ||
        ((String(formik.values.Category?.value) === "15" ||
          String(formik.values.Category?.value) === "8" ||
          String(formik.values.Category?.value) === "14") &&
          parseInt(formik.values.Quantity) === 1) ? (
          <div className="">
            <label htmlFor="Teeth" className="block text-sm  text-TextColor">
              Teeth
            </label>
            <Select
              styles={Style}
              id="Teeth"
              name="Teeth"
              maxMenuHeight={140}
              className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
              options={[
                {
                  value: "0",
                  label: "Kheeri",
                },
                {
                  value: "2",
                  label: "Two Teeth",
                },
                {
                  value: "4",
                  label: "Four Teeth",
                },
                {
                  value: "6",
                  label: "Six Teeth",
                },
              ]}
              value={formik.values.Teeth ? formik.values.Teeth : ""}
              label="Teeth"
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
              onChange={(value) => {
                formik.setFieldValue("Teeth", value);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.Teeth && formik.errors.Teeth ? (
              <span className="text-red-600  text-[11px] ">
                {formik.errors.Teeth}
              </span>
            ) : null}
          </div>
        ) : null}
        {/* if quantity is greater then when then fields are min ,max teeth, weight, age */}
        {(String(formik.values.Category?.value) === "10" ||
          String(formik.values.Category?.value) === "15" ||
          String(formik.values.Category?.value) === "8" ||
          String(formik.values.Category?.value) === "14") &&
        parseInt(formik.values.Quantity) > 1 ? (
          <>
            <div className="col-span-2">
              <p>Min age</p>
              {formik.touched.MinAgeYears && formik.errors.MinAgeYears ? (
                <p className="text-red-600  text-[11px] ">
                  {formik.errors.MinAgeYears}
                </p>
              ) : null}
              {formik.touched.MinAgeMonths && formik.errors.MinAgeMonths ? (
                <p className="text-red-600  text-[11px] ">
                  {formik.errors.MinAgeMonths}
                </p>
              ) : null}
            </div>
            <div className="">
              <label
                htmlFor="MinAgeYears"
                className="block text-sm  text-TextColor"
              >
                Age in Years
              </label>
              <Select
                styles={Style}
                id="MinAgeYears"
                name="MinAgeYears"
                maxMenuHeight={140}
                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                options={AgeYearsOptions}
                value={
                  formik.values.MinAgeYears ? formik.values.MinAgeYears : ""
                }
                label="MinAgeYears"
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                onChange={(value) => {
                  formik.setFieldValue("MinAgeYears", value);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="">
              <label
                htmlFor="MinAgeMonths"
                className="block text-sm  text-TextColor"
              >
                Age in Months
              </label>
              <Select
                styles={Style}
                id="MinAgeMonths"
                name="MinAgeMonths"
                maxMenuHeight={140}
                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                options={AgeYearsOptions}
                value={
                  formik.values.MinAgeMonths ? formik.values.MinAgeMonths : ""
                }
                label="MinAgeMonths"
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                onChange={(value) => {
                  formik.setFieldValue("MinAgeMonths", value);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="col-span-2">
              <p>Max age</p>
              {formik.touched.MaxAgeYears && formik.errors.MaxAgeYears ? (
                <p className="text-red-600  text-[11px] ">
                  {formik.errors.MaxAgeYears}
                </p>
              ) : null}
              {formik.touched.MaxAgeMonths && formik.errors.MaxAgeMonths ? (
                <p className="text-red-600  text-[11px] ">
                  {formik.errors.MaxAgeMonths}
                </p>
              ) : null}
            </div>
            <div className="">
              <label
                htmlFor="MaxAgeYears"
                className="block text-sm  text-TextColor"
              >
                Age in Years
              </label>
              <Select
                styles={Style}
                id="MaxAgeYears"
                name="MaxAgeYears"
                maxMenuHeight={140}
                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                options={AgeYearsOptions}
                value={
                  formik.values.MaxAgeYears ? formik.values.MaxAgeYears : ""
                }
                label="MaxAgeYears"
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                onChange={(value) => {
                  formik.setFieldValue("MaxAgeYears", value);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="">
              <label
                htmlFor="MaxAgeMonths"
                className="block text-sm  text-TextColor"
              >
                Age in Months
              </label>
              <Select
                styles={Style}
                id="MaxAgeMonths"
                name="MaxAgeMonths"
                maxMenuHeight={140}
                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                options={AgeYearsOptions}
                value={
                  formik.values.MaxAgeMonths ? formik.values.MaxAgeMonths : ""
                }
                label="MaxAgeMonths"
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                onChange={(value) => {
                  formik.setFieldValue("MaxAgeMonths", value);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
          </>
        ) : null}
        {(String(formik.values.Category?.value) === "10" ||
          String(formik.values.Category?.value) === "15" ||
          String(formik.values.Category?.value) === "8" ||
          String(formik.values.Category?.value) === "14") &&
        parseInt(formik.values.Quantity) > 1 ? (
          <>
            <div className="">
              <label
                htmlFor="MinLiveWeight"
                className="block text-sm  text-TextColor"
              >
                Min Live Weight
              </label>
              <input
                id="MinLiveWeight"
                name="MinLiveWeight"
                type="text"
                className="w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
                value={
                  formik.values.MinLiveWeight ? formik.values.MinLiveWeight : ""
                }
                label="MinLiveWeight"
                onChange={(e) => {
                  formik.setFieldValue("MinLiveWeight", e.target.value);
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.MinLiveWeight && formik.errors.MinLiveWeight ? (
                <span className="text-red-600  text-[11px] ">
                  {formik.errors.MinLiveWeight}
                </span>
              ) : null}
            </div>
            <div className="">
              <label
                htmlFor="MaxLiveWeight"
                className="block text-sm  text-TextColor"
              >
                Max Live Weight
              </label>
              <input
                id="MaxLiveWeight"
                name="MaxLiveWeight"
                type="text"
                className="w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
                value={
                  formik.values.MaxLiveWeight ? formik.values.MaxLiveWeight : ""
                }
                label="MaxLiveWeight"
                onChange={(e) => {
                  formik.setFieldValue("MaxLiveWeight", e.target.value);
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.MaxLiveWeight && formik.errors.MaxLiveWeight ? (
                <span className="text-red-600  text-[11px] ">
                  {formik.errors.MaxLiveWeight}
                </span>
              ) : null}
            </div>
          </>
        ) : null}
        {(String(formik.values.Category?.value) === "15" ||
          String(formik.values.Category?.value) === "8" ||
          String(formik.values.Category?.value) === "14") &&
        parseInt(formik.values.Quantity) > 1 ? (
          <>
            <div className="">
              <label
                htmlFor="MinTeeth"
                className="block text-sm  text-TextColor"
              >
                Min Teeth
              </label>
              <Select
                styles={Style}
                id="MinTeeth"
                name="MinTeeth"
                maxMenuHeight={140}
                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                options={[
                  {
                    value: "0",
                    label: "Kheeri",
                  },
                  {
                    value: "2",
                    label: "Two Teeth",
                  },
                  {
                    value: "4",
                    label: "Four Teeth",
                  },
                  {
                    value: "6",
                    label: "Six Teeth",
                  },
                ]}
                value={formik.values.MinTeeth ? formik.values.MinTeeth : ""}
                label="MinTeeth"
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                onChange={(value) => {
                  formik.setFieldValue("MinTeeth", value);
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.MinTeeth && formik.errors.MinTeeth ? (
                <span className="text-red-600  text-[11px] ">
                  {formik.errors.MinTeeth}
                </span>
              ) : null}
            </div>
            <div className="">
              <label
                htmlFor="MaxTeeth"
                className="block text-sm  text-TextColor"
              >
                Max Teeth
              </label>
              <Select
                styles={Style}
                id="MaxTeeth"
                name="MaxTeeth"
                maxMenuHeight={140}
                className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
                options={[
                  {
                    value: "0",
                    label: "Kheeri",
                  },
                  {
                    value: "2",
                    label: "Two Teeth",
                  },
                  {
                    value: "4",
                    label: "Four Teeth",
                  },
                  {
                    value: "6",
                    label: "Six Teeth",
                  },
                ]}
                value={formik.values.MaxTeeth ? formik.values.MaxTeeth : ""}
                label="MaxTeeth"
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                onChange={(value) => {
                  formik.setFieldValue("MaxTeeth", value);
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.MaxTeeth && formik.errors.MaxTeeth ? (
                <span className="text-red-600  text-[11px] ">
                  {formik.errors.MaxTeeth}
                </span>
              ) : null}
            </div>
          </>
        ) : null}
        {/*  */}
        <div className="col-span-2">
          <GoogleMap
            autoComplete2={autoComplete2}
            setAutoComplete2={setAutoComplete2}
            HandleChangeLatLong={HandleChangeLatLong}
            locationStates={locationStates}
            setLocationStates={setLocationStates}
            formikInitialValues={formik.values}
          />
        </div>
        <div>
          <label
            htmlFor="Province"
            className="block text-sm font-medium text-TextColor"
          >
            Province
          </label>
          <Select
            styles={Style}
            id="Province"
            name="Province"
            maxMenuHeight={140}
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={Provinces}
            value={formik.values.Province ? formik.values.Province : []}
            label="Province"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onChange={(value) => {
              formik.setFieldValue("Province", value);

              formik.setFieldValue("District", []);
              formik.setFieldValue("Tehsil", []);
              setLocationStates((prev) => ({
                ...prev,
                address: "",
                latitude: null,
                longitude: null,
              }));
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        {formik.touched.Province && formik.errors.Province ? (
          <span className="text-red-600  text-[11px] ">
            {formik.errors.Province}
          </span>
        ) : null}
        <div className="">
          <label
            htmlFor="District"
            className="block text-sm font-medium text-TextColor"
          >
            District
          </label>
          <Select
            styles={Style}
            id="District"
            name="District"
            maxMenuHeight={140}
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={DistrictsOptons}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            allowSelectAll={false}
            value={formik.values.District ? formik.values.District : []}
            label="District"
            onChange={async (value) => {
              formik.setFieldValue("District", value);
              formik.setFieldValue("Tehsil", []);
              const District = DistrictsData?.data?.find(
                (item) => parseInt(item.id) === parseInt(value.value)
              );
              const address = await getAddress(
                parseFloat(District?.latitude),
                parseFloat(District?.longitude)
              );
              setLocationStates((prev) => ({
                ...prev,
                address: address,
                latitude: parseFloat(District?.latitude),
                longitude: parseFloat(District?.longitude),
              }));
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.District && formik.errors.District ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.District}
            </span>
          ) : null}
        </div>

        <div className="">
          <label
            htmlFor="Tehsil"
            className="block text-sm font-medium text-TextColor"
          >
            Tehsil
          </label>
          <Select
            styles={Style}
            id="Tehsil"
            name="Tehsil"
            maxMenuHeight={140}
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={TehsilsOptons}
            value={formik.values.Tehsil ? formik.values.Tehsil : ""}
            label="Tehsil"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onChange={async (value) => {
              formik.setFieldValue("Tehsil", value);
              // const tehsil = TehsilsData?.data?.find(
              //   (item) => parseInt(item.id) === parseInt(value?.value)
              // );
              // const address = await getAddress(
              //   parseFloat(tehsil?.latitude),
              //   parseFloat(tehsil?.longitude)
              // );
              // setLocationStates((prev) => ({
              //   ...prev,
              //   address: address,
              //   latitude: parseFloat(tehsil?.latitude),
              //   longitude: parseFloat(tehsil?.longitude),
              // }));
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.Tehsil && formik.errors.Tehsil ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Tehsil}
            </span>
          ) : null}
        </div>

        <div className="col-span-2">
          <label
            htmlFor="AreaVillageTown"
            className="block text-sm  text-TextColor"
          >
            Area/Village/Town
          </label>
          <textarea
            id="AreaVillageTown"
            name="AreaVillageTown"
            className="block
  
w-full
px-3
py-1.5
text-base
font-normal
text-gray-700
bg-white bg-clip-padding
border-solid border-gray-300
rounded
transition
ease-in-out
m-0
border-2
focus:text-gray-700 focus:bg-white focus:outline-none resize-none"
            placeholder="Area/Village/Town"
            value={formik.values.AreaVillageTown ?? ""}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.setFieldValue("AreaVillageTown", e.target.value);
            }}
          />
          {formik.touched.AreaVillageTown && formik.errors.AreaVillageTown ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.AreaVillageTown}
            </span>
          ) : null}
        </div>
        <div className="col-span-2">
          {formik.touched.Pictures && formik.errors.Pictures ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Pictures}
            </span>
          ) : null}
          <FileUploader
            formik={formik}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            previewImages={previewImages}
            setPreviewImages={setPreviewImages}
            inputRef={inputRef}
            adstatus={adstatus}
          />
        </div>
      </div>
    </div>
  );
}
