import {
  //  React,
  Component,
} from "react";

class VerticalGoogleAd extends Component {
  // componentDidMount() {
  //   if (process.env.REACT_APP_SERVER_TYPE === "production") {
  //     try {
  //       (window.adsbygoogle = window.adsbygoogle || []).push({});
  //     } catch (error) {
  //       //
  //     }
  //   }
  // }

  render() {
    return null;
    // return (
    //   <div id="ad-container" className="mx-auto py-2">
    //     <ins
    //       className="adsbygoogle"
    //       data-ad-client="ca-pub-9191039315895871"
    //       data-ad-slot="9615557956"
    //       style={{ display: "inline-block", width: "160px", height: "600px" }}
    //     ></ins>
    //   </div>
    // );
  }
}

export default VerticalGoogleAd;
