import LogoCut from "../assets/icons/LogoCut.svg";
import { OtpForm } from "../Components/RegisterComponents/OtpForm";
// import { AddressForm } from "../Components/RegisterComponents/AddressForm";
import { useMultistepForm } from "../Components/RegisterComponents/useMultistepForm";
import { UserForm } from "../Components/RegisterComponents/UserForm";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CountdownTimer from "../Components/RegisterComponents/otp/CountDown";
import {
  useCreateResendCode,
  useCreateVerifyUser,
  useCreateUser,
} from "../Middlewares/Api";

import AuthContext from "../Context/AuthContext";
import { useContext } from "react";
import UpdateMetaTags from "../Utils/UpdateMetaTags";
export default function Register() {
  const { isAuth, setIsAuth, setUser } = useContext(AuthContext);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const { mutateAsync: UserRegister, isLoading: CreateUserLoading } =
    useCreateUser();
  const { mutateAsync: ResendCode, isLoading: ResendCodeLoading } =
    useCreateResendCode();
  const { mutateAsync: VerifyUser, isLoading: VerifyUserLoading } =
    useCreateVerifyUser();
  const [timeEnded, setTimeEnded] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [successFullyRegistered, setSuccessFullyRegistered] = useState(false);

  const formik = useFormik({
    initialValues: {
      Phone: "",
      Name: "",
      // FarmName: "",
      Password: "",
      ConfirmPassword: "",
      // Province: [],
      // District: [],
      // Tehsil: "",
      // Village: "",
      OTP: "",
      // isStep2: false,
    },

    validationSchema: yup.object({
      Phone: yup.string().required("Mobile number is Required"),
      Name: yup.string().required("Name is Required").max(40, "Max 40 chars"),
      // FarmName: yup.string().max(40, "Max 40 chars"),
      Password: yup
        .string()
        .required("Password is Required")
        .min(4, "Password must be at least 4 characters"),
      ConfirmPassword: yup
        .string()
        .oneOf([yup.ref("Password"), null], "Passwords must match")
        .required("Confirm Password is Required"),
      // isStep2: false,
      // Province: yup
      //   .object()
      //   .nullable()
      //   .when("isStep2", {
      //     is: true,
      //     then: yup.object().nullable().required("Province is Required"),
      //   }),
      // District: yup
      //   .object()
      //   .nullable()
      //   .when("isStep2", {
      //     is: true,
      //     then: yup.object().nullable().required("District is Required"),
      //   }),
      // Tehsil: yup
      //   .object()
      //   .nullable()
      //   .when("isStep2", {
      //     is: true,
      //     then: yup.object().nullable().required("Tehsil is Required"),
      //   }),
      // Village: yup.string().max(50, "Max 50 chars"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  function updateFields(fields) {
    formik.setValues((prev) => {
      return { ...prev, ...fields };
    });
  }

  const { currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
      // eslint-disable-next-line react/jsx-key
      <UserForm
        {...formik.values}
        updateFields={updateFields}
        formik={formik}
      />,
      // eslint-disable-next-line react/jsx-key
      // <AddressForm
      //   {...formik.values}
      //   updateFields={updateFields}
      //   formik={formik}
      // />,
      // eslint-disable-next-line react/jsx-key
      <OtpForm
        {...formik.values}
        updateFields={updateFields}
        formik={formik}
      />,
    ]);

  const handleUserRegister = async (values) => {
    const UserData = {
      name: values.Name,
      // farmName: values.FarmName,
      phone: values.Phone,

      pass: values.Password,
      // province: values.Province.value,
      // district: values.District.value,
      // tehsil: values.Tehsil.value,
      // town_en: values.Village,
    };

    try {
      const data = await UserRegister(JSON.stringify(UserData));

      if (data.code !== 200) {
        formik.setErrors({ Phone: data.message });

        return data;
      }
      return data;
    } catch (error) {
      // do nothing
    }
  };

  const handleSubmit = (values) => {
    if (!isLastStep) {
      const data =
        // currentStepIndex === 1 &&
        handleUserRegister(values);
      Promise.resolve(data).then((data) => {
        if (data && data.code !== 200) {
          return;
        } else {
          formik.setFieldValue("key", data?.data?.key ?? null);

          return next();
        }
      });
    }
  };

  useEffect(() => {
    if (isAuth) navigate("/");

    // if (currentStepIndex === 1) {
    //   formik.setFieldValue("isStep2", true);
    //   formik.setTouched({ Province: false, District: false, Tehsil: false });
    //   formik.setErrors({ Province: "", District: "", Tehsil: "" });
    // } else {
    //   formik.setFieldValue("isStep2", false);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // currentStepIndex,
    isAuth,
  ]);

  const handleResendCode = () => {
    formik.setErrors({ OTP: "" });
    formik.setFieldValue("OTP", "");

    const data = ResendCode(formik.values.key);
    Promise.resolve(data).then((data) => {
      if (data && data.code === 200) {
        setTimeLeft(60);
        setTimeEnded(false);
      }
    });
  };
  const handleVerify = () => {
    if (formik.values.OTP.length < 4) {
      formik.setErrors({ OTP: "OTP must be 4 digits" });
      return;
    }

    const data = VerifyUser({
      key: formik.values.key,
      otp: formik.values.OTP,
    });
    Promise.resolve(data).then((data) => {
      if (data && data.code === 200) {
        setUserData(data?.data);
        localStorage.setItem("key", formik.values.key);
        setUser(data?.data);

        setSuccessFullyRegistered(true);
        setTimeout(() => {
          setIsAuth(true);
          navigate("/");
        }, 2000);
      }
      if (data && data.code !== 200) {
        formik.setErrors({ OTP: data.message });
      }
    });
  };
  const handleSkip = () => {
    if (formik.values.key) {
      window.localStorage.setItem("key", formik.values.key);
      setUser(userData);

      setSuccessFullyRegistered(true);
      setTimeout(() => {
        setIsAuth(true);
        navigate("/");
      }, 2000);
    }
  };

  if (
    CreateUserLoading ||
    ResendCodeLoading ||
    VerifyUserLoading ||
    successFullyRegistered
  ) {
    return (
      <div className="font-PoppinsRegular mt-10 md:mt-3">
        <div className="relative w-full h-full bg-[#F4F4F4] flex justify-center items-center py-10">
          <div className="max-w-[600px] px-10 w-full h-[501px] rounded-[15px] bg-[#FFFFFF] z-10 ">
            <div className="flex flex-col items-center mt-[40px] w-full h-full ">
              <div className="space-y-4 mt-[29px]  w-full h-full max-w-[340px] text-left">
                {successFullyRegistered ? (
                  <div className="ml-auto mr-auto mt-44 font-PoppinsBold text-center text-[18px] text-Primary">
                    Successfully registered and logged in Redirecting to home
                    page
                  </div>
                ) : (
                  <div className="spinner ml-auto mr-auto mt-44"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <UpdateMetaTags data={{ title: "Register" }} />

      <div className="font-PoppinsRegular mt-10 md:mt-3">
        <div className="relative w-full h-full bg-[#F4F4F4] flex justify-center items-center py-10">
          <div className="max-w-[600px] px-10 w-full max-h-[801px] h-auto rounded-[15px] bg-[#FFFFFF] z-10 pb-6 ">
            <form
              className="w-full h-full relative"
              onSubmit={formik.handleSubmit}
            >
              {/* <div style={{ position: "absolute", top: ".5rem", right: ".5rem" }}>
              {currentStepIndex + 1} / {steps.length}
            </div> */}

              <div className="text-center sm:text-[16px] text-[14px]">
                {step}

                {isLastStep && timeLeft > 0 && (
                  <CountdownTimer
                    setTimeEnded={setTimeEnded}
                    timeLeft={timeLeft}
                    setTimeLeft={setTimeLeft}
                  />
                )}

                {timeEnded ? (
                  <div className="flex justify-center items-center space-x-2">
                    <button
                      type="button"
                      onClick={() => handleResendCode()}
                      className="max-w-[340px]  w-[150px] h-[42px] rounded-[10px] bg-Primary text-white mt-4 inline-block"
                    >
                      Resend Code
                    </button>

                    <button
                      type="button"
                      className="max-w-[340px]  w-[150px] h-[42px] rounded-[10px] bg-Primary text-white mt-4 inline-block"
                      onClick={() => handleSkip()}
                    >
                      Skip
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-center items-center space-x-2">
                    {!isFirstStep && !isLastStep && (
                      <button
                        type="button"
                        onClick={back}
                        className="max-w-[340px] w-[150px] h-[42px] rounded-[10px] bg-Primary text-white mt-4 inline-block"
                      >
                        Back
                      </button>
                    )}

                    {isLastStep ? (
                      <button
                        type="button"
                        className="max-w-[340px]  w-[150px] h-[42px] rounded-[10px] bg-Primary text-white mt-4 inline-block"
                        onClick={() => handleVerify()}
                      >
                        Verify
                      </button>
                    ) : (
                      <button
                        type="submit"
                        name="submit"
                        className="max-w-[340px]  w-[150px] h-[42px] rounded-[10px] bg-Primary text-white mt-4 inline-block"
                      >
                        {currentStepIndex === 1 ? "Register" : "Continue"}
                      </button>
                    )}
                  </div>
                )}
                <div className="mt-2">
                  Already have an account?{" "}
                  <Link to="/auth/login" className="text-Primary">
                    Login now
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <img
            src={LogoCut}
            alt="Logo"
            className="absolute bottom-0 right-0 "
          />
        </div>
      </div>
    </>
  );
}
