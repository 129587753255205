import { useEffect } from "react";
import { useTransaction_history } from "../../Middlewares/Api";
import BannerTop from "../HomePageComponents/BannerTop";
import JazzCash from "./../../assets/icons/jazz-cash.png";
import EasyPaisa from "./../../assets/icons/easy-paisa.png";
import DebitCredit from "./../../assets/icons/credit-debit.svg";

export default function TransactionHistory() {
  const { data, isLoading, mutate } = useTransaction_history();

  useEffect(() => {
    const payload = {
      key: localStorage.getItem("key"),
    };
    mutate(payload);
  }, []);
  if (isLoading) {
    return (
      <div className="flex items-center justify-center mt-20">
        <div className="spinner"></div>
      </div>
    );
  }
  return (
    <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
      <div className="pt-3 ">
        <BannerTop />
      </div>

      <div className="font-PoppinsRegular">
        <div className="flex gap-4 items-center relative my-3">
          <h2 className="text-TextColor font-bold text-xl">
            Transaction History
          </h2>
          <span className="rounded-full font-semibold text-[16px] leading-[19.5px] text-white bg-Primary p-2">
            {data?.data?.length}
          </span>
        </div>

        <div className="hidden md:block relative overflow-x-auto h-[738px]">
          <table className="w-full text-left ">
            <thead className="border-y">
              <tr>
                <th
                  scope="col"
                  className="text-[10px] lg:text-[14px]  w-[130px] h-[17px] py-3"
                >
                  Ad ID
                </th>
                <th
                  scope="col"
                  className="text-[10px] lg:text-[14px] w-[130px] h-[17px] py-3"
                >
                  Transaction ID
                </th>
                <th
                  scope="col"
                  className="text-[10px] lg:text-[14px] p-3 h-[23px] w-[140px] py-3"
                >
                  Job Name
                </th>
                <th
                  scope="col"
                  className="text-[10px] lg:text-[14px] h-[23px] w-[190px] py-3"
                >
                  Request Amount
                </th>
                <th
                  scope="col"
                  className="text-[10px] lg:text-[14px] h-[23px] w-[190px] py-3"
                >
                  Transaction via
                </th>

                <th
                  scope="col"
                  className="text-[10px] lg:text-[14px] w-[151px] py-3"
                >
                  Due On
                </th>
              </tr>
            </thead>
            {data?.data?.map((item, index) => (
              <tbody key={index}>
                <tr className="bg-white border-b">
                  <th
                    scope="row"
                    className="min-w-[140px] py-3 font-bold text-[14px] leading-[17.07px]"
                  >
                    {item?.service_type === "paid_ad"
                      ? "paid ad"
                      : item?.ad_post_id}
                  </th>
                  <td className="min-w-[151px] py-3 font-bold text-[14px] leading-[17.07px]">
                    <label className="flex-col md:flex md:flex-row items-center gap-5">
                      {item?.transaction_id}
                    </label>
                  </td>

                  <td className="min-w-[190px] py-3 font-bold text-[14px] leading-[16.8px] text-Primary">
                    <label className="flex-col md:flex md:flex-row items-center gap-5">
                      {item?.service_type === "star_ad"
                        ? "star ad"
                        : item?.service_type === "paid_ad"
                        ? "paid ad"
                        : item?.service_type === "payment_guarantee"
                        ? "payment guarantee"
                        : item?.service_type === "self-stock"
                        ? "self stock"
                        : item?.service_type}
                    </label>
                  </td>
                  <td className="min-w-[180px] py-4 font-normal text-[14px] leading-[16.8px]">
                    Rs. {item?.amount}
                  </td>
                  <td className="flex items-center gap-2 min-w-[190px] py-4 font-normal text-[14px] leading-[16.8px]">
                    <img
                      src={`${
                        item?.transaction_via === "MasterCard"
                          ? DebitCredit
                          : item?.transaction_via === "JAZZCASH"
                          ? JazzCash
                          : item?.transaction_via === "easypaisa"
                          ? EasyPaisa
                          : DebitCredit
                      }`}
                      alt=""
                      className="object-cover h-auto max-h-[35px] max-w-[35px]"
                    />
                    {item?.transaction_via}
                  </td>
                  <td className=" py-4 font-normal text-[14px] leading-[16.8px]">
                    {item?.date}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
          {data?.message === "No Payment History Found" ? (
            <div className="w-full mx-auto my-8 text-center border rounded-lg py-3">
              <p className="text-[10px] lg:text-[12px] text center text-black">
                {data?.message}
              </p>
            </div>
          ) : null}
        </div>

        {/* -------------------------- for small screen size -------------------------- */}
        <div className="min-h-[738px] max-w-[358px] block md:hidden ">
          {data?.data?.map((item, index) => (
            <div key={index} className="block md:hidden mb-5">
              <div className="flex flex-col gap-2 justify-between  bg-white border rounded-2xl p-2 font-Trueno">
                <div className="flex flex-col gap-2 p-3">
                  <p className="flex gap-3 items-center text-sm font-bold">
                    Ad ID:
                    <label className="font-medium">{item?.ad_post_id}</label>
                  </p>
                  <p className="flex gap-3 items-center text-sm font-bold">
                    Transaction ID:
                    <label className="font-medium">
                      {item?.transaction_id}
                    </label>
                  </p>
                  <p className="flex gap-3 items-center text-sm font-bold">
                    Service Type:
                    <label className="font-medium">
                      {item?.service_type === "star_ad"
                        ? "star ad"
                        : item?.service_type === "paid_ad"
                        ? "paid ad"
                        : item?.service_type === "payment_guarantee"
                        ? "payment guarantee"
                        : item?.service_type === "self-stock"
                        ? "self stock"
                        : item?.service_type}
                    </label>
                  </p>
                  <h1 className="font-bold text-[16px] leading-[22px] text-[#0973BA]">
                    {item?.request_title}
                  </h1>
                  <div className="w-full grid md:flex gap-2 justify-between ">
                    <div className="flex items-center gap-3 text-sm font-bold">
                      Total Amount:
                      <span className="font-medium">{item?.amount}</span>
                    </div>
                    <div className="w-full flex items-center gap-3 text-sm font-bold">
                      via:
                      <label className="font-medium">
                        {item?.transaction_via}
                      </label>
                      <img
                        src={`${
                          item?.transaction_via === "MasterCard"
                          ? DebitCredit
                          : item?.transaction_via === "JAZZCASH"
                          ? JazzCash
                          : item?.transaction_via === "easypaisa"
                          ? EasyPaisa
                          : DebitCredit
                        }`}
                        alt=""
                        className="object-cover h-auto max-h-[30px] max-w-[30px]"
                      />
                    </div>
                    <div className="flex items-center gap-3 text-sm font-bold">
                      Due on:
                      <span className="font-medium">{item?.date}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
