// import HeroSlider from "../Components/HomePageComponents/HeroSlider/HeroSlider";
import { Link } from "react-router-dom";
import BannerTop from "../Components/HomePageComponents/BannerTop";

import OurServices from "../Components/HomePageComponents/OurServices/OurServices";

import AdsByCategory from "../Components/HomePageComponents/AdsByCategory/AdsByCategory";
import SelectCategory from "../Components/HomePageComponents/SelectCategory";

import ExploreProducts from "../Components/HomePageComponents/ExploreProducts";

import WhyFarmGhar from "../Components/HomePageComponents/WhyFarmGhar";
import VideoComponent from "../Components/HomePageComponents/VideoComponent";
// import News from "../Components/HomePageComponents/News/News";
import Blogs from "../Components/HomePageComponents/Blogs/Blogs";
import CallToActionBottom from "../Components/HomePageComponents/CallToActionBottom";
import UpdateMetaTags from "../Utils/UpdateMetaTags";
import SelectCategoryLoader from "../Loaders/SelectCategoryLoader";
import OurServicesLoader from "../Loaders/OurServicesLoader";
import BlogsLoader from "../Loaders/BlogsLoader";
import AdsByCategoryLoader from "../Loaders/AdsByCategoryLoader";
// import QurbaniByFarmGhar from "../Components/HomePageComponents/QurbaniByFarmGhar";
// import QurbaniSectionLoader from "../Loaders/QurbaniSectionLoader";
import {
  useFetchMetaDataHome,
  useFetchHomePageCategories,
  // useFetchHomePageAdsByCategory,
  useFetchHomeAdsByCategory,
  useFetchHomePageServices,
  useFetchAllBlogsPosts,
  // useFetchQurbaniPackages,
} from "../Middlewares/Api";
// import CheckoutButton from "../Components/QurbaniComponents/CheckoutButton";
import SEOContent from "../Components/HomePageComponents/SEOContent";
import AllCategories from "../Components/HomePageComponents/AllCategories/AllCategories";
export default function HomePage() {
  const { data } = useFetchMetaDataHome();
  const {
    data: SelectCategoriesData,
    isLoading: SelectCategorySectionLoading,
  } = useFetchHomePageCategories();

  const { data: AdsByCategoryData, isLoading: AdsByCategoryLoading } =
    useFetchHomeAdsByCategory();

  const { data: OurServicesData, isLoading: OurServicesLoading } =
    useFetchHomePageServices();

  const { data: BlogsData, isLoading: BlogsLoading } = useFetchAllBlogsPosts({
    page: 1,
  });
  // const { data: QurbaniPackages, isLoading: QurbaniPackagesLoading } =
  //   useFetchQurbaniPackages({
  //     package_category_id: "0",
  //   });

  // if (
  //   SelectCategorySectionLoading ||
  //   AdsByCategoryLoading ||
  //   OurServicesLoading ||
  //   BlogsLoading
  // )
  //   return (
  //     <div className="flex items-center justify-center pt-16 sm:pt-10">
  //       <div className="spinner"></div>
  //     </div>
  //   );
  return (
    <div className="font-PoppinsRegular">
      <UpdateMetaTags data={data?.data ?? {}} />
      <div className="max-w-[1280px] md:mx-auto px-[5%] xxl:px-[2%] mt-[70px] pt-0  md:pt-3 md:mt-0">
        <BannerTop />
      </div>
      {/* <div className=" w-full mt-[42px] md:mt-0">
        <HeroSlider />
      </div> */}
      {/* <div className="max-w-[1280px] md:mx-auto px-[5%] xxl:px-[2%]">
        {QurbaniPackagesLoading ? (
          <QurbaniSectionLoader />
        ) : (
          <QurbaniByFarmGhar QurbaniPackages={QurbaniPackages} />
        )}
      </div> */}
      {/* HidedForQurbani */}
      <div className="max-w-[1280px] md:mx-auto px-[5%] xxl:px-[2%]">
        {SelectCategorySectionLoading ? (
          <SelectCategoryLoader />
        ) : (
          <SelectCategory data={SelectCategoriesData} />
        )}
      </div>
      {/* HidedForQurbani */}
      <div className="max-w-[1280px] md:mx-auto px-[5%] xxl:px-[2%]">
        <AllCategories />
      </div>

      <div className="max-w-[1280px] md:mx-auto px-[5%] xxl:px-[2%]">
        {AdsByCategoryLoading ? (
          <AdsByCategoryLoader />
        ) : (
          <AdsByCategory CategoriesAndAdsHome={AdsByCategoryData} />
        )}
      </div>
      <div
        className="max-w-[1280px] md:mx-auto px-[5%] xxl:px-[2%]"
        id="service"
      >
        {OurServicesLoading ? (
          <OurServicesLoader />
        ) : (
          <OurServices data={OurServicesData} />
        )}
      </div>
      <div className="max-w-[1280px] md:mx-auto px-[5%] xxl:px-[2%]">
        <ExploreProducts />
      </div>
      <div className="max-w-[1280px] md:mx-auto px-[5%] xxl:px-[2%]">
        <WhyFarmGhar />
      </div>
      <div className="max-w-[1280px] mx-auto px-[5%] xxl:px-[2%]">
        <VideoComponent />
      </div>
      {/* <div className="max-w-[1280px] md:mx-auto px-[8%]">
        <BannerTop />
      </div> */}
      {/* hiding for now but show after implementation */}
      {/* <div className="max-w-[1280px] md:mx-auto px-[5px] xl-[1865px]:px-[2%]">
        <News />
      </div>
      <div className="max-w-[1280px] md:mx-auto px-[5%]">
        <Blogs />
      </div> */}
      <div className="max-w-[1280px] md:mx-auto px-[5%] xxl:px-[2%]">
        {BlogsLoading ? <BlogsLoader /> : <Blogs services={BlogsData} />}
      </div>

      <div className="max-w-[1280px] md:mx-auto px-[5%] xxl:px-[2%]">
        <CallToActionBottom />
      </div>
      {/* HidedForQurbani */}
      <div className="fixed flex justify-center items-center  right-0 left-0 bottom-[19px] md:hidden">
        <Link
          className="block bg-Primary py-[10px] px-[14px] rounded-[50px] w-[108px] HeaderShadowButton"
          to="/account/post-ad"
        >
          <div className="flex items-center space-x-2 justify-center">
            {" "}
            <span className="text-[14px] text-white">+</span>
            <span className="text-[14px] text-white">Post Ad</span>
          </div>
        </Link>
      </div>
      <div className="max-w-[1280px] md:mx-auto px-[5%] xxl:px-[2%]">
        <SEOContent />
      </div>
      {/* <div className="fixed z-50 bottom-[134px] right-[10px]">
        <CheckoutButton />
      </div> */}
    </div>
  );
}
