import BannerTop from "../Components/HomePageComponents/BannerTop";
import {
  useFetchShowAllAdsUser,
  useFetchHomePageCategories,
} from "../Middlewares/Api";
import UpdateMetaTags from "../Utils/UpdateMetaTags";
import { Link } from "react-router-dom";
import { ReactComponent as StarIcon } from "../assets/icons/star.svg";
import { ReactComponent as VerifiedIcon } from "../assets/icons/verified.svg";
import { ReactComponent as PackageIcon } from "../assets/icons/package.svg";
import { ReactComponent as DeliveryIcon } from "../assets/icons/delivery.svg";
import { ReactComponent as LocationAdIcon } from "../assets/icons/locationAd.svg";
import { ReactComponent as EyeIcon } from "../assets/icons/eye.svg";
import { ReactComponent as SoldOutIcon } from "../assets/icons/SoldOut.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function FavouritesAds() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: categories } = useFetchHomePageCategories();
  const ad_user_id = location?.state;

  const DisplaySoldOut = (e) => {
    e.preventDefault();
    return alert("This item has been sold and no longer available.");
  };
  const { data, isLoading } = useFetchShowAllAdsUser({
    ad_user_id: ad_user_id ? ad_user_id : "",
  });

  const getShortName = (name) => {
    let str;

    if (name.length > 14) {
      str = name.substring(0, 20) + "...";
    } else {
      str = name;
    }
    return str;
  };

  useEffect(() => {
    !ad_user_id ? navigate("/") : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ad_user_id]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center mt-20">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <>
      <UpdateMetaTags data={{ title: "View All User Ads" }} />
      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>

        {data && data?.data?.length > 0 ? (
          <ul className="flex items-center justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-6 xl:grid-cols-4  pt-2">
              {data &&
                data.data.map((item, index) => (
                  <li key={index}>
                    <div
                      className={`rounded-[13px] border border-[#DBDED9] ${
                        item?.managed_by_farmghar === "1"
                          ? "bg-Primary"
                          : "bg-white"
                      } w-[255px] h-[312px]`}
                    >
                      <Link
                        to={
                          /sold/i.test(item.ad_status)
                            ? "#sold"
                            : `/${
                                (categories &&
                                  categories?.find(
                                    (cat) => cat.id === item.p_parent_cat_id
                                  )?.category_slug) ??
                                "item"
                              }/${item?.ad_slug}`
                        }
                        onClick={(e) => {
                          /sold/i.test(item.ad_status)
                            ? DisplaySoldOut(e)
                            : null;
                        }}
                        className="w-full h-full flex flex-col justify-center"
                      >
                        <div className="rounded-[8px] mt-[6.77px] mr-[5.95px] ml-[5px] w-[244.48px] h-[180.49px] ">
                          <div className="relative w-[244.48px] h-[180.49px]">
                            <figure className="w-full h-full">
                              <img
                                src={item.img_thumbnail && item.img_thumbnail}
                                className=" rounded-[9px] w-full h-full object-cover"
                                alt={item.ad_title && item.ad_title}
                              />
                            </figure>

                            {item?.is_star === "1" && item?.priority === "11" && (
                              <div className="absolute top-[-15px] left-[-15px]">
                                <StarIcon />
                              </div>
                            )}
                            {item.ad_status && /sold/i.test(item.ad_status) && (
                              <div className="absolute right-[85px] top-[86px]">
                                <SoldOutIcon />
                              </div>
                            )}
                            {item.is_verified === "1" && (
                              <div className="absolute bottom-[7.62px] right-[6.43px]">
                                <VerifiedIcon />
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className={` ${
                            item?.managed_by_farmghar === "1"
                              ? "bg-white rounded-md ml-0 mr-0 px-3 m-[3.51px]"
                              : "m-[12.51px]"
                          }`}
                        >
                          <div
                            className={`flex ${
                              item?.managed_by_farmghar === "1"
                                ? "mt-[3px] pt-[3px]"
                                : null
                            }`}
                          >
                            {item.is_delivery === "1" && (
                              <div>
                                <DeliveryIcon />
                              </div>
                            )}
                            {item.is_delivery_ad === "1" && (
                              <div>
                                <PackageIcon />
                              </div>
                            )}
                          </div>

                          {/* for Ad */}
                          {item.ad_title && (
                            <div>
                              <h3 className="font-PoppinsBold text-[16px]">
                                {getShortName(item.ad_title)}
                              </h3>
                            </div>
                          )}

                          <div className="flex justify-between">
                            {item.posted_text && (
                              <p className="font-[10px] text-[#39342F] opacity-50">
                                {item.posted_text}
                              </p>
                            )}
                            {item.price && (
                              <p className="text-black font-[18px]">
                                RS {item.price}
                              </p>
                            )}
                          </div>

                          <div className="flex justify-between">
                            {item.district && (
                              <div className="flex items-center">
                                <div className="mr-[5.85px]">
                                  <LocationAdIcon />
                                </div>
                                <p>{item.district}</p>{" "}
                              </div>
                            )}
                            {item.detailviews && (
                              <div className="flex items-center">
                                <div className="opacity-50 mr-[5.85px]">
                                  <EyeIcon />
                                </div>
                                <p className="text-Primary">
                                  {item.detailviews}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        {item?.managed_by_farmghar === "1" && (
                          <div className="flex justify-center text-white font-bold text-[14px]">
                            farmGhar Self-stock
                          </div>
                        )}
                      </Link>
                    </div>
                  </li>
                ))}
            </div>
          </ul>
        ) : (
          <div className="flex justify-center items-center h-[300px]">
            <div className="flex flex-col items-center">
              <p className="text-[#39342F] font-PoppinsBold text-[18px] mt-[20px]">
                No Ads Found
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
