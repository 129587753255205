import LogoCut from "../assets/icons/LogoCut.svg";
import { OtpForm } from "../Components/RegisterComponents/OtpForm";
import { useMultistepForm } from "../Components/RegisterComponents/useMultistepForm";
import { ResetPasswordForm } from "../Components/ForgotPasswordComponents/ResetPasswordForm";
import { useFormik } from "formik";
import * as yup from "yup";

import { useState, useEffect } from "react";
import CountdownTimer from "../Components/RegisterComponents/otp/CountDown";

import {
  usePassResetResendCode,
  usePassResetVerifyUser,
  usePassResetChangePassword,
  usePassReset,
} from "../Middlewares/Api";
import ChangePassword from "../Components/ForgotPasswordComponents/ChangePassword";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import { useContext } from "react";
import UpdateMetaTags from "../Utils/UpdateMetaTags";
export default function ForgotPassword() {
  const navigate = useNavigate();
  const { isAuth } = useContext(AuthContext);
  const [timeEnded, setTimeEnded] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [SuccessfullyChanged, setSuccessfullyChanged] = useState(false);
  const {
    mutateAsync: passResetGetKeyAndSendOtp,
    isLoading: passResetGetKeyAndSendOtpLoading,
  } = usePassReset();
  const {
    mutateAsync: passResetResendCode,
    isLoading: passResetResendCodeLoading,
  } = usePassResetResendCode();
  const {
    mutateAsync: passResetVerifyUser,
    isLoading: passResetVerifyUserLoading,
  } = usePassResetVerifyUser();
  const {
    mutateAsync: passResetChangePassword,
    isLoading: passResetChangePasswordLoading,
  } = usePassResetChangePassword();

  const formik = useFormik({
    initialValues: {
      Phone: "",
      OTP: "",
      Password: "",
      ConfirmPassword: "",
      key: "",
      isLastStep: false,
    },

    validationSchema: yup.object({
      Phone: yup.string().required("Mobile Number is Required"),
      Password: yup
        .string()
        .min(4, "Password must be at least 4 characters")
        .when("isLastStep", {
          is: true,
          then: yup.string().required("Password is Required"),
        }),
      ConfirmPassword: yup
        .string()
        .oneOf([yup.ref("Password"), null], "Passwords must match")
        .when("isLastStep", {
          is: true,
          then: yup.string().required("Confirm Password is Required"),
        }),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  //   const { currentStepIndex, step, isFirstStep, isLastStep, back, next } =
  const { currentStepIndex, isFirstStep, isLastStep, step, next } =
    useMultistepForm([
      // eslint-disable-next-line react/jsx-key
      <ResetPasswordForm {...formik.values} formik={formik} />,
      // eslint-disable-next-line react/jsx-key
      <OtpForm {...formik.values} formik={formik} />,
      // eslint-disable-next-line react/jsx-key
      <ChangePassword formik={formik} />,
    ]);

  const SendOtp = () => {
    formik.setErrors({ OTP: "" });
    formik.setFieldValue("OTP", "");

    setTimeLeft(60);
    setTimeEnded(false);
    return passResetResendCode(formik.values.key);
  };

  const handleSubmit = () => {
    if (isFirstStep) {
      const data =
        formik.values.key === "" &&
        passResetGetKeyAndSendOtp({ phone: formik.values.Phone });

      Promise.resolve(data).then((data) => {
        if (data && data.code !== 200) {
          formik.setErrors({ Phone: data.message });
          return;
        } else {
          formik.setFieldValue("key", data?.data?.key);
          return next();
        }
      });
    }
    if (!isFirstStep && !isLastStep) {
      if (formik.values.OTP.length < 4) {
        formik.setErrors({ OTP: "OTP must be 4 digits" });
        return;
      }
      const data = passResetVerifyUser({
        key: formik.values.key,
        otp: formik.values.OTP,
      });

      Promise.resolve(data).then((data) => {
        if (data && data.code !== 200) {
          formik.setErrors({ OTP: data.message });
          return;
        } else {
          return next();
        }
      });
    }
    if (isLastStep) {
      const data = passResetChangePassword({
        key: formik.values.key,
        password: formik.values.Password,
      });
      Promise.resolve(data).then((data) => {
        if (data && data.code !== 200) {
          formik.setErrors({ Password: data.message });
          return;
        } else {
          setSuccessfullyChanged(true);
          setTimeout(() => {
            navigate("/auth/login");
          }, 2000);
        }
      });
    }
  };

  useEffect(() => {
    if (isAuth) navigate("/");
    if (isLastStep) {
      formik.setFieldValue("isLastStep", true);
      formik.setTouched({ Password: false, ConfirmPassword: false });
      formik.setErrors({ Password: "", ConfirmPassword: "" });
    } else {
      formik.setFieldValue("isLastStep", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLastStep, isAuth]);
  if (
    passResetGetKeyAndSendOtpLoading ||
    passResetResendCodeLoading ||
    passResetVerifyUserLoading ||
    passResetChangePasswordLoading ||
    SuccessfullyChanged
  ) {
    return (
      <div className="font-PoppinsRegular mt-10 md:mt-3">
        <div className="relative w-full h-full bg-[#F4F4F4] flex justify-center items-center py-10">
          <div className="max-w-[600px] px-10 w-full h-[501px] rounded-[15px] bg-[#FFFFFF] z-10 ">
            <div className="flex flex-col items-center mt-[40px] w-full h-full ">
              <div className="space-y-4 mt-[29px]  w-full h-full max-w-[340px] text-left">
                {SuccessfullyChanged ? (
                  <div className="ml-auto mr-auto mt-44 font-PoppinsBold text-center text-[18px] text-Primary">
                    Password Changed Successfully <br /> Redirecting to Login
                  </div>
                ) : (
                  <div className="spinner ml-auto mr-auto mt-44"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <UpdateMetaTags data={{ title: "Forgotten password" }} />
      <div className="font-PoppinsRegular mt-10 md:mt-3">
        <div className="relative w-full h-full bg-[#F4F4F4] flex justify-center items-center py-10">
          <div className="max-w-[600px] px-10 w-full max-h-[801px] h-auto rounded-[15px] bg-[#FFFFFF] z-10 pb-6 ">
            <form
              className="w-full h-full relative"
              onSubmit={formik.handleSubmit}
            >
              {/* <div style={{ position: "absolute", top: ".5rem", right: ".5rem" }}>
              {currentStepIndex + 1} / {steps.length}
            </div> */}

              <div className="text-center sm:text-[16px] text-[14px]">
                {step}

                {currentStepIndex === 1 && timeLeft > 0 && (
                  <CountdownTimer
                    setTimeEnded={setTimeEnded}
                    timeLeft={timeLeft}
                    setTimeLeft={setTimeLeft}
                  />
                )}
                {isLastStep ? (
                  <button
                    type="submit"
                    name="submit"
                    className="max-w-[340px]  w-full h-[42px] rounded-[10px] bg-Primary text-white mt-4 inline-block"
                  >
                    Reset Password
                  </button>
                ) : (
                  <>
                    {timeEnded ? (
                      <button
                        type="button"
                        name="button"
                        className="max-w-[340px]  w-full h-[42px] rounded-[10px] bg-Primary text-white mt-4 inline-block"
                        onClick={() => SendOtp()}
                      >
                        Resend OTP
                      </button>
                    ) : (
                      <button
                        type="submit"
                        name="submit"
                        className="max-w-[340px]  w-full h-[42px] rounded-[10px] bg-Primary text-white mt-4 inline-block"
                      >
                        Verify
                      </button>
                    )}
                  </>
                )}
              </div>
            </form>
          </div>
          <img
            src={LogoCut}
            alt="Logo"
            className="absolute bottom-0 right-0 "
          />
        </div>
      </div>
    </>
  );
}
