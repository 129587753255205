import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: false,
  arrows: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: false,
  className: "slider variable-width",
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1198,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        centerMode: false,
      },
    },

    {
      breakpoint: 1197,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,

        centerMode: false,
      },
    },
    {
      breakpoint: 827,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      },
    },

    {
      breakpoint: 555,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      },
    },
  ],
};
export default function OurServicesLoader() {
  return (
    <div className="pt-3 relative animate-pulse">
      <div className="flex justify-between mt-[21px] mb-[28px] ">
        <p className="font-PoppinsBold md:text-[22px] w-6 h-2 bg-gray-200"></p>
        <>
          <p className="font-PoppinsBold text-[18px] text-Primary mr-[15px] w-6 h-2 bg-gray-200"></p>
        </>
      </div>

      <Slider {...settings}>
        {[1, 2, 3, 4, 5, 6].map((index) => {
          return (
            <div key={index} style={{ width: 240 }}>
              <div className="w-[220px] h-[73.68px] rounded-[8px] flex border border-[#DBDED9]">
                <div className="w-[68.21px] h-[63.16px] m-[5.68px] mr-[12.11px]">
                  <div className="w-[68.21px] h-[63.16px] rounded-[8px] bg-gray-200" />
                </div>
                <div className="flex items-center">
                  <div>
                    <p className="text-[12px] w-10 h-2 font-PoppinsBold bg-gray-200 "></p>
                    <br />
                    <p className="text-[10px] w-10 h-2 bg-gray-200"></p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
