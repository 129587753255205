import CardSlider from "../CardSlider";

export default function Blogs({ services }) {
  const getShortName = (title) => {
    let str;

    if (title?.length > 20) {
      str = title?.substring(0, 20) + "...";
    } else {
      str = title ?? "";
    }
    return str;
  };
  // get text substring from html string
  const getTextFromHtmlString = (htmlString) => {
    const div = document.createElement("div");
    div.innerHTML = htmlString;
    const text = div.textContent || div.innerText || "";
    if (text.length > 60) {
      return text.substring(0, 60) + "...";
    }
    return text;
  };

  //limiting the data to 6
  const servicesData = services?.data?.slice(0, 10);

  const data = servicesData?.map((data) => {
    return {
      Image: data.image,
      IsBlog: true,
      BlogTitle: getShortName(data.title),
      ViewsCount: data.count,
      Description: getTextFromHtmlString(data.content),
      ReadMoreLink: `/blogs/${data.slug}`,
      ReadMore: "Read More",
      // PostedText: "Posted 2 days ago",
    };
  });

  const settings = {
    SectionLink: `/blogs`,
    SectionName: "Our Blogs",
    ViewAll: "View All",
    Width: "xsm:w-[270px]",
    Height: "h-[325px]",
    ImageWidth: "xsm:w-[259.48px]",
    ImageHeight: "h-[180.49px]",
    slidesToShow: 3,
    slidesToScroll: 1,
    hiddenOnOneSlide: "lg:block",
    infinite: false,
    data: data,
    responsive: [
      {
        breakpoint: 1206,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 817,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: false,
          centerMode: false,
        },
      },
    ],
  };

  return <CardSlider settings={settings} />;
}
