import SectionBackWithName from "../Components/StaticPageComponents/SectionBackWithName";
import { ReactComponent as ContactUsEmail } from "../assets/icons/ContactUsEmail.svg";
import { ReactComponent as ContactUsPhone } from "../assets/icons/ContactUsPhone.svg";
import { ReactComponent as ContactUsWhatsapp } from "../assets/icons/ContactUsWhatsapp.svg";
import { useState } from "react";
import useAxios from "../Utils/useAxios";
import UpdateMetaTags from "../Utils/UpdateMetaTags";

export default function ContactUs() {
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const api = useAxios();

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const dat = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone_number: e.target.phone_number.value,
      message: e.target.message.value,
    };

    const { data } = await api.post("/contact_form.php", JSON.stringify(dat));
    setStatus(data.code);

    setLoading(false);
  };

  const Contacts = [
    {
      icon: <ContactUsPhone />,
      name: "Phone Number",
      contact: "0304-111-0434",
    },
    {
      icon: <ContactUsWhatsapp />,
      name: "Whatsapp",
      contact: "0301-101-8207",
    },
    {
      icon: <ContactUsEmail />,
      name: "Email",
      contact: "info@farmghar.com",
    },
  ];
  return (
    <>
      <UpdateMetaTags data={{ title: "Contact" }} />
      <div className="mt-10 md:pt-3 md:mt-0">
        <SectionBackWithName pageName={"Contact Us"} />
      </div>
      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-10">
          <div className="flex justify-center space-y-4 sm:space-x-4 sm:space-y-0 flex-col items-center sm:flex-row">
            {Contacts.map((item, index) => (
              <div
                key={index}
                className="w-[300px] sm:w-[316px] h-[145px] rounded-[12px] border border-[#DBDED9] flex flex-col justify-center space-y-2 pl-4"
              >
                {item.icon}
                <p className="font-PoppinsBold  text-[12px]">{item.name}</p>
                {item.name === "Email" ? (
                  <a
                    href={`mailto:${item.contact}`}
                    className="font-PoppinsBold text-[18px] w-fit"
                  >
                    {item.contact}
                  </a>
                ) : item.name === "Phone Number" ? (
                  <a
                    href={`tel:${item.contact}`}
                    className="font-PoppinsBold text-[18px] w-fit"
                  >
                    {item.contact}
                  </a>
                ) : (
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={`https://wa.me/92${item.contact.replaceAll("-", "")}`}
                    className="font-PoppinsBold text-[18px] w-fit"
                  >
                    {item.contact}
                  </a>
                )}
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center flex-col mt-[79px]">
            <h3 className="font-PoppinsBold text-[14px] md:text-[22px]">
              Questions, Comments, or Concerns?
            </h3>
            <p className="max-w-[496px] p-4 text-center text-[14px]">
              Use the form below to get in touch. Our office hours are Monday
              through Friday, 9 am to 5 pm Eastern, and we attempt to respond to
              support requests within 1 business day.
            </p>
            {status === 200 ? (
              <div className="flex items-center justify-center">
                <div className="p-10 sm:p-16 text-[18px] text-center">
                  Thanks for reaching out!We&apos;re thrilled to hear from you.
                  Our inbox can&apos;t wait to get your messages, so talk to us
                  at any time you like.
                </div>
              </div>
            ) : (
              <>
                {loading ? (
                  <div className="flex items-center justify-center">
                    <div className="spinner"></div>
                  </div>
                ) : (
                  <form
                    className="max-w-full mx-auto space-y-4"
                    onSubmit={(e) => HandleSubmit(e)}
                  >
                    <div className="flex items-center">
                      <label htmlFor="name" className="sr-only">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        required
                        className=" pl-4 block w-[945px] h-[42px] rounded-md  shadow-sm border border-gray-300 focus:border-Primary focus:border focus:outline-none focus:ring-0 sm:text-sm"
                        placeholder="Name"
                      />
                    </div>
                    <div className="flex items-center">
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        required
                        className=" pl-4 block w-[945px] h-[42px] rounded-md  shadow-sm border border-gray-300 focus:border-Primary focus:border focus:outline-none focus:ring-0 sm:text-sm"
                        placeholder="Email"
                      />
                    </div>
                    <div className="flex items-center">
                      <label htmlFor="phone_number" className="sr-only">
                        Phone
                      </label>
                      <input
                        type="phone"
                        name="phone_number"
                        id="phone_number"
                        required
                        className=" pl-4 block w-[945px] h-[42px] rounded-md  shadow-sm border border-gray-300 focus:border-Primary focus:border focus:outline-none focus:ring-0 sm:text-sm"
                        placeholder="Phone"
                      />
                    </div>

                    <div className="flex items-center">
                      <label htmlFor="message" className="sr-only">
                        Message
                      </label>

                      <textarea
                        id="message"
                        name="message"
                        rows={10}
                        placeholder="Message"
                        className="pl-4 pt-2 block w-[945px] rounded-md  shadow-sm border border-gray-300 focus:border-Primary focus:border focus:outline-none focus:ring-0 sm:text-sm"
                        defaultValue={""}
                      />
                    </div>
                    <div className="flex items-center justify-center">
                      <button
                        type="submit"
                        className="w-[364px] h-[42px] bg-Primary text-white rounded-md font-PoppinsBold text-[14px] "
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
