// import sidebarAd from "../../assets/images/SideAd.png";
import { useLocation } from "react-router-dom";
import VerticalGoogleAd from "../../Utils/VerticalGoogleAd";
export default function SideAd() {
  return (
    useLocation().pathname === "/" && (
      <div className="absolute top-[320px] right-[-107px]">
        <VerticalGoogleAd />
      </div>
    )
  );
}
