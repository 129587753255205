import BackLogo from "../../assets/images/BackLogo.png";
export default function SectionBackWithName({ pageName }) {
  return (
    <div className=" w-full  h-[156px]  bg-[#F4F4F4] flex justify-center items-center bg-opacity-80 ">
      <div
        className="w-[250.37px] h-[156px] flex justify-center items-center position-absolute right-0 top-0"
        style={{
          backgroundImage: `url(${BackLogo})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <h2 className="font-PoppinsBold  text-TextColor text-[16px] sm:text-[22px]">
          {pageName}
        </h2>
      </div>
    </div>
  );
}
