import { FormWrapper } from "../../Components/RegisterComponents/FormWrapper";
import { ReactComponent as JustLogoWithoutName } from "../../assets/icons/JustLogoWithoutName.svg";
import { ReactComponent as InputPhoneIcon } from "../../assets/icons/InputPhone.svg";

export function ResetPasswordForm({ Phone, formik }) {
  return (
    <FormWrapper title="User Details">
      <div className="flex flex-col items-center mt-[40px] w-full h-full ">
        <JustLogoWithoutName />

        <h1 className="text-TextColor  mt-[40px] ">
          Fill your mobile number and we will send <br /> you a 4 digit pin to
          change your <br /> password
        </h1>
        <div className="space-y-4 mt-[29px]  w-full h-full max-w-[340px] text-left">
          <div className="relative text-gray-600 focus-within:text-gray-400">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <InputPhoneIcon />
            </span>

            <label htmlFor="Phone" className="sr-only">
              Mobile Number
            </label>
            <input
              type="text"
              value={Phone}
              name="Phone"
              id="Phone"
              placeholder="Mobile Number"
              className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
              onChange={(e) => {
                formik.setFieldValue("Phone", e.target.value);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.touched.Phone && formik.errors.Phone ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Phone}
            </span>
          ) : null}
        </div>
      </div>
    </FormWrapper>
  );
}
