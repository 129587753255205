import { useQuery, useMutation } from "@tanstack/react-query";
import useAxios from "../Utils/useAxios";
import UsePaymob from "../Utils/UsePaymob";

//for ad banners top start comment
export const useFetchAdBanners = () => {
  const api = useAxios();

  return useQuery(["AdBanners"], async () => {
    const { data } = await api.get("/banners_ads.php");
    return data;
  });
};
//for ad banners top end comment

//for ad details start comment
export const useFetchAdDetails = ({ AdId, key }) => {
  const api = useAxios();

  return useQuery(["AdDetails", { AdId, key }], async () => {
    const Ad = {
      id: AdId,
      key: key,
    };
    const { data } = await api.post("/ad_list.php", JSON.stringify(Ad));
    return data;
  });
};

export const useMutateAddtoFavList = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (Payl) => {
      const { data } = await api.post(
        "/add_to_favourite_list.php",
        JSON.stringify(Payl)
      );
      return data;
    },
  });
};

export const useFetchModalBuyWithPaymentGuarantee = (isfarmgharSelfStock) => {
  const api = useAxios();

  return useQuery(["ModalBuyWithPaymentGuarantee"], async () => {
    const { data } = await api.post(
      `${
        isfarmgharSelfStock
          ? "/self_stock_banner.php"
          : "/delivery_ad_banner.php"
      }`
    );
    return data;
  });
};
//for ad details end comment

//for blogs start comment
export const useFetchAllBlogsPosts = ({ page }) => {
  const api = useAxios();

  return useQuery(["AllBlogsPosts", { page }], async () => {
    const { data } = await api.post(
      "/web_seo_blogs.php",
      JSON.stringify({ page: page })
    );
    return data;
  });
};

export const useFetchBlogPost = ({ BlogPostSlug }) => {
  const api = useAxios();

  return useQuery(["BlogPost", { BlogPostSlug }], async () => {
    const { data } = await api.post(
      "/web_seo_blog_page.php",
      JSON.stringify({ slug: BlogPostSlug })
    );
    return data;
  });
};
//for blogs end comment

//for categories seo content start comment
export const useFetchCategorySeoContent = ({ CatId }) => {
  const api = useAxios();

  return useQuery(["CategorySeoContent", { CatId }], async () => {
    if (!CatId)
      return {
        status: false,
        code: 400,
        message: "Category Id Required",
        data: [],
        records: 0,
        pages: 0,
        qurbani_filter: 0,
      };
    const { data } = await api.post(
      "/web_seo_category_page.php",
      JSON.stringify({ category_id: CatId })
    );
    return data;
  });
};
//for categories seo content end comment

//for filter start comment

export const useFetchFilterDistricts = ({ ProvinceId }) => {
  const api = useAxios();
  return useQuery(["FilterDistricts", { ProvinceId }], async () => {
    const FilterDistrict = {
      province: ProvinceId,
    };
    if (!ProvinceId)
      return {
        status: false,
        code: 400,
        message: "Provice Id Required",
        data: [],
        records: 0,
        pages: 0,
        qurbani_filter: 0,
      };
    const { data } = await api.post(
      "/list_district.php",
      JSON.stringify(FilterDistrict)
    );
    return data;
  });
};

export const useMutateFilterDistricts = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post("/list_district.php", values);
      return data;
    },
  });
};

export const useFetchFilterTehsils = ({ DistrictId }) => {
  const api = useAxios();

  return useQuery(["FilterTehsils", { DistrictId }], async () => {
    const FilterTehsil = {
      district: DistrictId,
    };
    if (!DistrictId)
      return {
        status: false,
        code: 400,
        message: "District Id Required",
        data: [],
        records: 0,
        pages: 0,
        qurbani_filter: 0,
      };
    const { data } = await api.post(
      "/list_tehsil.php",
      JSON.stringify(FilterTehsil)
    );
    return data;
  });
};
export const useMutateFilterTehsils = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post("/list_tehsil.php", values);
      return data;
    },
  });
};

// i am using to fetch all animals from multiple apis

// export const useFetchFilterAllCategoriesAnimals = () => {
//   const api = useAxios();

//   return useQuery(["FilterAllCategoriesAnimals"], async () => {
//     const FilterLiveStock = {
//       parent_category: 6,
//     };

//     const LiveStockData = api.post(
//       "/list_category_v2.php",
//       JSON.stringify(FilterLiveStock)
//     );

//     const FarmServicesData = api.post("/farm_service.php");
//     const FarmEquipmentsData = api.post("/farm_equipment.php");
//     const FarmProductsData = api.post("/farm_products.php");
//     const OtherAnimalsData = api.post("/other_animals.php");

//     return Promise.all([
//       LiveStockData,
//       FarmServicesData,
//       FarmEquipmentsData,
//       FarmProductsData,
//       OtherAnimalsData,
//     ]).then(function (values) {
//       return values;
//     });
//   });
// };

export const useFetchChildCategories = ({ CatId }) => {
  const api = useAxios();

  return useQuery(["ChildCategories", { CatId }], async () => {
    const Filter = {
      parent_category: CatId,
    };
    if (!CatId)
      return {
        status: false,
        code: 400,
        message: "Parent Category Required",
        data: [],
        records: 0,
        pages: 0,
        qurbani_filter: 0,
      };
    const { data } = await api.post(
      "/child_categories.php",
      JSON.stringify(Filter)
    );
    return data;
  });
};

export const useFetchChildCategoriesPostAd = ({ CatId }) => {
  const api = useAxios();

  return useQuery(["ChildCategoriesPostAd", { CatId }], async () => {
    const Filter = {
      parent_category: CatId,
    };
    if (!CatId)
      return {
        status: false,
        code: 400,
        message: "Parent Category Required",
        data: [],
        records: 0,
        pages: 0,
        qurbani_filter: 0,
      };
    const { data } = await api.post(
      "/list_category.php",
      JSON.stringify(Filter)
    );
    return data;
  });
};
export const useFetchFilterBreeds = ({ SubCateId }) => {
  const api = useAxios();

  return useQuery(["FilterBreeds", { SubCateId }], async () => {
    const FilterBreeds = {
      category: SubCateId,
    };

    if (!SubCateId)
      return {
        status: false,
        code: 400,
        message: "SubCateId Category Required",
        data: [],
        records: 0,
        pages: 0,
        qurbani_filter: 0,
      };
    const { data } = await api.post(
      "/list_breed.php",
      JSON.stringify(FilterBreeds)
    );
    return data;
  });
};

//for filter end comment

//for get id from slug category start comment
export const useFetchGetIdFromSlugCategory = ({ CatSlug }) => {
  const api = useAxios();
  return useQuery(["GetIdFromSlugCategory", { CatSlug }], async () => {
    const slugy = {
      slug: CatSlug,
    };
    const { data } = await api.post("/slugs.php", JSON.stringify(slugy));
    return data;
  });
};

//for get id from slug category end comment

//for home page start comment

export const useFetchHomePageServices = () => {
  const api = useAxios();
  return useQuery(["homePageServices"], async () => {
    const { data } = await api.get("/services.php");
    return data.data;
  });
};

// export const useMutateHomeCategories = (values) => {
//   const api = useAxios();
//   return useMutation({
//     mutationFn: async () => {
//       const { data } = await api.post("/home_ads_list.php", values);
//       return data;
//     },
//   });
// };
export const useFetchHomeAdsByCategory = () => {
  const api = useAxios();
  return useQuery(["homePageAdsByCategoryNew"], async () => {
    const { data } = await api.post("/home_ads_list_web.php");
    return data;
  });
};

export const useFetchHomePageCategories = () => {
  const api = useAxios();
  return useQuery(["homePageCategories"], async () => {
    const { data } = await api.post("/home_ads_list_web.php");

    return data.data2;
  });
};

export const useFetchHomePageAdsByCategory = () => {
  const api = useAxios();
  return useQuery(["homePageAdsByCategory"], async () => {
    const { data } = await api.post("/ads_list.php");
    return data;
  });
};

//for home page end comment

//for meta tags start comment

export const useFetchMetaDataAboutUs = () => {
  const api = useAxios();

  return useQuery(["MetaDataAboutUs"], async () => {
    const { data } = await api.post("/web_seo_about.php");
    return data;
  });
};

export const useFetchMetaDataHome = () => {
  const api = useAxios();

  return useQuery(["MetaDataHome"], async () => {
    const { data } = await api.post("/web_seo_home.php");
    return data;
  });
};

//for meta tags end comment

//for fetch service by slug start comment

export const useFetchServiceBySlug = ({ ServiceSlug }) => {
  const api = useAxios();

  return useQuery(["ServiceBySlug", { ServiceSlug }], async () => {
    const { data } = await api.post(
      "/web_seo_service_page.php",
      JSON.stringify({ slug: ServiceSlug })
    );
    return data;
  });
};

//for fetch service by slug end comment

//for single category details start comment

export const useFetchSingleCategoryDetails = ({
  CatId,
  page,
  sortBy,
  Province,
  District,
  Tehsil,
  Category,
  MaxPrice,
  Breed,
  IsDelivery,
  IsVerified,
  IsPregnant,
  MinWeight,
  MinAge,
  MaxAge,
  Castrated,
  Query,
  latitude,
  longitude,
}) => {
  const api = useAxios();

  return useQuery(
    [
      "SingleCategoryDetails",
      {
        CatId,
        page,
        sortBy,
        Province,
        District,
        Tehsil,
        Category,
        MaxPrice,
        Breed,
        IsDelivery,
        IsVerified,
        IsPregnant,
        MinWeight,
        MinAge,
        MaxAge,
        Castrated,
        Query,
        latitude,
        longitude,
      },
    ],
    async () => {
      const filterCategory = {
        parent_category: CatId,
        page: page,
        price: sortBy,
        category: Category,
        tehsil: Tehsil,
        district: District,
        province: Province,
        breed: Breed,
        max_price: MaxPrice,
        min_weight: MinWeight,
        is_delivery: IsDelivery,
        castrated: Castrated,
        pregnant: IsPregnant,
        is_verified: String(CatId) === "9999" ? "1" : IsVerified,
        max_age: MaxAge,
        min_age: MinAge,
        s: Query,
        latitude,
        longitude,
      };
      if (!CatId) {
        return {
          status: true,
          code: 200,
          message: "No Ad Found",
          data: [],
          records: 0,
          pages: 0,
          qurbani_filter: "0",
        };
      }
      const { data } = await api.post(
        "/ads_list_v2.php",
        JSON.stringify(filterCategory)
      );
      return data;
    }
  );
};

//for single category details end comment

//for user account start comment

//needed a key
export const useAccountGetUserDetails = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (key) => {
      const { data } = await api.post("/user_details.php", {
        key,
      });
      return data;
    },
  });
};

export const useAccountGetUserProfilePhotoUpload = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post("/file_upload_s3.php", values);
      return data;
    },
  });
};
//needed a key
export const useAccountGetUserProfilePhotoUpdate = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post("/profile_image_update.php", values);
      return data;
    },
  });
};
//needed a key
export const useAccountGetUserProfileEdit = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post("/user_edit.php", values);
      return data;
    },
  });
};

export const useAccountDeleteUser = () => {
  const api = useAxios();
  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post("/user_delete.php", values);
      return data;
    },
  });
};

//for user account end comment

//for forget password start comment

export const usePassReset = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post(
        "/user_pass_reset.php",
        JSON.stringify(values)
      );
      return data;
    },
    onSuccess: async (data) => {
      if (data.code !== 200) return;
      const { key } = data.data;
      await api.post("/user_otp_send_v3.php", { key, hashCode: "" });
    },
  });
};

export const usePassResetResendCode = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (key) => {
      const { data } = await api.post("/user_otp_send_v3.php", {
        key,
        hashCode: "",
      });
      return data;
    },
  });
};

export const usePassResetVerifyUser = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post(
        "/user_otp_verify.php",
        JSON.stringify(values)
      );
      return data;
    },
  });
};

//needed a key
export const usePassResetChangePassword = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post(
        "/user_pw_edit.php",
        JSON.stringify(values)
      );
      return data;
    },
  });
};

//for forget password end comment

//for login start comment

export const useLoginUser = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post(
        "/user_login.php",
        JSON.stringify(values)
      );
      return data;
    },
  });
};

//for login end comment

//for register start comment

export const useCreateUser = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post("/user_signup_v1.php", values);
      return data;
    },
    onSuccess: async (data) => {
      if (data.code !== 200) return;
      const { key } = data.data;
      await api.post("/user_otp_send_v3.php", { key, hashCode: "" });
    },
  });
};

export const useCreateResendCode = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (key) => {
      const { data } = await api.post("/user_otp_send_v3.php", {
        key,
        hashCode: "",
      });
      return data;
    },
  });
};

export const useCreateVerifyUser = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post(
        "/user_otp_verify.php",
        JSON.stringify(values)
      );
      return data;
    },
  });
};

//for register end comment

//for my favorites ads start

export const useFetchMyFavouritesAdsList = (payl) => {
  const api = useAxios();

  return useQuery(["MyFavouritesAdsList", { payl }], async () => {
    const { data } = await api.post(
      "/my_favourite_list.php",
      JSON.stringify(payl)
    );
    return data;
  });
};

//for my favorites ads end

//for my ads user list

export const useFetchShowAllAdsUser = (payl) => {
  const api = useAxios();

  return useQuery(["ShowAllAdsUser", { payl }], async () => {
    const { data } = await api.post("/ads_list_user.php", JSON.stringify(payl));
    return data;
  });
};

//for my ads user list end

//for post ad start

export const useMutateCreateAd = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (payl) => {
      const { data } = await api.post(
        "/myad_create_v3.php",
        JSON.stringify(payl)
      );
      return data;
    },
  });
};

export const useMutateMyAdDetails = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (payl) => {
      const { data } = await api.post("/myad_detail.php", JSON.stringify(payl));
      return data;
    },
  });
};

export const useMutateMyAdData = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (payl) => {
      const { data } = await api.post("/myad_data.php", JSON.stringify(payl));
      return data;
    },
  });
};

export const useFetchBuyStarAdBanner = () => {
  const api = useAxios();

  return useQuery(["BuyStarAdBanner"], async () => {
    const { data } = await api.get("/star_ad_acc_info.php");
    return data;
  });
};

export const useFetchPostAdFarmChildCategories = ({ CatId }) => {
  const api = useAxios();
  const Filter = {
    parent_category: CatId,
  };

  return useQuery(["PostAdFarmChildCategorie", { CatId }], async () => {
    if (!CatId)
      return {
        status: false,
        code: 400,
        message: "Parent Category Required",
        data: [],
        records: 0,
        pages: 0,
        qurbani_filter: 0,
      };
    const { data } = await api.post(
      "/main_category_list.php",
      JSON.stringify(Filter)
    );
    return data;
  });
};

//for post ad end

//for my ads start

export const useFetchMyAdsAdsList = (payl) => {
  const api = useAxios();

  return useQuery(["MyAdsAdsList", { payl }], async () => {
    const { data } = await api.post("/myads_list.php", JSON.stringify(payl));
    return data;
  });
};

export const useFetchMyAdsStats = (payl) => {
  const api = useAxios();

  return useQuery(["MyAdsStats", { payl }], async () => {
    const { data } = await api.post("/my_ad_stats.php", JSON.stringify(payl));
    return data;
  });
};

export const useFetchMyAdsPaidAdBanner = (payl) => {
  const api = useAxios();

  return useQuery(["MyAdsPaidAdBanner", { payl }], async () => {
    const { data } = await api.post(
      "/paid_ad_banner.php",
      JSON.stringify(payl)
    );
    return data;
  });
};

export const useMutateDeleteAd = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (payl) => {
      const { data } = await api.post(
        "/myad_status_update.php",
        JSON.stringify(payl)
      );
      return data;
    },
  });
};

export const useMutateMyAdCreateDone = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (payl) => {
      const { data } = await api.post(
        "/myad_create_done.php",
        JSON.stringify(payl)
      );
      return data;
    },
  });
};

// export const useStarAdPackage = () => {
//   const api = useAxios();

//   return useQuery(["ad_id"], async () => {
//     const { data } = await api.post("/v3/star-ad-packages.php");
//     return data;
//   });
// };

export const useStarAdPackage = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post("/star-ad-packages.php", values);
      return data;
    },
  });
};

//for my ads end

//for qurbani work start
export const useFetchQurbaniPackageCategory = (payl) => {
  const api = useAxios();

  return useQuery(["QurbaniPackageCategory", { payl }], async () => {
    const { data } = await api.post(
      "/qurbani_package_categories.php",
      JSON.stringify(payl)
    );
    return data;
  });
};

export const useFetchQurbaniPackages = (payl) => {
  const api = useAxios();

  return useQuery(
    ["QurbaniPackages", { payl }],
    async () => {
      const { data } = await api.post(
        "/qurbani_packages.php",
        JSON.stringify(payl)
      );
      return data;
    },
    {
      cacheTime: 0, // Disable caching for this query
    }
  );
};

export const useMutateOrderQurbaniPackage = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (payl) => {
      const { data } = await api.post(
        "/package-order.php",
        JSON.stringify(payl)
      );
      return data;
    },
  });
};

export const useFetchQurbaniPickUpAddress = () => {
  const api = useAxios();

  return useQuery(["QurbaniPickUpAddress"], async () => {
    const { data } = await api.post("/qurbani-pickup-adress.php");
    return data;
  });
};

export const useMutateTrackQurbani = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (payl) => {
      const { data } = await api.post(
        "/qurbani_order_tracking.php",
        JSON.stringify(payl)
      );
      return data;
    },
  });
};

export const useFetchQurbaniSupportInfo = () => {
  const api = useAxios();

  return useQuery(["QurbaniSupportInfo"], async () => {
    const { data } = await api.post("/qurbani_support_info.php");
    return data;
  });
};

export const useMutateQurbaniAbandonedCart = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (payl) => {
      const { data } = await api.post(
        "/qurbani_abandon_cart.php",
        JSON.stringify(payl)
      );
      return data;
    },
  });
};

export const useFetchQurbaniFAQs = () => {
  const api = useAxios();

  return useQuery(["QurbaniFAQs"], async () => {
    const { data } = await api.post("/qurbani_faqs.php");
    return data;
  });
};

export const useFetchQurbaniSEOContent = ({ QurbaniSlug }) => {
  const api = useAxios();

  return useQuery(["QurbaniSEOContent", { QurbaniSlug }], async () => {
    const { data } = await api.post(
      "/qurbani_package_details.php",
      JSON.stringify({ slug: QurbaniSlug })
    );
    return data;
  });
};
//for qurbani work end

//for Location work start
export const useMutateLatLongdata = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (payl) => {
      const { data } = await api.post(
        "/get_lat_long_data.php",
        JSON.stringify(payl)
      );
      return data;
    },
  });
};

//for Location work end

//for Paymob Payment Integration work

export const usePaymob_auth_token = () => {
  const api = UsePaymob();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post("/auth/tokens", values);
      return data;
    },
  });
};

export const usePaymob_order_registration = () => {
  const api = UsePaymob();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post("/ecommerce/orders", values);
      return data;
    },
  });
};

export const usePaymob_card_payment_key = () => {
  const api = UsePaymob();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post("/acceptance/payment_keys", values);
      return data;
    },
  });
};

export const useService_payment = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post("/service_payment.php", values);
      return data;
    },
  });
};

export const useTransaction_history = () => {
  const api = useAxios();

  return useMutation({
    mutationFn: async (values) => {
      const { data } = await api.post("/transaction_history.php", values);
      return data;
    },
  });
};

//for Paymob Payment Integration work ends here
