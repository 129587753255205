//make user auth context and use it in the app check if the user is logged in by checking the localstorage key
import { useNavigate } from "react-router-dom";
import { createContext, useState, useEffect } from "react";
const AuthContext = createContext();

export default AuthContext;

export const AuthContextProvider = ({ children }) => {
  const Navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(
    localStorage.getItem("key") ? true : false
  );
  const [user, setUser] = useState(null);

  useEffect(() => {
    const key = localStorage.getItem("key");
    if (key) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, [isAuth]);
  let logoutUser = () => {
    setIsAuth(false);
    setUser(null);
    localStorage.removeItem("key");
    localStorage.clear();
    Navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{ isAuth, setIsAuth, user, setUser, logoutUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};
