import { FormWrapper } from "./FormWrapper";

import { ReactComponent as JustLogoWithoutName } from "../../assets/icons/JustLogoWithoutName.svg";
// import { ReactComponent as InputFarmIcon } from "../../assets/icons/InputFarm.svg";
import { ReactComponent as InputPasswordIcon } from "../../assets/icons/InputPassword.svg";
import { ReactComponent as InputPersonIcon } from "../../assets/icons/InputPerson.svg";
import { ReactComponent as InputPhoneIcon } from "../../assets/icons/InputPhone.svg";

export function UserForm({
  Phone,
  Name,
  // FarmName,
  Password,
  ConfirmPassword,
  formik,
  updateFields,
}) {
  return (
    <FormWrapper title="User Details">
      <div className="flex flex-col items-center mt-[40px] w-full h-full ">
        <JustLogoWithoutName />

        <h1 className="text-TextColor font-bold  mt-[40px] ">
          Lets Join Our Community
        </h1>
        <div className="space-y-4 mt-[29px]  w-full h-full max-w-[340px] text-left">
          <div className="relative text-gray-600 focus-within:text-gray-400">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <InputPhoneIcon />
            </span>

            <label htmlFor="Phone" className="sr-only">
              Mobile Number
            </label>
            <input
              type="text"
              value={Phone}
              name="Phone"
              id="Phone"
              placeholder="Mobile Number"
              className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
              onChange={(e) => {
                updateFields({ Phone: e.target.value });
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <p className="text-[11px] text-center">
            e.g (03011234567 or 3011234567)
          </p>

          {formik.touched.Phone && formik.errors.Phone ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Phone}
            </span>
          ) : null}

          <div className="relative text-gray-600 focus-within:text-gray-400">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <InputPersonIcon />
            </span>
            <label htmlFor="Name" className="sr-only">
              Name
            </label>
            <input
              type="text"
              value={Name}
              name="Name"
              id="Name"
              placeholder="Name"
              className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
              onChange={(e) => {
                updateFields({ Name: e.target.value });
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.touched.Name && formik.errors.Name ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Name}
            </span>
          ) : null}
          {/* <div className="relative text-gray-600 focus-within:text-gray-400">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <InputFarmIcon />
            </span>
            <label htmlFor="FarmName" className="sr-only">
              Farm Name
            </label>
            <input
              type="text"
              value={FarmName}
              name="FarmName"
              id="FarmName"
              placeholder="Farm Name"
              className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
              onChange={(e) => {
                updateFields({ FarmName: e.target.value });
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.touched.FarmName && formik.errors.FarmName ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.FarmName}
            </span>
          ) : null} */}
          <div className="relative text-gray-600 focus-within:text-gray-400">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <InputPasswordIcon />
            </span>
            <label htmlFor="Password" className="sr-only">
              Password
            </label>
            <input
              type="password"
              value={Password}
              name="Password"
              id="Password"
              placeholder="Password"
              className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
              onChange={(e) => {
                updateFields({ Password: e.target.value });
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.touched.Password && formik.errors.Password ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Password}
            </span>
          ) : null}
          <div className="relative text-gray-600 focus-within:text-gray-400">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <InputPasswordIcon />
            </span>
            <label htmlFor="ConfirmPassword" className="sr-only">
              Confirm Password
            </label>
            <input
              type="password"
              value={ConfirmPassword}
              name="ConfirmPassword"
              id="ConfirmPassword"
              placeholder="Confirm Password"
              className="w-full h-[42px] rounded-[10px] text-[#39342F]  border-2 border-[rgba(0, 0, 0, 0.16)] focus:outline-none pl-10"
              onChange={(e) => {
                updateFields({ ConfirmPassword: e.target.value });
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.ConfirmPassword}
            </span>
          ) : null}
        </div>
      </div>
    </FormWrapper>
  );
}
