import { ReactComponent as ImpPostAdAlert } from "../../assets/icons/ImpPostAdAlert.svg";
import { ReactComponent as ImpPostAdAds } from "../../assets/icons/ImpPostAdAds.svg";
import { ReactComponent as ImpPostAdMoney } from "../../assets/icons/ImpPostAdMoney.svg";
import { ReactComponent as ImpPostAdTick } from "../../assets/icons/ImpPostAdTick.svg";

export function ImportantInformation() {
  return (
    <div className="flex flex-col  px-5 space-y-4 py-5">
      <h3 className="text-left font-PoppinsBold">
        Important Information for Sellers
      </h3>
      <section className="max-w-full w-full bg-[#EFA922]  flex  items-center rounded-[15px] bg-opacity-[0.15] h-auto p-2 overflow-hidden">
        <ImpPostAdAlert className="w-10 h-10" />
        <p className="w-[90%]  text-[14px] text-justify pl-2">
          Dear Seller, please read this information carefully before you
          advertise your animals for sale on farmGhar App.
        </p>
      </section>
      <section className="max-w-full w-full bg-white  border-2 border-gray-200 flex  items-center rounded-[15px] bg-opacity-[0.15] h-auto p-2 overflow-hidden">
        <ImpPostAdAds className="w-10 h-10" />
        <p className="w-[90%]  text-[14px] text-justify pl-2">
          Make sure all the information you have provided in your animal ad is
          correct. If we find that an ad is fake, we shall delete the ad, block
          the account and also report it to the Police to take the necessary
          legal action.
        </p>
      </section>
      <section className="max-w-full w-full  bg-white  border-2 border-gray-200  flex  items-center rounded-[15px] bg-opacity-[0.15] h-auto p-2 overflow-hidden">
        <ImpPostAdMoney className="w-10 h-10" />
        <p className="w-[90%]  text-[14px] text-justify pl-2">
          Do not ask the buyers to send you money in advance. The buyer should
          only pay you after you have given the animal to the buyer.
        </p>
      </section>
      <section className="max-w-full w-full  bg-white   border-2 border-gray-200 flex  items-center rounded-[15px] bg-opacity-[0.15] h-auto p-2 overflow-hidden">
        <ImpPostAdTick className="w-10 h-10" />
        <p className="w-[90%]  text-[14px] text-justify pl-2">
          To sell your animal quickly, make sure you provide as much photos and
          details as possible in your ad and ask a reasonable price.
        </p>
      </section>
      <p className="">
        Thank You for using{" "}
        <span className="text-Primary font-PoppinsBold">farmGhar</span> App!
      </p>

      <div className="flex items-center space-x-2">
        <input
          type="radio"
          name="doNotShowAgain"
          id="doNotShowAgain"
          className="w-4 h-4 accent-Primary "
          onChange={() => {
            localStorage.setItem("doNotShowAgainPostAd", true);
            window.location.reload();
          }}
        />
        <label htmlFor="doNotShowAgain" className="text-[14px]">
          Do not show this information again
        </label>
      </div>
    </div>
  );
}
