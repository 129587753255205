import { ReactComponent as FooterLogo } from "../../assets/logo-footer.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/icons/whatsapp.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/icons/youtube.svg";
import { useFetchHomePageCategories } from "../../Middlewares/Api";
import { Link } from "react-router-dom";
import { useContext } from "react";
import FilterAdsContext from "../../Context/FilterAdsContext";
import { useLocation } from "react-router-dom";
const Navigation = {
  menu: [
    { name: "Home", href: "/" },
    { name: "Qurbani 2023", href: "/qurbani" },
    { name: "Track Qurbani", href: "/track-your-qurbani" },
    { name: "Our Services", href: "/services" },
    { name: "About Us", href: "/about-us" },
    { name: "Blogs", href: "/blogs" },
    // { name: "news", href: "#news" },
    { name: "Contact Us", href: "/contact-us" },
    { name: "Transaction History", href: "account/transaction-history" },
  ],

  FarmGhar: [
    { name: "farmGhar Payment Details", href: "/farmGhar-payment-details" },
    // { name: "Help", href: "#help" },
    // { name: "Qurbani Terms & Conditions", href: "/qurbani/terms" },
    { name: "Terms & Conditions", href: "/terms-conditions" },
    { name: "Privacy Policy", href: "/privacy-policy" },
  ],
};
export default function Footer() {
  const location = useLocation();
  const { data } = useFetchHomePageCategories();
  const { setFilterAds } = useContext(FilterAdsContext);

  if (
    location.pathname.startsWith("/auth") ||
    location.pathname.startsWith("/account/post-ad") ||
    location.pathname.startsWith("/qurbani/checkout")
  ) {
    return null;
  }
  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <div className="w-full">
        <div className="xl:px-20 pb-6 lg:px-16 md:px-8 sm:px-5 pl-10">
          <div className="w-full pt-12 flex flex-col sm:flex-row space-y-2 justify-start">
            <div className="w-full sm:w-2/5 pr-6 sm:flex flex-col space-y-4 hidden">
              <Link to="/" className="w-[189px]">
                <FooterLogo />
              </Link>
              <p className="text-TextColor font-semibold text-[12px]">
                Copyright {new Date().getFullYear()} farmGhar. All <br /> Rights
                Reserved
              </p>
            </div>
            <div className="w-full sm:w-1/5 flex flex-col space-y-4">
              <h3 className="font-bold text-[16px] font-PoppinsBold text-TextColor mt-5 sm:mt-0">
                Menu
              </h3>
              <ul className="flex flex-col space-y-2">
                {Navigation.menu.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={`${item.href}`}
                      className="text-sm text-TextColor hover:text-gray-900"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full sm:w-1/5 flex flex-col space-y-4 ">
              <h3 className="text-TextColor font-bold text-[16px] font-PoppinsBold mt-5 sm:mt-0">
                Categories
              </h3>
              <ul className="flex flex-col space-y-2">
                {data?.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={`/${item.category_slug}`}
                      onClick={() =>
                        setFilterAds((prev) => ({
                          ...prev,
                          page: 1,
                        }))
                      }
                      className="text-sm text-TextColor hover:text-gray-900"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full sm:w-1/5 flex flex-col space-y-4">
              <h3 className="text-TextColor font-bold text-[16px] font-PoppinsBold mt-5 sm:mt-0">
                farmGhar
              </h3>
              <ul className="flex flex-col space-y-2">
                {Navigation.FarmGhar.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={`${item.href}`}
                      className="text-sm text-TextColor hover:text-gray-900"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
              <h4 className="font-bold text-[12px] font-PoppinsBold">
                Follow US
              </h4>
              <div className="flex  space-x-2 items-center pb-[50px] ">
                <a
                  href="https://www.facebook.com/official.farmGhar/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.youtube.com/channel/UC0Km80c5qBFojTcG9SMRoZg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <YoutubeIcon />
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=923019681454"
                  target="_blank"
                  rel="noreferrer"
                >
                  <WhatsappIcon />
                </a>
                <a
                  href="https://instagram.com/farmghar?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:border-0 border-t py-6">
        <p className=" sm:hidden block text-[12px] text-TextColor pl-10">
          Copyright {new Date().getFullYear()} farmGhar. All Rights Reserved
        </p>
      </div>
    </footer>
  );
}
