export default function NameContact({ formik }) {
  return (
    <div className="pt-4 pl-4">
      <h3 className="font-PoppinsBold">Personal Information</h3>

      <div className="grid grid-cols-2 gap-4 w-full h-full p-3">
        <div className="flex flex-col justify-center mt-1 col-span-2">
          <label htmlFor="FullName" className="block text-sm  text-TextColor">
            Full Name *
          </label>
          <input
            id="FullName"
            name="FullName"
            type="text"
            className="placeholder:text-[10px] sm:placeholder:text-[16px] w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
            value={formik.values.FullName ? formik.values.FullName : ""}
            label="FullName"
            placeholder="Ahmed"
            onChange={(e) => {
              formik.setFieldValue("FullName", e.target.value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.FullName && formik.errors.FullName ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.FullName}
            </span>
          ) : null}
        </div>
        <div className="flex flex-col justify-center mt-1 col-span-2">
          <label
            htmlFor="ContactNumber"
            className="block text-sm  text-TextColor"
          >
            Contact Number *
          </label>
          <input
            id="ContactNumber"
            name="ContactNumber"
            type="text"
            className=" placeholder:text-[10px] sm:placeholder:text-[16px] w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
            value={
              formik.values.ContactNumber ? formik.values.ContactNumber : ""
            }
            label="ContactNumber"
            placeholder="Mobile Number"
            onChange={(e) => {
              formik.setFieldValue("ContactNumber", e.target.value);
            }}
            onBlur={formik.handleBlur}
          />
          <span className="text-[11px] text-TextColor tracking-widest	">
            03101111222
          </span>
          {formik.touched.ContactNumber && formik.errors.ContactNumber ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.ContactNumber}
            </span>
          ) : null}
        </div>
        {/* <div className="flex flex-col justify-center mt-1 mb-[15px]">
          <label
            htmlFor="AlternateContactNumber"
            className="block text-sm  text-TextColor"
          >
            Alternative Contact
          </label>
          <input
            id="AlternateContactNumber"
            name="AlternateContactNumber"
            type="text"
            className="placeholder:text-[10px] sm:placeholder:text-[16px] w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
            value={
              formik.values.AlternateContactNumber
                ? formik.values.AlternateContactNumber
                : ""
            }
            label="AlternateContactNumber"
            placeholder="Alternate Contact Number"
            onChange={(e) => {
              formik.setFieldValue("AlternateContactNumber", e.target.value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.AlternateContactNumber &&
          formik.errors.AlternateContactNumber ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.AlternateContactNumber}
            </span>
          ) : null}
        </div> */}
      </div>
    </div>
  );
}
