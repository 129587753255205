import {
  //  React,
  Component,
} from "react";

class SquareGoogleAd extends Component {
  // componentDidMount() {
  //   if (process.env.REACT_APP_SERVER_TYPE === "production") {
  //     try {
  //       (window.adsbygoogle = window.adsbygoogle || []).push({});
  //     } catch (error) {
  //       //
  //     }
  //   }
  // }

  render() {
    return null;
    // return (
    //   <div id="ad-container" className="mx-auto py-2">
    //     <ins
    //       className="adsbygoogle"
    //       style={{ display: "inline-block", width: "300px", height: "250px" }}
    //       data-ad-client="ca-pub-9191039315895871"
    //       data-ad-slot="7341319223"
    //     ></ins>
    //   </div>
    // );
  }
}

export default SquareGoogleAd;
