import { ReactComponent as TwitterShareIcon } from "../../../assets/icons/TwitterShare.svg";

import { ReactComponent as LinkedinShareIcon } from "../../../assets/icons/LinkedinShare.svg";

import { ReactComponent as WhatsappShareIcon } from "../../../assets/icons/WhatsappShare.svg";

import { ReactComponent as FacebookShareIcon } from "../../../assets/icons/FacebookShare.svg";

export default function ShareButtons({ slug, service, blog, qurbani }) {
  const url = service
    ? `https://${document.location.hostname}/${service && "services"}/${
        slug ?? ""
      }`
    : blog
    ? `https://${document.location.hostname}/${blog && "blogs"}/${slug ?? ""}`
    : `https://${document.location.hostname}/${qurbani && "qurbani"}/${
        slug ?? ""
      }`;
  return (
    <div className="flex items-center justify-end">
      <div className="flex items-center">
        <div className="mr-[10px]">
          <a
            href={`https://twitter.com/intent/tweet?text=${url}`}
            target="_blank"
            rel="noreferrer"
          >
            <TwitterShareIcon />
          </a>
        </div>

        <div className="mr-[10px]">
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinShareIcon />
          </a>
        </div>
        <div className="mr-[10px]">
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
            target="_blank"
            rel="noreferrer"
          >
            <FacebookShareIcon />
          </a>
        </div>
        <div className="mr-[10px]">
          <a
            href={`https://api.whatsapp.com/send?text=${url}`}
            target="_blank"
            rel="noreferrer"
          >
            <WhatsappShareIcon />
          </a>
        </div>
      </div>
    </div>
  );
}
