import { useOutletContext } from "react-router-dom";
import QurbaniFaqs from "../Components/QurbaniFaqs";

const content = `
<p>Welcome to <a href="https://www.farmghar.com/"><strong>farmGhar</strong></a> Qurbani Services 2023. Eid Ul Azha is celebrated by Muslims all over the world, and farmGhar completely understands the importance of Qurbani. We aim to provide a seamless and hassle-free Qurbani experience to all people in Lahore, ensuring that your sacrifice is carried out with utmost care and adherence to Islamic principles. Moreover, eid qurbani is a meaningful act that includes:</p>

 

<p>&nbsp;</p>

 

<ul>
<li>Obedience to Allah</li>
<li>Gratitude and Remembrance</li>
<li>Sharing and Generosity</li>
<li>Spiritual Purification</li>
<li>Build the Faith and Brotherhood</li>
</ul>

 

<p>&nbsp;</p>

 

<p>farmGhar eid Qurbani will be performed according to the Sharia rules, keeping the following protocols in mind:</p>

 

<p>&nbsp;</p>

 

<ul>
<li>Shariah-compliant</li>
<li>Guaranteed weight</li>
<li>Organic feeding</li>
<li>Vaccinated animals</li>
<li>Hygiene</li>
</ul>

 

<p>&nbsp;</p>

 

<p><strong>Following are the Qurbani online packages 2023 </strong></p>

 

<p>Goat package: 1</p>

 

<p>Meat weight: 11-13 kg</p>

 

<p>Price: Rs: 47,000/-</p>

 

<p>Discounted price: 44,500/-</p>

 

<p>&nbsp;</p>

 

<p>Goat package: 2</p>

 

<p>Meat weight: 14- 16 kg</p>

 

<p>Price: Rs: 55,500/-</p>

 

<p>Discounted price: 52,300/-</p>

 

<p>&nbsp;</p>

 

<p>Cow package: 1</p>

 

<p>Cow hissa: 14-16 kg</p>

 

<p>Price: 27,900/-</p>

 

<p>Discounted price: 24,900/-</p>

 

<p>&nbsp;</p>

 

<p>Why Choose farmGhar for Qurbani 2023?</p>

 

<p>We aim to provide the best <a href="https://www.farmghar.com/collections/cow-farmghar-onlinequrbani-cow"><strong>Qurbani online</strong></a> services, including the following aspects.</p>

 

<p>&nbsp;</p>

 

<p><strong>Premium Quality Animals</strong></p>

 

<p>We take immense pride in offering only the finest and healthiest animals for Qurbani. Our livestock is carefully selected from trusted sources, ensuring that they are well-nourished, disease-free, and meet the required standards for sacrifice.</p>

 

<p>&nbsp;</p>

 

<p><strong>Ethical Treatment: </strong></p>

 

<p>At farmGhar, we prioritise the ethical treatment of animals. Our livestock is raised and kept in spacious, clean, and comfortable environments. Moreover, we provide them with proper nutrition and veterinary care. We ensure that our animals are treated with respect and compassion.</p>

 

<p>&nbsp;</p>

 

<p><strong>Convenient Online Booking: </strong></p>

 

<p>With farmGhar, booking your Qurbani animal is just a few clicks away. Our user-friendly website allows you to browse various packages, select your preferred package according to the meat weight and price, and make a secure online payment. It&#39;s quick, convenient, and saves you valuable time.</p>

 

<p>&nbsp;</p>

 

<p><strong>Professional Slaughtering Services: </strong></p>

 

<p>The team at farmGhar consists of experienced and skilled butchers who adhere to Islamic principles when performing the sacrifice. The Qurbani will be performed after Namaz e eid. However, we take pride in following the proper procedures and maintaining high hygiene standards.&nbsp;</p>

 

<p>&nbsp;</p>

 

<p><strong>Convenient Delivery: </strong></p>

 

<p>Looking for Qurbani near me? Once your Qurbani animal is sacrificed, we offer a hassle-free delivery and pick-up service. Our team takes utmost care in handling the meat and ensures that it reaches you <a href="https://en.wikipedia.org/wiki/Hygiene">hygienically</a> and timely, packed and labelled.</p>

 

<p>&nbsp;</p>

 

<p><strong>Qurbani Donation: </strong></p>

 

<p>farmGhar believes in the spirit of generosity during the blessed occasion of Eid al-Adha. With your Qurbani, we contribute a portion of the meat to those in need, ensuring that the less fortunate members of our society can also partake in the joy of this auspicious occasion.</p>

 

<p>&nbsp;</p>

 

<p><strong>Excellent Customer Support: </strong></p>

 

<p>We value our customers, and their satisfaction is our top priority. Our dedicated customer support team can assist you with queries or concerns throughout the Qurbani process. Feel free to contact us at 0304-111-0-434 or WhatsApp us at 0301-101-8-207.</p>

 

<p>&nbsp;</p>

 

<p><strong>Make farmGhar your Qurbani Partner now!</strong></p>

 

<p>Don&#39;t miss the opportunity to perform your Qurbani with farmGhar, where quality, convenience, and compassion come together. Visit our website or download the <strong><em><a href="https://play.google.com/store/apps/details?id=com.farmghar.app&amp;hl=en&amp;gl=US&amp;pli=1">farmGhar App</a> </em></strong>to reserve your Qurbani package today.</p>

 

<p>&nbsp;</p>

 

<p><em>Eid Mubarak - May this Eid al-Adha bring you joy, peace, and blessings!</em></p>
`;

export default function QurbaniServices() {
  const {
    qurbaniPackageCategory,
    QurbaniPackages,
    CatId,
    setCatId,
    navigate,
    handleAddToCart,
    cartItems,
    handleShowToast,
    setToastMessage,
    AdCallIcon,
    AdWhatsappIcon,
    supportInfo,
  } = useOutletContext();
  return (
    <>
      <div className="my-4 flex space-x-2 text-[12px] ">
        <div className="rounded-[14px] bg-gray-200 w-auto p-2 h-auto flex justify-center items-center text-center">
          {Array.isArray(QurbaniPackages?.data) && (
            <span className="text-[#0C2302] ">
              {QurbaniPackages?.data.length}{" "}
            </span>
          )}
        </div>
        <div className="space-x-2 lg-[800px]:space-x-5  items-center font-bold flex text-[12px] lg-[800px]:text-[18px]  whitespace-nowrap overflow-x-auto scrollbar">
          {qurbaniPackageCategory &&
            qurbaniPackageCategory?.data.map((category) => (
              <div
                key={category.package_category_id}
                className={`inline-block ${
                  CatId === category.package_category_id ? "check" : null
                }`}
              >
                <button
                  onClick={() => {
                    setCatId(category.package_category_id);
                  }}
                  className={`${
                    CatId === category.package_category_id
                      ? "text-Primary font-bold"
                      : "text-TextColor font-normal"
                  }`}
                >
                  {category.name}
                </button>
              </div>
            ))}
          {qurbaniPackageCategory?.data?.length > 0 && (
            <div
              key={"0"}
              className={`inline-block ${CatId === "0" ? "check" : null}`}
            >
              <button
                onClick={() => {
                  setCatId("0");
                }}
                className={`${
                  CatId === "0"
                    ? "text-Primary font-bold"
                    : "text-TextColor font-normal"
                }`}
              >
                All
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2  gap-6 lg:grid-cols-3  pt-2">
        {Array.isArray(QurbaniPackages?.data)
          ? QurbaniPackages?.data?.map((item, index) => (
              //eslint-disable-next-line
              <div
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/qurbani/${item.slug}`);
                }}
              >
                <div
                  // commented because we are not using ads for now
                  //       className={`
                  // rounded-[13px] border border-[#DBDED9] md:w-[214px]  lg:w-[260px] h-[310px]
                  // `}
                  className={`
rounded-[13px] border border-[#DBDED9]  w-[260px] h-[370px] relative BtnShowWhenHoverCard cursor-pointer
`}
                >
                  <div className={"rounded-[8px] mt-[6.77px] mx-[5.95px] "}>
                    <div className={" h-[200px]"}>
                      <img
                        src={item?.image && item?.image}
                        className={`  rounded-[9px] w-full h-full
     `}
                        alt={item?.package_name && item?.package_name}
                      />
                    </div>
                  </div>
                  {/* <p className="text-[12px] font-PoppinsBold absolute top-[39px] left-[6px] p-1 bg-black text-white rounded-r-xl">
                    {getShortName(item?.package_name)}
                  </p> */}
                  <h2 className="text-[16px] font-PoppinsBold m-[12.51px]">
                    {item?.package_name}
                  </h2>
                  <div className=" m-[12.51px]  flex justify-between">
                    <div className="flex justify-between flex-col">
                      <p className=" text-[12px]  text-[#39342F] text-opacity-50 lg:text-[13px] font-PoppinsBold ">
                        Price
                      </p>
                      <p className=" text-[12px]  text-[#39342F] lg:text-[13px] font-PoppinsBold ">
                        PKR {item?.price}
                      </p>
                    </div>
                    <div className="flex justify-between flex-col">
                      <p className=" text-[12px]  text-[#39342F] text-opacity-50 lg:text-[13px] font-PoppinsBold ">
                        Weight
                      </p>
                      <p className=" text-[12px]  text-[#39342F] lg:text-[13px] font-PoppinsBold  ">
                        {item?.description}
                      </p>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        // navigate("/qurbani/order", {
                        //   state: { item: item },
                        // });
                        const found = cartItems.find((cartItem) => {
                          if (
                            parseInt(cartItem.id) === parseInt(item.package_id)
                          ) {
                            return cartItem;
                          }
                        });
                        if (
                          parseInt(item.f_d_available_quantity) -
                            parseInt(found?.quantity ?? 0) >=
                          1
                        ) {
                          if (cartItems[0]?.SelectedEidDay === "1") {
                            item.SelectedEidDay = "1";
                            handleAddToCart(item);
                          } else if (cartItems[0]?.SelectedEidDay === "2") {
                            setToastMessage(
                              "Please remove Eid Day 2 items to add Eid Day 1 items."
                            );
                            handleShowToast();
                          }

                          if (cartItems.length === 0) {
                            item.SelectedEidDay = "1";
                            handleAddToCart(item);
                          }
                        } else {
                          setToastMessage("Out of stock.");
                          handleShowToast();
                        }
                      }}
                      className="bg-Primary w-[90%] py-2 flex mx-auto text-white font-PoppinsBold rounded-[50px]"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className="inline-block ml-4"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.01672 13.3333L9.26672 14.5833L11.9751 12.0833"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.34162 1.66667L4.32495 4.69167"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.6583 1.66667L15.675 4.69167"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.66663 6.54167C1.66663 5 2.49163 4.875 3.51663 4.875H16.4833C17.5083 4.875 18.3333 5 18.3333 6.54167C18.3333 8.33333 17.5083 8.20833 16.4833 8.20833H3.51663C2.49163 8.20833 1.66663 8.33333 1.66663 6.54167Z"
                          stroke="white"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M2.91663 8.33333L4.09163 15.5333C4.35829 17.15 4.99996 18.3333 7.38329 18.3333H12.4083C15 18.3333 15.3833 17.2 15.6833 15.6333L17.0833 8.33333"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                      <span className="text-center w-full mr-4">Buy Now</span>
                    </button>
                    {/* <button
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        // navigate("/qurbani/order", {
                        //   state: { item: item },
                        // });
                        const found = cartItems.find((cartItem) => {
                          if (
                            parseInt(cartItem.id) === parseInt(item.package_id)
                          ) {
                            return cartItem;
                          }
                        });
                        if (
                          parseInt(item.s_d_available_quantity) -
                            parseInt(found?.quantity ?? 0) >=
                          1
                        ) {
                          if (cartItems[0]?.SelectedEidDay === "2") {
                            item.SelectedEidDay = "2";
                            handleAddToCart(item);
                          } else if (cartItems[0]?.SelectedEidDay === "1") {
                            setToastMessage(
                              "Please remove Eid Day 1 items to add Eid Day 2 items."
                            );
                            handleShowToast();
                          }

                          if (cartItems.length === 0) {
                            item.SelectedEidDay = "2";
                            handleAddToCart(item);
                          }
                        } else {
                          setToastMessage("Out of stock.");
                          handleShowToast();
                        }
                      }}
                      className="bg-Primary w-[90%] py-2 flex mx-auto text-white font-PoppinsBold rounded-[50px]"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className="inline-block ml-4"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.01672 13.3333L9.26672 14.5833L11.9751 12.0833"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.34162 1.66667L4.32495 4.69167"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.6583 1.66667L15.675 4.69167"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.66663 6.54167C1.66663 5 2.49163 4.875 3.51663 4.875H16.4833C17.5083 4.875 18.3333 5 18.3333 6.54167C18.3333 8.33333 17.5083 8.20833 16.4833 8.20833H3.51663C2.49163 8.20833 1.66663 8.33333 1.66663 6.54167Z"
                          stroke="white"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M2.91663 8.33333L4.09163 15.5333C4.35829 17.15 4.99996 18.3333 7.38329 18.3333H12.4083C15 18.3333 15.3833 17.2 15.6833 15.6333L17.0833 8.33333"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                      <span className="text-center w-full mr-4">
                        Buy Now Eid Day 2
                      </span>
                    </button> */}
                  </div>
                </div>
              </div>
            ))
          : "No Packages Found"}
      </div>
      <div className="w-[249px] h-[157px] mt-6 xl:hidden border border-[rgba(12, 35, 2, 0.15)] rounded-[13px]  mx-auto block">
        <h4 className="font-PoppinsBold pl-4 pt-2">Contact Us</h4>
        <div className="flex flex-col items-center mt-[15px] space-y-2">
          <a
            href={`https://wa.me/92${supportInfo?.data?.whatsapp_number.replace(
              /-/g,
              ""
            )}`}
            target={"_blank"}
            rel="noreferrer"
            className="w-[230px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
          >
            <AdWhatsappIcon />
            <span className="ml-10">Whatsapp</span>
          </a>
          <div>
            <a
              href={`tel:${supportInfo?.data?.phone_number.replace(/-/g, "")}`}
              className="w-[230px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
            >
              <AdCallIcon />
              <span className="ml-12">Call Now</span>
            </a>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <h4 className="mb-2 font-PoppinsBold text-[18px]">
          Frequently Asked Questions
        </h4>
        <QurbaniFaqs />
      </div>
      <div className="mt-6">
        <div
          className="mt-10 StylesCategorySeoContent font-PoppinsRegular space-y-2 overflow-hidden"
          dangerouslySetInnerHTML={{
            __html: content ? content : ``,
          }}
        />
      </div>
    </>
  );
}
