import SectionBackWithName from "../Components/StaticPageComponents/SectionBackWithName";
import { ReactComponent as DangerIcon } from "../assets/icons/danger.svg";
import { ReactComponent as BankIcon } from "../assets/icons/Bank.svg";
import { ReactComponent as EasypaisaIcon } from "../assets/icons/Easypaisa.svg";
import { ReactComponent as JazzcashIcon } from "../assets/icons/Jazzcash.svg";
import CallToActionBottom from "../Components/HomePageComponents/CallToActionBottom";
import UpdateMetaTags from "../Utils/UpdateMetaTags";

export default function FarmgharPaymentDetails() {
  return (
    <>
      <UpdateMetaTags data={{ title: "farmGhar payment details" }} />
      <div className="mt-10 md:pt-3 md:mt-0">
        <SectionBackWithName pageName={"Payment Information"} />
      </div>

      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-10">
          <section className="max-w-full w-full bg-[#EFA922]  flex  items-center rounded-[15px] bg-opacity-[0.15] h-auto p-4 relative overflow-hidden">
            <p className="w-[90%]  text-[12px] text-justify">
              Dear Customer, if you want to make payment for &quot;animal
              purchase with delivery guarantee&quot; or publish a &quot;banner
              ad&quot; or make your ad a &quot;star ad&quot; on farmGhar App,
              you should make your payment in the following accounts only. You
              should not make payment to any other Account.
            </p>

            <DangerIcon className=" absolute -z-10 top-[-22px] right-[-5px] opacity-[0.15]" />
          </section>
          <section className="max-w-full w-full max-h-full h-auto rounded-[13px] border border-[rgba(103, 103, 103, 0.1)] mt-10 p-[10px] md:p-[50px] ">
            <h2 className="font-PoppinsBold text-lg">How To Pay</h2>
            <p className="font-PoppinsRegular text-[12px] mt-2 text-justify">
              There are multiple ways to pay. You can choose one as <br /> per
              your convenience.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <div className="col-span-2 flex flex-col md:flex-row md:divide-x-2 space-y-6 md:space-y-0">
                <div className="w-full md:w-[50%] pr-2">
                  <EasypaisaIcon className="w-52 ml-[-25px]" />
                  <div className="flex justify-between md:pr-4 mt-[16px] items-center">
                    <h3 className="font-PoppinsBold text-[13px]">
                      Account Title
                    </h3>
                    <p className="font-PoppinsRegular text-[12px]">
                      FarmGhar Private Limited
                    </p>
                  </div>
                  <div className="flex justify-between md:pr-4 items-center">
                    <h3 className="font-PoppinsBold text-[13px]">
                      Mobile number
                    </h3>
                    <p className="font-PoppinsRegular text-[12px]">
                      0301 9681454
                    </p>
                  </div>
                </div>

                <div className="w-full md:w-[50%] md:pl-6">
                  <JazzcashIcon className="w-30 ml-[-5px]" />
                  <div className="flex justify-between md:pr-4 mt-6 items-center">
                    <h3 className="font-PoppinsBold text-[13px]">
                      Account Title
                    </h3>
                    <p className="font-PoppinsRegular text-[12px]">
                      FarmGhar Private Limited
                    </p>
                  </div>
                  <div className="flex justify-between md:pr-4 items-center">
                    <h3 className="font-PoppinsBold text-[13px]">
                      Mobile number
                    </h3>
                    <p className="font-PoppinsRegular text-[12px]">
                      0301 9681454
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-1 mt-6">
                <div className="flex items-center mb-4">
                  <BankIcon className="" />
                  <h3 className="font-PoppinsBold text-lg mt-2 pl-4 text-center">
                    Bank Account
                  </h3>
                </div>
                <div className="flex  md:pr-4 items-center justify-between">
                  <h3 className="font-PoppinsBold text-[13px]">Bank Name</h3>
                  <p className="font-PoppinsRegular text-[12px]">MCB Bank</p>
                </div>
                <div className="flex md:pr-4 items-center justify-between">
                  <h3 className="font-PoppinsBold text-[13px]">
                    Account Title
                  </h3>
                  <p className="font-PoppinsRegular text-[12px]">
                    Farmghar Private Limited
                  </p>
                </div>
                <div className="flex  md:pr-4 items-center justify-between">
                  <h3 className="font-PoppinsBold text-[13px]">Account IBAN</h3>
                  <p className="font-PoppinsRegular text-[12px]">
                    PK66MUCB1206098601010134
                  </p>
                </div>
                <div className="flex  md:pr-4 items-center justify-between">
                  <h3 className="font-PoppinsBold text-[13px]">
                    Account Number
                  </h3>
                  <p className="font-PoppinsRegular text-[12px]">
                    1206098601010134
                  </p>
                </div>
                <div className="flex  md:pr-4 items-center justify-between">
                  <h3 className="font-PoppinsBold text-[13px]">Branch Code</h3>
                  <p className="font-PoppinsRegular text-[12px]">0477</p>
                </div>
              </div>
            </div>
          </section>
        </div>
        <CallToActionBottom />
      </div>
    </>
  );
}
