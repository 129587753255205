import { useEffect } from "react";
export default function UpdateMetaTags({ data }) {
  useEffect(() => {
    document.title = data?.title ?? "Buy & Sell Livestock For Free – farmGhar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        data?.description ??
          "FarmGhar is the largest online livestock market and a one-stop shop for animal lovers. Buy and sell livestock, pets, animals and mawashi without any hidden charges!"
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        data?.keywords ??
          "farm animals, pets, animals, cat, livestock, pet grooming near me, farm animals, dogs, hamster, persian cat, dogs for sale, siamese cat, kitten, cockatiel, adopt a pet, cute dogs, lab dog, cats for sale, veterinarians near me, goat farming, livestock farming, farmyard animals, cow calf, duck farm, dairy cattle, sahiwal cow, agriculture, birds, pigeon, love birds, parakeet, parrots"
      );
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", data?.canonical_url ?? window.location.href);
  }, [data]);

  return <></>;
}
