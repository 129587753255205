import { useEffect, useState } from "react";
import { useService_payment } from "../../Middlewares/Api";
import Toast from "../../Utils/Toast";
import { useNavigate } from "react-router-dom";

export default function PaymobOrderConfirmation() {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleCloseToast = () => {
    setShowToast(false);
  };
  const handleShowToast = () => {
    setShowToast(true);
  };

  const {
    data: service_payment_data,
    mutate: service_payment_mutate,
    isLoading: service_payment_isLoading,
  } = useService_payment();

  const navigate = useNavigate();

  useEffect(() => {
    if (service_payment_data?.status === true) {
      setToastMessage(service_payment_data?.message);
      handleShowToast();
      setTimeout(() => {
        navigate("/");
        localStorage.removeItem("package_id");
        localStorage.removeItem("service_type");
        localStorage.removeItem("package_quantity");
        localStorage.removeItem("ad_id");
      }, 5000);
    } else {
      if (service_payment_data?.message === "Payment Saved Successfully.") {
        return null;
      } else {
        setToastMessage(service_payment_data?.message);
        handleShowToast();
        setTimeout(() => {
          navigate("/");
          localStorage.removeItem("package_id");
          localStorage.removeItem("service_type");
          localStorage.removeItem("package_quantity");
          localStorage.removeItem("ad_id");
        }, 5000);
      }
    }
  }, [service_payment_data]);

  const params = new URLSearchParams(window.location.search);

  const payload = {
    service_id: localStorage.getItem("package_id"),
    quantity:
      localStorage.getItem("service_type") === "paid_ad"
        ? localStorage.getItem("package_quantity")
        : "",
    key: localStorage.getItem("key"),
    transaction_id: params.get("id"),
    service_type: localStorage.getItem("service_type"),
    adpost_id:
      localStorage.getItem("service_type") === "paid_ad"
        ? ""
        : localStorage.getItem("ad_id"),
    status: params.get("success") === "true" ? 1 : 0,
    // pending: params.get("pending") === "true" ? 1 : 0,
    // amount: params.get("amount_cents") / 100,
    success: params.get("success") === "true" ? 1 : 0,
    integration_id: params.get("integration_id"),
    profile_id: params.get("profile_id"),
    order: params.get("order"),
    // currency: params.get("currency"),
    merchant_commission: params.get("merchant_commission"),
    discount_details: encodeURI(params.getAll("discount_details")),
    error_occurred: params.get("error_occured"),
    is_settled: params.get("is_settled") === "true" ? 1 : 0,
    owner: params.get("owner"),
    data_message: params.get("data.message"),
    source_data_type: params.get("source_data.type"),
    source_data_pan: params.get("source_data.pan"),
    source_data_card_num: params.get("source_data.card_num")
      ? params.get("source_data.card_num")
      : params.get("source_data.pan"),
    source_data_sub_type: params.get("source_data.sub_type"),
    txn_response_code: params.get("txn_response_code"),
    hmac: params.get("hmac"),
  };
  useEffect(() => {
    if (params.get("success") === "true") {
      service_payment_mutate(payload);
    } else {
      service_payment_mutate(payload);
    }
  }, []);

  if (service_payment_isLoading) {
    return (
      <div className="flex items-center justify-center mt-5">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <>
      {showToast && (
        <Toast
          message={toastMessage}
          duration={3000}
          onClose={handleCloseToast}
        />
      )}
      <div className="font-PoppinsRegular mt-10 md:mt-3">
        <div className="relative w-full h-full bg-[#F4F4F4] flex justify-center items-center py-10">
          <div className="max-w-[600px] px-10 w-full h-[501px] rounded-[15px] bg-[#FFFFFF] z-10 ">
            <div className="flex flex-col items-center mt-[40px] w-full h-full ">
              {params.get("success") === "true" &&
              service_payment_data?.status === true ? (
                <div className="space-y-4 mt-[29px]  w-full h-full max-w-[340px] text-left">
                  <div className="ml-auto mr-auto mt-44 font-PoppinsBold text-center text-[18px] text-Primary">
                    Your Payment Proceed Successfully <br />
                    Redirecting to home page
                  </div>

                  <div className="spinner ml-auto mr-auto mt-44"></div>
                </div>
              ) : (
                <div className="space-y-4 mt-[29px]  w-full h-full max-w-[340px] text-left">
                  <div className="ml-auto mr-auto mt-44 font-PoppinsBold text-center text-[18px] text-Primary">
                    Your Payment Failed <br />
                    Redirecting to home page
                  </div>

                  <div className="spinner ml-auto mr-auto mt-44"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
