import {
  //  React,
  Component,
} from "react";

class HorizentalGoogleAd extends Component {
  // componentDidMount() {
  //   if (process.env.REACT_APP_SERVER_TYPE === "production") {
  //     try {
  //       (window.adsbygoogle = window.adsbygoogle || []).push({});
  //     } catch (error) {
  //       //
  //     }
  //   }
  // }

  render() {
    return null;
    // return (
    //   <div id="ad-container" className="mx-auto py-2">
    //     <ins
    //       className="adsbygoogle"
    //       style={{ display: "block" }}
    //       data-ad-client="ca-pub-9191039315895871"
    //       data-ad-slot="6677974823"
    //       data-ad-format="auto"
    //       data-full-width-responsive="true"
    //     ></ins>
    //   </div>
    // );
  }
}

export default HorizentalGoogleAd;
