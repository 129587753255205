import { Component } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "../Pages/HomePage";
import SingleCategoryPage from "../Pages/SingleCategoryPage";
import AdDetailsPage from "../Pages/AdDetailsPage";

import TermsConditions from "../Pages/TermsConditions";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import AboutUs from "../Pages/AboutUs";
import ContactUs from "../Pages/ContactUs";
import Blogs from "../Pages/Blogs";
import BlogDetails from "../Pages/BlogDetails";
import AllServices from "../Pages/AllServices";
import SingleService from "../Pages/SingleService";
import MigratedContent from "../Pages/MigratedContent";
import Register from "../Pages/Register";
import MyAccount from "../Pages/MyAccount";
import PrivateRoute from "../Utils/PrivateRoute";
import PublicRoute from "../Utils/PublicRoute";
import Login from "../Pages/Login";
import ForgotPassword from "../Pages/ForgotPassword";
import Profile from "../Components/MyAccountComponents/Profile";
import ChangePassword from "../Components/MyAccountComponents/ChangePassword";
import FarmgharPaymentDetails from "../Pages/FarmgharPaymentDetails";
import PostAd from "../Pages/PostAd";
import FavouritesAds from "../Pages/FavouritesAds";
import ViewAllUserAds from "../Pages/ViewAllUserAds";
import MyAds from "../Pages/MyAds";
import Qurbani from "../Pages/Qurbani";
import QurbaniServices from "../Pages/QurbaniServices";
import QurbaniCheckout from "../Pages/QurbaniCheckout";
import QurbaniOrderConfirmed from "../Components/QurbaniComponents/QurbaniOrderConfirmed";
import TrackQurbani from "../Pages/TrackQurbani";
import QurbaniSEOPage from "../Pages/QurbaniSEOPage";
import PaymobOrderConfirmation from "./PaymobComponents/PaymobOrderConfirmation";
import TransactionHistory from "./PaymobComponents/TransactionHistory";
// import QurbaniTerms from "../Pages/QurbaniTerms";
class MainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    if (!document.querySelector("#googleMaps") && !this.state.isLoaded) {
      const script = document.createElement("script");
      script.id = "googleMaps";
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.type = "text/javascript";
      document.head.appendChild(script);
      this.setState({ isLoaded: true });
    }
  }
  render() {
    return (
      <Routes>
        <Route path="/account" element={<PrivateRoute />} exact>
          <Route path="/account" element={<MyAccount />}>
            <Route path="/account/profile" element={<Profile />} />
            <Route
              path="/account/password/change"
              element={<ChangePassword />}
            />
          </Route>
          <Route path="/account/my-ads" element={<MyAds />} />
          <Route path="/account/favourites" element={<FavouritesAds />} />
          <Route path="/account/post-ad" element={<PostAd />} />
          <Route
            path="/account/payment-confirmation"
            element={<PaymobOrderConfirmation />}
          />
          <Route
            path="/account/transaction-history"
            element={<TransactionHistory />}
          />
        </Route>

        <Route path="/view-all-user-ads" element={<ViewAllUserAds />} exact />

        <Route path="/auth/register" element={<PublicRoute />} exact>
          <Route path="/auth/register" element={<Register />} />
        </Route>
        <Route path="/auth/login" element={<PublicRoute />} exact>
          <Route path="/auth/login" element={<Login />} />
        </Route>

        <Route path="/auth/password/reset" element={<PublicRoute />} exact>
          <Route path="/auth/password/reset" element={<ForgotPassword />} />
        </Route>

        <Route path="/products/cholistani-cow" element={<MigratedContent />} />
        <Route
          path="/products/cow-for-sale-in-pakistan"
          element={<MigratedContent />}
        />
        <Route
          path="/collections/cow-farmghar-onlinequrbani-cow"
          element={<MigratedContent />}
        />
        <Route
          path="/products/qurbani-full-cow-farmghar"
          element={<MigratedContent />}
        />
        <Route
          path="/collections/live-cow/products/gulabi-bakra"
          element={<MigratedContent />}
        />
        <Route path="/products/bakra-mandi-1" element={<MigratedContent />} />
        <Route path=":CatSlug" element={<SingleCategoryPage />} />
        <Route path=":CatSlug/:AdSlug" element={<AdDetailsPage />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:BlogSlug" element={<BlogDetails />} />

        <Route path="/services" element={<AllServices />} />
        <Route path="/qurbani" element={<Qurbani />}>
          <Route path="/qurbani" element={<QurbaniServices />} />
        </Route>
        <Route path="/qurbani/:QurbaniSlug" element={<QurbaniSEOPage />} />
        <Route path="/qurbani/checkout" element={<QurbaniCheckout />} />
        <Route
          path="/qurbani/checkout/order-confirmed"
          element={<QurbaniOrderConfirmed />}
        />
        {/* <Route path="/qurbani/terms" element={<QurbaniTerms />} /> */}
        <Route path="/track-your-qurbani" element={<TrackQurbani />} />
        <Route path="/services/:ServiceSlug" element={<SingleService />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="/farmghar-payment-details"
          element={<FarmgharPaymentDetails />}
        />

        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  }
}
export default MainComponent;
