import { XMarkIcon } from "@heroicons/react/24/outline";
const FileUploader = ({
  formik,
  selectedFiles,
  setSelectedFiles,
  previewImages,
  setPreviewImages,
  inputRef,
  adstatus,
}) => {
  const handleFileChange = (event) => {
    const files = event.target.files;
    const previews = [];
    if (selectedFiles.length + files.length > 4) {
      alert("You can only upload 4 images");
      return;
    }
    const filesArray = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/webp" ||
        file.type === "image/gif"
      ) {
        filesArray.push(file);
        // setSelectedFiles((selectedFiles) => [...selectedFiles, file]);
        // formik.setFieldValue("Pictures", [...selectedFiles, file]);
        const reader = new FileReader();
        reader.onloadend = () => {
          previews.push(reader.result);
          if (previews.length === files.length) {
            setPreviewImages((previewImages) => [
              ...previewImages,
              ...previews,
            ]);
          }
        };
        reader.readAsDataURL(file);
      } else {
        alert("Only .jpeg and .png files are allowed");
      }
    }
    setSelectedFiles((selectedFiles) => [...selectedFiles, ...filesArray]);
    formik.setFieldValue("Pictures", [...selectedFiles, ...filesArray]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleInputDragStart = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const previews = [];
    if (selectedFiles.length + files.length > 4) {
      alert("You can only upload 4 images");
      return;
    }
    const filesArray = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // Check for duplicates based on content
      const isDuplicate = [...selectedFiles].some((selectedFile) => {
        if (
          selectedFile.name === file.name &&
          selectedFile.size === file.size
        ) {
          return new Promise((resolve) => {
            const reader1 = new FileReader();
            const reader2 = new FileReader();

            reader1.onload = () => {
              reader2.readAsArrayBuffer(selectedFile);
            };
            reader2.onload = () => {
              resolve(reader1.result === reader2.result);
            };

            reader1.readAsArrayBuffer(file);
          });
        }
        return false;
      });

      if (isDuplicate) {
        alert(`File '${file.name}' has already been selected.`);
        continue;
      }
      if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/webp" ||
        file.type === "image/gif"
      ) {
        filesArray.push(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          previews.push(reader.result);
          if (previews.length === files.length) {
            setPreviewImages((previewImages) => [
              ...previewImages,
              ...previews,
            ]);
          }
        };
        reader.readAsDataURL(file);
      } else {
        alert("Only .jpeg and .png files are allowed");
      }
    }
    setSelectedFiles((selectedFiles) => [...selectedFiles, ...filesArray]);
    formik.setFieldValue("Pictures", [...selectedFiles, ...filesArray]);
  };
  const handleDeleteClick = (index) => {
    //delete the file from the array of files input

    const newSelectedFiles = [...selectedFiles];
    const newPreviewImages = [...previewImages];
    newSelectedFiles.splice(index, 1);
    newPreviewImages.splice(index, 1);
    formik.setFieldValue("Pictures", newSelectedFiles);
    setSelectedFiles(newSelectedFiles);
    setPreviewImages(newPreviewImages);
    const input = inputRef.current;
    const files = input.files;
    const newFiles = [];

    // Copy all files except the one that needs to be removed
    for (let i = 0; i < files.length; i++) {
      if (i !== index) {
        newFiles.push(files[i]);
      }
    }

    // Create a new FileList object
    const newFileList = new DataTransfer();
    newFiles.forEach((file) => {
      newFileList.items.add(file);
    });

    // Update the value of the input element with the new FileList object
    input.files = newFileList.files;
    formik.setFieldValue("CoverImageIndex", 0);
  };

  const ChangeCoverImage = (index) => {
    formik.setFieldValue("CoverImageIndex", index);
  };
  return (
    <>
      {adstatus.is_verified !== "1" && adstatus.is_star !== "1" ? (
        //eslint-disable-next-line
        <div
          className="flex flex-col items-center justify-center border-dashed border-2 border-gray-400 h-48"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={() => document.getElementById("image-input").click()}
        >
          <div className="flex flex-col items-center justify-center">
            <svg
              className="w-12 h-12 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>

            <p className="text-center">
              Drag and drop your images here or click
              <br />
              <span className="bg-Primary text-white cursor-pointer p-1 rounded-sm font-PoppinsBold">
                browse {}
              </span>{" "}
              <br />
              to select images
            </p>
            <p className="text-xs text-gray-500 text-center">
              Only .jpeg, .png, .webp and gif files are allowed
            </p>
          </div>

          <div>
            <input
              id="image-input"
              type="file"
              accept=".jpeg, .jpg, .png, .webp,.gif"
              className="hidden"
              ref={inputRef}
              onChange={handleFileChange}
              onDragStart={handleInputDragStart}
              multiple
            />
            {/* <button
          type="submit"
          className="px-4 py-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-600"
        >
          Upload
        </button> */}
          </div>
        </div>
      ) : null}
      <div>
        {previewImages?.length > 0 && (
          <ul className="flex flex-wrap justify-start">
            {previewImages.map((previewImage, index) => (
              //eslint-disable-next-line
              <li
                key={index}
                className="relative m-1"
                onClick={(event) => event.stopPropagation()}
              >
                <img
                  className="w-20 h-20 object-cover"
                  src={previewImage?.path || previewImage}
                  alt={`Preview ${index}`}
                />
                {formik.values.CoverImageIndex === index ? (
                  <p className="absolute top-0 right-0 bottom-0 left-0 font-PoppinsBold text-white text-[10px] flex justify-center items-center bg-Primary w-fit h-fit m-auto p-[2px] rounded-md">
                    Cover
                  </p>
                ) : adstatus.is_verified !== "1" && adstatus.is_star !== "1" ? (
                  <button
                    className="absolute top-0 right-0 bottom-0 left-0 font-PoppinsBold text-white text-[10px] flex justify-center items-center w-fit h-fit m-auto bg-Primary p-[2px] rounded-md"
                    type="button"
                    onClick={() => ChangeCoverImage(index)}
                  >
                    Make Cover
                  </button>
                ) : null}
                {adstatus.is_verified !== "1" && adstatus.is_star !== "1" && (
                  <button
                    className="absolute top-0 right-0 p-[2px] m-1 text-white bg-red-500 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    type="button"
                    onClick={() => handleDeleteClick(index)}
                  >
                    <XMarkIcon className="w-4 h-4" />
                  </button>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};
export default FileUploader;
