import React, { useEffect } from "react";

const CountdownTimer = ({ setTimeEnded, timeLeft, setTimeLeft }) => {
  useEffect(() => {
    if (timeLeft < 0) {
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    timeLeft === 1 && setTimeEnded(true);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return (
    <div className="font-PoppinsBold text-[30px] pt-4 text-Primary">
      {timeLeft >= 1 && timeLeft}
    </div>
  );
};

export default CountdownTimer;
