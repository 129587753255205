import Slider from "react-slick";
import { useRef } from "react";

import { Link } from "react-router-dom";
import { ReactComponent as PrevIcon } from "../../assets/slider/left.svg";
import { ReactComponent as NextIcon } from "../../assets/slider/right.svg";

function SampleNextArrow(props) {
  const { onClick } = props;

  return (
    //eslint-disable-next-line
    <div
      className={`z-10 absolute top-[8px] right-[-34px]  ${
        onClick ? "block" : "hidden"
      } `}
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <NextIcon />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;

  return (
    //eslint-disable-next-line
    <div
      className={`z-10 absolute top-[8px] left-[-38px]  ${
        onClick ? "block" : "hidden"
      } `}
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <PrevIcon />
    </div>
  );
}

export default function SelectCategory({ data }) {
  const slider = useRef(null);

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    className: "slider variable-width",
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1198,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          centerMode: false,
        },
      },

      {
        breakpoint: 1197,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,

          centerMode: false,
        },
      },
      {
        breakpoint: 827,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },

      {
        breakpoint: 555,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="pt-3 relative">
      {/* <div
        className={"z-10 absolute top-[101px] left-[-38px] hidden  lg:block"}
      >
        <button onClick={() => slider?.current?.slickPrev()}>
          <PrevIcon />
        </button>
      </div> */}
      <div className="mt-[21px] mb-[28px]">
        <p className="font-PoppinsBold md:text-[22px]">Select Category</p>
      </div>

      <Slider ref={slider} {...settings}>
        {data &&
          data.map((item, index) => {
            return (
              <div key={index} style={{ width: 240 }}>
                <Link
                  to={`/${item.category_slug}`}
                  onClick={() => {
                    if (typeof window.fbq === "function") {
                      window.fbq("track", "view category", {
                        "Category name": item.name,
                      });
                    } else {
                      console.warn("Facebook Pixel function not yet available");
                    }
                  }}
                  className="w-[220px] h-[73.68px] rounded-[8px] flex border border-[#DBDED9]"
                >
                  <div className="w-[68.21px] h-[63.16px] m-[5.68px] mr-[12.11px]">
                    <img
                      src={item.icon}
                      alt={item.name}
                      className="w-[68.21px] h-[63.16px] rounded-[8px]"
                    />
                  </div>
                  <div className="flex items-center">
                    <div>
                      <h3 className="text-[12px] font-PoppinsBold">
                        {item.name}
                      </h3>
                      <p className="text-[10px]">{item.ads_count} + Ads</p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
      </Slider>
      {/* <div
        className={"z-10 absolute top-[101px] right-[-34px] hidden  lg:block"}
      >
        <button onClick={() => slider?.current?.slickNext()}>
          <NextIcon />
        </button>
      </div> */}
    </div>
  );
}
