import SectionBackWithName from "../Components/StaticPageComponents/SectionBackWithName";
import WhyFarmGhar from "../Components/HomePageComponents/WhyFarmGhar";
import CallToActionBottom from "../Components/HomePageComponents/CallToActionBottom";
import AboutUsCow from "../assets/images/AboutUsCow.webp";
// import { useState } from "react";
import { ReactComponent as AboutUs1 } from "../assets/icons/AboutUs1.svg";
import { ReactComponent as AboutUs2 } from "../assets/icons/AboutUs2.svg";
import { ReactComponent as AboutUs3 } from "../assets/icons/AboutUs3.svg";
import { ReactComponent as AboutUs4 } from "../assets/icons/AboutUs4.svg";
import { ReactComponent as AboutUs5 } from "../assets/icons/AboutUs5.svg";
import { ReactComponent as AboutUs6 } from "../assets/icons/AboutUs6.svg";
import { ReactComponent as AboutUs7 } from "../assets/icons/AboutUs7.svg";
import { ReactComponent as AboutUs8 } from "../assets/icons/AboutUs8.svg";
import { ReactComponent as AboutUsJoinNow } from "../assets/icons/AboutUsJoinNow.svg";
import { Link } from "react-router-dom";

// import Modal from "../Components/Modal";
// import { ReactComponent as PlayStoreIcon } from "../assets/slider/PlayStore.svg";
// import { ReactComponent as AppStoreIcon } from "../assets/slider/AppStore.svg";
// import { XMarkIcon } from "@heroicons/react/24/outline";

import { useFetchMetaDataAboutUs } from "../Middlewares/Api";

import UpdateMetaTags from "../Utils/UpdateMetaTags";

const AboutUsCardsData = [
  {
    icon: <AboutUs1 />,
    title: `Buy and Sell`,
    titleSpan: `animals online`,
    description: `farmGhar provides a safe platform to all its customers for buying and selling animals. Since there is little back and forth between the two parties, you are spared the trouble of dealing with repeated inquiries.`,
  },
  {
    icon: <AboutUs2 />,
    title: `Buying and`,
    titleSpan: `selling verified animals`,
    description: `Our buyers and sellers are saved from the hassle of going to the maweshi mandis and local markets in search of healthy animals because our verified ad service offers healthy animals to the buyers. They offer a full inspection through videos, pictures and on-site if needed. Also, they provide an animal health certificate.`,
  },
  {
    icon: <AboutUs3 />,
    title: `Veterinary doctors are`,
    titleSpan: `available for consultation`,
    description: `farmGhar has introduced a consultation feature to bring ease to your life. Apart from the animals available on our website, our highly skilled veterinary doctors offer consultation for other animals.`,
  },
  {
    icon: <AboutUs4 />,
    title: `Payment with guarantee`,
    titleSpan: `feature (for secure payment)`,
    description: `Your money is safe with us as farmGhar acts as an intermediary between you and the seller. Payment with the guarantee is beneficial for both buyers and sellers. farmGhar will not release the payment if the actually ordered animal is not delivered and on the other hand, buyers cannot deny payment after receiving a healthy animal.`,
  },
  {
    icon: <AboutUs5 />,
    title: `farmGhar`,
    titleSpan: `Star Ad Service`,
    description: `Star Ad is a unique feature of farmGhar which allows its users to keep their ads on the top and the ad will be visible to a higher number of audience. However, with this unique feature, our sellers get a higher chance to sell their animals at the best possible price. Our star ad service will ensure more people see your ad and contact you to buy your animal.`,
  },
  {
    icon: <AboutUs6 />,
    title: `Qurbani service on`,
    titleSpan: `EID al-Adha`,
    description: `Experience the joy of giving this Eid al-Adha with our reliable and trustworthy qurbani service. Our goal is to make Qurbani easier for tourists and locals who struggle to locate animals, find a butcher, and a slaughterhouse. farmGhar offers complete Qurbani services at your footstep. farmGhar’s qurbani service saves you from rushy bakra mandi and allows you to buy hassle-free qurbani k janwar.`,
  },
  {
    icon: <AboutUs7 />,
    title: "At-home",
    titleSpan: `slaughtering service`,
    description: `farmGhar’s at-home slaughtering service allows you to get fresh meat at your doorsteps keeping all the hygiene protocols in mind. Our skilled butcher will slaughter the animal at your home and will provide you with fresh meat of your desired weight.`,
  },
  {
    icon: <AboutUs8 />,
    title: ` Continuous monitoring of ads`,
    titleSpan: `being posted on the app`,
    description: `farmGhar aims to provide security and safety to all buyers and sellers. Therefore this feature enables the highly professional team of farmGhar to do the ad review to save the buyers from fraud. We ensure that only authentic and righteous ads are posted on our app.`,
  },
  {
    icon: <AboutUs1 />,
    title: `farmGhar`,
    titleSpan: `Self-stock`,
    description: `farmGhar has introduced a unique and effective feature ‘farmGhar Self-stock’. By using this feature you can save yourself from the hassle of price negotiation and direct dealing with the buyers. However, this service allows us to deal with the buyers on your behalf, negotiate the price and let you know. farmGhar’s team will serve you with the best possible services.`,
  },
];

export default function AboutUs() {
  // const [showModal, setShowModal] = useState(false);
  const { data } = useFetchMetaDataAboutUs();
  return (
    <>
      <UpdateMetaTags data={data?.data ?? {}} />
      {/* {showModal ? (
        <Modal>
          <div id="modalInner">
            <div id="modalContent" className="font-PoppinsRegular relative ">
              <div className="w-[250px] sm:w-[500px] max-w-[600px] h-[350px] mx-auto flex items-center mt-2 flex-col space-y-3">
                <p className="pt-14 text-center">
                  Buy and sell animals at a faster rate! Download the farmGhar
                  app and post your ad online from the comfort of your home.{" "}
                </p>
                <p className="text-[18px] font-PoppinsBold text-center md:text-left">
                  Get The App Now!
                </p>
                <div className="flex  justify-center md:justify-start">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.farmghar.app"
                    className="mr-[10.53px]"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <PlayStoreIcon />
                  </a>
                  <a
                    href="https://apps.apple.com/pk/app/farmghar-%D8%A2%D9%86-%D9%84%D8%A7%D8%A6%D9%86-%D9%85%D9%88%DB%8C%D8%B4%DB%8C-%D9%85%D9%86%DA%88%DB%8C/id1624237338"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <AppStoreIcon />
                  </a>
                </div>
              </div>
              <div className=" absolute top-[-13px] right-[4px] mt-3">
                <button
                  className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                  onClick={() => setShowModal(false)}
                >
                  <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null} */}
      <div className="mt-10 md:pt-3 md:mt-0">
        <SectionBackWithName pageName={"About Us"} />
      </div>
      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-10">
          <div className="flex justify-around  items-center flex-col-reverse lg:flex-row">
            <div className="pt-10 lg:pt-0  max-w-[444px] lg:max-w-[525px]">
              <p className="text-[20px] lg:text-[36px] font-PoppinsBold">
                Welcome to farmGhar
              </p>

              <p className="pb-5 text-justify">
                Welcome to Pakistan’s largest online buying & selling animals,
                pets, and bird market in Pakistan. We established an online
                bird, pets and other animals market. Farmers and sellers can
                advertise and sell their animals online with just one click. All
                they need to do is download the
                <a
                  href="https://bit.ly/3ExcPg4"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <strong className="text-Primary">farmGhar app </strong>
                </a>
                or visit our website. Thanks to our user-friendly, unique
                software and procedures built by our highly skilled team.
              </p>
              <p className="pb-2 text-justify">
                We understand how difficult farming and searching for an animal
                could be, which is why everything in our app is meant to make
                buying and selling animals easy, and as quick as possible.
                However, we ensure the security of our clients with our ad
                approval procedure where the ads are cross-checked to avoid any
                fraud. Also, farmGhar is offering veterinary assistance where
                our team of skilled veterinary doctors examine the health of
                animals. Moreover, stop wasting your time in bakra mandi and use
                farmGhar App instead to book your Qurbani k janwar because we
                offer hassle-free purchases to save your time.
              </p>
            </div>
            <div className="max-w-[415px] max-h-[500px] mx-auto">
              <img src={AboutUsCow} alt="About Us Cow" className="w-full" />
            </div>
          </div>
          <div>
            <p className="text-[16px]  font-PoppinsRegular text-center pt-[40px]">
              farmGhar is an online marketplace for buying and selling
              livestock, farm products, farm services, farm equipment, poultry
              and pets. However, our main features include the following
            </p>
            <div className="flex items-center justify-center">
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 pt-10">
                {AboutUsCardsData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className=" rounded-[15px] w-full sm:w-[339px] border border-[#B6B6B6] h-auto flex pt-[25px] flex-col pl-[18px] space-y-2"
                    >
                      <div>{item?.icon}</div>
                      <h2 className="text-[16px] md:text-[18px] lg:text-[20px] font-PoppinsBold">
                        {item?.title}
                        <span className="block">{item?.titleSpan}</span>
                      </h2>
                      <p className="text-justify pr-2">{item?.description}</p>
                    </div>
                  );
                })}
                <div className="bg-Primary bg-opacity-[0.15] rounded-[15px] w-full sm:w-[339px]  h-[480px] flex justify-center items-center flex-col space-y-5">
                  <h2 className="text-[16px] md:text-[18px] lg:text-[20px] font-PoppinsBold text-center">
                    Start Exploring today Using
                    <span className="block text-[28px] text-Primary font-PoppinsBold">
                      FarmGhar
                    </span>
                  </h2>
                  <Link
                    to="/auth/register"
                    className="bg-Primary w-[151px] h-[53px] rounded-[50px] text-white font-PoppinsBold text-[16px] md:text-[18px] lg:text-[20px]  py-3 px-6"
                  >
                    Join Now
                  </Link>
                  {/* <button
                    onClick={() => setShowModal(true)}
                    className="bg-Primary w-[151px] h-[53px] rounded-[50px] text-white font-PoppinsBold text-[16px] md:text-[18px] lg:text-[20px]  py-3 px-6"
                  >
                    Join Now
                  </button> */}
                  <div className="">
                    <AboutUsJoinNow />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-10">
          <WhyFarmGhar />
        </div>
        <div className="pt-20">
          <CallToActionBottom />
        </div>
      </div>
    </>
  );
}
