export function HenBirdPetOtherForm({
  formik,
  ChildCategoriesOptionsOther,
  DropdownIndicator,
  Style,
  Select,
  AgeMonthsOptions,
  AgeYearsOptions,
  Provinces,
  DistrictsOptons,
  TehsilsOptons,
  FileUploader,
  selectedFiles,
  setSelectedFiles,
  previewImages,
  setPreviewImages,
  edit,
  inputRef,
  RemoveAllFilesFromImageUploader,
  GoogleMap,
  getAddress,
  HandleChangeLatLong,
  locationStates,
  setLocationStates,
  autoComplete2,
  setAutoComplete2,
  DistrictsData,
  // TehsilsData,
  adstatus,
}) {
  return (
    <div className="flex flex-col items-center mt-[40px] w-full h-full ">
      <div className="grid grid-cols-2 gap-4 w-full h-full p-10">
        <div className="">
          <label htmlFor="Category" className="block text-sm  text-TextColor">
            Category
          </label>
          <Select
            styles={Style}
            id="Category"
            name="Category"
            maxMenuHeight={140}
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={ChildCategoriesOptionsOther}
            value={formik.values.Category ? formik.values.Category : ""}
            label="Category"
            isDisabled={edit}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onChange={(value) => {
              formik.resetForm({
                values: {
                  ...formik.values,
                  BreedName: "",
                  AdTitle: "",
                  AgeYears: "",
                  AgeMonths: "",
                  AnimalDescription: "",
                  Price: "",
                  PriceType: "",
                  Pictures: [],
                },
              });
              RemoveAllFilesFromImageUploader();
              setSelectedFiles([]);
              setPreviewImages([]);
              formik.setFieldValue("Category", value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.Category && formik.errors.Category ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Category}
            </span>
          ) : null}
        </div>
        <div className="flex flex-col justify-center mt-1">
          <label htmlFor="AdTitle" className="block text-sm  text-TextColor">
            Ad Title
          </label>
          <input
            id="AdTitle"
            name="AdTitle"
            type="text"
            className="w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
            value={formik.values.AdTitle ? formik.values.AdTitle : ""}
            label="AdTitle"
            placeholder="Ad Title"
            onChange={(e) => {
              formik.setFieldValue("AdTitle", e.target.value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.AdTitle && formik.errors.AdTitle ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.AdTitle}
            </span>
          ) : null}
        </div>
        <div className="flex flex-col justify-center mt-1">
          <label htmlFor="BreedName" className="block text-sm  text-TextColor">
            Breed
          </label>
          <input
            id="BreedName"
            name="BreedName"
            type="text"
            className="w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
            value={formik.values.BreedName ? formik.values.BreedName : ""}
            label="BreedName"
            placeholder="Breed Name"
            onChange={(e) => {
              formik.setFieldValue("BreedName", e.target.value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.BreedName && formik.errors.BreedName ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.BreedName}
            </span>
          ) : null}
        </div>
        <div className="col-span-2">
          <label
            htmlFor="AnimalDescription"
            className="block text-sm  text-TextColor"
          >
            Description
          </label>
          <textarea
            className="block
            
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
       border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      border-2
      focus:text-gray-700 focus:bg-white focus:outline-none resize-none"
            placeholder="Description"
            id="AnimalDescription"
            name="AnimalDescription"
            value={formik.values.AnimalDescription ?? ""}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.setFieldValue("AnimalDescription", e.target.value);
            }}
          />
          {formik.touched.AnimalDescription &&
          formik.errors.AnimalDescription ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.AnimalDescription}
            </span>
          ) : null}
        </div>
        <div className="">
          <label htmlFor="AgeYears" className="block text-sm  text-TextColor">
            Age In Years
          </label>
          <Select
            styles={Style}
            id="AgeYears"
            name="AgeYears"
            maxMenuHeight={140}
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={AgeYearsOptions}
            value={formik.values.AgeYears ? formik.values.AgeYears : ""}
            label="AgeYears"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onChange={(value) => {
              formik.setFieldValue("AgeYears", value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.AgeYears && formik.errors.AgeYears ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.AgeYears}
            </span>
          ) : null}
        </div>
        <div className="">
          <label htmlFor="AgeMonths" className="block text-sm  text-TextColor">
            Age In Months
          </label>
          <Select
            styles={Style}
            id="AgeMonths"
            name="AgeMonths"
            maxMenuHeight={140}
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={AgeMonthsOptions}
            value={formik.values.AgeMonths ? formik.values.AgeMonths : ""}
            label="AgeMonths"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onChange={(value) => {
              formik.setFieldValue("AgeMonths", value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.AgeMonths && formik.errors.AgeMonths ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.AgeMonths}
            </span>
          ) : null}
        </div>

        <div className="flex flex-col justify-center mt-1">
          <label htmlFor="Price" className="block text-sm  text-TextColor">
            Price (PKR)
          </label>
          <input
            id="Price"
            name="Price"
            type="text"
            className="w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
            value={formik.values.Price ? formik.values.Price : ""}
            label="Price"
            disabled={adstatus?.is_star === "1"}
            onChange={(e) => {
              formik.setFieldValue("Price", e.target.value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.Price && formik.errors.Price ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Price}
            </span>
          ) : null}
        </div>

        <div className="">
          <label htmlFor="PriceType" className="block text-sm  text-TextColor">
            Price Type
          </label>
          <Select
            styles={Style}
            id="PriceType"
            name="PriceType"
            maxMenuHeight={140}
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={
              String(formik.values.SelectedCategory) === "123" ||
              String(formik.values.SelectedCategory) === "21" ||
              String(formik.values.SelectedCategory) === "115"
                ? [
                    {
                      value: "total",
                      label: "Total",
                    },
                  ]
                : [
                    {
                      value: "perkg",
                      label: "Per Kg",
                    },
                    {
                      value: "total",
                      label: "Total",
                    },
                  ]
            }
            value={formik.values.PriceType ? formik.values.PriceType : ""}
            label="PriceType"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onChange={(value) => {
              formik.setFieldValue("PriceType", value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.PriceType && formik.errors.PriceType ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.PriceType}
            </span>
          ) : null}
        </div>
        <div className="col-span-2">
          <GoogleMap
            autoComplete2={autoComplete2}
            setAutoComplete2={setAutoComplete2}
            HandleChangeLatLong={HandleChangeLatLong}
            locationStates={locationStates}
            setLocationStates={setLocationStates}
            formikInitialValues={formik.values}
          />
        </div>
        <div>
          <label
            htmlFor="Province"
            className="block text-sm font-medium text-TextColor"
          >
            Province
          </label>
          <Select
            styles={Style}
            id="Province"
            name="Province"
            maxMenuHeight={140}
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={Provinces}
            value={formik.values.Province ? formik.values.Province : []}
            label="Province"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onChange={(value) => {
              formik.setFieldValue("Province", value);

              formik.setFieldValue("District", []);
              formik.setFieldValue("Tehsil", []);
              setLocationStates((prev) => ({
                ...prev,
                address: "",
                latitude: null,
                longitude: null,
              }));
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        {formik.touched.Province && formik.errors.Province ? (
          <span className="text-red-600  text-[11px] ">
            {formik.errors.Province}
          </span>
        ) : null}
        <div className="">
          <label
            htmlFor="District"
            className="block text-sm font-medium text-TextColor"
          >
            District
          </label>
          <Select
            styles={Style}
            id="District"
            name="District"
            maxMenuHeight={140}
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={DistrictsOptons}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            allowSelectAll={false}
            value={formik.values.District ? formik.values.District : []}
            label="District"
            onChange={async (value) => {
              formik.setFieldValue("District", value);
              formik.setFieldValue("Tehsil", []);
              const District = DistrictsData?.data?.find(
                (item) => parseInt(item.id) === parseInt(value.value)
              );
              const address = await getAddress(
                parseFloat(District?.latitude),
                parseFloat(District?.longitude)
              );
              setLocationStates((prev) => ({
                ...prev,
                address: address,
                latitude: parseFloat(District?.latitude),
                longitude: parseFloat(District?.longitude),
              }));
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.District && formik.errors.District ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.District}
            </span>
          ) : null}
        </div>

        <div className="">
          <label
            htmlFor="Tehsil"
            className="block text-sm font-medium text-TextColor"
          >
            Tehsil
          </label>
          <Select
            styles={Style}
            id="Tehsil"
            name="Tehsil"
            maxMenuHeight={140}
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={TehsilsOptons}
            value={formik.values.Tehsil ? formik.values.Tehsil : ""}
            label="Tehsil"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onChange={async (value) => {
              formik.setFieldValue("Tehsil", value);
              // const tehsil = TehsilsData?.data?.find(
              //   (item) => parseInt(item.id) === parseInt(value?.value)
              // );
              // const address = await getAddress(
              //   parseFloat(tehsil?.latitude),
              //   parseFloat(tehsil?.longitude)
              // );
              // setLocationStates((prev) => ({
              //   ...prev,
              //   address: address,
              //   latitude: parseFloat(tehsil?.latitude),
              //   longitude: parseFloat(tehsil?.longitude),
              // }));
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.Tehsil && formik.errors.Tehsil ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Tehsil}
            </span>
          ) : null}
        </div>

        <div className="col-span-2">
          <label
            htmlFor="AreaVillageTown"
            className="block text-sm  text-TextColor"
          >
            Area/Village/Town
          </label>
          <textarea
            id="AreaVillageTown"
            name="AreaVillageTown"
            className="block
  
w-full
px-3
py-1.5
text-base
font-normal
text-gray-700
bg-white bg-clip-padding
border-solid border-gray-300
rounded
transition
ease-in-out
m-0
border-2
focus:text-gray-700 focus:bg-white focus:outline-none resize-none"
            placeholder="Area/Village/Town"
            value={formik.values.AreaVillageTown ?? ""}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.setFieldValue("AreaVillageTown", e.target.value);
            }}
          />
          {formik.touched.AreaVillageTown && formik.errors.AreaVillageTown ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.AreaVillageTown}
            </span>
          ) : null}
        </div>
        <div className="col-span-2">
          {formik.touched.Pictures && formik.errors.Pictures ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Pictures}
            </span>
          ) : null}
          <FileUploader
            formik={formik}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            previewImages={previewImages}
            setPreviewImages={setPreviewImages}
            inputRef={inputRef}
            adstatus={adstatus}
          />
        </div>
      </div>
    </div>
  );
}
