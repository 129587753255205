import { createContext } from "react";
import { useState, useEffect } from "react";
const FilterAdsContext = createContext();

export default FilterAdsContext;

export const FilterAdsContextProvider = ({ children }) => {
  const [filterAds, setFilterAds] = useState({
    parent_category: undefined,
    page: 1,
    sortBy: undefined,
    Province: undefined,
    district: undefined,
    Tehsil: undefined,
    Category: undefined,
    Breed: undefined,
    IsDelivery: undefined,
    IsVerified: undefined,
    IsPregnant: undefined,
    Castrated: undefined,
    MinWeight: undefined,
    MinAge: undefined,
    MaxAge: undefined,
    MaxPrice: undefined,
    SelectedPaginationCategory: undefined,
  });

  useEffect(() => {}, [filterAds]);

  return (
    <FilterAdsContext.Provider value={{ filterAds, setFilterAds }}>
      {children}
    </FilterAdsContext.Provider>
  );
};
