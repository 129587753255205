import { ReactComponent as DeliveryIcon } from "../../assets/icons/DeliveryIcon.svg";
import { ReactComponent as DeliveryGuaranteeIcon } from "../../assets/icons/DeliveryGuaranteeIcon.svg";
import { ReactComponent as PriceSideIcon } from "../../assets/icons/PriceSideIcon.svg";
import ModalBuyWithPaymentGuarantee from "./ModalBuyWithPaymentGuarantee";
import { ReactComponent as ShareIcon } from "../../assets/icons/Share.svg";
import { useState, useContext } from "react";
import Modal from "../Modal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import AuthContext from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function DeliveryOptions({
  data,
  mutateAddtoFavList,
  setIsAuth,
  setUser,
}) {
  const { isAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [
    showModalBuyWithPaymentGuarantee,
    setShowModalBuyWithPaymentGuarantee,
  ] = useState(false);

  return (
    <>
      {showModalBuyWithPaymentGuarantee ? (
        <Modal>
          <div id="modalInner">
            <div className="w-full h-[480px] sm:h-[580px] bg-[#fff] p-[20px] rounded-[13px] my-0 mx-auto -mt-[120px] sm:-mt-0 relative">
              <div className="w-[270px]  sm:w-[500px] max-w-[600px] h-[350px] mx-auto flex items-center flex-col mt-2 font-PoppinsRegular ">
                <ModalBuyWithPaymentGuarantee
                  isfarmgharSelfStock={data?.managed_by_farmghar === "1"}
                />
              </div>
              <div className=" absolute top-[-13px] right-[4px] mt-3">
                <button
                  className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                  onClick={() => {
                    setShowModalBuyWithPaymentGuarantee(false);
                  }}
                >
                  <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      <div className=" justify-between mx-2 mt-[20px] flex sm:hidden">
        <div className="space-x-2 items-center flex">
          <PriceSideIcon />
          <div>
            <p>Price</p>
            <p className="font-PoppinsBold text-Primary">
              RS {data?.price && data.price}{" "}
              <span>{data?.price_type && data.price_type}</span>
            </p>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          {/* hide when implement will show this */}
          <div className="hidden">
            <button>
              <ShareIcon />
            </button>
          </div>
          <div>
            <svg
              onClick={(e) => {
                e.preventDefault();
                if (!isAuth) {
                  navigate("/auth/login");
                }
                const target = e.currentTarget;

                const response =
                  isAuth &&
                  mutateAddtoFavList({
                    ad_id: data?.id,
                    key: localStorage.getItem("key"),
                  });

                Promise.resolve(response).then((res) => {
                  if (
                    res.web_status_code === 101 ||
                    res.web_status_code === 102 ||
                    res.web_status_code === 103
                  ) {
                    localStorage.removeItem("key");
                    setIsAuth(false);
                    setUser(null);
                    navigate("/auth/login");
                  }
                  if (res?.code === 200 || res?.code === 300) {
                    target.classList.add("animate-pulse");
                    target.classList.toggle("fill-[#FE251B]");
                    target.classList.toggle("stroke-[#FE251B]");
                    setTimeout(() => {
                      target.classList.remove("animate-pulse");
                    }, 1000);
                  }
                  if (res?.code === 201) {
                    alert(res?.message);
                  }
                });
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke={"black"}
              className={`w-6 h-6 cursor-pointer ${
                String(data?.is_fav) === "1" &&
                "fill-[#FE251B] stroke-[#FE251B]"
              }  `}
              fill="none"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center space-y-2 mt-[24px] mx-[10px]">
        {data?.is_delivery === "1" && (
          <div className="w-full h-[42px] rounded-[8px] text-[#2C9BDA]  bg-[#2C9BDA] flex  items-center bg-opacity-[0.15]">
            <div className="ml-[10px]">
              <DeliveryIcon />
            </div>
            <div className="text-center w-full font-PoppinsBold text-[14px] -ml-[34px]">
              Home Delivery
            </div>
          </div>
        )}

        {data?.is_delivery_ad === "1" && (
          <button
            onClick={() => setShowModalBuyWithPaymentGuarantee(true)}
            type="button"
            className="w-full h-[42px] text-[#CD4848E3] rounded-[8px]  bg-[#D25C5C] flex  items-center bg-opacity-[0.15]"
          >
            <div className="ml-[10px]">
              <DeliveryGuaranteeIcon />
            </div>
            <div className="text-center w-full font-PoppinsBold text-[14px] ml-[8px]">
              {data?.managed_by_farmghar === "1"
                ? "farmGhar Payment Guarantee"
                : "Buy With Payment Guarantee"}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        )}
      </div>
    </>
  );
}
