import BannerTop from "../Components/HomePageComponents/BannerTop";
import {
  useFetchMyFavouritesAdsList,
  useMutateAddtoFavList,
  useFetchHomePageCategories,
} from "../Middlewares/Api";
import UpdateMetaTags from "../Utils/UpdateMetaTags";
import { Link } from "react-router-dom";
import { ReactComponent as StarIcon } from "../assets/icons/star.svg";
import { ReactComponent as VerifiedIcon } from "../assets/icons/verified.svg";
import { ReactComponent as PackageIcon } from "../assets/icons/package.svg";
import { ReactComponent as DeliveryIcon } from "../assets/icons/delivery.svg";
import { ReactComponent as LocationAdIcon } from "../assets/icons/locationAd.svg";
import { ReactComponent as EyeIcon } from "../assets/icons/eye.svg";
import { ReactComponent as SoldOutIcon } from "../assets/icons/SoldOut.svg";
import AuthContext from "../Context/AuthContext";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function FavouritesAds() {
  const navigate = useNavigate();
  const { data: categories } = useFetchHomePageCategories();
  const { isAuth, setIsAuth, setUser } = useContext(AuthContext);
  const { mutateAsync: mutateAddtoFavList, isLoading: AddToFavourLoading } =
    useMutateAddtoFavList();
  const DisplaySoldOut = (e) => {
    e.preventDefault();
    return alert("This item has been sold and no longer available.");
  };
  const { data, isLoading, refetch } = useFetchMyFavouritesAdsList({
    key: localStorage.getItem("key") ? localStorage.getItem("key") : "",
  });

  const getShortName = (name) => {
    let str;

    if (name.length > 14) {
      str = name.substring(0, 20) + "...";
    } else {
      str = name;
    }
    return str;
  };

  useEffect(() => {
    if (
      data?.web_status_code === 101 ||
      data?.web_status_code === 102 ||
      data?.web_status_code === 103
    ) {
      localStorage.removeItem("key");
      setIsAuth(false);
      setUser(null);
      navigate("/auth/login");
    }
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || AddToFavourLoading) {
    return (
      <div className="flex items-center justify-center mt-20">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <>
      <UpdateMetaTags data={{ title: "My Favourites" }} />
      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>

        {data && data?.data?.length > 0 ? (
          <ul className="flex items-center justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-6 xl:grid-cols-4  pt-2">
              {data &&
                data.data.map((item, index) => (
                  <li key={index}>
                    <div
                      className={`rounded-[13px] border border-[#DBDED9] ${
                        item?.managed_by_farmghar === "1"
                          ? "bg-Primary"
                          : "bg-white"
                      } w-[255px] h-[312px]`}
                    >
                      <Link
                        to={
                          /sold/i.test(item.ad_status)
                            ? "#sold"
                            : `/${
                                (categories &&
                                  categories?.find(
                                    (cat) => cat.id === item.p_parent_cat_id
                                  )?.category_slug) ??
                                "item"
                              }/${item?.ad_slug}`
                        }
                        onClick={(e) => {
                          /sold/i.test(item.ad_status)
                            ? DisplaySoldOut(e)
                            : null;
                        }}
                        className="w-full h-full flex flex-col justify-center"
                      >
                        <div className="rounded-[8px] mt-[6.77px] mr-[5.95px] ml-[5px] w-[244.48px] h-[180.49px] ">
                          <div className="relative w-[244.48px] h-[180.49px]">
                            <figure className="w-full h-full">
                              <img
                                src={item.img_thumbnail && item.img_thumbnail}
                                className=" rounded-[9px] w-full h-full object-cover"
                                alt={item.ad_title && item.ad_title}
                              />
                            </figure>

                            {item?.is_star === "1" && item?.priority === "11" && (
                              <div className="absolute top-[-15px] left-[-15px]">
                                <StarIcon />
                              </div>
                            )}
                            {item.ad_status && /sold/i.test(item.ad_status) && (
                              <div className="absolute right-[85px] top-[86px]">
                                <SoldOutIcon />
                              </div>
                            )}

                            <div className="absolute top-[11.49px]  right-[9.84px] bg-gray-50 h-9 w-9 rounded-full flex justify-center items-center bg-opacity-25">
                              {" "}
                              <svg
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  if (!isAuth) {
                                    navigate("/auth/login");
                                  }

                                  const response =
                                    isAuth &&
                                    mutateAddtoFavList({
                                      ad_id: item?.id,
                                      key: localStorage.getItem("key"),
                                    });

                                  Promise.resolve(response).then((res) => {
                                    if (
                                      res.web_status_code === 101 ||
                                      res.web_status_code === 102 ||
                                      res.web_status_code === 103
                                    ) {
                                      localStorage.removeItem("key");
                                      setIsAuth(false);
                                      setUser(null);
                                      navigate("/auth/login");
                                    }
                                    if (
                                      res?.code === 200 ||
                                      res?.code === 300
                                    ) {
                                      refetch();
                                    }
                                    if (res?.code === 201) {
                                      alert(res?.message);
                                    }
                                  });
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke={"black"}
                                className={`w-6 h-6 cursor-pointer fill-[#FE251B] stroke-[#FE251B]`}
                                fill="none"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                                />
                              </svg>
                            </div>

                            {item.is_verified === "1" && (
                              <div className="absolute bottom-[7.62px] right-[6.43px]">
                                <VerifiedIcon />
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className={` ${
                            item?.managed_by_farmghar === "1"
                              ? "bg-white rounded-md ml-0 mr-0 px-3 m-[3.51px]"
                              : "m-[12.51px]"
                          }`}
                        >
                          <div
                            className={`flex ${
                              item?.managed_by_farmghar === "1"
                                ? "mt-[3px] pt-[3px]"
                                : null
                            }`}
                          >
                            {item.is_delivery === "1" && (
                              <div>
                                <DeliveryIcon />
                              </div>
                            )}
                            {item.is_delivery_ad === "1" && (
                              <div>
                                <PackageIcon />
                              </div>
                            )}
                          </div>

                          {/* for Ad */}
                          {item.ad_title && (
                            <div>
                              <h3 className="font-PoppinsBold text-[16px]">
                                {getShortName(item.ad_title)}
                              </h3>
                            </div>
                          )}

                          <div className="flex justify-between">
                            {item.posted_text && (
                              <p className="font-[10px] text-[#39342F] opacity-50">
                                {item.posted_text}
                              </p>
                            )}
                            {item.price && (
                              <p className="text-black font-[18px]">
                                RS {item.price}
                              </p>
                            )}
                          </div>

                          <div className="flex justify-between">
                            {item.district && (
                              <div className="flex items-center">
                                <div className="mr-[5.85px]">
                                  <LocationAdIcon />
                                </div>
                                <p>{item.district}</p>{" "}
                              </div>
                            )}
                            {item.detailviews && (
                              <div className="flex items-center">
                                <div className="opacity-50 mr-[5.85px]">
                                  <EyeIcon />
                                </div>
                                <p className="text-Primary">
                                  {item.detailviews}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        {item?.managed_by_farmghar === "1" && (
                          <div className="flex justify-center text-white font-bold text-[14px]">
                            farmGhar Self-stock
                          </div>
                        )}
                      </Link>
                    </div>
                  </li>
                ))}
            </div>
          </ul>
        ) : (
          <div className="flex justify-center items-center h-[300px]">
            <div className="flex flex-col items-center">
              <p className="text-[#39342F] font-PoppinsBold text-[18px] mt-[20px]">
                No Ads Found
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
