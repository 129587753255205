import { useState } from "react";
const ImagePreviewModal = ({
  clickedImg,
  setClickedImg,
  handelRotationRight,
  handelRotationLeft,
  isOneImage,
}) => {
  const [checked, setChecked] = useState(false);

  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedImg(null);
      document.body.style.overflow = "unset";
    }
  };

  return (
    <>
      {/* eslint-disable-next-line  */}
      <div
        className="dismiss   fixed top-0 left-0 right-0 w-full h-full bg-black bg-opacity-80 flex items-center z-[51] p-10 justify-center"
        onClick={handleClick}
      >
        <input
          type="checkbox"
          id="zoomCheck"
          className=""
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
        <label
          htmlFor="zoomCheck"
          className="h-full max-h-full flex justify-center items-center "
          // className={`max-w-full max-h-full flex justify-center  m-auto `}
        >
          <img
            src={clickedImg}
            alt="bigger pic"
            className="cursor-zoom-in max-h-full max-w-full object-contain"
            // className={`block max-w-[95%] max-h-[95%] shadow-md transform transition duration-500 ease-in-out cursor-zoom-in rounded-[9px]`}
          />
        </label>
        {/* eslint-disable-next-line  */}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="dismiss absolute top-6 right-2 sm:right-4 text-white text-3xl cursor-pointer z-[999] h-6 w-6"
          onClick={handleClick}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>

        {!isOneImage && (
          //eslint-disable-next-line
          <div
            onClick={handelRotationLeft}
            className="flex justify-between items-center cursor-pointer bg-white rounded-full absolute top-1/2 w-10 h-10 left-0 z-[999] opacity-50 sm:opacity-100 sm:ml-4"
          >
            <div className="m-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className=" w-5 h-5 mx-2"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        )}
        {!isOneImage && (
          //eslint-disable-next-line
          <div
            onClick={handelRotationRight}
            className=" flex justify-between cursor-pointer  items-center bg-white rounded-full absolute top-1/2 w-10 h-10 right-0 z-[999] opacity-50 sm:opacity-100 sm:mr-4"
          >
            <div className="m-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className=" w-5 h-5 mx-2"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ImagePreviewModal;
