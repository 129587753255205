import StaticBottomHPaage from "../../assets/images/StaticBottomHPage.webp";
import { ReactComponent as PlayStoreIcon } from "../../assets/slider/PlayStore.svg";
import { ReactComponent as AppStoreIcon } from "../../assets/slider/AppStore.svg";
import CallToActionBottomBack from "../../assets/images/CallToAction.png";

export default function CallToActionBottom() {
  return (
    <div className="pt-3 rounded-[15px]">
      <div
        className="w-full rounded-[15px]"
        style={{
          backgroundImage: `url(${CallToActionBottomBack})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="flex justify-around rounded-[15px]  flex-col items-center md:items-start md:flex-row ">
          <div className="my-auto ml-[0px] md:ml-[62px] pt-[49px]">
            <p className="text-[21px] lg:text-[36px]  font-PoppinsBold text-left">
              Pakistan No 1 Livestock{" "}
              <span className="block">Selling & Buying Platform</span>
            </p>
            <p className="text-[18px] font-PoppinsRegular text-left">
              Get The App Now!
            </p>
            <div className="flex  justify-center md:justify-start mt-">
              <a
                href="https://play.google.com/store/apps/details?id=com.farmghar.app"
                className="mr-[10.53px]"
                target={"_blank"}
                rel="noreferrer"
              >
                <PlayStoreIcon />
              </a>
              <a
                href="https://apps.apple.com/pk/app/farmghar-%D8%A2%D9%86-%D9%84%D8%A7%D8%A6%D9%86-%D9%85%D9%88%DB%8C%D8%B4%DB%8C-%D9%85%D9%86%DA%88%DB%8C/id1624237338"
                target={"_blank"}
                rel="noreferrer"
              >
                <AppStoreIcon />
              </a>
            </div>
          </div>

          <div className=" mx-auto rounded-[15px] ">
            <img
              src={StaticBottomHPaage}
              alt="Hero"
              className="max-h-[352px] h-full w-full max-w-[394px] rounded-[15px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
