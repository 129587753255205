import { createContext } from "react";
import { useState, useEffect } from "react";
const LocationContext = createContext();

export default LocationContext;

export const LocationContextProvider = ({ children }) => {
  const [locationStates, setLocationStates] = useState({
    address: "",
    selectedPlace: null,
    currentLocation: null,
    locality: null,
    latitude: null,
    longitude: null,
    lat2: null,
    lng2: null,
    isViewNearby: false,
  });

  useEffect(() => {}, [locationStates]);

  return (
    <LocationContext.Provider
      value={{
        locationStates,
        setLocationStates,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
