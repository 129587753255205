import { useMultistepFormPostAd } from "../PostAdComponents/useMultistepFormPostAd";
import QurbaniForm from "./QurbaniForm";
import Select, { components } from "react-select";
import { Style } from "../../Styles/ReactSelectStyles";
import { ReactComponent as CaretDownIcon } from "../../assets/icons/CaretDown.svg";
// import QurbaniPayment from "./QurbaniPayment";
// import QurbaniPaymentHandler from "./QurbaniPaymentHandler";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { CartContext } from "../../Context/CartContext";
import QurbaniPaymentHelpAreUs from "./QurbaniPaymentHelpAreUs";
import NameContact from "../QurbaniComponents/NameContact";
export default function QurbaniOrder({
  refetch,
  pickUpAddress,
  OrderQurbaniPackage,
  OrderRequestInProcess,
  formik,
  setValidationSchema,
  Yup,
}) {
  const { cartItems, setCartItems, totalPrice } = useContext(CartContext);

  const navigate = useNavigate();

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };
  const pickUpAddressOptions = pickUpAddress?.data?.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
  const {
    step,
    isLastStep,
    isFirstStep,
    // goTo,
    back,
    next,
    // steps,
    currentStepIndex,
  } = useMultistepFormPostAd([
    // eslint-disable-next-line react/jsx-key
    <NameContact formik={formik} />,
    // eslint-disable-next-line react/jsx-key
    <QurbaniForm
      formik={formik}
      DropdownIndicator={DropdownIndicator}
      Select={Select}
      Style={Style}
      pickUpAddressOptions={pickUpAddressOptions}
      DeliveryAndPaymentOptions={pickUpAddress?.data2[0] ?? []}
    />,
    // eslint-disable-next-line react/jsx-key
    <QurbaniPaymentHelpAreUs formik={formik} />,
    // // eslint-disable-next-line react/jsx-key
    // <QurbaniPayment formik={formik} />,
    // // eslint-disable-next-line react/jsx-key
    // <QurbaniPaymentHandler formik={formik} />,
  ]);
  const handleQurbaniForm = () => {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        const payl = {
          name: formik.values.FullName,
          mobileNumber: formik.values.ContactNumber,
          package_id: cartItems.map((item) => item.id),
          quantity: cartItems.map((item) => item.quantity),
          isDelivery: formik.values.DeliveryOption === "SelfPickUp" ? "0" : "1",
          delivery_day: formik.values.PickupDay.value,
          cnic: formik.values.Cnic,
          email: formik.values.Email,
          city: formik.values.City.label,
          address:
            formik.values.HouseNumber +
            " " +
            formik.values.Block +
            " " +
            formik.values.Society,
          // price: totalPrice,
          // delivery_price:
          //   formik.values.DeliveryOption === "SelfPickUp"
          //     ? "0"
          //     : 0 +
          //       cartItems.array.forEach((element) => {
          //         element.DeliveryPrice;
          //       }),
          alternative_phone: formik.values.AlternateContactNumber,
          pickup_adress_id: formik.values.PickupPoint.value,
          payment_option:
            formik.values.PaymentOption === "FullPayment" ? "2" : "1",
        };

        OrderQurbaniPackage(payl).then((res) => {
          if (res.code === 200) {
            const items = JSON.parse(JSON.stringify(cartItems));
            const formikValues = JSON.parse(JSON.stringify(formik.values));

            navigate("/qurbani/checkout/order-confirmed", {
              state: {
                data: [...items],
                formik: { values: formikValues },
                pickUpAddress: pickUpAddress,
                tracking_number: res?.data?.tracking_number ?? "",
                totalPrice: totalPrice,
              },
            });
            setCartItems([]);
            formik.resetForm();
          } else {
            let messages = "";
            Array.isArray(res?.data) &&
              res?.data?.forEach((element) => {
                messages += element + "\n";
              });

            messages +=
              "We have removed already added products from your cart. Please add new products to complete your order.";
            setCartItems([]);
            alert(messages);
          }
        });
        refetch();

        // next();
      }
      formik.setTouched({
        ...formik.touched,
        ...Object.keys(errors).reduce((acc, curr) => {
          if (!acc[curr]) {
            acc[curr] = true;
          }
          return acc;
        }, {}),
      });
    });
  };
  const handleValidateFields = () => {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        next();
      }
      formik.setTouched({
        ...formik.touched,
        ...Object.keys(errors).reduce((acc, curr) => {
          if (!acc[curr]) {
            acc[curr] = true;
          }
          return acc;
        }, {}),
      });
    });
  };

  useEffect(() => {
    if (currentStepIndex === 1) {
      setValidationSchema({
        FullName: Yup.string().required("Required"),
        PaymentMethodHelpAreUs: Yup.string().required("Required"),
        ContactNumber: Yup.string()
          .required("Required")
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(11, "Must be exactly 11 digits")
          .max(11, "Must be exactly 11 digits"),
        AlternateContactNumber: Yup.string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(11, "Must be exactly 11 digits")
          .max(11, "Must be exactly 11 digits"),
        HouseNumber: Yup.string().required("Required"),
        Block: Yup.string(),
        Society: Yup.string(),
        Cnic: Yup.string().matches(/^\d{5}-\d{7}-\d{1}$/, "Invalid cnic"),
        DeliveryOption: Yup.string().required("Required"),
        PaymentOption: Yup.string().required("Required"),
        PickupPoint: Yup.object().when("DeliveryOption", {
          is: "SelfPickUp",
          then: Yup.object().required("Required"),
        }),
        PickupDay: Yup.object().required("Required"),

        City: Yup.object().required("Required"),
        Email: Yup.string().email("Invalid email address"),
        PaymentMethod: Yup.string().required("Required"),
        JazzCashNumber: Yup.string().when("PaymentMethod", {
          is: "JazzCash",
          then: Yup.string()
            .required("Required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(11, "Must be exactly 11 digits")
            .max(11, "Must be exactly 11 digits"),
        }),
        CardNumber: Yup.string().when("PaymentMethod", {
          is: "Card",
          then: Yup.string()
            .required("Required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(16, "Must be exactly 16 digits")
            .max(16, "Must be exactly 16 digits"),
        }),
        CardExpiry: Yup.string().when("PaymentMethod", {
          is: "Card",
          then: Yup.string()
            .required("Required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(4, "Must be exactly 4 digits")
            .max(4, "Must be exactly 4 digits"),
        }),
        CardCVV: Yup.string().when("PaymentMethod", {
          is: "Card",
          then: Yup.string()
            .required("Required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(3, "Must be exactly 3 digits")
            .max(3, "Must be exactly 3 digits"),
        }),
        FullNameOnCard: Yup.string().when("PaymentMethod", {
          is: "Card",
          then: Yup.string().required("Required"),
        }),
      });
    } else {
      setValidationSchema({
        FullName: Yup.string().required("Required"),
        PaymentMethodHelpAreUs: Yup.string().required("Required"),
        ContactNumber: Yup.string()
          .required("Required")
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(11, "Must be exactly 11 digits")
          .max(11, "Must be exactly 11 digits"),
        AlternateContactNumber: Yup.string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(11, "Must be exactly 11 digits")
          .max(11, "Must be exactly 11 digits"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex]);

  if (OrderRequestInProcess) {
    return (
      <>
        <div className="flex items-center justify-center mt-20">
          <div className="spinner" style={{ width: "80px" }}></div>
          <svg
            className="absolute"
            width="59"
            height="58"
            viewBox="0 0 59 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_227_20)">
              <path
                d="M29.4133 58C45.4296 58 58.4133 45.0163 58.4133 29C58.4133 12.9837 45.4296 0 29.4133 0C13.3971 0 0.41333 12.9837 0.41333 29C0.41333 45.0163 13.3971 58 29.4133 58Z"
                fill="#3ED400"
              />
              <mask
                id="mask0_227_20"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="13"
                y="12"
                width="25"
                height="32"
              >
                <path
                  d="M37.3993 12H13.4133V43.8182H37.3993V12Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_227_20)">
                <path
                  d="M37.0954 17.7479C36.7394 16.478 36.0904 15.3108 35.2012 14.3412C34.664 13.7745 34.0547 13.2819 33.3891 12.8761C32.956 12.607 32.5229 12.2853 32.0219 12.1619C31.2299 11.9606 30.4021 11.9484 29.6045 12.1265C28.784 12.3181 28.0625 12.7671 27.3776 13.2476L27.105 13.4432C26.3524 13.9824 25.6711 14.6159 25.0776 15.3284C23.3295 17.4577 22.0733 19.9512 21.4 22.6288C21.1391 23.6331 20.7621 23.7079 19.9206 23.3075C17.8033 22.298 15.8582 21.1283 14.8928 18.8204C14.7206 18.4095 14.4779 17.9881 13.9117 18.2257C13.3025 18.4817 13.3325 18.9556 13.5764 19.4571C13.7778 19.9096 14.0088 20.3482 14.2679 20.7699C15.5725 22.726 17.4759 23.9049 19.5906 24.7398C20.5207 25.1061 20.7882 25.5787 20.7034 26.5777C20.509 28.8843 20.5533 31.204 20.9865 33.4896C21.6061 36.7584 22.4137 39.9695 24.9485 42.3601C27.5068 44.773 31.0461 44.114 32.6168 40.9607C33.2026 39.7792 33.6605 38.5425 34.228 37.3531C34.498 36.7847 34.6285 36.232 33.9762 35.9563C33.35 35.6938 33.1504 36.3436 32.9234 36.744C32.1406 38.129 31.6358 39.6663 30.6586 40.9449C29.4845 42.4848 27.9334 42.7211 26.3339 41.6407C25.4442 41.0381 24.8193 40.2045 24.3666 39.2488C22.6707 35.6544 21.9388 31.8446 22.0562 27.8826C22.1201 25.7231 22.1723 25.7664 24.3484 26.1537C28.103 26.8232 31.7845 26.8757 35.0016 24.3499C37.1828 22.638 37.8638 20.4194 37.0954 17.7479ZM33.7414 23.4204C31.9632 24.6518 29.9581 25.0798 27.7833 24.98C26.3926 25.0628 25.0046 24.7503 23.6087 24.4851C22.8664 24.3433 22.4763 24.1031 22.745 23.1723C23.6269 20.103 24.9994 17.337 27.3502 15.1433C29.8537 12.8078 32.3076 13.0599 34.4902 15.8181C36.6727 18.5762 36.3649 21.6009 33.7414 23.4204Z"
                  fill="white"
                />
                <path
                  d="M27.5811 30.7441C27.5354 31.1799 27.3202 31.4805 26.8649 31.4635C26.7832 31.4642 26.7021 31.4486 26.6264 31.4177C26.5507 31.3867 26.4818 31.341 26.4238 31.2832C26.3657 31.2253 26.3196 31.1565 26.288 31.0807C26.2565 31.0048 26.2401 30.9234 26.24 30.8412C26.2047 30.387 26.4461 30.0811 26.8662 30.0391C27.3136 29.9879 27.5459 30.2925 27.5811 30.7441Z"
                  fill="white"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_227_20">
                <rect width="59" height="58" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <p className="text-center mt-2">
          Your Qurbani Booking Request is in progress
        </p>
      </>
    );
  }
  return (
    <div>
      {" "}
      <form onSubmit={formik.handleSubmit}>
        {step}
        {isLastStep && formik.values.PaymentMethodHelpAreUs === "other" && (
          <div className="flex justify-around items-center flex-row-reverse">
            {" "}
            <button
              type="Button"
              className="bg-Primary my-2 rounded-[10px] mx-auto font-PoppinsBold text-white z-10 flex items-center justify-center max-w-[130px] w-full h-[42px]"
              onClick={(e) => {
                e.preventDefault();
                handleQurbaniForm();
              }}
            >
              Order Now
            </button>
            <button
              type="Button"
              className="bg-Primary  my-2 rounded-[10px] mx-auto font-PoppinsBold text-white flex items-center justify-center max-w-[130px] w-full h-[42px]"
              onClick={(e) => {
                e.preventDefault();
                back();
              }}
            >
              Back
            </button>
          </div>
        )}
        {isFirstStep ? (
          <button
            type="Button"
            className="bg-Primary mt-10 my-2 rounded-[10px] mx-auto font-PoppinsBold text-white  flex items-center justify-center max-w-[130px] w-full h-[42px]"
            onClick={(e) => {
              e.preventDefault();
              handleValidateFields();
            }}
          >
            Next
          </button>
        ) : (
          !isLastStep && (
            <div className="flex justify-around flex-row-reverse">
              <button
                type="Button"
                className="bg-Primary mt-10 my-2 rounded-[10px] mx-auto font-PoppinsBold text-white flex items-center justify-center max-w-[130px] w-full h-[42px]"
                onClick={(e) => {
                  e.preventDefault();
                  handleValidateFields();
                }}
              >
                Next
              </button>
              <button
                type="Button"
                className="bg-Primary mt-10 my-2 rounded-[10px] mx-auto font-PoppinsBold text-white flex items-center justify-center max-w-[130px] w-full h-[42px]"
                onClick={(e) => {
                  e.preventDefault();
                  back();
                }}
              >
                Back
              </button>
            </div>
          )
        )}
      </form>
    </div>
    // ) : isLastStep ? (
    //   <button
    //     className="bg-Primary my-2 rounded-[10px] mx-auto font-PoppinsBold text-white z-10 flex items-center justify-center max-w-[130px] w-full h-[42px]"
    //     onClick={(e) => {
    //       e.preventDefault();
    //       handleLastStepSuccessFullyOrdered();
    //     }}
    //     type="button"
    //   >
    //     Finish
    //   </button>
    // ) : (
    //   <button
    //     className="bg-Primary my-2 rounded-[10px] mx-auto font-PoppinsBold text-white z-10 flex items-center justify-center max-w-[130px] w-full h-[42px]"
    //     onClick={(e) => {
    //       e.preventDefault();
    //       handleQurbaniForm(e);
    //     }}
    //     type="button"
    //   >
    //     Pay Now
    //   </button>
    // )}
    //   </form>
    // </div>
  );
}
