export default function QurbaniForm({
  formik,
  DropdownIndicator,
  Select,
  Style,
  pickUpAddressOptions,
  DeliveryAndPaymentOptions,
}) {
  return (
    <div className="pt-4 pl-4">
      <h3 className="font-PoppinsBold">Personal Information</h3>

      <div className="grid grid-cols-2 gap-4 w-full h-full p-3">
        <div className="flex flex-col justify-center mt-1 col-span-2">
          <label htmlFor="FullName" className="block text-sm  text-TextColor">
            Full Name *
          </label>
          <input
            id="FullName"
            name="FullName"
            type="text"
            className="placeholder:text-[10px] sm:placeholder:text-[16px] w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
            value={formik.values.FullName ? formik.values.FullName : ""}
            label="FullName"
            placeholder="Ahmed"
            onChange={(e) => {
              formik.setFieldValue("FullName", e.target.value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.FullName && formik.errors.FullName ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.FullName}
            </span>
          ) : null}
        </div>
        <div className="flex flex-col justify-center mt-1 col-span-2">
          <label
            htmlFor="ContactNumber"
            className="block text-sm  text-TextColor"
          >
            Contact Number *
          </label>
          <input
            id="ContactNumber"
            name="ContactNumber"
            type="text"
            className=" placeholder:text-[10px] sm:placeholder:text-[16px] w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
            value={
              formik.values.ContactNumber ? formik.values.ContactNumber : ""
            }
            label="ContactNumber"
            placeholder="Mobile Number"
            onChange={(e) => {
              formik.setFieldValue("ContactNumber", e.target.value);
            }}
            onBlur={formik.handleBlur}
          />
          <span className="text-[11px] text-TextColor tracking-widest	">
            03101111222
          </span>
          {formik.touched.ContactNumber && formik.errors.ContactNumber ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.ContactNumber}
            </span>
          ) : null}
        </div>
        <div className="flex flex-col justify-center mt-1 col-span-2">
          <label htmlFor="Cnic" className="block text-sm  text-TextColor">
            CNIC
          </label>
          <input
            id="Cnic"
            name="Cnic"
            type="text"
            className="placeholder:text-[10px] sm:placeholder:text-[16px] w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
            value={formik.values.Cnic ? formik.values.Cnic : ""}
            label="Cnic"
            placeholder="30000-0000000-0"
            onChange={(e) => {
              const digitsOnly = e.target.value.replace(/\D/g, "");

              // Add dashes to the formatted value
              let formattedValue = "";

              for (let i = 0; i < digitsOnly.length; i++) {
                if (i === 5 || i === 12) {
                  formattedValue += "-";
                }
                formattedValue += digitsOnly[i];
              }
              if (formattedValue.length > 15) return;
              formik.setFieldValue("Cnic", formattedValue);
            }}
            onBlur={formik.handleBlur}
          />
          <span className="text-[11px] text-TextColor 	">
            Enter your CNIC without dashes
          </span>
          {formik.touched.Cnic && formik.errors.Cnic ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Cnic}
            </span>
          ) : null}
        </div>
        <div className="flex flex-col justify-center mt-1">
          <p className="block text-sm  text-TextColor">Home Address *</p>
          <input
            id="HouseNumber"
            name="HouseNumber"
            type="text"
            className="placeholder:text-[10px] sm:placeholder:text-[16px] w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
            value={formik.values.HouseNumber ? formik.values.HouseNumber : ""}
            label="HouseNumber"
            placeholder="House NO# Street."
            onChange={(e) => {
              formik.setFieldValue("HouseNumber", e.target.value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.HouseNumber && formik.errors.HouseNumber ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.HouseNumber}
            </span>
          ) : null}
        </div>
        <div className="">
          <label htmlFor="City" className="block text-sm  text-TextColor">
            City *
          </label>
          <Select
            styles={Style}
            id="City"
            name="City"
            maxMenuHeight={140}
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={[{ label: "Lahore", value: "1" }]}
            value={formik.values.City ? formik.values.City : ""}
            label="City"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onChange={(value) => {
              formik.setFieldValue("City", value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.City && formik.errors.City ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.City}
            </span>
          ) : null}
        </div>
        <div className="flex flex-col justify-center mt-1 col-span-2">
          <input
            id="Society"
            name="Society"
            type="text"
            className="placeholder:text-[10px] sm:placeholder:text-[16px] w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
            value={formik.values.Society ? formik.values.Society : ""}
            label="Society"
            placeholder="Society"
            onChange={(e) => {
              formik.setFieldValue("Society", e.target.value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.Society && formik.errors.Society ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Society}
            </span>
          ) : null}
        </div>
        <div className="flex flex-col justify-center mt-1 col-span-2">
          <label htmlFor="Email" className="block text-sm  text-TextColor">
            Email
          </label>
          <input
            id="Email"
            name="Email"
            type="text"
            className="placeholder:text-[10px] sm:placeholder:text-[16px] w-full h-[37.5px] rounded-[10px] text-[#39342F]  border-2 border-[#cccccc] focus:outline-none pl-3"
            value={formik.values.Email ? formik.values.Email : ""}
            label="Email"
            placeholder="name@email.com"
            onChange={(e) => {
              formik.setFieldValue("Email", e.target.value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.Email && formik.errors.Email ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.Email}
            </span>
          ) : null}
        </div>
      </div>
      <h3 className="font-PoppinsBold">Order Detail</h3>
      <div className="grid grid-cols-2 gap-4 w-full h-full p-3">
        <p className="font-PoppinsBold text-sm whitespace-nowrap">
          Delivery option
        </p>
        <div className="col-span-2  mx-auto space-y-2 sm:space-y-0">
          <label
            className={`QurbaniFormRadioBtnLabel w-full sm:w-[245px]  ml-0 sm:ml-2  ${
              formik.values.DeliveryOption === "HomeDelivery"
                ? "bg-Primary bg-opacity-[0.1] text-Primary"
                : "bg-[#f2f2f2] text-TextColor"
            } rounded-lg py-1 px-2 inline-flex items-center justify-center text-[12px]`}
          >
            <input
              type="radio"
              disabled={DeliveryAndPaymentOptions?.is_delivery === "0"}
              className="QurbaniFormRadioBtn h-5 w-5 text-gray-600"
              name="delivery-option"
              value="HomeDelivery"
              checked={formik.values.DeliveryOption === "HomeDelivery"}
              onChange={(e) => {
                formik.setFieldValue("PickupPoint", "");
                formik.setFieldValue("DeliveryOption", e.target.value);
              }}
            />
            <span className="space-x-2">
              <svg
                className="mr-2"
                width="23"
                height="24"
                viewBox="0 0 23 24"
                fill="none"
                stroke={
                  formik.values.DeliveryOption === "HomeDelivery"
                    ? "#3ed400"
                    : "none"
                }
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.39425 15.6178V15.6429C2.20798 15.6429 2.02118 15.653 1.83546 15.6396C1.61119 15.6245 1.4309 15.4727 1.40538 15.1028C1.37822 14.7086 1.52864 14.4091 1.7703 14.3831C2.2574 14.3303 2.74939 14.3378 3.23813 14.3638C3.42276 14.3731 3.54168 14.6046 3.54657 14.8998C3.55309 15.3032 3.38475 15.5498 3.15287 15.6044C2.90524 15.6631 2.6473 15.6178 2.39425 15.6178V15.6178Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M1.37036 12.9276C1.20582 12.9267 1.02933 13.0005 0.881628 12.8127C0.648122 12.6919 0.624771 12.4117 0.693194 12.1173C0.770306 11.786 0.959283 11.646 1.17758 11.6409C1.68695 11.6283 2.19632 11.6309 2.70623 11.6309C3.52133 11.63 4.33643 11.6317 5.15208 11.6309C5.37309 11.6309 5.5713 11.7231 5.63212 12.0829C5.69023 12.4285 5.599 12.6952 5.36332 12.8085C5.21561 12.993 5.04021 12.9301 4.87458 12.9301C3.70651 12.9343 2.53844 12.9343 1.37036 12.9276V12.9276Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M3.50805 9.68975C3.52488 9.25276 3.71494 9.03972 3.98266 9.02966C4.45619 9.01204 4.92972 9.02462 5.40379 9.02462V9.02127C5.84365 9.02127 6.28351 9.02546 6.72337 9.01959C6.9748 9.01624 7.17464 9.15463 7.19201 9.56478C7.20993 9.98919 7.03453 10.2844 6.76627 10.292C5.80238 10.3197 4.83794 10.3138 3.87351 10.3012C3.63566 10.2987 3.49393 10.0596 3.50805 9.68975V9.68975Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M8.85379 20.2054C8.10603 20.2163 7.47284 21.1314 7.48642 22.1807C7.49837 23.0932 7.99253 23.7701 8.64309 23.7659C9.39194 23.76 10.0436 22.8575 10.0425 21.8259C10.0425 20.8957 9.52824 20.1962 8.85379 20.2054V20.2054ZM8.72183 22.8861C8.37755 22.8274 8.12938 22.6017 8.13481 22.0708C8.14132 21.4493 8.4123 21.1498 8.79623 21.1222C9.15735 21.0962 9.40063 21.3864 9.38597 21.9467C9.36913 22.5648 9.0672 22.8081 8.72183 22.8861V22.8861Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M16.4249 10.9534C16.2343 13.6626 16.0589 16.3742 15.8786 19.0851C15.7999 20.2677 15.3502 20.9513 14.5856 20.9647C13.3464 20.9865 12.1067 20.9689 10.8675 20.974C10.5585 20.9756 10.3174 20.8054 10.1105 20.4422C9.43981 19.2654 8.47157 19.1664 7.74444 20.2602C7.38387 20.8028 6.99831 21.0083 6.51175 20.9832C5.86716 20.9496 5.22149 20.9714 4.57636 20.9765C4.28421 20.9798 3.98608 20.9228 3.98499 20.3675C3.98391 19.8089 4.26846 19.6462 4.58994 19.6454C5.25244 19.6429 5.91441 19.6454 6.57691 19.6437C6.71213 19.6437 6.84952 19.6529 6.9831 19.6252C7.25191 19.5682 7.4322 19.3308 7.42351 18.8997C7.41428 18.4351 7.19706 18.3067 6.92826 18.3076C6.06211 18.3101 5.19651 18.3076 4.33037 18.3067C3.97413 18.3067 3.61736 18.3 3.26058 18.3084C2.94996 18.3159 2.6274 18.2589 2.64804 17.6567C2.6665 17.1165 2.96191 16.9857 3.28556 16.9882C4.72896 16.9983 6.17181 16.9949 7.6152 16.9932C7.83567 16.9932 8.05778 17.0033 8.27662 16.9664C8.559 16.9178 8.77133 16.6955 8.74906 16.2098C8.72788 15.7469 8.50415 15.6395 8.23752 15.6412C7.11723 15.6462 5.9964 15.6412 4.87558 15.6428C4.59645 15.6437 4.34068 15.5539 4.33905 15.0331C4.33743 14.55 4.56713 14.3671 4.84951 14.3319C4.96789 14.3176 5.08736 14.3268 5.20629 14.3268C6.30974 14.3268 7.41319 14.3277 8.51718 14.3277C8.61873 14.3277 8.72082 14.3252 8.82237 14.3277C9.15906 14.3377 9.48759 14.1952 9.4952 13.6181C9.50443 12.9689 9.13896 12.9756 8.82129 12.9781C8.17616 12.9832 7.53103 12.979 6.8859 12.979C6.60298 12.979 6.35209 12.8641 6.36784 12.344C6.38196 11.8701 6.61004 11.6797 6.89839 11.6781C7.88292 11.6739 8.86799 11.6789 9.85251 11.6756C9.98719 11.6747 10.13 11.6881 10.2538 11.6261C10.4835 11.5103 10.6318 11.2453 10.6052 10.8569C10.5791 10.482 10.395 10.3294 10.1707 10.3243C9.66136 10.3117 9.152 10.3126 8.64263 10.3185C8.36568 10.321 8.09742 10.2555 8.09307 9.73384C8.08927 9.21633 8.32766 9.01922 8.64154 9.02006H12.105C13.1748 9.0209 14.2446 9.01419 15.3144 9.02258C16.1637 9.02929 16.5156 9.67345 16.4249 10.9534Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M18.9309 20.2117C18.1815 20.2033 17.52 21.126 17.5342 22.1585C17.5466 23.0777 18.043 23.7722 18.6876 23.7722C19.4462 23.7714 20.0805 22.8756 20.0745 21.8121C20.0696 20.8794 19.5972 20.2193 18.9309 20.2117V20.2117ZM19.4288 21.9328C19.412 22.5586 19.1269 22.8068 18.7658 22.8865C18.4383 22.8362 18.2092 22.6273 18.1891 22.0863C18.169 21.5386 18.4926 21.0983 18.889 21.1209C19.2241 21.1402 19.4435 21.4002 19.4288 21.9328Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M11.2093 7.77591C11.3434 5.2521 12.1346 3.28355 13.4542 1.87277C15.426 -0.235853 17.5449 -0.690456 19.6921 1.16151C22.0614 3.20555 22.5745 8.05773 20.8509 11.3054C20.53 11.911 20.1406 11.5285 19.7654 11.5864C20.3323 10.5664 20.7581 9.48025 20.947 8.19444C21.4694 4.63059 19.7219 1.38462 17.1995 1.20848C14.4214 1.01389 12.0445 4.31773 12.0179 7.91933C12.0124 8.62892 11.6611 8.29342 11.4542 8.34458C11.1468 8.42007 11.1946 8.0594 11.2093 7.77591V7.77591Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M21.3607 14.7277C21.3439 14.6825 21.3254 14.6388 21.308 14.5935C20.2133 11.767 20.879 12.3641 18.8345 12.2895C18.3523 12.2895 17.945 12.3021 17.5383 12.2861C17.0457 12.2677 16.7932 12.5923 16.7449 13.3799C16.6108 15.5682 16.4527 17.7523 16.2996 19.9372C16.2535 20.5906 16.4348 20.9211 16.8568 20.9773C17.1983 21.0217 17.4443 20.8926 17.6871 20.4438C18.3436 19.231 19.5117 19.1983 20.0916 20.3029C20.4289 20.9454 21.1088 21.2063 21.598 20.8833C21.8397 20.724 21.9532 20.4355 21.9923 20.0404C22.1791 18.1521 21.9686 16.3812 21.3607 14.7277V14.7277ZM20.803 16.3692C20.4148 16.3885 20.0254 16.3742 19.6366 16.3734C19.2646 16.3734 18.8926 16.3642 18.5206 16.3767C18.197 16.3876 18.0536 16.1813 18.0938 15.6663C18.1497 14.9408 18.1888 14.2119 18.2377 13.4847C18.267 13.0502 18.4185 12.8255 18.7096 12.8523C18.7433 12.8557 18.7775 12.8489 18.8112 12.8523C19.2613 12.9127 19.8522 12.5336 20.134 12.9982C20.5945 13.7582 20.8937 14.773 21.1978 15.7284C21.3477 16.1981 21.0604 16.3558 20.803 16.3683V16.3692Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M16.4249 10.9534C16.2343 13.6626 16.0589 16.3742 15.8786 19.0851C15.7999 20.2677 15.3502 20.9513 14.5856 20.9647C13.3464 20.9865 12.1067 20.9689 10.8675 20.974C10.5585 20.9756 10.3174 20.8054 10.1105 20.4422C9.43981 19.2654 8.47157 19.1664 7.74444 20.2602C7.38387 20.8028 6.99831 21.0083 6.51175 20.9832C5.86716 20.9496 5.22149 20.9714 4.57636 20.9765C4.28421 20.9798 3.98608 20.9228 3.98499 20.3675C3.98391 19.8089 4.26846 19.6462 4.58994 19.6454C5.25244 19.6429 5.91441 19.6454 6.57691 19.6437C6.71213 19.6437 6.84952 19.6529 6.9831 19.6252C7.25191 19.5682 7.4322 19.3308 7.42351 18.8997C7.41428 18.4351 7.19706 18.3067 6.92826 18.3076C6.06211 18.3101 5.19651 18.3076 4.33037 18.3067C3.97413 18.3067 3.61736 18.3 3.26058 18.3084C2.94996 18.3159 2.6274 18.2589 2.64804 17.6567C2.6665 17.1165 2.96191 16.9857 3.28556 16.9882C4.72896 16.9983 6.17181 16.9949 7.6152 16.9932C7.83567 16.9932 8.05778 17.0033 8.27662 16.9664C8.559 16.9178 8.77133 16.6955 8.74906 16.2098C8.72788 15.7469 8.50415 15.6395 8.23752 15.6412C7.11723 15.6462 5.9964 15.6412 4.87558 15.6428C4.59645 15.6437 4.34068 15.5539 4.33905 15.0331C4.33743 14.55 4.56713 14.3671 4.84951 14.3319C4.96789 14.3176 5.08736 14.3268 5.20629 14.3268C6.30974 14.3268 7.41319 14.3277 8.51718 14.3277C8.61873 14.3277 8.72082 14.3252 8.82237 14.3277C9.15906 14.3377 9.48759 14.1952 9.4952 13.6181C9.50443 12.9689 9.13896 12.9756 8.82129 12.9781C8.17616 12.9832 7.53103 12.979 6.8859 12.979C6.60298 12.979 6.35209 12.8641 6.36784 12.344C6.38196 11.8701 6.61004 11.6797 6.89839 11.6781C7.88292 11.6739 8.86799 11.6789 9.85251 11.6756C9.98719 11.6747 10.13 11.6881 10.2538 11.6261C10.4835 11.5103 10.6318 11.2453 10.6052 10.8569C10.5791 10.482 10.395 10.3294 10.1707 10.3243C9.66136 10.3117 9.152 10.3126 8.64263 10.3185C8.36568 10.321 8.09742 10.2555 8.09307 9.73384C8.08927 9.21633 8.32766 9.01922 8.64154 9.02006H12.105C13.1748 9.0209 14.2446 9.01419 15.3144 9.02258C16.1637 9.02929 16.5156 9.67345 16.4249 10.9534Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M21.3607 14.7277C21.3439 14.6825 21.3254 14.6388 21.308 14.5935C20.2133 11.767 20.879 12.3641 18.8345 12.2895C18.3523 12.2895 17.945 12.3021 17.5383 12.2861C17.0457 12.2677 16.7932 12.5923 16.7449 13.3799C16.6108 15.5682 16.4527 17.7523 16.2996 19.9372C16.2535 20.5906 16.4348 20.9211 16.8568 20.9773C17.1983 21.0217 17.4443 20.8926 17.6871 20.4438C18.3436 19.231 19.5117 19.1983 20.0916 20.3029C20.4289 20.9454 21.1088 21.2063 21.598 20.8833C21.8397 20.724 21.9532 20.4355 21.9923 20.0404C22.1791 18.1521 21.9686 16.3812 21.3607 14.7277V14.7277ZM20.803 16.3692C20.4148 16.3885 20.0254 16.3742 19.6366 16.3734C19.2646 16.3734 18.8926 16.3642 18.5206 16.3767C18.197 16.3876 18.0536 16.1813 18.0938 15.6663C18.1497 14.9408 18.1888 14.2119 18.2377 13.4847C18.267 13.0502 18.4185 12.8255 18.7096 12.8523C18.7433 12.8557 18.7775 12.8489 18.8112 12.8523C19.2613 12.9127 19.8522 12.5336 20.134 12.9982C20.5945 13.7582 20.8937 14.773 21.1978 15.7284C21.3477 16.1981 21.0604 16.3558 20.803 16.3683V16.3692Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M20.8509 11.3054C20.53 11.911 20.1406 11.5285 19.7654 11.5864C20.3323 10.5664 20.7581 9.48025 20.947 8.19444C21.4694 4.63059 19.7219 1.38462 17.1995 1.20848C14.4214 1.01389 12.0445 4.31773 12.0179 7.91933C12.0124 8.62892 11.6611 8.29342 11.4542 8.34458C11.1468 8.42007 11.1946 8.0594 11.2093 7.77591C11.3434 5.2521 12.1346 3.28355 13.4542 1.87277C15.426 -0.235853 17.5449 -0.690456 19.6921 1.16151C22.0614 3.20555 22.5745 8.05773 20.8509 11.3054Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M8.85379 20.2054C8.10603 20.2163 7.47284 21.1314 7.48642 22.1807C7.49837 23.0932 7.99253 23.7701 8.64309 23.7659C9.39194 23.76 10.0436 22.8575 10.0425 21.8259C10.0425 20.8957 9.52824 20.1962 8.85379 20.2054V20.2054ZM8.72183 22.8861C8.37755 22.8274 8.12938 22.6017 8.13481 22.0708C8.14132 21.4493 8.4123 21.1498 8.79623 21.1222C9.15735 21.0962 9.40063 21.3864 9.38597 21.9467C9.36913 22.5648 9.0672 22.8081 8.72183 22.8861V22.8861Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M18.9309 20.2117C18.1815 20.2033 17.52 21.126 17.5342 22.1585C17.5466 23.0777 18.043 23.7722 18.6876 23.7722C19.4462 23.7714 20.0805 22.8756 20.0745 21.8121C20.0696 20.8794 19.5972 20.2193 18.9309 20.2117V20.2117ZM19.4288 21.9328C19.412 22.5586 19.1269 22.8068 18.7658 22.8865C18.4383 22.8362 18.2092 22.6273 18.1891 22.0863C18.169 21.5386 18.4926 21.0983 18.889 21.1209C19.2241 21.1402 19.4435 21.4002 19.4288 21.9328Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M5.36332 12.8085C3.86942 12.8261 2.37552 12.8169 0.881628 12.8127C0.648122 12.6919 0.624771 12.4117 0.693194 12.1173C0.770306 11.786 0.959283 11.646 1.17758 11.6409C1.68695 11.6283 2.19632 11.6309 2.70623 11.6309C3.52133 11.63 4.33643 11.6317 5.15208 11.6309C5.37309 11.6309 5.5713 11.7231 5.63212 12.0829C5.69023 12.4285 5.599 12.6952 5.36332 12.8085V12.8085Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M6.76627 10.292C5.80238 10.3197 4.83794 10.3138 3.87351 10.3012C3.63566 10.2987 3.49393 10.0596 3.50805 9.68975C3.52488 9.25276 3.71494 9.03972 3.98266 9.02966C4.45619 9.01204 4.92972 9.02462 5.40379 9.02462V9.02127C5.84365 9.02127 6.28351 9.02546 6.72337 9.01959C6.9748 9.01624 7.17464 9.15463 7.19201 9.56478C7.20993 9.98919 7.03453 10.2844 6.76627 10.292V10.292Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M3.54657 14.8998C3.55309 15.3032 3.38475 15.5498 3.15287 15.6044C2.90524 15.6631 2.6473 15.6178 2.39425 15.6178V15.6429C2.20798 15.6429 2.02118 15.653 1.83546 15.6396C1.61119 15.6245 1.4309 15.4727 1.40538 15.1028C1.37822 14.7086 1.52864 14.4091 1.7703 14.3831C2.2574 14.3303 2.74939 14.3378 3.23813 14.3638C3.42276 14.3731 3.54168 14.6046 3.54657 14.8998V14.8998Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M5.36316 12.8095C5.21545 12.994 5.04005 12.9311 4.87443 12.9311C3.70635 12.9353 2.53828 12.9353 1.3702 12.9286C1.20566 12.9277 1.02918 13.0015 0.88147 12.8137C0.965641 12.6451 1.08674 12.661 1.20403 12.661H5.03951C5.15626 12.661 5.2779 12.6426 5.36316 12.8095V12.8095Z"
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                />
                <path
                  d="M14.6716 5.56638L15.8336 7.36029L19.3197 3.77246"
                  stroke={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "none"
                  }
                  fill={
                    formik.values.DeliveryOption === "HomeDelivery"
                      ? "#3ed400"
                      : "black"
                  }
                  strokeLinecap="round"
                />
              </svg>
              Home Delivery
            </span>
          </label>
          <label
            className={`QurbaniFormRadioBtnLabel w-full sm:w-[245px]  ml-0 sm:ml-2  ${
              formik.values.DeliveryOption === "SelfPickUp"
                ? "bg-Primary bg-opacity-[0.1] text-Primary"
                : "bg-[#f2f2f2] text-TextColor"
            } rounded-lg py-1 px-2 inline-flex items-center justify-center text-[12px]`}
          >
            <input
              type="radio"
              className="QurbaniFormRadioBtn h-5 w-5 text-gray-600"
              name="delivery-option"
              value="SelfPickUp"
              checked={formik.values.DeliveryOption === "SelfPickUp"}
              onChange={(e) => {
                formik.setFieldValue("DeliveryOption", e.target.value);
              }}
            />
            <span>
              <svg
                className="mr-2"
                width="23"
                height="25"
                viewBox="0 0 23 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_206_25)">
                  <path
                    d="M-2.86381e-06 18.5067C-0.000753517 18.9559 0.184658 19.2329 0.560735 19.4262C4.09781 21.2472 7.63263 23.0697 11.1637 24.9032C11.5188 25.0879 11.8063 24.9815 12.1178 24.8195C15.1219 23.2536 18.1283 21.6917 21.1339 20.1281C21.5805 19.8965 22.0257 19.661 22.4753 19.4348C22.8258 19.2579 22.9992 18.9872 22.9985 18.5701C22.994 14.5282 22.9932 10.4864 23 6.44375C23.0007 6.01178 22.8349 5.73554 22.4685 5.54773C21.1144 4.85362 19.7624 4.15167 18.4143 3.44347C18.2581 3.36208 18.1455 3.38399 18.0037 3.46068C14.4883 5.35601 10.9723 7.24977 7.45098 9.13258C7.24305 9.24448 7.20701 9.3783 7.20776 9.59115C7.21302 11.416 7.21152 13.2417 7.21077 15.0674C7.21002 15.5721 7.08391 15.652 6.65153 15.4188C6.05251 15.0964 5.46025 14.7583 4.85447 14.4508C4.59024 14.3162 4.50917 14.1338 4.51142 13.8357C4.52268 11.9576 4.52493 10.0802 4.50917 8.20291C4.50617 7.89146 4.61351 7.74512 4.86798 7.60974C8.30372 5.78171 11.7342 3.94351 15.1654 2.10766C15.2503 2.06227 15.3351 2.01767 15.4785 1.94176C15.0055 1.69448 14.5867 1.4738 14.1678 1.25625C13.4524 0.884541 12.7333 0.52144 12.0225 0.141123C11.6847 -0.0388628 11.3754 -0.050601 11.0263 0.13095C7.52979 1.9488 4.0295 3.75883 0.526203 5.5626C0.153879 5.75433 -0.000753517 6.02978 -2.86381e-06 6.45862C0.00675301 10.4746 0.00750366 14.4907 -2.86381e-06 18.5067Z"
                    fill="#F7F9FA"
                  />
                  <path
                    d="M-2.86381e-06 18.5067C-0.000753517 18.9559 0.184658 19.2329 0.560735 19.4262C4.09781 21.2472 7.63263 23.0697 11.1637 24.9032C11.5188 25.0879 11.8063 24.9815 12.1178 24.8195C15.1219 23.2536 18.1283 21.6917 21.1339 20.1281C21.5805 19.8965 22.0257 19.661 22.4753 19.4348C22.8258 19.2579 22.9992 18.9872 22.9985 18.5701C22.994 14.5282 22.9932 10.4864 23 6.44375C23.0007 6.01178 22.8349 5.73554 22.4685 5.54773C21.1144 4.85362 19.7624 4.15167 18.4143 3.44347C18.2581 3.36208 18.1455 3.38399 18.0037 3.46068C14.4883 5.35601 10.9723 7.24977 7.45098 9.13258C7.24305 9.24448 7.20701 9.3783 7.20776 9.59115C7.21302 11.416 7.21152 13.2417 7.21077 15.0674C7.21002 15.5721 7.08391 15.652 6.65153 15.4188C6.05251 15.0964 5.46025 14.7583 4.85447 14.4508C4.59024 14.3162 4.50917 14.1338 4.51142 13.8357C4.52268 11.9576 4.52493 10.0802 4.50917 8.20291C4.50617 7.89146 4.61351 7.74512 4.86798 7.60974C8.30372 5.78171 11.7342 3.94351 15.1654 2.10766C15.2503 2.06227 15.3351 2.01767 15.4785 1.94176C15.0055 1.69448 14.5867 1.4738 14.1678 1.25625C13.4524 0.884541 12.7333 0.52144 12.0225 0.141123C11.6847 -0.0388628 11.3754 -0.050601 11.0263 0.13095C7.52979 1.9488 4.0295 3.75883 0.526203 5.5626C0.153879 5.75433 -0.000753517 6.02978 -2.86381e-06 6.45862C0.00675301 10.4746 0.00750366 14.4907 -2.86381e-06 18.5067Z"
                    fill="#3ED400"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_206_25">
                    <rect
                      width="23"
                      height="25"
                      fill="white"
                      transform="matrix(-1 0 0 1 23 0)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Self Pickup
            </span>
          </label>
        </div>
        <div
          className={`col-span-2 flex text-[12px] ${
            formik.values.DeliveryOption === "SelfPickUp" ? "block" : "hidden"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill={"#EFA922"}
            className="w-4 h-4"
          >
            <path
              fillRule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
              clipRule="evenodd"
            />
          </svg>
          Self pickup from
        </div>

        <div
          className={`${
            formik.values.DeliveryOption === "SelfPickUp" ? "block" : "hidden"
          } `}
        >
          <p className="block text-sm  text-TextColor">Pickup point *</p>
          <Select
            styles={Style}
            id="PickupPoint"
            name="PickupPoint"
            isDisabled={formik.values.DeliveryOption !== "SelfPickUp"}
            maxMenuHeight={140}
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={pickUpAddressOptions}
            value={formik.values.PickupPoint ? formik.values.PickupPoint : ""}
            label="PickupPoint"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onChange={(value) => {
              formik.setFieldValue("PickupPoint", value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.PickupPoint && formik.errors.PickupPoint ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.PickupPoint}
            </span>
          ) : null}
        </div>

        <div
          className={`${
            formik.values.DeliveryOption === "SelfPickUp" ? null : "col-span-2"
          }`}
        >
          <p className="block text-sm  text-TextColor">
            {formik.values.DeliveryOption === "SelfPickUp"
              ? "Pickup day *"
              : "Delivery day *"}
          </p>
          <Select
            styles={Style}
            id="PickupDay"
            name="PickupDay"
            maxMenuHeight={140}
            isDisabled={
              formik.values.PickupDay?.value === "1" ||
              formik.values.PickupDay?.value === "2"
                ? true
                : false
            }
            className="mt-1 block w-full   text-base  sm:text-sm rounded-[10px] focus:outline-none "
            options={[
              { label: "1st day of Eid", value: "1" },
              { label: "2nd day of Eid", value: "2" },
            ]}
            value={formik.values.PickupDay ? formik.values.PickupDay : ""}
            label="PickupDay"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onChange={(value) => {
              formik.setFieldValue("PickupDay", value);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.PickupDay && formik.errors.PickupDay ? (
            <span className="text-red-600  text-[11px] ">
              {formik.errors.PickupDay}
            </span>
          ) : null}
        </div>
        {/* <p className="font-PoppinsBold text-sm">Payment</p> */}
        {/* <div className="col-span-2  mx-auto space-y-2 sm:space-y-0"> */}
        {/* <div className="col-span-2  flex justify-center w-full">
          <label
            className={`QurbaniFormRadioBtnLabel w-full sm:w-[245px]  ml-0 sm:ml-2  ${
              formik.values.PaymentOption === "FullPayment"
                ? "bg-Primary bg-opacity-[0.1] text-Primary"
                : "bg-[#f2f2f2] text-TextColor"
            } rounded-lg py-1 px-2 inline-flex items-center justify-center text-[12px]`}
          >
            <input
              type="radio"
              className="QurbaniFormRadioBtn h-5 w-5 text-gray-600"
              name="PaymentOption"
              value="FullPayment"
              checked={formik.values.PaymentOption === "FullPayment"}
              onChange={(e) => {
                formik.setFieldValue("PaymentOption", e.target.value);
              }}
            />
            <span>
              <svg
                className="mr-2"
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0.5H5C2 0.5 0 2 0 5.5V12.5C0 16 2 17.5 5 17.5H15C18 17.5 20 16 20 12.5V5.5C20 2 18 0.5 15 0.5ZM6 14.75H3C2.59 14.75 2.25 14.41 2.25 14C2.25 13.59 2.59 13.25 3 13.25H6C6.41 13.25 6.75 13.59 6.75 14C6.75 14.41 6.41 14.75 6 14.75ZM10 12C8.34 12 7 10.66 7 9C7 7.34 8.34 6 10 6C11.66 6 13 7.34 13 9C13 10.66 11.66 12 10 12ZM17 4.75H14C13.59 4.75 13.25 4.41 13.25 4C13.25 3.59 13.59 3.25 14 3.25H17C17.41 3.25 17.75 3.59 17.75 4C17.75 4.41 17.41 4.75 17 4.75Z"
                  fill={
                    formik.values.PaymentOption === "FullPayment"
                      ? "#3ed400"
                      : "#292D32"
                  }
                />
              </svg>
              Full Payment
            </span>
          </label>
          <label
            className={`QurbaniFormRadioBtnLabel w-full sm:w-[245px]  ml-0 sm:ml-2  ${
              formik.values.PaymentOption === "BookingPayment"
                ? "bg-Primary bg-opacity-[0.1] text-Primary"
                : "bg-[#f2f2f2] text-TextColor"
            } rounded-lg py-1 px-2 inline-flex items-center justify-center text-[12px]`}
          >
            <input
              type="radio"
              className="QurbaniFormRadioBtn h-5 w-5 text-gray-600"
              name="PaymentOption"
              value="BookingPayment"
              disabled={
                DeliveryAndPaymentOptions?.qurbani_advance_payment === "100"
              }
              checked={formik.values.PaymentOption === "BookingPayment"}
              onChange={(e) => {
                formik.setFieldValue("PaymentOption", e.target.value);
              }}
            />
            <span className="space-x-2">
              <svg
                className="mr-2"
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0.5H5C2 0.5 0 2 0 5.5V12.5C0 16 2 17.5 5 17.5H15C18 17.5 20 16 20 12.5V5.5C20 2 18 0.5 15 0.5ZM6 14.75H3C2.59 14.75 2.25 14.41 2.25 14C2.25 13.59 2.59 13.25 3 13.25H6C6.41 13.25 6.75 13.59 6.75 14C6.75 14.41 6.41 14.75 6 14.75ZM10 12C8.34 12 7 10.66 7 9C7 7.34 8.34 6 10 6C11.66 6 13 7.34 13 9C13 10.66 11.66 12 10 12ZM17 4.75H14C13.59 4.75 13.25 4.41 13.25 4C13.25 3.59 13.59 3.25 14 3.25H17C17.41 3.25 17.75 3.59 17.75 4C17.75 4.41 17.41 4.75 17 4.75Z"
                  fill={
                    formik.values.PaymentOption === "BookingPayment"
                      ? "#3ed400"
                      : "#292D32"
                  }
                />
              </svg>
              Booking Payment
            </span>
          </label>
        </div> */}
        <div className="col-span-2 flex text-[12px] text-justify">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill={"#EFA922"}
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          Pay {formik.values.PaymentOption === "FullPayment" ? "100%" : "20%"}{" "}
          advance to confirm your booking.
        </div>
      </div>
    </div>
  );
}
