import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as DeleteAdIcon } from "../../assets/icons/DeleteAd.svg";
import { ReactComponent as EditAdIcon } from "../../assets/icons/EditAd.svg";
export default function DraftAds({
  data,
  setDeleteAdId,
  setShowModal,
  navigate,
  CategoriesData,
  PostAdSuccessfully,
  StarIcon,
  VerifiedIcon,
}) {
  return data.map(
    (item) =>
      item.ad_status === "draft" && (
        <div
          key={item.id}
          className={`h-[390px] w-[280px] sm:w-[344px] sm:h-[420px] ${
            item.managed_by_farmghar === "1" ? "bg-Primary" : "bg-white"
          } rounded-[8px] py-2 px-2 border border-gray-[#DBDED9]`}
        >
          <div className="flex flex-col items-center justify-center h-full w-full bg-white p-2">
            <div className="relative">
              <img
                src={item.img_thumbnail}
                alt={item.ad_title}
                className="w-[262px]  h-[185px] sm:w-[326px]  sm:h-[216px] rounded-[5px] object-cover"
              />
              <div className="flex items-center absolute right-[10px] top-[8px]">
                <div className="opacity-50 mr-[5.85px]">
                  <EyeIcon />
                </div>
                <p className="text-[#39342F]">{item.impressions}</p>
              </div>
              {item?.is_star === "1" && item?.priority === "11" && (
                <div className="flex items-center absolute left-[-17px] top-[-14px]">
                  <StarIcon />
                </div>
              )}
              {item.is_verified === "1" && (
                <div className="flex items-center absolute right-[7px] bottom-[55px]">
                  <VerifiedIcon />
                </div>
              )}
              <div className="flex items-center justify-center absolute right-[10px] bottom-[20px] bg-white rounded-full w-[28px] h-[28px]">
                <button
                  type="button"
                  onClick={() => {
                    setShowModal(true);
                    setDeleteAdId(item.id);
                  }}
                >
                  <DeleteAdIcon className="" />
                </button>
              </div>
              <div className="flex items-center absolute right-[50px] bottom-[20px] justify-center w-[28px] h-[28px]  bg-white rounded-full">
                <button
                  type="button"
                  onClick={() => {
                    navigate(`/account/post-ad/`, {
                      state: {
                        ad_post_id: item.id,
                        edit: true,
                        p_parent_cat_id: item.p_parent_cat_id ?? "",
                      },
                    });
                  }}
                >
                  <EditAdIcon className="" />
                </button>
              </div>
              {item.review_status !== "" ? (
                <div
                  className={`${
                    item.review_status === "rejected"
                      ? "bg-red-600"
                      : "bg-Primary"
                  } text-white absolute px-[10px] py-[2px] left-[20px] bottom-[10px]`}
                >
                  {item.review_status}
                </div>
              ) : (
                item.ad_status === "draft" && (
                  <div
                    className={`${"bg-[#EFA922]"} text-white absolute px-[10px] py-[2px] left-[20px] bottom-[10px]`}
                  >
                    {item?.ad_status}
                  </div>
                )
              )}
            </div>
            <h3 className="text-left pt-2 w-full font-PoppinsBold">
              {item.ad_title}
            </h3>
            <div className="flex justify-between items-start w-full">
              <p className="text-Primary text-[12px] font-PoppinsBold">
                {item.category}
              </p>
              <p>{item.created_at} ago</p>
            </div>
            <p className="font-PoppinsBold text-[28px] text-Primary text-left w-full overflow-hidden whitespace-nowrap">
              Rs {item.price}
            </p>
            <div
              className={`flex justify-center w-full ${
                item.ad_status === "draft" ? "space-x-3" : null
              }`}
            >
              {item.ad_status === "draft" && (
                <>
                  <button
                    className="bg-Primary text-white rounded-[8px] px-2 sm:px-6 py-2 w-full"
                    type="button"
                    onClick={() => {
                      sessionStorage?.setItem("ad_post_id", item.id);
                      const payl = {
                        ad_id: item.id,
                        key: localStorage.getItem("key") ?? "",
                        status: "yes",
                      };
                      PostAdSuccessfully(payl);
                    }}
                  >
                    Publish Ad
                  </button>
                  <button
                    className="text-[#EFA922]  border border-[#EFA922] rounded-[8px] px-2 sm:px-6 py-2 w-full"
                    type="button"
                    disabled={item.draft_ad_preview !== "1"}
                    onClick={() => {
                      const category =
                        CategoriesData?.find(
                          (cat) => cat.id === item.p_parent_cat_id
                        ).category_slug ?? "item";
                      navigate(`/${category}/${item.ad_slug}`);
                    }}
                  >
                    Preview Ad
                  </button>
                </>
              )}
            </div>
          </div>
          {item.managed_by_farmghar === "1" && (
            <div className="mt-[6px] bg-Primary flex justify-center items-center w-full text-white font-bold text-[18px]">
              farmGhar Self-stock
            </div>
          )}
        </div>
      )
  );
}
