import UpdateMetaTags from "../Utils/UpdateMetaTags";
import LogoCut from "../assets/icons/LogoCut.svg";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useMultistepFormPostAd } from "../Components/PostAdComponents/useMultistepFormPostAd";
import { HenBirdPetOtherForm } from "../Components/PostAdComponents/HenBirdPetOtherForm";
import { ImportantInformation } from "../Components/PostAdComponents/ImportantInformation";
import { LivestockForm } from "../Components/PostAdComponents/LivestockForm";
import { ProductsServicesEquiqPetFoodForm } from "../Components/PostAdComponents/ProductsServicesEquiqPetFoodForm";
import { SelectCategory } from "../Components/PostAdComponents/SelectCategory";
import { useFormik } from "formik";
import * as yup from "yup";
import FileUploader from "../Components/PostAdComponents/FileUploader";
import Select, { components } from "react-select";
import { Style } from "../Styles/ReactSelectStyles";
import { ReactComponent as CaretDownIcon } from "../assets/icons/CaretDown.svg";
import {
  useFetchHomePageAdsByCategory,
  useFetchFilterDistricts,
  useFetchFilterTehsils,
  useFetchChildCategoriesPostAd,
  useFetchFilterBreeds,
  useMutateCreateAd,
  useMutateMyAdDetails,
  useAccountGetUserProfilePhotoUpload,
  useMutateMyAdData,
  useFetchChildCategories,
  useFetchPostAdFarmChildCategories,
  useAccountGetUserDetails,
  useMutateLatLongdata,
  useMutateFilterDistricts,
  // useMutateFilterTehsils,
} from "../Middlewares/Api";
import { Provinces } from "../StaticData/Provinces";
import { AgeMonthsOptions } from "../StaticData/AgeMonths";
import { AgeYearsOptions } from "../StaticData/AgeYears";
import { DailyMilkQuantityOptions } from "../StaticData/DailyMilkQuantity";
import { QuantityUnits } from "../StaticData/QuantityUnits";
import { PriceUnits } from "../StaticData/PriceUnits";
import AdPreview from "../Components/PostAdComponents/AdPreview";
import AdSuccessfullyPosted from "../Components/PostAdComponents/AdSuccessfullyPosted";
import { useState, useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import IsContentDoesNotContainsBadWords from "../Utils/IsContentDoesNotContainsBadWords";
import AuthContext from "../Context/AuthContext";
import GoogleMap, { getAddress } from "../Utils/GoogleMap";
import Modal from "../Components/Modal";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function PostAd() {
  //adding location
  const [autoComplete2, setAutoComplete2] = useState(null);
  const [locationStates, setLocationStates] = useState({
    address: "",
    selectedPlace: null,
    currentLocation: null,
    latitude: null,
    longitude: null,
  });
  const [adstatus, setAdStatus] = useState({
    is_verified: "0",
    is_delivery: "0",
    is_star: "0",
  });
  const [showAlertModal, setShowAlertModal] = useState();
  const { mutateAsync } = useMutateLatLongdata();
  const { mutateAsync: MutateDistricts } = useMutateFilterDistricts();
  // const { mutateAsync: MutateTehsils } = useMutateFilterTehsils();
  const HandleChangeLatLong = async (latitude, longitude) => {
    setLocationStates((prev) => ({
      ...prev,
      latitude: latitude,
      longitude: longitude,
    }));
    let dataLatLong = null;
    await mutateAsync({
      latitude,
      longitude,
    }).then((res) => {
      dataLatLong = res;
    });
    const Province = Provinces.find(
      (item) =>
        parseInt(item.value) === parseInt(dataLatLong?.data?.province_id)
    );
    let District = null;

    await MutateDistricts({ province: Province?.value }).then(
      (res) =>
        (District = res?.data?.find(
          (item) => parseInt(item.id) === parseInt(dataLatLong?.data?.id)
        ))
    );
    formik.setValues((prev) => ({
      ...prev,
      Province: {
        value: Province?.value,
        label: Province?.label,
      },
      District: {
        value: District?.id,
        label: District?.name,
      },
      Tehsil: "",
    }));
  };

  const inputRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { setIsAuth, setUser } = useContext(AuthContext);
  const { ad_post_id, edit, p_parent_cat_id } = location.state || {
    ad_post_id: "",
    edit: false,
    p_parent_cat_id: "",
  };

  const { mutateAsync: UserDetailsData } = useAccountGetUserDetails();
  const [isAdSuccessfullyPosted, setIsAdSuccessfullyPosted] = useState(false);
  const [isAdPreview, setIsAdPreview] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [adDataForPreview, setAdDataForPreview] = useState(null);
  const { data: dataCategories, isLoading: dataCategoriesLoading } =
    useFetchHomePageAdsByCategory();

  const {
    mutateAsync: createAd,
    isLoading: isCreatingAd,
    data: createAdData,
  } = useMutateCreateAd();

  const after_ad_post_id = adDataForPreview?.id;

  const { mutateAsync: MyAdDetails, isLoading: MyAdDetailsLoading } =
    useMutateMyAdDetails();

  const formik = useFormik({
    initialValues: {
      SelectedCategory: "",
      //Livestock
      Category: "",
      Breed: "",
      Sex: "",
      Quantity: 1,
      AgeYears: "",
      AgeMonths: "",
      LiveWeight: "",
      AnimalDescription: "",
      Price: "",
      PriceType: "",
      Teeth: "",
      DailyMilkQuantity: "",
      Pregnant: "",
      PregnancyCount: "",
      Castrated: "",
      Pictures: [],
      //if quantity is grerater than 1
      MinLiveWeight: "",
      MaxLiveWeight: "",
      MaxAgeYears: "",
      MaxAgeMonths: "",
      MinAgeYears: "",
      MinAgeMonths: "",
      MaxTeeth: "",
      MinTeeth: "",
      //HenBirdPetOther
      AdTitle: "",
      BreedName: "",

      // ProductsServicesEquiqPetFood
      QuantityUnit: "",
      PriceType2: "",
      //
      Province: "",
      District: "",
      Tehsil: "",
      AreaVillageTown: "",
      CoverImageIndex: 0,
    },

    validationSchema: yup.object({
      SelectedCategory: yup.string().required("Parent Category is Required"),
      Category: yup.object().nullable().required("Category is Required"),
      Breed: yup
        .object()
        .nullable()
        .when("SelectedCategory", {
          is: (val) => String(val) === "6",
          then: yup.object().nullable().required("Breed is Required"),
        }),
      LiveWeight: yup
        .string()
        .nullable()
        .matches(/^[1-9]\d*$/, "Live Weight must be a positive integer"),
      Sex: yup
        .object()
        .nullable()
        .when("Category", {
          is: (val) => ["10", "15", "8", "14"].includes(String(val?.value)),
          then: yup
            .object()
            .nullable()
            .when("SelectedCategory", {
              is: (val) => String(val) === "6",
              then: yup.object().nullable().required("Sex is Required"),
            }),
        }),
      Quantity: yup
        .string()
        .nullable()
        .matches(/^[1-9]\d*$/, "Price must be a positive integer")
        .when("Category", {
          is: (val) =>
            [
              "10",
              "15",
              "8",
              "14",
              "35",
              "104",
              "105",
              "112",
              "113",
              "36",
              "107",
              "108",
              "109",
              "111",
              "114",
              "37",
              "110",
            ].includes(String(val?.value)),

          then: yup.string().when("SelectedCategory", {
            is: (val) => ["6", "100", "101", "102"].includes(String(val)),
            then: yup
              .string()
              .nullable()
              .matches(/^[1-9]\d*$/, "Price must be a positive integer")
              .required("Quantity is Required"),
          }),
        }),

      AgeYears: yup
        .object()
        .nullable()
        .when(["SelectedCategory"], {
          is: (SelectedCategory) =>
            !["130", "100", "101", "102"].includes(String(SelectedCategory)),
          then: yup
            .object()
            .nullable()
            .when(["Category", "Quantity"], {
              is: (Category, quantity) =>
                ["15", "8", "14", "10"].includes(String(Category?.value)) &&
                parseInt(quantity) <= 1,
              then: yup
                .object()
                .nullable()
                .required("Age In Years is Required"),
            }),
        }),

      AgeMonths: yup
        .object()
        .nullable()
        .when(["SelectedCategory"], {
          is: (SelectedCategory) =>
            !["130", "100", "101", "102"].includes(String(SelectedCategory)),
          then: yup
            .object()
            .nullable()
            .when(["Category", "Quantity"], {
              is: (Category, quantity) =>
                ["15", "8", "14", "10"].includes(String(Category?.value)) &&
                parseInt(quantity) <= 1,
              then: yup
                .object()
                .nullable()
                .required("Age In Months is Required")
                .when(["AgeYears", "AgeMonths"], {
                  is: (ageYears, ageMonths) =>
                    ageYears?.value === 0 && ageMonths?.value === 0,
                  then: yup
                    .object()
                    .nullable()
                    .test("test", "Select valid age", () => {
                      return false;
                    }),
                }),
            }),
        }),
      AnimalDescription: yup.string().required("Description is Required"),
      Price: yup
        .string()
        .required("Price is Required")
        .matches(/^[1-9]\d*$/, "Price must be a positive integer"),

      PriceType: yup
        .object()
        .nullable()
        .when("Category", {
          is: (val) =>
            String(val?.value) === "10" ||
            String(val?.value) === "15" ||
            String(val?.value) === "8" ||
            String(val?.value) === "14" ||
            String(val?.value) === "26" ||
            String(val?.value) === "126" ||
            String(val?.value) === "127" ||
            String(val?.value) === "25" ||
            String(val?.value) === "116" ||
            String(val?.value) === "117" ||
            String(val?.value) === "118" ||
            String(val?.value) === "119" ||
            String(val?.value) === "120" ||
            String(val?.value) === "121" ||
            String(val?.value) === "122" ||
            String(val?.value) === "27" ||
            String(val?.value) === "28" ||
            String(val?.value) === "22" ||
            String(val?.value) === "23" ||
            String(val?.value) === "24" ||
            String(val?.value) === "29" ||
            String(val?.value) === "30" ||
            String(val?.value) === "31" ||
            String(val?.value) === "103",
          then: yup
            .object()
            .nullable()
            .when("SelectedCategory", {
              is: (val) =>
                String(val) === "6" ||
                String(val) === "124" ||
                String(val) === "115" ||
                String(val) === "123" ||
                String(val) === "21",
              then: yup.object().nullable().required("Price Type is Required"),
            }),
        }),
      Teeth: yup.object().nullable(),
      DailyMilkQuantity: yup.object().nullable(),
      Pregnant: yup
        .object()
        .nullable()
        .when(["Category", "SelectedCategory", "Sex"], {
          is: (Category, SelectedCategory, Sex) =>
            (String(Category?.value) === "9" ||
              String(Category?.value) === "7" ||
              String(Category?.value) === "8") &&
            String(SelectedCategory) === "6" &&
            String(Sex?.value) === "female",
          then: yup.object().nullable().required("Pregnant is Required"),
        }),
      PregnancyCount: yup
        .object()
        .nullable()
        .when(["Category", "SelectedCategory", "Pregnant"], {
          is: (Category, SelectedCategory, Pregnant) =>
            (String(Category?.value) === "9" ||
              String(Category?.value) === "7") &&
            String(SelectedCategory) === "6" &&
            String(Pregnant?.label) === "Yes",
          then: yup.object().nullable().required("Pregnancy Count is Required"),
        }),
      Castrated: yup
        .object()
        .nullable()
        .when(["Category", "SelectedCategory", "Sex"], {
          is: (Category, SelectedCategory, Sex) =>
            String(Category?.value) === "8" &&
            String(SelectedCategory) === "6" &&
            String(Sex?.value) === "male",
          then: yup.object().nullable().required("Castrated is Required"),
        }),
      Pictures: yup
        .array()
        .min(1, "Atleast 1 Picture is Required")
        .max(4, "Maximum 4 Pictures are Allowed"),
      Province: yup.object().nullable().required("Province is Required"),
      District: yup.object().nullable().required("District is Required"),
      Tehsil: yup.object().nullable().required("Tehsil is Required"),
      AreaVillageTown: yup.string().max(20).nullable(),

      //HenBirdPetOther
      AdTitle: yup.string().when("SelectedCategory", {
        is: (val) =>
          String(val) === "124" ||
          String(val) === "115" ||
          String(val) === "123" ||
          String(val) === "21" ||
          String(val) === "100" ||
          String(val) === "101" ||
          String(val) === "102" ||
          String(val) === "130",
        then: yup.string().max(25).nullable().required("Ad Title is Required"),
      }),
      QuantityUnit: yup.object().when("SelectedCategory", {
        is: (val) =>
          String(val) === "100" ||
          String(val) === "101" ||
          String(val) === "102",
        then: yup.object().nullable().required("Quantity Unit is Required"),
      }),
      PriceType2: yup.object().when("SelectedCategory", {
        is: (val) =>
          String(val) === "100" ||
          String(val) === "101" ||
          String(val) === "102",
        then: yup.object().nullable().required("Price Type is Required"),
      }),
      BreedName: yup.string().nullable(),
      //when quentity is greater than 1
      MinLiveWeight: yup
        .string()
        .nullable()
        .matches(/^[1-9]\d*$/, "Weight must be a positive integer")
        .test(
          "min-max-weight",
          "Min weight should be less than Max weight",
          function (value) {
            const { MaxLiveWeight } = this.parent;
            if (value && MaxLiveWeight) {
              return parseInt(value) <= parseInt(MaxLiveWeight);
            }
            return true;
          }
        ),
      MaxLiveWeight: yup
        .string()
        .nullable()
        .matches(/^[1-9]\d*$/, "Weight must be a positive integer"),
      MaxAgeYears: yup
        .object()
        .nullable()
        .when(["Category", "Quantity", "SelectedCategory"], {
          is: (category, quantity, SelectedCategory) =>
            ["15", "8", "14", "10"].includes(String(category?.value)) &&
            ["6"].includes(String(SelectedCategory)) &&
            parseInt(quantity) > 1,
          then: yup
            .object()
            .nullable()
            .required("Max Age In Years is Required"),
        }),
      MaxAgeMonths: yup
        .object()
        .nullable()
        .when(["Category", "Quantity", "SelectedCategory"], {
          is: (category, quantity, SelectedCategory) =>
            ["15", "8", "14", "10"].includes(String(category?.value)) &&
            ["6"].includes(String(SelectedCategory)) &&
            parseInt(quantity) > 1,
          then: yup
            .object()
            .nullable()
            .required("Max Age In Months is Required")
            .when(["MaxAgeYears", "MaxAgeMonths"], {
              is: (maxAgeYears, maxAgeMonths) =>
                maxAgeYears?.value === 0 && maxAgeMonths?.value === 0,
              then: yup
                .object()
                .nullable()
                .test("test", "Select valid max age", () => {
                  return false;
                }),
            }),
        }),
      MinAgeYears: yup
        .object()
        .nullable()
        .when(["Category", "Quantity", "SelectedCategory"], {
          is: (category, quantity, SelectedCategory) =>
            ["15", "8", "14", "10"].includes(String(category?.value)) &&
            ["6"].includes(String(SelectedCategory)) &&
            parseInt(quantity) > 1,
          then: yup
            .object()
            .nullable()
            .required("Min Age In Years is Required"),
        }),
      MinAgeMonths: yup
        .object()
        .nullable()
        .when(["Category", "Quantity", "SelectedCategory"], {
          is: (category, quantity, SelectedCategory) =>
            ["15", "8", "14", "10"].includes(String(category?.value)) &&
            ["6"].includes(String(SelectedCategory)) &&
            parseInt(quantity) > 1,
          then: yup
            .object()
            .nullable()
            .required("Min Age In Months is Required")
            .when(["MinAgeYears", "MinAgeMonths"], {
              is: (minAgeYears, minAgeMonths) =>
                minAgeYears?.value === 0 && minAgeMonths?.value === 0,
              then: yup
                .object()
                .nullable()
                .test("test", "Select valid min age", () => {
                  return false;
                }),
            })
            .test("minAge", "Min age must be less than max age", function () {
              const { MinAgeYears, MinAgeMonths, MaxAgeYears, MaxAgeMonths } =
                this.parent;
              const minAge =
                parseInt(MinAgeYears?.value ?? 0) * 12 +
                parseInt(MinAgeMonths?.value ?? 0);
              const maxAge =
                parseInt(MaxAgeYears?.value ?? 0) * 12 +
                parseInt(MaxAgeMonths?.value ?? 0);
              return minAge < maxAge;
            }),
        }),

      MinTeeth: yup
        .object()
        .nullable()
        .test(
          "teeth-validation",
          "Min teeth should be less than max teeth",
          function (value) {
            const maxTeeth = this.parent.MaxTeeth?.value;
            const minTeeth = value?.value;
            if (maxTeeth && minTeeth) {
              return parseInt(minTeeth) <= parseInt(maxTeeth);
            }
            return true;
          }
        ),

      MaxTeeth: yup
        .object()
        .nullable()
        .test(
          "teeth-validation",
          "Max teeth should be greater than min teeth",
          function (value) {
            const maxTeeth = value?.value;
            const minTeeth = this.parent.MinTeeth?.value;
            if (maxTeeth && minTeeth) {
              return parseInt(maxTeeth) >= parseInt(minTeeth);
            }
            return true;
          }
        ),
    }),

    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const RemoveAllFilesFromImageUploader = () => {
    const input = inputRef.current;
    if (input !== null) {
      // Create an empty FileList object
      const emptyFileList = new DataTransfer().files;

      // Set the value of the input element to the empty FileList object
      input.files = emptyFileList;
    }
  };
  const { mutateAsync: UploadAdImages, isLoading: isUploadingImages } =
    useAccountGetUserProfilePhotoUpload();

  const { data: ChildCategoriesData } = useFetchChildCategoriesPostAd({
    CatId: formik.values.SelectedCategory,
  });

  const { data: dataCategoriesChild } = useFetchChildCategories({
    CatId: formik.values.SelectedCategory,
  });

  const { data: DataPostAdFarmChildCategories } =
    useFetchPostAdFarmChildCategories({
      CatId: formik.values.SelectedCategory,
    });

  const { mutateAsync: MyAdData, isLoading: MyAdDataLoading } =
    useMutateMyAdData();

  const { data: BreedsData } = useFetchFilterBreeds({
    SubCateId: formik.values.Category && formik.values.Category.value,
  });

  const BreedsOptons = BreedsData?.data.map((item) => {
    return { value: item.id, label: item.name };
  });

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };
  //it is just for live stock
  const getChildCategories = () => {
    return ChildCategoriesData?.data?.map((item) => {
      return { value: item.id, label: item.name };
    });
  };

  //for other categories
  const getChildCategoriesOther = () => {
    return dataCategoriesChild?.data?.map((item) => {
      return { value: item.id, label: item.name };
    });
  };

  //for farmProduct etc post ad child categories
  const getChildCategoriesFarm = () => {
    return DataPostAdFarmChildCategories?.data?.map((item) => {
      return { value: item.id, label: item.name };
    });
  };

  const ChildCategoriesOptionsOther =
    dataCategoriesChild && getChildCategoriesOther();

  const ChildCategoriesOptions = ChildCategoriesData && getChildCategories();

  //for farmProduct etc post ad child categories
  const PostAdFarmChildCategoriesOptions =
    DataPostAdFarmChildCategories && getChildCategoriesFarm();

  const { data: DistrictsData } = useFetchFilterDistricts({
    ProvinceId: formik.values.Province && formik.values.Province.value,
  });

  const DistrictsOptons =
    DistrictsData &&
    DistrictsData.data.map((item) => {
      return { value: item.id, label: item.name };
    });

  const { data: TehsilsData } = useFetchFilterTehsils({
    DistrictId: formik.values.District && formik.values.District.value,
  });

  const TehsilsOptons =
    TehsilsData &&
    TehsilsData.data.map((item) => {
      return { value: item.id, label: item.name };
    });

  const makeAdDraft = () => {
    let payl = {};
    if (adDataForPreview?.new_img) {
      const element = adDataForPreview.AllOldAndNewImages.splice(
        formik.values.CoverImageIndex,
        1
      )?.[0];
      const status = {};
      adstatus.is_delivery === "1" &&
        (status.is_delivery = adstatus.is_delivery);

      adstatus.is_verified === "1" &&
        (status.is_verified = adstatus.is_verified);
      adstatus.is_star === "1" && (status.is_star = "0");
      payl = {
        ad_id: adDataForPreview.id,
        live_adid: ad_post_id ?? null,
        new_img: adDataForPreview.AllOldAndNewImages,
        old_img: [],
        ...status,
        img_thumbnail: element,
        key: localStorage.getItem("key") ?? "",
        status: "draft",
      };
    } else {
      let image = null;
      image = adDataForPreview.img.splice(formik.values.CoverImageIndex, 1)[0];
      payl = {
        ad_id: adDataForPreview.id,
        img: adDataForPreview.img,
        img_thumbnail: image,
        live_adid: ad_post_id ?? null,
        key: localStorage.getItem("key") ?? "",
        status: "draft",
      };
    }

    const res = createAd(payl);
    Promise.resolve(res).then((res) => {
      if (
        res.web_status_code === 101 ||
        res.web_status_code === 102 ||
        res.web_status_code === 103
      ) {
        localStorage.removeItem("key");
        setIsAuth(false);
        setUser(null);
        navigate("/auth/login");
      }
      if (res.code === 200) {
        if (!ad_post_id) {
          sessionStorage.setItem("ad_post_id", after_ad_post_id);
        } else {
          sessionStorage.setItem("ad_post_id", ad_post_id);
        }
        location.state = null;
        setIsAdPreview(false);
        navigate("/account/my-ads");
      } else {
        alert(res.message);
      }
    });
  };

  const PostAdSuccessfully = () => {
    let payl = {};
    if (adDataForPreview?.new_img) {
      const element = adDataForPreview.AllOldAndNewImages.splice(
        formik.values.CoverImageIndex,
        1
      )?.[0];
      const status = {};
      adstatus.is_delivery === "1" &&
        (status.is_delivery = adstatus.is_delivery);

      adstatus.is_verified === "1" &&
        (status.is_verified = adstatus.is_verified);
      adstatus.is_star === "1" && (status.is_star = "0");
      payl = {
        ad_id: adDataForPreview.id,
        live_adid: ad_post_id ?? null,
        new_img: adDataForPreview.AllOldAndNewImages,
        old_img: [],
        ...status,
        img_thumbnail: element,
        key: localStorage.getItem("key") ?? "",
        status: "yes",
      };
    } else {
      let image = null;
      image = adDataForPreview.img.splice(formik.values.CoverImageIndex, 1)[0];
      payl = {
        ad_id: adDataForPreview.id,
        img: adDataForPreview.img,
        live_adid: ad_post_id ?? null,
        img_thumbnail: image,
        key: localStorage.getItem("key") ?? "",
        status: "yes",
      };
    }
    const res = createAd(payl);
    Promise.resolve(res).then((res) => {
      if (
        res.web_status_code === 101 ||
        res.web_status_code === 102 ||
        res.web_status_code === 103
      ) {
        localStorage.removeItem("key");
        setIsAuth(false);
        setUser(null);
        navigate("/auth/login");
      }
      if (res.code === 200) {
        if (!ad_post_id) {
          sessionStorage.setItem("ad_post_id", after_ad_post_id);
        } else {
          sessionStorage.setItem("ad_post_id", ad_post_id);
        }
        location.state = null;
        setIsAdPreview(false);
        setIsAdSuccessfullyPosted(true);
        next();
      } else {
        setShowAlertModal(true);
      }
    });
  };

  const {
    step,
    isLastStep,
    isFirstStep,
    goTo,
    back,
    next,
    steps,
    currentStepIndex,
  } = useMultistepFormPostAd(
    localStorage.getItem("doNotShowAgainPostAd") !== "true"
      ? [
          //do not change the order of these steps as it will break the form
          // eslint-disable-next-line react/jsx-key
          <ImportantInformation />,
          // eslint-disable-next-line react/jsx-key
          <SelectCategory
            dataCategories={dataCategories?.data2 ?? []}
            dataCategoriesLoading={dataCategoriesLoading}
            formik={formik}
            setSelectedFiles={setSelectedFiles}
            setPreviewImages={setPreviewImages}
            RemoveAllFilesFromImageUploader={RemoveAllFilesFromImageUploader}
          />,
          // eslint-disable-next-line react/jsx-key
          formik.values.SelectedCategory === "6" ? (
            <LivestockForm
              formik={formik}
              ChildCategoriesOptions={ChildCategoriesOptions}
              BreedsOptons={BreedsOptons}
              DropdownIndicator={DropdownIndicator}
              Style={Style}
              Select={Select}
              AgeMonthsOptions={AgeMonthsOptions}
              AgeYearsOptions={AgeYearsOptions}
              Provinces={Provinces}
              DistrictsOptons={DistrictsOptons}
              TehsilsOptons={TehsilsOptons}
              FileUploader={FileUploader}
              DailyMilkQuantity={DailyMilkQuantityOptions}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              previewImages={previewImages}
              setPreviewImages={setPreviewImages}
              edit={edit}
              inputRef={inputRef}
              RemoveAllFilesFromImageUploader={RemoveAllFilesFromImageUploader}
              GoogleMap={GoogleMap}
              getAddress={getAddress}
              HandleChangeLatLong={HandleChangeLatLong}
              locationStates={locationStates}
              setLocationStates={setLocationStates}
              autoComplete2={autoComplete2}
              setAutoComplete2={setAutoComplete2}
              TehsilsData={TehsilsData}
              DistrictsData={DistrictsData}
              adstatus={adstatus}
            />
          ) : formik.values.SelectedCategory === "124" ||
            formik.values.SelectedCategory === "115" ||
            formik.values.SelectedCategory === "21" ||
            formik.values.SelectedCategory === "123" ? (
            <HenBirdPetOtherForm
              formik={formik}
              ChildCategoriesOptionsOther={ChildCategoriesOptionsOther}
              BreedsOptons={BreedsOptons}
              DropdownIndicator={DropdownIndicator}
              Style={Style}
              Select={Select}
              AgeMonthsOptions={AgeMonthsOptions}
              AgeYearsOptions={AgeYearsOptions}
              Provinces={Provinces}
              DistrictsOptons={DistrictsOptons}
              TehsilsOptons={TehsilsOptons}
              FileUploader={FileUploader}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              previewImages={previewImages}
              setPreviewImages={setPreviewImages}
              edit={edit}
              inputRef={inputRef}
              RemoveAllFilesFromImageUploader={RemoveAllFilesFromImageUploader}
              GoogleMap={GoogleMap}
              getAddress={getAddress}
              HandleChangeLatLong={HandleChangeLatLong}
              locationStates={locationStates}
              setLocationStates={setLocationStates}
              autoComplete2={autoComplete2}
              setAutoComplete2={setAutoComplete2}
              TehsilsData={TehsilsData}
              DistrictsData={DistrictsData}
              adstatus={adstatus}
            />
          ) : (
            <ProductsServicesEquiqPetFoodForm
              formik={formik}
              PostAdFarmChildCategoriesOptions={
                PostAdFarmChildCategoriesOptions
              }
              BreedsOptons={BreedsOptons}
              QuantityUnitsOptions={QuantityUnits}
              PriceUnitsOptions={PriceUnits}
              DropdownIndicator={DropdownIndicator}
              Style={Style}
              Select={Select}
              AgeMonthsOptions={AgeMonthsOptions}
              AgeYearsOptions={AgeYearsOptions}
              Provinces={Provinces}
              DistrictsOptons={DistrictsOptons}
              TehsilsOptons={TehsilsOptons}
              FileUploader={FileUploader}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              previewImages={previewImages}
              setPreviewImages={setPreviewImages}
              edit={edit}
              inputRef={inputRef}
              RemoveAllFilesFromImageUploader={RemoveAllFilesFromImageUploader}
              GoogleMap={GoogleMap}
              getAddress={getAddress}
              HandleChangeLatLong={HandleChangeLatLong}
              locationStates={locationStates}
              setLocationStates={setLocationStates}
              autoComplete2={autoComplete2}
              setAutoComplete2={setAutoComplete2}
              TehsilsData={TehsilsData}
              DistrictsData={DistrictsData}
              adstatus={adstatus}
            />
          ),
          //eslint-disable-next-line react/jsx-key
          <AdPreview
            data={adDataForPreview}
            makeAdDraft={makeAdDraft}
            edit={edit}
            PostAdSuccessfully={PostAdSuccessfully}
          />,
          // eslint-disable-next-line react/jsx-key
          <AdSuccessfullyPosted
            location={location}
            ad_id={sessionStorage?.getItem("ad_post_id")}
          />,
        ]
      : [
          //do not change the order of these steps as it will break the form
          // eslint-disable-next-line react/jsx-key
          <SelectCategory
            dataCategories={dataCategories?.data2 ?? []}
            dataCategoriesLoading={dataCategoriesLoading}
            formik={formik}
            setSelectedFiles={setSelectedFiles}
            setPreviewImages={setPreviewImages}
            RemoveAllFilesFromImageUploader={RemoveAllFilesFromImageUploader}
          />,
          // eslint-disable-next-line react/jsx-key
          formik.values.SelectedCategory === "6" ? (
            <LivestockForm
              formik={formik}
              ChildCategoriesOptions={ChildCategoriesOptions}
              BreedsOptons={BreedsOptons}
              DropdownIndicator={DropdownIndicator}
              Style={Style}
              Select={Select}
              AgeMonthsOptions={AgeMonthsOptions}
              AgeYearsOptions={AgeYearsOptions}
              Provinces={Provinces}
              DistrictsOptons={DistrictsOptons}
              TehsilsOptons={TehsilsOptons}
              FileUploader={FileUploader}
              DailyMilkQuantity={DailyMilkQuantityOptions}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              previewImages={previewImages}
              setPreviewImages={setPreviewImages}
              edit={edit}
              inputRef={inputRef}
              RemoveAllFilesFromImageUploader={RemoveAllFilesFromImageUploader}
              GoogleMap={GoogleMap}
              getAddress={getAddress}
              HandleChangeLatLong={HandleChangeLatLong}
              locationStates={locationStates}
              setLocationStates={setLocationStates}
              autoComplete2={autoComplete2}
              setAutoComplete2={setAutoComplete2}
              TehsilsData={TehsilsData}
              DistrictsData={DistrictsData}
              adstatus={adstatus}
            />
          ) : formik.values.SelectedCategory === "124" ||
            formik.values.SelectedCategory === "115" ||
            formik.values.SelectedCategory === "21" ||
            formik.values.SelectedCategory === "123" ? (
            <HenBirdPetOtherForm
              formik={formik}
              ChildCategoriesOptionsOther={ChildCategoriesOptionsOther}
              BreedsOptons={BreedsOptons}
              DropdownIndicator={DropdownIndicator}
              Style={Style}
              Select={Select}
              AgeMonthsOptions={AgeMonthsOptions}
              AgeYearsOptions={AgeYearsOptions}
              Provinces={Provinces}
              DistrictsOptons={DistrictsOptons}
              TehsilsOptons={TehsilsOptons}
              FileUploader={FileUploader}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              previewImages={previewImages}
              setPreviewImages={setPreviewImages}
              edit={edit}
              inputRef={inputRef}
              RemoveAllFilesFromImageUploader={RemoveAllFilesFromImageUploader}
              GoogleMap={GoogleMap}
              getAddress={getAddress}
              HandleChangeLatLong={HandleChangeLatLong}
              locationStates={locationStates}
              setLocationStates={setLocationStates}
              autoComplete2={autoComplete2}
              setAutoComplete2={setAutoComplete2}
              TehsilsData={TehsilsData}
              DistrictsData={DistrictsData}
              adstatus={adstatus}
            />
          ) : (
            <ProductsServicesEquiqPetFoodForm
              formik={formik}
              PostAdFarmChildCategoriesOptions={
                PostAdFarmChildCategoriesOptions
              }
              BreedsOptons={BreedsOptons}
              QuantityUnitsOptions={QuantityUnits}
              PriceUnitsOptions={PriceUnits}
              DropdownIndicator={DropdownIndicator}
              Style={Style}
              Select={Select}
              AgeMonthsOptions={AgeMonthsOptions}
              AgeYearsOptions={AgeYearsOptions}
              Provinces={Provinces}
              DistrictsOptons={DistrictsOptons}
              TehsilsOptons={TehsilsOptons}
              FileUploader={FileUploader}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              previewImages={previewImages}
              setPreviewImages={setPreviewImages}
              edit={edit}
              inputRef={inputRef}
              RemoveAllFilesFromImageUploader={RemoveAllFilesFromImageUploader}
              GoogleMap={GoogleMap}
              getAddress={getAddress}
              HandleChangeLatLong={HandleChangeLatLong}
              locationStates={locationStates}
              setLocationStates={setLocationStates}
              autoComplete2={autoComplete2}
              setAutoComplete2={setAutoComplete2}
              TehsilsData={TehsilsData}
              DistrictsData={DistrictsData}
              adstatus={adstatus}
            />
          ),
          //eslint-disable-next-line react/jsx-key
          <AdPreview
            data={adDataForPreview}
            makeAdDraft={makeAdDraft}
            edit={edit}
            PostAdSuccessfully={PostAdSuccessfully}
          />,
          // eslint-disable-next-line react/jsx-key
          <AdSuccessfullyPosted
            location={location}
            ad_id={sessionStorage?.getItem("ad_post_id")}
          />,
        ]
  );
  const percentage = ((currentStepIndex + 1) / steps.length) * 100;

  const progressBarStyle = {
    width: `${percentage}%`,
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isLastStep && isAdSuccessfullyPosted) {
      return next();
    } else if (currentStepIndex === steps.length - 3) {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length === 0) {
        if (!locationStates.latitude) {
          alert("Please select location");
          return;
        }
        //if contains bad words then function will return false
        if (
          !IsContentDoesNotContainsBadWords(formik.values.AnimalDescription) ||
          !IsContentDoesNotContainsBadWords(formik.values.AdTitle) ||
          !IsContentDoesNotContainsBadWords(formik.values.BreedName) ||
          !IsContentDoesNotContainsBadWords(formik.values.AreaVillageTown)
        ) {
          alert(
            "Your ad contain some words which is against our policy. Please remove it. If you think it is a mistake then please contact us."
          );
          return;
        }
        const formData = new FormData();
        formData.parent_category = formik?.values?.SelectedCategory;
        formData.category = formik?.values?.Category?.value;
        formData.category_name = formik?.values?.Category?.label;
        formData.breed = formik?.values?.Breed?.value;
        formData.breed_name = formik?.values?.Breed?.label;
        formData.description = formik?.values?.AnimalDescription;
        formData.price = formik?.values?.Price;
        formData.price_type =
          String(formik.values.SelectedCategory) === "100" ||
          String(formik.values.SelectedCategory) === "101" ||
          String(formik.values.SelectedCategory) === "102"
            ? formik.values.PriceType2?.value
            : formik?.values?.PriceType?.value;
        formData.province = formik?.values?.Province?.value;
        formData.district = formik?.values?.District?.value;
        formData.district_name = formik?.values?.District?.label;
        formData.tehsil = formik?.values?.Tehsil?.value;
        formData.tehsil_name = formik?.values?.Tehsil?.label;
        formData.town_en = formik?.values?.AreaVillageTown;
        formData.age = parseInt(
          formik?.values?.AgeMonths?.value +
            formik?.values?.AgeYears?.value * 12
        );
        formData.live_weight = formik?.values?.LiveWeight;
        formData.pregnancy_count = formik?.values?.PregnancyCount
          ? formik?.values?.PregnancyCount?.value
          : null;
        formData.milk_quantity = formik?.values?.DailyMilkQuantity?.value;
        formData.castrated = formik?.values?.Castrated;
        formData.pregnant = formik?.values?.Pregnant.value;
        formData.key = localStorage.getItem("key");
        formData.sex = formik.values.Sex?.value;
        formData.quantity = formik.values.Quantity
          ? formik.values?.Quantity
          : 1;

        //for livestock end
        //for hen bird pet other start
        formData.animal_title = formik?.values?.AdTitle;
        formData.breedtitle = formik?.values?.BreedName;
        formData.quantityUnit = formik?.values?.QuantityUnit?.value;
        formData.teeth = formik?.values?.Teeth?.value;
        formData.min_live_weight = formik?.values?.MinLiveWeight;
        formData.max_live_weight = formik?.values?.MaxLiveWeight;
        formData.min_teeth = formik?.values?.MinTeeth?.value;
        formData.max_teeth = formik?.values?.MaxTeeth?.value;
        formData.latitude = locationStates?.latitude;
        formData.longitude = locationStates?.longitude;
        //calculated age min age months and years
        formData.min_age = parseInt(
          formik?.values?.MinAgeMonths?.value +
            formik?.values?.MinAgeYears?.value * 12
        );
        //calculated age max age months and years
        formData.max_age = parseInt(
          formik?.values?.MaxAgeMonths?.value +
            formik?.values?.MaxAgeYears?.value * 12
        );
        // formData.old_preview = ad_post_id || null;
        const pictures = [];
        const Oldpictures = [];
        const picturesForPreview = [];
        const AllOldAndNewImages = [];

        // append other form data fields to formData here
        const picturePromises = formik.values.Pictures?.map((item) => {
          //if there is no id then it is a new image
          if (item?.id || item.id === "") {
            Oldpictures.push(item?.id);
            picturesForPreview.push(item?.path);
            AllOldAndNewImages.push(item?.path);
          }
          if (!item?.id) {
            const pictureData = new FormData();
            pictureData.append("fileToUpload", item);
            return UploadAdImages(pictureData).then((data) => {
              return data;
            });
          }
        });
        Promise.all(picturePromises).then((data) => {
          if (data) {
            data.forEach((item) => {
              if (item?.code === 200) {
                const { file_url } = item.data;
                pictures.push(file_url);
                picturesForPreview.push(file_url);
                AllOldAndNewImages.push(file_url);
              }
            });
          } else {
            alert(data.message);
          }
          if (pictures.length > 0 || Oldpictures.length > 0) {
            const res = createAd(formData);
            res.then((res) => {
              if (
                res.web_status_code === 101 ||
                res.web_status_code === 102 ||
                res.web_status_code === 103
              ) {
                localStorage.removeItem("key");
                setIsAuth(false);
                setUser(null);
                navigate("/auth/login");
              }
              if (res.code === 200) {
                MyAdDetails({
                  id: res.data.ad_id,
                  key: localStorage.getItem("key"),
                }).then((res) => {
                  if (res.code === 200) {
                    setIsAdPreview(true);
                    next();
                    let dataa = {};
                    if (Oldpictures.length > 0) {
                      dataa = {
                        ...res?.data[0],
                        old_img: Oldpictures,
                        new_img: pictures,
                      };
                    } else {
                      dataa = {
                        ...res?.data[0],
                        img: pictures,
                      };
                    }
                    dataa.img = picturesForPreview;
                    dataa.AllOldAndNewImages = AllOldAndNewImages;
                    setAdDataForPreview({
                      ...dataa,
                    });
                  }
                });
              } else {
                alert(res.message);
              }
            });
          }
        });
      } else {
        // show form errors
        for (let error in errors) {
          formik.setFieldTouched(error, true);
        }
      }
    } else if (currentStepIndex === steps.length - 4) {
      // validate SelectedCategory field
      if (formik.values.SelectedCategory) {
        return next();
      } else {
        formik.setFieldTouched("SelectedCategory", true);
      }
    }
    // else if (currentStepIndex === steps.length - 2 && isAdPreview) {
    //   return next();
    // // }
    else {
      return next();
    }
  };
  const handleBackClick = () => {
    if (currentStepIndex === steps.length - 2 && isAdPreview) {
      setIsAdPreview(false);
      return back();
    } else if (
      currentStepIndex === steps.length - 1 &&
      isAdSuccessfullyPosted
    ) {
      setIsAdSuccessfullyPosted(false);
      return back();
    } else {
      return back();
    }
  };

  useEffect(() => {
    if (!edit) {
      const data = UserDetailsData(localStorage.getItem("key"));
      Promise.resolve(data).then((data) => {
        if (
          data.web_status_code === 101 ||
          data.web_status_code === 102 ||
          data.web_status_code === 103
        ) {
          localStorage.removeItem("key");
          setIsAuth(false);
          setUser(null);
          navigate("/auth/login");
        }
        if (data.code === 200) {
          Provinces.find((item) => {
            if (String(item.value) === String(data.data[0].province_id)) {
              formik.setFieldValue("Province", item);
            }
          });
          formik.setFieldValue("District", {
            value: data.data[0].district_id,
            label: data.data[0].district,
          });
          formik.setFieldValue("Tehsil", {
            value: data.data[0].tehsil_id,
            label: data.data[0].tehsil,
          });
          formik.setFieldValue("AreaVillageTown", data.data[0].town_en);
          if (data.data[0].district_id && data.data[0].province_id) {
            MutateDistricts({
              province: data.data[0].province_id,
            }).then((res) => {
              res?.data?.map(async (item) => {
                if (String(item.id) === String(data.data[0].district_id)) {
                  const addrss = await getAddress(
                    parseFloat(item.latitude),
                    parseFloat(item.longitude)
                  );

                  setLocationStates((prev) => {
                    return {
                      ...prev,
                      address: addrss,
                      latitude: item.latitude,
                      longitude: item.longitude,
                    };
                  });
                }
              });
            });
          }
        }
      });
    }
    if (edit) {
      MyAdData({ ad_post_id: ad_post_id }).then((res1) => {
        if (res1.code === 200) {
          const adData = res1?.data[0] ?? [];
          formik.setValues({
            CoverImageIndex: 0,
            SelectedCategory: String(p_parent_cat_id),
            //checking parent category id
            Category: ["130", "100", "101", "102", "6"].includes(
              String(p_parent_cat_id)
            )
              ? adData.category_id && {
                  value: adData.category_id,
                  label: adData.category_eng,
                }
              : adData.category_idd && {
                  value: adData.category_idd,
                  label: adData.category_idd_eng,
                },
            Breed: adData.breed_id && {
              value: adData.breed_id,
              label: adData.breed_eng,
            },
            Sex: adData.sex && { value: adData.sex, label: adData.sex },
            Quantity: adData.quantity,
            AgeYears: adData.age && {
              value: Math.floor(parseInt(adData.age) / 12),
              label: String(Math.floor(parseInt(adData.age) / 12)),
            },
            AgeMonths: adData.age && {
              value: parseInt(adData.age) % 12,
              label: String(parseInt(adData.age) % 12),
            },
            LiveWeight: adData.live_weight === "0" ? "" : adData.live_weight,
            AnimalDescription: adData.description,
            Price: adData.price,
            PriceType: adData.price_type && {
              value: adData.price_type,
              label:
                (adData.price_type === "perkg" && "Per Kg") ||
                (adData.price_type === "total" && "Total") ||
                (adData.price_type === "perhead" && "Per Head"),
            },
            PriceType2: adData.price_type && {
              value: adData.price_type,
              label: PriceUnits.find(
                (item) => item.value === adData?.price_type
              )?.label,
            },
            Teeth:
              adData.teeth &&
              [
                {
                  value: "0",
                  label: "Kheeri",
                },
                {
                  value: "2",
                  label: "Two Teeth",
                },
                {
                  value: "4",
                  label: "Four Teeth",
                },
                {
                  value: "6",
                  label: "Six Teeth",
                },
              ].find((item) => String(item.value) === adData.teeth),
            DailyMilkQuantity: adData.milk_quantity && {
              value: adData.milk_quantity,
              label: adData.milk_quantity,
            },
            Pregnant:
              (adData.pregnant &&
                adData.pregnant === "yes" && { value: true, label: "Yes" }) ||
              (adData.pregnant === "no" && { value: false, label: "No" }),
            PregnancyCount: adData.pregnancy_count && {
              value: adData.pregnancy_count,
              label: adData.pregnancy_count,
            },
            Castrated:
              (adData.castrated &&
                adData.castrated === "1" && { value: true, label: "Yes" }) ||
              (adData.castrated === "0" && { value: false, label: "No" }),
            Pictures: adData.img,
            //
            Province: adData.province_id && {
              value: parseInt(adData.province_id),
              label: String(adData.province_title),
            },
            District: adData.district_id && {
              value: adData.district_id,
              label: adData.district_eng,
            },
            Tehsil: adData.tehsil_id && {
              value: adData.tehsil_id,
              label: adData.tehsil_eng,
            },
            AreaVillageTown: adData.town_en,
            AdTitle: adData.post_title,
            BreedName: adData.breed_title,
            QuantityUnit: adData.quantity_unit && {
              value: adData.quantity_unit,
              label: QuantityUnits.find(
                (item) => item.value === adData.quantity_unit
              ).label,
            },
            MinLiveWeight: adData.min_live_weight,
            MaxLiveWeight: adData.max_live_weight,
            MaxAgeYears: adData.max_age && {
              value: Math.floor(parseInt(adData.max_age) / 12),
              label: String(Math.floor(parseInt(adData.max_age) / 12)),
            },
            MaxAgeMonths: adData.max_age && {
              value: parseInt(adData.max_age) % 12,
              label: String(parseInt(adData.max_age) % 12),
            },
            MinAgeYears: adData.min_age && {
              value: Math.floor(parseInt(adData.min_age) / 12),
              label: String(Math.floor(parseInt(adData.min_age) / 12)),
            },
            MinAgeMonths: adData.max_age && {
              value: parseInt(adData.min_age) % 12,
              label: String(parseInt(adData.min_age) % 12),
            },
            MaxTeeth:
              adData.max_teeth &&
              [
                {
                  value: "0",
                  label: "Kheeri",
                },
                {
                  value: "2",
                  label: "Two Teeth",
                },
                {
                  value: "4",
                  label: "Four Teeth",
                },
                {
                  value: "6",
                  label: "Six Teeth",
                },
              ].find((item) => String(item.value) === adData.max_teeth),
            MinTeeth:
              adData.min_teeth &&
              [
                {
                  value: "0",
                  label: "Kheeri",
                },
                {
                  value: "2",
                  label: "Two Teeth",
                },
                {
                  value: "4",
                  label: "Four Teeth",
                },
                {
                  value: "6",
                  label: "Six Teeth",
                },
              ].find((item) => String(item.value) === adData.min_teeth),
          });
          setSelectedFiles(adData.img);
          setPreviewImages(adData.img);
          setAdStatus({
            is_delivery: adData.is_delivery,
            is_star: adData.is_star,
            is_verified: adData.is_verified,
          });

          if (adData.district_id && adData.province_id) {
            MutateDistricts({
              province: adData.province_id,
            }).then((res) => {
              res?.data?.map(async (item) => {
                if (String(item.id) === String(adData.district_id)) {
                  const addrss = await getAddress(
                    parseFloat(item.latitude),
                    parseFloat(item.longitude)
                  );

                  setLocationStates((prev) => {
                    return {
                      ...prev,
                      address: addrss,
                      latitude: item.latitude,
                      longitude: item.longitude,
                    };
                  });
                }
              });
            });
          }

          localStorage.getItem("doNotShowAgainPostAd") !== "true"
            ? goTo(2)
            : goTo(1);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {showAlertModal && (
        <Modal>
          <div id="modalInner">
            <div className="w-full h-[480px] max-w-[600px] bg-[#fff] p-3 md:p-[20px] rounded-[13px] my-0 mx-auto mt-2 md:mt-4 relative">
              <div className=" absolute top-[-13px] right-[4px] mt-3">
                <button
                  className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                  onClick={() => {
                    setShowAlertModal(false);
                  }}
                >
                  <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                </button>
              </div>
              <div className="flex flex-col items-center gap-2 mt-28">
                <p className="mb-8 text-center">{createAdData?.message}</p>

                <button
                  className="bg-Primary text-white font-PoppinsMedium text-[14px] max-w-[300] w-full p-3 rounded-lg"
                  type="button"
                  onClick={() => {
                    navigate("/services/paid-ads", {
                      state: {
                        fromMyAds: true,
                      },
                    });
                  }}
                >
                  Buy Paid Ads
                </button>

                <button
                  className="bg-Primary text-white font-PoppinsMedium text-[14px] max-w-[300] w-full p-3 rounded-lg"
                  type="button"
                  onClick={() => {
                    navigate("/account/my-ads");
                  }}
                >
                  Go to My Ads Page
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <UpdateMetaTags data={{ title: "Post your ad" }} />
      <div className="relative min-w-screen min-h-screen bg-[#F4F4F4] font-PoppinsRegular">
        <img
          src={LogoCut}
          alt="Logo"
          className="absolute bottom-0 right-0 z-0 "
        />
        <div className="bg-white">
          <div className="font-PoppinsRegular max-w-[1280px] mx-auto">
            <div className="flex justify-between items-center  px-5 py-3">
              <Link to="/" className=" h-8 w-28">
                <Logo />
              </Link>
              <Link
                to="/"
                className="w-[110px]  text-center sm:w-[203px] flex-shrink h-[42px] rounded-[50px] bg-[#EFA922] flex justify-center items-center text-white text-[14px]"
              >
                Go Back To homepage
              </Link>
            </div>
          </div>
        </div>
        <div className="max-w-[1280px] mx-auto">
          {/* <BannerTop /> */}

          <div className="py-5">
            <h2 className="font-PoppinsBold text-[22px] text-center">
              Post your ad
            </h2>
          </div>

          {isUploadingImages ? (
            // show loading spinner with uploading images text
            <div className="flex justify-center items-center h-[500px]">
              <div className="flex flex-col items-center">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-green-500"></div>
                <p className="font-PoppinsBold text-[22px] text-center mt-5">
                  Uploading Images
                </p>
              </div>
            </div>
          ) : isCreatingAd || MyAdDetailsLoading ? (
            // show loading spinner with creating ad text
            <div className="flex justify-center items-center h-[500px]">
              <div className="flex flex-col items-center">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-green-500"></div>
                <p className="font-PoppinsBold text-[22px] text-center mt-5">
                  Please Wait
                </p>
              </div>
            </div>
          ) : MyAdDataLoading ? (
            <div className="flex justify-center items-center h-[500px]">
              <div className="flex flex-col items-center">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-green-500"></div>
                <p className="font-PoppinsBold text-[22px] text-center mt-5">
                  Loading Ad Data Please Wait
                </p>
              </div>
            </div>
          ) : (
            <form
              onSubmit={(e) => onSubmit(e)}
              className="w-full h-full bg-white rounded-xl z-10 relative"
            >
              {step}
              {currentStepIndex !== steps.length - 1 &&
                !isAdSuccessfullyPosted && (
                  <>
                    <div className={`border-t-2`}></div>
                    <div className=" flex justify-between items-center mb-[120px] md:mb-0 px-5 py-3 space-x-4">
                      {!isFirstStep && (
                        <>
                          {currentStepIndex <= steps.length - 3 &&
                          edit ? null : (
                            <button
                              className="font-PoppinsBold text-black z-10 flex items-center"
                              type="button"
                              onClick={handleBackClick}
                            >
                              <span className=" font-PoppinsBold text-5xl">
                                &lt;
                              </span>
                              Back{" "}
                            </button>
                          )}
                        </>
                      )}
                      <div className="h-2 w-[70%] mx-auto bg-neutral-200 rounded-lg">
                        <div
                          className={`h-2 bg-green-500 rounded-lg`}
                          style={progressBarStyle}
                        ></div>
                      </div>
                      {!isAdPreview ? (
                        <button
                          className=" font-PoppinsBold text-Primary z-10 flex items-center"
                          type="submit"
                        >
                          {currentStepIndex === steps.length - 3
                            ? "Preview Ad"
                            : "Next"}
                          <span className="text-Primary font-PoppinsBold text-5xl">
                            &gt;
                          </span>
                        </button>
                      ) : (
                        <button
                          className=" font-PoppinsBold text-Primary z-10 flex items-center"
                          type="button"
                          onClick={PostAdSuccessfully}
                        >
                          Publish Ad
                          <span className="text-Primary font-PoppinsBold text-5xl">
                            &gt;
                          </span>
                        </button>
                      )}
                    </div>
                  </>
                )}
            </form>
          )}
          <p className="text-center text-[14px] text-TextColor pt-2">
            Copyright {new Date().getFullYear()} farmGhar. All Rights Reserved
          </p>
        </div>
      </div>
    </>
  );
}
