import { Link } from "react-router-dom";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye.svg";
export default function RenderArticles({ articles }) {
  const getShortName = (title) => {
    let str;

    if (title?.length > 40) {
      str = title?.substring(0, 40) + "...";
    } else {
      str = title ?? "";
    }
    return str;
  };
  // get text substring from html string
  const getTextFromHtmlString = (htmlString) => {
    const div = document.createElement("div");
    div.innerHTML = htmlString;
    const text = div.textContent || div.innerText || "";
    if (text.length > 60) {
      return text.substring(0, 60) + "...";
    }
    return text;
  };

  return articles?.map(
    (item, index) =>
      index !== 0 && (
        <Link
          key={index}
          to={item?.slug ?? item.slug}
          className={`
          rounded-[13px] border border-[#DBDED9] md:w-[220px] md:h-[350px]   lg:w-[240px] h-[325px]
          `}
        >
          <div
          // commented for ads space in blog page
          // className={`
          // rounded-[13px] border border-[#DBDED9] md:w-[220px] md:h-[350px]   lg:w-[260px] h-[325px]
          // `}
          >
            <div className={"rounded-[8px] mt-[6.77px] mx-[5.95px] "}>
              <div className={"relative h-[180.49px]"}>
                <img
                  src={item?.image && item?.image}
                  className={`  rounded-[9px] w-full h-full
               `}
                  alt={item?.image_alt_tag && item?.image_alt_tag}
                  title={item?.image_title && item?.image_title}
                />
              </div>
            </div>
            <div className="m-[12.51px]">
              {/* for Blog Card  */}

              <div className="flex justify-between">
                <h2 className="text-[18px] font-PoppinsBold">
                  {getShortName(item?.title)}
                </h2>

                <div className="flex items-center">
                  {item?.count && (
                    <div className="opacity-50 mr-[5.85px]">
                      <EyeIcon />
                    </div>
                  )}
                  {item?.count ?? item?.count}
                </div>
              </div>

              <div className="text-[12px]">
                {item?.content && (
                  <p className="text-justify">
                    {getTextFromHtmlString(item?.content ?? item.content)}
                  </p>
                )}
              </div>

              <div className="flex justify-between mt-[10px]">
                <p className="text-[16px] sm:text-[14px] lg:text-[16px] text-[#39342F] opacity-50">
                  {item?.PostedText ?? item?.PostedText}
                </p>

                <p className=" text-[16px]  text-Primary sm:text-[14px] lg:text-[16px] font-PoppinsBold">
                  Read More
                </p>
              </div>
            </div>
          </div>
        </Link>
      )
  );
}
