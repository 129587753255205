import Slider from "react-slick";
import { useRef } from "react";
import { ReactComponent as PrevIcon } from "../../assets/slider/left.svg";
import { ReactComponent as NextIcon } from "../../assets/slider/right.svg";
import { ReactComponent as PackageIcon } from "../../assets/icons/package.svg";
import { ReactComponent as DeliveryIcon } from "../../assets/icons/delivery.svg";
import { ReactComponent as LocationAdIcon } from "../../assets/icons/locationAd.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as SoldOutIcon } from "../../assets/icons/SoldOut.svg";
import { ReactComponent as NewsIcon } from "../../assets/icons/news.svg";
import { ReactComponent as StarIcon } from "../../assets/icons/star.svg";
import { ReactComponent as VerifiedIcon } from "../../assets/icons/verified.svg";
import { Link } from "react-router-dom";
import BannerTop from "./BannerTop";
import HorizentalGoogleAd from "../../Utils/HorizentalGoogleAd";

export default function CardSlider(props) {
  const slider = useRef(null);

  const IsServiceheckForCustomArrow = props.settings.IsService ?? "";
  function SampleNextArrow(props) {
    const { onClick } = props;

    return (
      //eslint-disable-next-line
      <div
        className={`z-10 absolute ${
          IsServiceheckForCustomArrow ? "top-[25px]" : "top-[120px]"
        }  right-[-34px]  ${onClick ? "block" : "hidden"} `}
        style={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <NextIcon />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;

    return (
      //eslint-disable-next-line
      <div
        className={`z-10 absolute ${
          IsServiceheckForCustomArrow ? "top-[25px]" : "top-[120px]"
        } left-[-38px]  ${onClick ? "block" : "hidden"} `}
        style={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <PrevIcon />
      </div>
    );
  }
  const settings = {
    dots: false,
    infinite: props.settings.infinite ? props.settings.infinite : false,
    arrows: true,
    speed: 500,
    className: "slider variable-width",
    variableWidth: true,
    slidesToShow: props.settings.slidesToShow,
    slidesToScroll: props.settings.slidesToScroll,
    autoplay: props.settings.autoplay ? props.settings.autoplay : false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: props.settings.responsive,
  };

  const DisplaySoldOut = () => {
    return alert("This item has been sold and no longer available.");
  };

  const getShortName = (name) => {
    let str;

    if (name.length > 14) {
      str = name.substring(0, 20) + "...";
    } else {
      str = name;
    }
    return str;
  };

  if (props.settings.data?.length === 0) return null;
  return (
    <>
      <div className="pt-3 relative">
        {/*
        {props.settings.IsService ? (
          <div
            className={
              "z-10 absolute top-[120px] left-[-38px] hidden " +
              props.settings.hiddenOnOneSlide
            }
          >
            <button onClick={() => slider?.current?.slickPrev()}>
              <PrevIcon />
            </button>
          </div>
        ) : (
          <div
            className={
              "z-10 absolute top-[206px] left-[-38px] hidden " +
              props.settings.hiddenOnOneSlide
            }
          >
            <button onClick={() => slider?.current?.slickPrev()}>
              <PrevIcon />
            </button>
          </div>
        )} */}

        <div className="flex justify-between mt-[21px] mb-[28px] ">
          {props.settings.SectionName && (
            <p className="font-PoppinsBold md:text-[22px]">
              {props.settings.SectionName}
            </p>
          )}

          <>
            {props.settings.ViewAll && (
              <Link
                to={props.settings.SectionLink}
                className="font-PoppinsBold text-[18px] text-Primary mr-[15px]"
              >
                {props.settings.ViewAll}
              </Link>
            )}
          </>
        </div>

        <Slider ref={slider} {...settings}>
          {props.settings.data?.map((data, index) => {
            return (
              <div key={index} style={{ width: data.IsBlog ? 280 : 270 }}>
                <div
                  className={`
                    rounded-[13px] border border-[#DBDED9]  ${
                      data.isViewAllLastCard
                        ? "bg-gray-100 flex justify-center items-center"
                        : data?.IsManagedByFarmGhar === "1"
                        ? "bg-Primary"
                        : "bg-white"
                    }  ${data.IsBlog ? " w-[270px] " : " w-[240px] "} ${
                    props.settings.Width
                  } ${props.settings.Height}
                  `}
                >
                  {data.IsAd || data.isViewAllLastCardLink ? (
                    <Link
                      to={
                        /sold/i.test(data.AdStatus)
                          ? "#sold"
                          : data.AdLink
                          ? data.AdLink
                          : data.isViewAllLastCardLink
                      }
                      onClick={
                        /sold/i.test(data.AdStatus) ? DisplaySoldOut : null
                      }
                      className="w-full h-full flex flex-col justify-center"
                    >
                      {!data.isViewAllLastCard && (
                        <div
                          className={
                            "rounded-[8px] mt-[6.77px] mr-[5.95px] ml-[5px] w-[230px] " +
                            props.settings.ImageWidth +
                            " " +
                            props.settings.ImageHeight
                          }
                        >
                          <div
                            className={
                              "relative w-[230px] " +
                              props.settings.ImageWidth +
                              " " +
                              props.settings.ImageHeight
                            }
                          >
                            {!data.isViewAllLastCard && (
                              <figure className="w-full h-full">
                                <img
                                  src={data.Image && data.Image}
                                  className={
                                    " rounded-[9px] w-full h-full object-cover "
                                  }
                                  alt={
                                    data.AdTitle
                                      ? data.AdTitle
                                      : data.SectionName && data.SectionName
                                  }
                                />
                              </figure>
                            )}

                            {data.IsStar === "1" && (
                              <div className="absolute top-[-15px] left-[-15px]">
                                <StarIcon />
                              </div>
                            )}
                            {data.AdStatus && /sold/i.test(data.AdStatus) && (
                              <div className="absolute right-[85px] top-[86px]">
                                <SoldOutIcon />
                              </div>
                            )}
                            {/* removed heart icon for now */}

                            {/* {data.IsHeartIcon && (
                              <div className="absolute top-[11.49px] right-[9.84px] bg-gray-50 h-9 w-9 rounded-full flex justify-center items-center bg-opacity-25">
                                {" "}
                                <svg
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.target.classList.add("animate-pulse");
                                    e.currentTarget.classList.toggle(
                                      "fill-[#FE251B]"
                                    );
                                    e.currentTarget.classList.toggle(
                                      "stroke-[#FE251B]"
                                    );

                                    setTimeout(() => {
                                      e.target.classList.remove(
                                        "animate-pulse"
                                      );
                                    }, 1000);
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke={"black"}
                                  className={`w-6 h-6 cursor-pointer ${
                                    data?.is_fav === 0 &&
                                    "fill-[#FE251B] stroke-[#FE251B]"
                                  }  `}
                                  fill="none"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                                  />
                                </svg>
                              </div>
                            )} */}
                            {data.IsVerified === "1" && (
                              <div className="absolute bottom-[7.62px] right-[6.43px]">
                                <VerifiedIcon />
                              </div>
                            )}
                            {data.IsNews && (
                              <div className="absolute bottom-[-14px]">
                                <NewsIcon />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <div
                        className={` ${
                          data?.IsManagedByFarmGhar === "1"
                            ? "bg-white rounded-md ml-0 mr-0 px-3 m-[3.51px]"
                            : "m-[12.51px]"
                        }`}
                      >
                        <div
                          className={`flex ${
                            data?.IsManagedByFarmGhar === "1"
                              ? "mt-[3px] pt-[3px]"
                              : null
                          }`}
                        >
                          {data.IsDeliveryAd === "1" && (
                            <div>
                              <PackageIcon />
                            </div>
                          )}
                        </div>

                        {/* for service  */}
                        {data.IsService && (
                          <div className="flex justify-between m-[12.59px]">
                            {data.ServiceName && (
                              <h3 className="text-[16px] font-PoppinsBold">
                                {data.ServiceName}
                              </h3>
                            )}
                            {/* hiding for now but show after implementation of service section */}
                            {/* {data.ServiceMoreInfo && data.ServiceName && (
                              <Link
                                to={`/${data.ServiceName.replace(
                                  /\s/g,
                                  "-"
                                ).toLowerCase()}`}
                                className="underline text-Primary"
                              >
                                {data.ServiceMoreInfo}
                              </Link>
                            )} */}
                          </div>
                        )}

                        {/* for Ad */}
                        {data.AdTitle && (
                          <div>
                            <h3
                              href="/"
                              className={`font-PoppinsBold  ${
                                data?.isViewAllLastCard
                                  ? "text-center text-Primary text-[20px]"
                                  : "text-[16px]"
                              }`}
                            >
                              {data?.isViewAllLastCard
                                ? data.AdTitle
                                : getShortName(data.AdTitle)}
                            </h3>
                          </div>
                        )}

                        {data.IsAd && (
                          <div className="flex justify-between">
                            {data.PostedText && (
                              <p className="font-[10px] text-[#39342F] opacity-50">
                                {data.PostedText}
                              </p>
                            )}
                            {data.AdPrice && (
                              <p className="text-black font-[18px]">
                                RS {data.AdPrice}
                              </p>
                            )}
                          </div>
                        )}
                        {/* for category ad  */}
                        {data.IsAd && (
                          <div className="flex justify-between">
                            {data.AdLocation && (
                              <div className="flex items-center">
                                <div className="mr-[5.85px]">
                                  <LocationAdIcon />
                                </div>
                                <p>{data.AdLocation}</p>{" "}
                              </div>
                            )}
                            {data.AdViewsCount && (
                              <div className="flex items-center">
                                <div className="opacity-50 mr-[5.85px]">
                                  <EyeIcon />
                                </div>
                                <p className="text-Primary">
                                  {data.AdViewsCount}
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      {data?.IsManagedByFarmGhar === "1" && (
                        <div className="flex justify-center text-white font-bold text-[14px]">
                          farmGhar Self-stock
                        </div>
                      )}
                    </Link>
                  ) : (
                    <>
                      <Link
                        to={
                          data?.ServiceMoreInfoLink
                            ? data?.ServiceMoreInfoLink
                            : data?.ReadMoreLink
                        }
                        className="inline-block w-full h-full"
                      >
                        <div
                          className={
                            "rounded-[8px] mt-[6.77px] mx-[5.95px] " +
                            props.settings.ImageWidth +
                            " " +
                            props.settings.ImageHeight
                          }
                        >
                          <div
                            className={
                              "relative " +
                              props.settings.ImageWidth +
                              " " +
                              props.settings.ImageHeight
                            }
                          >
                            <img
                              src={data.Image && data.Image}
                              className={` ${
                                data.IsBlog
                                  ? "rounded-[9px] w-full h-full object-cover"
                                  : "h-full w-full"
                              } `}
                              alt={
                                data.AdTitle
                                  ? data.AdTitle
                                  : data.SectionName && data.SectionName
                              }
                            />

                            {data.IsStar && (
                              <div className="absolute top-[-15px] left-[-13px]">
                                <StarIcon />
                              </div>
                            )}
                            {data.IsHeartIcon && (
                              <div className="absolute top-[11.49px] right-[9.84px]">
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="gray"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                                  />
                                </svg>
                              </div>
                            )}
                            {data.IsVerified && (
                              <div className="absolute bottom-[7.62px] right-[6.43px]">
                                <VerifiedIcon />
                              </div>
                            )}
                            {data.IsNews && (
                              <div className="absolute bottom-[-14px]">
                                <NewsIcon />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="m-[12.51px]">
                          {data.IsDeliveryIconAndPkgIcon && (
                            <div className="flex">
                              <div>
                                <DeliveryIcon />
                              </div>
                              <div>
                                <PackageIcon />
                              </div>
                            </div>
                          )}
                          {/* for service  */}
                          {data.IsService && (
                            <div
                              className="flex flex-col justify-between mt-[-90px]
                          ml-[113px]"
                            >
                              {data.ServiceName && (
                                <h3 className="text-[14px] font-PoppinsBold">
                                  {data.ServiceName}
                                </h3>
                              )}
                              {/* hiding for now but show after implementation of service section */}
                              {data.ServiceMoreInfo && data.ServiceName && (
                                <p className="underline text-Primary">
                                  {data.ServiceMoreInfo}
                                </p>
                                // <button
                                //   onClick={() => {
                                //     setModalData(data);
                                //     setShowModal(true);
                                //   }}
                                //   className="underline text-Primary ml-[-13px] text-[16px] w-[100px]"
                                // >
                                //   {data.ServiceMoreInfo}
                                // </button>
                              )}
                            </div>
                          )}

                          {/* for Ad */}
                          {data.AdTitle && (
                            <div>
                              <h3 className="font-PoppinsBold text-[16px]">
                                {data.AdTitle}
                              </h3>
                            </div>
                          )}

                          {/* for News tite  */}
                          {data.NewsTitle && (
                            <div className="mt-[23px] text-[18px] font-PoppinsBold">
                              <p>{data.NewsTitle}</p>
                            </div>
                          )}

                          {/* for Blog Card  */}
                          {data.IsBlog && (
                            <div className="flex justify-between">
                              {data.BlogTitle && (
                                <p className="text-[18px] font-PoppinsBold">
                                  {data.BlogTitle}
                                </p>
                              )}
                              {data.ViewsCount && (
                                <div className="flex items-center">
                                  <div className="opacity-50 mr-[5.85px]">
                                    <EyeIcon />
                                  </div>
                                  {data.ViewsCount}
                                </div>
                              )}
                            </div>
                          )}

                          {data.IsBlog && (
                            <div className="text-[12px] text-justify">
                              {data.Description && <p>{data.Description}</p>}
                            </div>
                          )}

                          {data.IsAd && (
                            <div className="flex justify-between">
                              {data.PostedText && (
                                <p className="font-[10px] text-[#39342F] opacity-50">
                                  {data.PostedText}
                                </p>
                              )}
                              {data.AdPrice && (
                                <p className="text-Primary font-[18px]">
                                  RS {data.AdPrice}
                                </p>
                              )}
                            </div>
                          )}
                          {/* for category ad  */}
                          {data.IsAd && (
                            <div className="flex justify-between">
                              {data.AdLocation && (
                                <div className="flex items-center">
                                  <div className="mr-[5.85px]">
                                    <LocationAdIcon />
                                  </div>
                                  <p>{data.AdLocation}</p>{" "}
                                </div>
                              )}
                              {data.AdViewsCount && (
                                <div className="flex items-center">
                                  <div className="opacity-50 mr-[5.85px]">
                                    <EyeIcon />
                                  </div>
                                  <p>{data.AdViewsCount}</p>
                                </div>
                              )}
                            </div>
                          )}

                          {/* for News and Blogs  */}
                          {data.IsNews && (
                            <div className="flex justify-between mt-[10px]">
                              {data.PostedText && (
                                <p className="font-[10px] text-[#39342F] opacity-50">
                                  {data.PostedText}
                                </p>
                              )}
                              {data.ReadMore && (
                                <p className="text-Primary font-[18px]">
                                  {data.ReadMore}
                                </p>
                              )}
                            </div>
                          )}
                          {data.IsBlog && (
                            <div className="flex justify-between mt-[10px]">
                              {data.PostedText && (
                                <p className="font-[10px] text-[#39342F] opacity-50">
                                  {data.PostedText}
                                </p>
                              )}
                              {data.ReadMore && (
                                <p className="text-Primary font-[18px] font-PoppinsBold">
                                  {data.ReadMore}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </Link>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </Slider>
        {/* {props.settings.IsService ? (
          <div
            className={
              "z-10 absolute top-[120px] right-[-34px] hidden " +
              props.settings.hiddenOnOneSlide
            }
          >
            <button onClick={() => slider?.current?.slickNext()}>
              <NextIcon />
            </button>
          </div>
        ) : (
          <div
            className={
              "z-10 absolute top-[206px] right-[-34px] hidden " +
              props.settings.hiddenOnOneSlide
            }
          >
            <button onClick={() => slider?.current?.slickNext()}>
              <NextIcon />
            </button>
          </div>
        )} */}
      </div>
      {props.settings.ShowBanner && <BannerTop />}
      {props.settings.ShowGoogleAd && <HorizentalGoogleAd />}
    </>
  );
}
