import Breadcrumbs from "../Components/BreadCrumbs";
import BannerTop from "../Components/HomePageComponents/BannerTop";
import CallToActionBottom from "../Components/HomePageComponents/CallToActionBottom";
import { useParams } from "react-router-dom";
import { ReactComponent as AdCallIcon } from "../assets/icons/AdCall.svg";
import { ReactComponent as AdWhatsappIcon } from "../assets/icons/AdWhatsapp.svg";
import SquareGoogleAd from "../Utils/SquareGoogleAd";
import ShareButtons from "../Components/BlogsComponents/BlogDetails/ShareButtons";
import { useFetchQurbaniSEOContent } from "../Middlewares/Api";
import UpdateMetaTags from "../Utils/UpdateMetaTags";
import { useEffect, useState } from "react";
import CheckoutButton from "../Components/QurbaniComponents/CheckoutButton";
import Toast from "../Utils/Toast";
import { useContext } from "react";
import { CartContext } from "../Context/CartContext";
export default function QurbaniSEOPage() {
  useEffect(() => {
    const checkAppended = document.querySelector(".applied");
    if (!checkAppended) {
      var el1 = document.querySelector("table");
      if (el1) {
        // create wrapper container
        var wrapper1 = document.createElement("div");

        wrapper1.className = "px-4 sm:px-6 lg:px-8 applied ";

        // insert wrapper before el in the DOM tree
        el1.parentNode.insertBefore(wrapper1, el1);
        // move el into wrapper
        wrapper1.appendChild(el1);
      }

      var el2 = document.querySelector("table");
      if (el2) {
        // create wrapper container
        var wrapper2 = document.createElement("div");

        wrapper2.className = "mt-8 flex flex-col applied ";

        // insert wrapper before el in the DOM tree
        el2.parentNode.insertBefore(wrapper2, el2);
        // move el into wrapper
        wrapper2.appendChild(el2);
      }

      var el3 = document.querySelector("table");
      if (el3) {
        // create wrapper container
        var wrapper3 = document.createElement("div");

        wrapper3.className =
          "-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 applied ";

        // insert wrapper before el in the DOM tree
        el3.parentNode.insertBefore(wrapper3, el3);
        // move el into wrapper
        wrapper3.appendChild(el3);
      }

      var el4 = document.querySelector("table");
      if (el4) {
        // create wrapper container
        var wrapper4 = document.createElement("div");

        wrapper4.className =
          "inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 applied ";

        // insert wrapper before el in the DOM tree
        el4.parentNode.insertBefore(wrapper4, el4);
        // move el into wrapper
        wrapper4.appendChild(el4);
      }

      var el5 = document.querySelector("table");
      if (el5) {
        // create wrapper container
        var wrapper5 = document.createElement("div");

        wrapper5.className = "overflow-hidden  md:rounded-lg applied ";

        // insert wrapper before el in the DOM tree
        el5.parentNode.insertBefore(wrapper5, el5);
        // move el into wrapper
        wrapper5.appendChild(el5);
      }
    }
  }, []);

  const { QurbaniSlug } = useParams();
  const { data, isLoading } = useFetchQurbaniSEOContent({
    QurbaniSlug: QurbaniSlug,
  });
  const QurbaniPackages = data?.data ?? {};

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "Item added to cart successfully."
  );
  const handleCloseToast = () => {
    setShowToast(false);
  };
  const handleShowToast = () => {
    setShowToast(true);
  };

  const { addToCart, cartItems } = useContext(CartContext);

  const handleAddToCart = (item) => {
    addToCart(
      {
        id: parseInt(item.package_id),
        name: item.package_name,
        price: parseInt(item?.price?.replace(/,/g, "")),
        catId: parseInt(item?.cat_id),
        totalQuantity: parseInt(item?.total_quantity),
        availableQuantity: parseInt(item?.available_quantity),
        packageCatName: item?.package_cat_name,
        deliveryPrice: parseInt(item?.delivery_price?.replace(/,/g, "")),
        description: item?.description,
        image: item?.image,
        SelectedEidDay: item.SelectedEidDay,
      },
      1
    );
    let cartsI = 0;
    const found = cartItems?.find(
      (element) => element.id === parseInt(item.package_id)
    );
    if (found) {
      cartsI = 0;
    } else {
      cartsI = 1;
    }

    cartItems?.forEach((element) => {
      cartsI = cartsI + element.quantity;
    });
    // setToastMessage(
    //   `${cartsI} ${cartsI > 1 ? "items" : "item"} added to cart successfully.`
    // );
    // handleShowToast();
    window.location.href = "/qurbani/checkout";
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center mt-20">
        <div className="spinner"></div>
      </div>
    );
  }

  if (QurbaniPackages.length === 0) {
    return (
      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>
        <div className="flex items-center justify-center mt-20">
          <div className="text-2xl font-PoppinsRegular">No Details Found</div>
        </div>
      </div>
    );
  }

  return (
    <>
      {showToast && (
        <Toast
          message={toastMessage}
          duration={3000}
          onClose={handleCloseToast}
        />
      )}
      <div className="relative font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>
        <div>
          <Breadcrumbs />
        </div>
        <div className="fixed z-50 bottom-[134px] right-[10px]">
          <CheckoutButton />
        </div>
        <UpdateMetaTags data={QurbaniPackages} />
        <div className=" font-PoppinsRegular">
          <h2 className="font-PoppinsBold text-[22px]">
            {QurbaniPackages?.title ?? ""}
          </h2>
          <div className="flex  flex-col md:flex-row pt-4">
            <div className="w-full md:w-[67%] lg:w-[70%]  mr-[25px]">
              <div className=" flex flex-col    rounded-[13px] bg-gray-50  p-4 sm:pr-4 lg:mr-[-30px]">
                <img
                  src={
                    QurbaniPackages?.seo_image ? QurbaniPackages?.seo_image : ""
                  }
                  alt={
                    QurbaniPackages?.image_alt_tag
                      ? QurbaniPackages?.image_alt_tag
                      : ""
                  }
                  title={
                    QurbaniPackages?.image_title
                      ? QurbaniPackages?.image_title
                      : ""
                  }
                  className=" mx-auto"
                />
              </div>
              <div className="py-4">
                <ShareButtons slug={QurbaniPackages?.slug} qurbani={true} />
              </div>
              <div>
                <h4 className="font-PoppinsBold  pt-2 pb-2">
                  {QurbaniPackages?.title ?? ""}
                </h4>
                <div className="space-y-1">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      const found = cartItems.find((cartItem) => {
                        if (
                          parseInt(cartItem.id) ===
                          parseInt(QurbaniPackages.package_id)
                        ) {
                          return cartItem;
                        }
                      });
                      if (
                        parseInt(QurbaniPackages.f_d_available_quantity) -
                          parseInt(found?.quantity ?? 0) >=
                        1
                      ) {
                        if (cartItems[0]?.SelectedEidDay === "1") {
                          QurbaniPackages.SelectedEidDay = "1";
                          handleAddToCart(QurbaniPackages);
                        } else if (cartItems[0]?.SelectedEidDay === "2") {
                          setToastMessage(
                            "Please remove Eid Day 2 items to add Eid Day 1 items."
                          );
                          handleShowToast();
                        }

                        if (cartItems.length === 0) {
                          QurbaniPackages.SelectedEidDay = "1";
                          handleAddToCart(QurbaniPackages);
                        }
                      } else {
                        setToastMessage("Out of stock.");
                        handleShowToast();
                      }
                    }}
                    className="bg-Primary w-[90%] py-2 flex mx-auto text-white font-PoppinsBold rounded-[50px]"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="inline-block ml-4"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.01672 13.3333L9.26672 14.5833L11.9751 12.0833"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.34162 1.66667L4.32495 4.69167"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.6583 1.66667L15.675 4.69167"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.66663 6.54167C1.66663 5 2.49163 4.875 3.51663 4.875H16.4833C17.5083 4.875 18.3333 5 18.3333 6.54167C18.3333 8.33333 17.5083 8.20833 16.4833 8.20833H3.51663C2.49163 8.20833 1.66663 8.33333 1.66663 6.54167Z"
                        stroke="white"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M2.91663 8.33333L4.09163 15.5333C4.35829 17.15 4.99996 18.3333 7.38329 18.3333H12.4083C15 18.3333 15.3833 17.2 15.6833 15.6333L17.0833 8.33333"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                    <span className="text-center w-full mr-4">Buy Now</span>
                  </button>
                  {/* <button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      const found = cartItems.find((cartItem) => {
                        if (
                          parseInt(cartItem.id) ===
                          parseInt(QurbaniPackages.package_id)
                        ) {
                          return cartItem;
                        }
                      });
                      if (
                        parseInt(QurbaniPackages.s_d_available_quantity) -
                          parseInt(found?.quantity ?? 0) >=
                        1
                      ) {
                        if (cartItems[0]?.SelectedEidDay === "2") {
                          QurbaniPackages.SelectedEidDay = "2";
                          handleAddToCart(QurbaniPackages);
                        } else if (cartItems[0]?.SelectedEidDay === "1") {
                          setToastMessage(
                            "Please remove Eid Day 1 items to add Eid Day 2 items."
                          );
                          handleShowToast();
                        }

                        if (cartItems.length === 0) {
                          QurbaniPackages.SelectedEidDay = "2";
                          handleAddToCart(QurbaniPackages);
                        }
                      } else {
                        setToastMessage("Out of stock.");
                        handleShowToast();
                      }
                    }}
                    className="bg-Primary w-[90%] py-2 flex mx-auto text-white font-PoppinsBold rounded-[50px]"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="inline-block ml-4"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.01672 13.3333L9.26672 14.5833L11.9751 12.0833"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.34162 1.66667L4.32495 4.69167"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.6583 1.66667L15.675 4.69167"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.66663 6.54167C1.66663 5 2.49163 4.875 3.51663 4.875H16.4833C17.5083 4.875 18.3333 5 18.3333 6.54167C18.3333 8.33333 17.5083 8.20833 16.4833 8.20833H3.51663C2.49163 8.20833 1.66663 8.33333 1.66663 6.54167Z"
                        stroke="white"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M2.91663 8.33333L4.09163 15.5333C4.35829 17.15 4.99996 18.3333 7.38329 18.3333H12.4083C15 18.3333 15.3833 17.2 15.6833 15.6333L17.0833 8.33333"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                    <span className="text-center w-full mr-4">
                      Buy Now Eid Day 2
                    </span>
                  </button> */}
                </div>
                <div
                  className="mt-10 StylesCategorySeoContent font-PoppinsRegular space-y-2 overflow-hidden"
                  dangerouslySetInnerHTML={{
                    __html: QurbaniPackages.content
                      ? QurbaniPackages.content
                      : ``,
                  }}
                />
              </div>
            </div>
            <div className="w-full md:w-[40%] lg:w-[30%] space-y-4 pt-10 md:pt-0 flex items-center flex-col">
              <div className="w-[249px] h-[157px] border border-[rgba(12, 35, 2, 0.15)] rounded-[13px]  mx-auto block">
                <h4 className="font-PoppinsBold pl-4 pt-2">Contact Us</h4>
                <div className="flex flex-col items-center mt-[15px] space-y-2">
                  <a
                    href={`https://wa.me/92${QurbaniPackages?.whatsapp_number.replace(
                      /-/g,
                      ""
                    )}`}
                    target={"_blank"}
                    rel="noreferrer"
                    className="w-[230px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
                  >
                    <AdWhatsappIcon />
                    <span className="ml-10">Whatsapp</span>
                  </a>

                  {/* <div>
                  <a
                    href={`sms://${QurbaniPackages?.whatsapp_number}&body=Hi, I am interested in your QurbaniPackages. Please contact me.`}
                    className="w-[230px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
                  >
                    <AdMessageIcon />
                    <span className="ml-7">Send Message</span>
                  </a>
                </div> */}

                  <div>
                    <a
                      href={`tel:${QurbaniPackages?.phone_number.replace(
                        /-/g,
                        ""
                      )}`}
                      className="w-[230px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
                    >
                      <AdCallIcon />
                      <span className="ml-12">Call Now</span>
                    </a>
                  </div>
                </div>
              </div>

              <SquareGoogleAd />
            </div>
          </div>
        </div>
        <CallToActionBottom />
      </div>
    </>
  );
}
