import { createContext } from "react";
import { useState, useEffect } from "react";
const PreserveFormikFilterContext = createContext();

export default PreserveFormikFilterContext;

export const PreserveFormikFilterContextProvider = ({ children }) => {
  const [formikInitialValues, setFormikInitialValues] = useState({
    Province: undefined,
    District: undefined,
    Tehsil: undefined,
    Category: undefined,
    MaxPrice: undefined,
    Breed: undefined,
    IsDelivery: undefined,
    IsVerified: undefined,
    IsPregnant: undefined,
    MinWeight: undefined,
    MinAge: 0,
    MaxAge: 10,
    Castrated: undefined,
  });

  useEffect(() => {}, [formikInitialValues]);

  return (
    <PreserveFormikFilterContext.Provider
      value={{ formikInitialValues, setFormikInitialValues }}
    >
      {children}
    </PreserveFormikFilterContext.Provider>
  );
};
