// import AdImage from "../assets/images/ad.png";
import PriceContactComponent from "../Components/AdDetailsPageComponents/PriceContactComponent";
import OwnerInformation from "../Components/AdDetailsPageComponents/OwnerInformation";
import { useFetchAdDetails, useMutateAddtoFavList } from "../Middlewares/Api";
import { useParams } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import AdSlider from "../Components/AdDetailsPageComponents/AdSlider";
import AdInformation from "../Components/AdDetailsPageComponents/AdInformation";
import DeliveryOptions from "../Components/AdDetailsPageComponents/DeliveryOptions";
import Features from "../Components/AdDetailsPageComponents/Features";
import DescriptionPostedBy from "../Components/AdDetailsPageComponents/DescriptionPostedBy";
import VerificationSteps from "../Components/AdDetailsPageComponents/VerificationSteps";
import Breadcrumbs from "../Components/BreadCrumbs";
import BannerTop from "../Components/HomePageComponents/BannerTop";
import CallToActionBottom from "../Components/HomePageComponents/CallToActionBottom";
import UpdateMetaTags from "../Utils/UpdateMetaTags";
import SquareGoogleAd from "../Utils/SquareGoogleAd";
import AuthContext from "../Context/AuthContext";
import Modal from ".././Components/Modal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import selfstockpopuop from "../assets/images/selfstockpopuop.webp";
export default function AdDetailsPage() {
  const { AdSlug } = useParams();
  const AdId = AdSlug.split("-").pop();

  const { data, isLoading } = useFetchAdDetails({
    AdId: AdId,
    key: localStorage.getItem("key"),
  });
  const { mutateAsync: mutateAddtoFavList } = useMutateAddtoFavList();
  const { setIsAuth, setUser } = useContext(AuthContext);
  const [
    showModalBuyWithPaymentGuarantee1,
    setShowModalBuyWithPaymentGuarantee1,
  ] = useState(false);
  useEffect(() => {
    if (
      data?.data[0]?.managed_by_farmghar === "1" &&
      (sessionStorage.getItem("selfstockopened") ?? "false") !== "true"
    ) {
      setShowModalBuyWithPaymentGuarantee1(true);
      sessionStorage.setItem("selfstockopened", "true");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  if (isLoading) {
    return (
      <div className="flex items-center justify-center mt-20">
        <div className="spinner"></div>
      </div>
    );
  }
  if (!data?.data[0]) {
    return (
      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>
        <div className="flex items-center justify-center mt-20">
          <div className="text-2xl font-PoppinsRegular">No Ad Found</div>
        </div>
      </div>
    );
  }
  return (
    <>
      {showModalBuyWithPaymentGuarantee1 ? (
        <Modal>
          <div id="modalInner">
            <div className="w-full h-[300px] sm:h-[500px] bg-[#fff] p-[20px] rounded-[13px] my-0 mx-auto -mt-[120px] sm:-mt-0 relative">
              <div className="w-[270px]  sm:w-[500px] max-w-[600px] h-[350px] mx-auto flex items-center flex-col mt-2 font-PoppinsRegular ">
                <div className="mt-2 sm:mt-0 w-[250px] sm:w-[470px] max-w-[600px] h-[470px] sm:h-[560px]">
                  <img
                    src={selfstockpopuop}
                    alt="Buy with payment guarantee"
                    className=" rounded-md"
                  />
                </div>
              </div>
              <div className=" absolute top-[-13px] right-[4px] mt-3">
                <button
                  className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                  onClick={() => {
                    setShowModalBuyWithPaymentGuarantee1(false);
                  }}
                >
                  <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>
        <div>
          <Breadcrumbs />
        </div>
        <UpdateMetaTags data={{ title: data?.data[0]?.ad_title }} />
        <div className="pt-4 font-PoppinsRegular">
          <div className="flex  flex-col md:flex-row">
            <div className="w-full md:w-[58%] lg:w-[70%] rounded-[13px] border border-[rgba(103, 103, 103, 0.1)] mr-[25px]">
              <AdSlider data={data?.data[0]} />
              <AdInformation data={data?.data[0]} />
              <DeliveryOptions
                data={data?.data[0]}
                mutateAddtoFavList={mutateAddtoFavList}
                setIsAuth={setIsAuth}
                setUser={setUser}
              />
              <Features data={data?.data[0]} />
              <VerificationSteps data={data?.data[0]} />
              <DescriptionPostedBy data={data?.data[0]} />
            </div>
            <div className="w-full md:w-[40%] lg:w-[30%] space-y-4 pt-10 md:pt-0 flex items-center flex-col">
              <PriceContactComponent
                data={data?.data[0]}
                mutateAddtoFavList={mutateAddtoFavList}
                setIsAuth={setIsAuth}
                setUser={setUser}
                ad_id={AdId}
              />

              <OwnerInformation data={data?.data[0]} />

              <SquareGoogleAd />

              {/* <div className="w-[249px] h-[207px]  block mx-auto">
              <img src={AdImage} alt="" width="249px" height="207px" />
            </div> */}
            </div>
          </div>
        </div>
        <CallToActionBottom />
      </div>
    </>
  );
}
