import Breadcrumbs from "../Components/BreadCrumbs";
import BannerTop from "../Components/HomePageComponents/BannerTop";
import CallToActionBottom from "../Components/HomePageComponents/CallToActionBottom";
import { useLocation, useParams } from "react-router-dom";
import { ReactComponent as AdCallIcon } from "../assets/icons/AdCall.svg";
// import { ReactComponent as AdMessageIcon } from "../assets/icons/AdMessage.svg";
import { ReactComponent as AdWhatsappIcon } from "../assets/icons/AdWhatsapp.svg";
// import AdImage from "../assets/images/ad.png";
import SquareGoogleAd from "../Utils/SquareGoogleAd";

import ShareButtons from "../Components/BlogsComponents/BlogDetails/ShareButtons";
import { useFetchServiceBySlug } from "../Middlewares/Api";
import UpdateMetaTags from "../Utils/UpdateMetaTags";
import { useEffect } from "react";
import PaymentConfirmation from "../Components/PaymobComponents/PaymentConfirmation";

export default function SingleService() {
  useEffect(() => {
    const checkAppended = document.querySelector(".applied");
    if (!checkAppended) {
      var el1 = document.querySelector("table");
      if (el1) {
        // create wrapper container
        var wrapper1 = document.createElement("div");

        wrapper1.className = "px-4 sm:px-6 lg:px-8 applied ";

        // insert wrapper before el in the DOM tree
        el1.parentNode.insertBefore(wrapper1, el1);
        // move el into wrapper
        wrapper1.appendChild(el1);
      }

      var el2 = document.querySelector("table");
      if (el2) {
        // create wrapper container
        var wrapper2 = document.createElement("div");

        wrapper2.className = "mt-8 flex flex-col applied ";

        // insert wrapper before el in the DOM tree
        el2.parentNode.insertBefore(wrapper2, el2);
        // move el into wrapper
        wrapper2.appendChild(el2);
      }

      var el3 = document.querySelector("table");
      if (el3) {
        // create wrapper container
        var wrapper3 = document.createElement("div");

        wrapper3.className =
          "-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 applied ";

        // insert wrapper before el in the DOM tree
        el3.parentNode.insertBefore(wrapper3, el3);
        // move el into wrapper
        wrapper3.appendChild(el3);
      }

      var el4 = document.querySelector("table");
      if (el4) {
        // create wrapper container
        var wrapper4 = document.createElement("div");

        wrapper4.className =
          "inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 applied ";

        // insert wrapper before el in the DOM tree
        el4.parentNode.insertBefore(wrapper4, el4);
        // move el into wrapper
        wrapper4.appendChild(el4);
      }

      var el5 = document.querySelector("table");
      if (el5) {
        // create wrapper container
        var wrapper5 = document.createElement("div");

        wrapper5.className = "overflow-hidden  md:rounded-lg applied ";

        // insert wrapper before el in the DOM tree
        el5.parentNode.insertBefore(wrapper5, el5);
        // move el into wrapper
        wrapper5.appendChild(el5);
      }
    }
  }, []);

  const { ServiceSlug } = useParams();
  const { data, isLoading } = useFetchServiceBySlug({
    ServiceSlug: ServiceSlug,
  });

  const service = data?.data ?? {};

  const location = useLocation();
  const { ad_id } = location.state || {};

  if (isLoading) {
    return (
      <div className="flex items-center justify-center mt-20">
        <div className="spinner"></div>
      </div>
    );
  }

  if (service.length === 0) {
    return (
      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>
        <div className="flex items-center justify-center mt-20">
          <div className="text-2xl font-PoppinsRegular">No Service Found</div>
        </div>
      </div>
    );
  }

  return (
    <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
      <div className="pt-3 ">
        <BannerTop />
      </div>
      <div>
        <Breadcrumbs />
      </div>
      <UpdateMetaTags data={service} />
      <div className=" font-PoppinsRegular">
        <h2 className="font-PoppinsBold text-[22px]">{service?.title ?? ""}</h2>
        <div className="flex  flex-col md:flex-row pt-4">
          <div className="w-full md:w-[67%] lg:w-[70%]  mr-[25px]">
            <div className=" flex flex-col    rounded-[13px] bg-gray-50  p-4 sm:pr-4 lg:mr-[-30px]">
              <img
                src={service?.image ? service?.image : ""}
                alt={service?.image_alt_tag ? service?.image_alt_tag : ""}
                title={service?.image_title ? service?.image_title : ""}
                className=" mx-auto"
              />
            </div>
            <div className="py-4">
              <ShareButtons slug={service?.slug} service={true} />
            </div>
            <div>
              <h4 className="font-PoppinsBold  pt-2">{service?.title ?? ""}</h4>
              <div
                className="mt-10 StylesCategorySeoContent font-PoppinsRegular space-y-2 overflow-hidden"
                dangerouslySetInnerHTML={{
                  __html: service.content ? service.content : ``,
                }}
              />
            </div>
          </div>
          <div className="w-full md:w-[40%] lg:w-[30%] space-y-4 pt-10 md:pt-0 flex items-center flex-col">
            <div className="w-[249px] h-[157px] border border-[rgba(12, 35, 2, 0.15)] rounded-[13px]  mx-auto block">
              <h4 className="font-PoppinsBold pl-4 pt-2">Contact Us</h4>
              <div className="flex flex-col items-center mt-[15px] space-y-2">
                <a
                  href={`https://wa.me/92${service?.whatsapp_number}`}
                  target={"_blank"}
                  rel="noreferrer"
                  className="w-[230px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
                >
                  <AdWhatsappIcon />
                  <span className="ml-10">Whatsapp</span>
                </a>

                {/* <div>
                  <a
                    href={`sms://${service?.whatsapp_number}&body=Hi, I am interested in your service. Please contact me.`}
                    className="w-[230px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
                  >
                    <AdMessageIcon />
                    <span className="ml-7">Send Message</span>
                  </a>
                </div> */}

                <div>
                  <a
                    href={`tel:${service?.phone_number}`}
                    className="w-[230px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
                  >
                    <AdCallIcon />
                    <span className="ml-12">Call Now</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full max-w-[240px] h-[42px] rounded-[8px]  bg-Primary flex justify-center items-center">
              {/* -------------------- Paymob Payment work -------------------- */}
              <PaymentConfirmation
                ad_detail_list={data}
                ad_id={ad_id}
                slug={ServiceSlug}
              />
            </div>
            <SquareGoogleAd />
          </div>
        </div>
      </div>
      <CallToActionBottom />
    </div>
  );
}
