import Breadcrumbs from "../Components/BreadCrumbs";
import BannerTop from "../Components/HomePageComponents/BannerTop";
import CallToActionBottom from "../Components/HomePageComponents/CallToActionBottom";
// import AdImage from "../assets/images/ad.png";
// import { ReactComponent as NewBlogPost } from "../assets/icons/NewBlogPost.svg";
import RenderArticles from "../Components/BlogsComponents/Blogs/RenderArticles";
import { ReactComponent as EyeIcon } from "../assets/icons/eye.svg";
import { Link } from "react-router-dom";
import { useFetchAllBlogsPosts } from "../Middlewares/Api";
import SquareGoogleAd from "../Utils/SquareGoogleAd";

import ReactPaginate from "react-paginate";

import BlogsContext from "../Context/BlogsContext";
import { useContext } from "react";
import UpdateMetaTags from "../Utils/UpdateMetaTags";

export default function Blogs() {
  const { BlogsStates, setBlogsStates } = useContext(BlogsContext);

  const { data: BlogsPosts, isLoading } = useFetchAllBlogsPosts({
    page: BlogsStates.page,
  });
  if (isLoading) {
    return (
      <div className="flex items-center justify-center mt-20">
        <div className="spinner"></div>
      </div>
    );
  }

  if (BlogsPosts?.data?.length === 0) {
    return (
      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>
        <div className="flex items-center justify-center mt-20">
          <div className="text-2xl font-PoppinsRegular">No Articles Found</div>
        </div>
      </div>
    );
  }

  const getShortName = (title) => {
    let str;

    if (title?.length > 40) {
      str = title?.substring(0, 50) + "...";
    } else {
      str = title ?? "";
    }
    return str;
  };

  // get text substring from html string
  const getTextFromHtmlString = (htmlString) => {
    const div = document.createElement("div");
    div.innerHTML = htmlString;
    const text = div.textContent || div.innerText || "";
    if (text.length > 280) {
      return text.substring(0, 280) + "...";
    }
    return text;
  };
  const handlePageClick = (data) => {
    let selected = data.selected;
    setBlogsStates({ ...BlogsStates, page: selected + 1 });
    window.scrollTo(0, 0);
  };

  return (
    <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
      <div className="pt-3 ">
        <BannerTop />
      </div>
      <div>
        <Breadcrumbs />
      </div>
      <UpdateMetaTags data={{ title: "Blogs" }} />
      <div className="pt-4 font-PoppinsRegular">
        <div className="flex  flex-col md:flex-row">
          <div className="w-full md:w-[67%] lg:w-[70%]  mr-[25px]">
            {/* <div className="w-full"> */}
            {BlogsPosts?.data[0]?.slug && (
              <Link to={BlogsPosts?.data[0]?.slug ?? BlogsPosts?.data[0]?.slug}>
                <div className="w-full">
                  <div className="w-full flex flex-col  sm:grid grid-cols-2  rounded-[13px] border border-[#DBDED9] sm:h-[473.93px] h-full p-4 sm:pr-4 lg:mr-[-30px]">
                    <div className="sm:pr-4  h-[150px] sm:h-[440.93px]  lg:h-full">
                      {BlogsPosts?.data[0]?.image && (
                        <img
                          src={
                            BlogsPosts?.data[0]?.image &&
                            BlogsPosts?.data[0]?.image
                          }
                          alt={
                            BlogsPosts?.data[0]?.image_alt_tag &&
                            BlogsPosts?.data[0]?.image_alt_tag
                          }
                          title={
                            BlogsPosts?.data[0]?.image_title &&
                            BlogsPosts?.data[0]?.image_title
                          }
                          className="w-full  h-full  rounded-lg"
                        />
                      )}
                    </div>
                    <div className="mt-[20px] lg:mt-[71px]">
                      <div className="space-x-0 sm:space-y-4">
                        {/* <div>
                      <NewBlogPost />
                    </div> */}
                        <h2 className="font-PoppinsBold">
                          {getShortName(BlogsPosts?.data[0]?.title)}
                        </h2>
                        <p className="text-justify">
                          {getTextFromHtmlString(
                            BlogsPosts?.data[0]?.content ?? ""
                          )}
                        </p>
                        <div>
                          <p className=" text-[16px]  text-Primary sm:text-[14px] lg:text-[16px] font-PoppinsBold">
                            Read More
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between mt-[5px] sm:mt-[30px] lg:mt-[59px]">
                        <p className="text-[16px] sm:text-[14px] lg:text-[16px] text-[#39342F] opacity-50">
                          {BlogsPosts?.data[0]?.PostedText}
                        </p>

                        <div className="flex items-center">
                          {BlogsPosts?.data[0]?.count && (
                            <div className="opacity-50 mr-[5.85px]">
                              <EyeIcon />
                            </div>
                          )}
                          {BlogsPosts?.data[0]?.count}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            )}

            {/* commented because we are not using google ads for now */}
            {/* <div className="grid grid-cols-1 sm:grid-cols-2  xl:grid-cols-3  gap-6 lg:gap-12  pt-2"> */}
            <div className="flex justify-center items-center">
              <div className="grid grid-cols-1 sm:grid-cols-2  xl:grid-cols-3  gap-6  pt-2">
                <RenderArticles articles={BlogsPosts?.data} />
              </div>
            </div>
            <div>
              {BlogsPosts?.pages === 1 || BlogsPosts?.pages === 0 ? null : (
                <ReactPaginate
                  previousLabel={
                    <button
                      title="previous"
                      type="button"
                      className="inline-flex items-center justify-center  py-0  "
                    >
                      <svg
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4"
                      >
                        <polyline points="15 18 9 12 15 6"></polyline>
                      </svg>
                    </button>
                  }
                  nextLabel={
                    <button
                      title="next"
                      type="button"
                      className="inline-flex items-center justify-center  py-0  "
                    >
                      <svg
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </button>
                  }
                  breakLabel={"..."}
                  forcePage={BlogsStates.page - 1}
                  pageCount={Math.ceil(BlogsPosts?.pages)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={
                    "flex justify-center items-center space-x-1   my-10 "
                  }
                  pageClassName={
                    "inline-flex items-center justify-center w-8 h-8 text-sm  "
                  }
                  pageLinkClassName={"page-link "}
                  previousClassName={
                    "inline-flex items-center justify-center py-0 "
                  }
                  previousLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={
                    "inline-flex items-center justify-center w-8 h-8 text-sm font-semibold border rounded-full shadow-md bg-[#3ED400] text-white"
                  }
                />
              )}
            </div>
          </div>
          {/* ad side commented for now */}
          <div className="w-full md:w-[40%] lg:w-[30%] space-y-4 pt-10 md:pt-0 flex items-center flex-col">
            <SquareGoogleAd />
          </div>
        </div>
      </div>
      <CallToActionBottom />
    </div>
  );
}
