import { useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
export default function GetSelectedFilters({
  filters,
  setFilters,
  filterAds,
  setFilterAds,
  searchParams,
  setSearchParams,
  setLocationStates,
}) {
  function makeCommaSeperatedIds(array) {
    var str = "";
    for (var i = 0; i < array?.length; i++) {
      str += array[i].value;
      if (i < array.length - 1) {
        str += ",";
      }
    }
    return str;
  }
  useEffect(() => {
    setFilterAds((prev) => {
      return {
        ...prev,
        Province: filters.Province && filters.Province.value,
        district:
          filters.District && filters.District.length === 1
            ? filters.District[0].value
            : makeCommaSeperatedIds(filters.District),
        Tehsil: filters.Tehsil && filters.Tehsil.value,
        Category: filters.Category && filters.Category.value,
        MaxPrice: filters.MaxPrice && filters.MaxPrice,
        Breed:
          filters.Breed && filters.Breed?.length === 1
            ? filters?.Breed[0].value
            : makeCommaSeperatedIds(filters.Breed),
        //sending payload undefined if value is 0 mean false
        IsDelivery: filters.IsDelivery ? 1 : undefined,
        IsVerified: filters.IsVerified ? 1 : undefined,
        IsPregnant: filters.IsPregnant ? 1 : undefined,
        MinWeight: filters.MinWeight && filters.MinWeight,
        MinAge:
          filters.MinAge && filters.MinAge === 0 ? undefined : filters.MinAge,
        MaxAge:
          filters.MaxAge && filters.MaxAge === 10 ? undefined : filters.MaxAge,
        Castrated: filters.Castrated ? 1 : undefined,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const filterKeys = Object.keys(filters);
  const selectedFilters = filterKeys
    .filter((key) => filters[key] !== null)
    .map((key) => {
      if (Array.isArray(filters[key])) {
        return filters[key].map((filter) => {
          return {
            key: key,
            value: filter?.value,
            label: filter?.label,
          };
        });
      } else if (typeof filters[key] === "object") {
        return {
          key: key,
          value: filters[key]?.value,
          label: filters[key]?.label,
        };
      } else {
        return {
          key: key,
          value: filters[key],
          label: filters[key],
        };
      }
    })
    .flat();

  const RemoveFilter = (filter) => {
    searchParams.delete("query");
    setSearchParams(searchParams);
    if (filter.key === "District" || filter.key === "Tehsil") {
      setLocationStates((prev) => ({
        ...prev,
        locality: null,
        latitude: null,
        longitude: null,
        isViewNearby: false,
      }));
    }
    setFilters((prev) => {
      if (Array.isArray(prev[filter.key])) {
        return {
          ...prev,
          [filter.key]: prev[filter.key].filter(
            (item) => item.value !== filter.value
          ),
        };
      } else {
        if (filter.key === "MinAge") {
          return {
            ...prev,
            [filter.key]: 0,
          };
        } else if (filter.key === "MaxAge") {
          return {
            ...prev,
            [filter.key]: 10,
          };
        }
        return {
          ...prev,
          [filter.key]: null,
        };
      }
    });
  };
  const AddSelectedFiltersButtons = selectedFilters.map((filter, index) => {
    return filter.key === "MinAge" && filterAds.MinAge ? (
      <div
        key={index}
        className="bg-gray-200 p-1 rounded-md text-TextColor border border-[#3ED400] font-PoppinsRegular text-[12px] mr-2 mt-2 inline-flex items-center"
      >
        Min age {filter.label}{" "}
        <button
          onClick={() => RemoveFilter(filter)}
          className="font-PoppinsBold text-Primary"
        >
          <XMarkIcon className="block h-4 w-4" />
        </button>
      </div>
    ) : filter.key === "MaxAge" && filterAds.MaxAge ? (
      <div
        key={index}
        className=" bg-gray-200 p-1 text-TextColor rounded-md  border border-[#3ED400] font-PoppinsRegular text-[12px] mr-2 mt-2 inline-flex items-center"
      >
        Max age {filter.label}{" "}
        <button
          onClick={() => RemoveFilter(filter)}
          className="font-PoppinsBold text-Primary "
        >
          <XMarkIcon className="block h-4 w-4" />
        </button>
      </div>
    ) : filter.value &&
      filter.key !== "MinAge" &&
      filter.key !== "MaxAge" &&
      filter.key !== "Province" ? (
      <div
        key={index}
        className="bg-gray-200 p-1 rounded-md text-TextColor border border-[#3ED400] font-PoppinsRegular text-[12px] mr-2 mt-2 inline-flex items-center"
      >
        {filter.key === "IsDelivery" ||
        filter.key === "IsVerified" ||
        filter.key === "IsPregnant"
          ? filter.key.substr(2)
          : filter.key === "MinWeight"
          ? "Min weight"
          : filter.key}{" "}
        {filter.key === "MinWeight"
          ? filter.label + " " + "KG"
          : filter.key === "MaxPrice"
          ? filter.label + " " + "RS"
          : filter.label}{" "}
        <button
          onClick={() => RemoveFilter(filter)}
          className="font-PoppinsBold text-Primary"
        >
          <XMarkIcon className="block h-4 w-4" />
        </button>
      </div>
    ) : null;
  });

  return <div>{AddSelectedFiltersButtons}</div>;
}
