import { useEffect } from "react";
import UpdateMetaTags from "../../Utils/UpdateMetaTags";
export default function CategorySeoContent({ data }) {
  useEffect(() => {
    const checkAppended = document.querySelector(".applied");
    if (!checkAppended) {
      var el1 = document.querySelector("table");
      if (el1) {
        // create wrapper container
        var wrapper1 = document.createElement("div");

        wrapper1.className = "px-4 sm:px-6 lg:px-8 applied ";

        // insert wrapper before el in the DOM tree
        el1.parentNode.insertBefore(wrapper1, el1);
        // move el into wrapper
        wrapper1.appendChild(el1);
      }

      var el2 = document.querySelector("table");
      if (el2) {
        // create wrapper container
        var wrapper2 = document.createElement("div");

        wrapper2.className = "mt-8 flex flex-col applied ";

        // insert wrapper before el in the DOM tree
        el2.parentNode.insertBefore(wrapper2, el2);
        // move el into wrapper
        wrapper2.appendChild(el2);
      }

      var el3 = document.querySelector("table");
      if (el3) {
        // create wrapper container
        var wrapper3 = document.createElement("div");

        wrapper3.className =
          "-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 applied ";

        // insert wrapper before el in the DOM tree
        el3.parentNode.insertBefore(wrapper3, el3);
        // move el into wrapper
        wrapper3.appendChild(el3);
      }

      var el4 = document.querySelector("table");
      if (el4) {
        // create wrapper container
        var wrapper4 = document.createElement("div");

        wrapper4.className =
          "inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 applied ";

        // insert wrapper before el in the DOM tree
        el4.parentNode.insertBefore(wrapper4, el4);
        // move el into wrapper
        wrapper4.appendChild(el4);
      }

      var el5 = document.querySelector("table");
      if (el5) {
        // create wrapper container
        var wrapper5 = document.createElement("div");

        wrapper5.className = "overflow-hidden  md:rounded-lg applied ";

        // insert wrapper before el in the DOM tree
        el5.parentNode.insertBefore(wrapper5, el5);
        // move el into wrapper
        wrapper5.appendChild(el5);
      }
    }
  }, []);

  return (
    <>
      <UpdateMetaTags data={data} />
      <div>
        <div className="max-w-full max-h-full">
          {data?.image && (
            <img
              src={data?.image ?? ""}
              alt={data?.image_alt_tag ?? ""}
              title={data?.image_title ?? ""}
              className="w-[700px] max-h-[500px] mx-auto"
            />
          )}
          <div
            className="mt-10 StylesCategorySeoContent font-PoppinsRegular space-y-2 overflow-hidden"
            dangerouslySetInnerHTML={{
              __html: data?.content ? data?.content : ``,
            }}
          />
        </div>
      </div>
    </>
  );
}
