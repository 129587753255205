export default function DescriptionPostedBy({ data }) {
  return (
    <div className="mx-[10px] divide-y divide-gray-300">
      <div className="pb-[5px] pt-[20px]">
        <h4 className="font-PoppinsBold ">Description</h4>
        <p className="text-[#848484] text-justify">{data?.description}</p>
      </div>
      <p className="text-[#848484] py-[10px]">{data?.posted_text}</p>
    </div>
  );
}
