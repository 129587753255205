export const Provinces = [
  {
    value: 1,
    label: "Punjab",
  },
  {
    value: 2,
    label: "Sindh",
  },
  {
    value: 3,
    label: "Khyber Pakhtunkhwa",
  },
  {
    value: 4,
    label: "Balochistan",
  },
  {
    value: 5,
    label: "Gilgit Baltistan",
  },
  {
    value: 6,
    label: "Islamabad",
  },
  {
    value: 11,
    label: "Azad Kashmir",
  },
];
