import Breadcrumbs from "../Components/BreadCrumbs";
import BannerTop from "../Components/HomePageComponents/BannerTop";
import CallToActionBottom from "../Components/HomePageComponents/CallToActionBottom";
import RenderServicesCard from "../Components/OurServicesComponents/RenderServicesCard";
// import AdImage from "../assets/images/ad.png";
import SquareGoogleAd from "../Utils/SquareGoogleAd";

import { useFetchHomePageServices } from "../Middlewares/Api";
import UpdateMetaTags from "../Utils/UpdateMetaTags";

export default function AllServices() {
  const { data: services, isLoading } = useFetchHomePageServices();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center mt-20">
        <div className="spinner"></div>
      </div>
    );
  }

  if (services?.data?.length === 0) {
    return (
      <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
        <div className="pt-3 ">
          <BannerTop />
        </div>
        <div className="flex items-center justify-center mt-20">
          <div className="text-2xl font-PoppinsRegular">No Services Found</div>
        </div>
      </div>
    );
  }
  return (
    <div className="font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
      <div className="pt-3 ">
        <BannerTop />
      </div>
      <div>
        <Breadcrumbs />
      </div>
      <UpdateMetaTags data={{ title: "Our services" }} />
      <div className=" font-PoppinsRegular">
        <h2 className="font-PoppinsBold text-[22px]">Services</h2>
        <div className="flex  flex-col xl:flex-row-reverse pt-4">
          {/* commented because we are not using ads for now */}
          <div className="w-full xl:w-[70%]  mr-[25px] flex items-center flex-col xl:block">
            {/* <div className="w-full flex justify-center"> */}
            <div className="grid grid-cols-1 sm:grid-cols-2  gap-6 lg:grid-cols-3  pt-2">
              <RenderServicesCard articles={services ?? []} />
            </div>
          </div>

          <div className="w-full xl:w-[30%] space-y-4 pt-10 xl:pt-0 flex items-center flex-col xl:block">
            <SquareGoogleAd />
          </div>
        </div>
      </div>
      <CallToActionBottom />
    </div>
  );
}
