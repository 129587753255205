import { ReactComponent as LocationAdIcon } from "../../assets/icons/locationAd.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";

export default function AdDetailsPage({ data }) {
  return (
    <div
      className={`${
        data?.img?.length > 1 ? "pt-[150px]" : "pt-[40px]"
      } space-y-4`}
    >
      <div className="flex justify-between mx-[10px] items-center font-PoppinsBold">
        <div>
          <h3 className="font-[22px]">{data?.ad_title}</h3>
        </div>
        <div>
          ID <span className="text-Primary">#{data?.ref_no}</span>
        </div>
      </div>
      <div className="flex justify-between mx-[10px] items-center ">
        <div className="flex  items-center space-x-2 opacity-50">
          <LocationAdIcon />
          <span>
            {data?.town_en && data?.town_en + ","}{" "}
            {data?.tehsil && data?.tehsil + ","} {data?.district}
          </span>
        </div>
        <div className="flex  items-center space-x-2 opacity-50">
          <EyeIcon />
          <span>{data?.detailviews}</span>
        </div>
      </div>
    </div>
  );
}
