import { ReactComponent as ShareIcon } from "../../assets/icons/Share.svg";
import { ReactComponent as AdCallIcon } from "../../assets/icons/AdCall.svg";
import { ReactComponent as AdMessageIcon } from "../../assets/icons/AdMessage.svg";
import { ReactComponent as AdWhatsappIcon } from "../../assets/icons/AdWhatsapp.svg";
import { useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import Modal from "../Modal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ModalBuyWithPaymentGuarantee from "./ModalBuyWithPaymentGuarantee";
import ModalBuyWithPaymentGuaranteeMobile from "./ModalBuyWithPaymentGuaranteeMobile";
import PaymentConfirmation from "../PaymobComponents/PaymentConfirmation";

// import { useFormik } from "formik";
// import * as yup from "yup";

export default function PriceContactComponent({
  data,
  mutateAddtoFavList,
  setIsAuth,
  setUser,
  ad_id,
}) {
  const navigate = useNavigate();
  const { isAuth } = useContext(AuthContext);

  const [showModal, setShowModal] = useState(false);
  const [showCustomerNumber, setShowCustomerNumber] = useState(false);
  const [makeContinueButtonWhatsapp, setMakeContinueButtonWhastapp] =
    useState(false);
  const [makeContinueButtonSMS, setMakeContinueButtonSMS] = useState(false);
  const [makeContinueButtonCall, setMakeContinueButtonCall] = useState(false);

  const [DoNotShowTerms, setDoNotShowTerms] = useState(null);
  const [
    showModalBuyWithPaymentGuarantee,
    setShowModalBuyWithPaymentGuarantee,
  ] = useState(false);
  const [
    showModalBuyWithPaymentGuaranteeMobile,
    setModalBuyWithPaymentGuaranteeMobile,
  ] = useState(false);

  // const formik = useFormik({
  //   initialValues: {
  //     Phone: "",
  //     Name: "",
  //   },

  //   validationSchema: yup.object({
  //     Phone: yup.string().required("Mobile number is Required"),
  //     Name: yup.string().required("Name is Required").max(40, "Max 40 chars"),
  //     // FarmName: yup.string().max(40, "Max 40 chars"),
  //     Password: yup
  //       .string()
  //       .required("Password is Required")
  //       .min(4, "Password must be at least 4 characters"),
  //     ConfirmPassword: yup
  //       .string()
  //       .oneOf([yup.ref("Password"), null], "Passwords must match")
  //       .required("Confirm Password is Required"),
  //   }),
  //   onSubmit: (values) => {
  //     // handleSubmit(values);
  //   },
  // });

  return (
    <>
      {localStorage.getItem("DoNotShowBuyTerms") === "true" ? null : (
        <>
          {showModal ? (
            <Modal>
              <div id="modalInner">
                <div id="modalContent" className="relative">
                  <div className="w-[270px]  sm:w-[500px] max-w-[600px] h-[350px] mx-auto flex items-center flex-col mt-2 font-PoppinsRegular">
                    <h3>Important Information for Buyers! </h3>
                    <div className=" text-[12px] mt-2">
                      <p className="">Attention</p>
                      <p className="text-justify">
                        Dear buyer, please read this information carefully to
                        avoid any fraud while buying animals from the sellers
                        who have post their animal ads on farmGhar App:
                      </p>
                      <ul className="list-disc pl-4 py-4 h-[200px] sm:h-[230px]  overflow-x-auto text-[14px] text-justify">
                        <li>
                          All animal ads are posted by App users not by us.
                          Therefore, while we try our best to ensure only real
                          ads are posted by genuine sellers, we take no
                          responsibility if the information provided by the
                          seller is incorrect or sellers are fraudulent.
                        </li>
                        <li>
                          Make sure you are happy and satisfied with the animal
                          and its description, and the seller has delivered you
                          the animal before you make any payment to the sellers.
                        </li>
                        <li>
                          Never pay advance or send money to the sellers who say
                          that they will send their animals by cargo to you.
                          This is because some sellers are fraudulent and never
                          send the animal after you have paid them the money. If
                          you send money to any seller you shall do it at your
                          own risk and we shall not be responsible if the seller
                          does not send you the animal.
                        </li>
                        <li>
                          If you feel that an animal ad is fake or the seller is
                          fraudulent, then report it immediately to our helpline
                          on whatsapp. We shall block the seller, remove the ad
                          and report to the police if required.
                        </li>
                      </ul>
                      <p className="">Thank You for using farmGhar!</p>
                      <div className="flex justify-center items-center space-x-2">
                        <input
                          type="checkbox"
                          name="DoNotShowBuyTerms"
                          id="CustomerContactAgreed"
                          onChange={(e) => {
                            setDoNotShowTerms(e.target.checked);
                          }}
                        />
                        <label htmlFor="CustomerContactAgreed">
                          Do not show again
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center space-x-6 mt-3">
                    {makeContinueButtonWhatsapp ||
                    makeContinueButtonSMS ||
                    makeContinueButtonCall ? (
                      <a
                        href={`${
                          makeContinueButtonSMS
                            ? `sms://${
                                data?.ad_sms_number && data.ad_sms_number
                              };?&body=farmGhar%20Ad ID : ${
                                data?.ref_no && data.ref_no
                              }%20${
                                data?.ad_title && data.ad_title
                              }%20 Price: ${data?.price && data.price}%20`
                            : makeContinueButtonCall
                            ? `tel:${data?.ad_user_phone && data.ad_user_phone}`
                            : `https://api.whatsapp.com/send?phone=${
                                data?.ad_whatsapp && "92" + data.ad_whatsapp
                              }`
                        }`}
                        target={"_blank"}
                        rel="noreferrer"
                        className=" bg-Primary w-[100px] h-[40px]  text-white rounded-[13px] font-PoppinsBold mt-2 text-center pt-2"
                        onClick={() => {
                          setMakeContinueButtonWhastapp(false);
                          setMakeContinueButtonSMS(false);
                          setMakeContinueButtonCall(false);
                          setShowModal(false);
                          if (DoNotShowTerms) {
                            localStorage.setItem(
                              "DoNotShowBuyTerms",
                              DoNotShowTerms
                            );
                          }
                        }}
                      >
                        Continue
                      </a>
                    ) : (
                      <button
                        className=" bg-Primary w-[100px] h-[40px]  text-white rounded-[13px] font-PoppinsBold mt-2"
                        onClick={() => {
                          setShowCustomerNumber(true);
                          setShowModal(false);

                          if (DoNotShowTerms) {
                            localStorage.setItem(
                              "DoNotShowBuyTerms",
                              DoNotShowTerms
                            );
                          }
                        }}
                      >
                        Continue
                      </button>
                    )}
                  </div>
                  <div className=" absolute top-[-13px] right-[4px] mt-3">
                    <button
                      className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                      onClick={() => {
                        setMakeContinueButtonWhastapp(false);
                        setMakeContinueButtonSMS(false);
                        setMakeContinueButtonCall(false);
                        setShowModal(false);
                      }}
                    >
                      <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          ) : null}
        </>
      )}

      {showModalBuyWithPaymentGuarantee ? (
        <Modal>
          <div id="modalInner">
            <div className="w-full h-[480px] sm:h-[580px] bg-[#fff] p-[20px] rounded-[13px] my-0 mx-auto -mt-[120px] sm:-mt-0 relative">
              <div className="w-[270px]  sm:w-[500px] max-w-[600px] h-[350px] mx-auto flex items-center flex-col mt-2 font-PoppinsRegular ">
                <ModalBuyWithPaymentGuarantee
                  isfarmgharSelfStock={data?.managed_by_farmghar === "1"}
                />
              </div>
              <div className=" absolute top-[-13px] right-[4px] mt-3">
                <button
                  className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                  onClick={() => {
                    setShowModalBuyWithPaymentGuarantee(false);
                  }}
                >
                  <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}

      {showModalBuyWithPaymentGuaranteeMobile ? (
        <Modal>
          <div id="modalInner">
            <div className="w-full h-[480px] bg-[#fff] p-[20px] rounded-[13px] my-0 mx-auto -mt-[120px]  relative">
              <div className="w-[270px]  sm:w-[500px] max-w-[600px] h-[350px] mx-auto flex items-center flex-col mt-2 font-PoppinsRegular ">
                <ModalBuyWithPaymentGuaranteeMobile
                  AdID={data?.id}
                  isfarmgharSelfStock={data?.managed_by_farmghar === "1"}
                />
              </div>
              <div className=" absolute top-[-13px] right-[4px] mt-3">
                <button
                  className=" bg-gray-100  w-[25px] h-[25px]  text-white rounded-full font-PoppinsBold mt-2 flex items-center justify-center"
                  onClick={() => {
                    setModalBuyWithPaymentGuaranteeMobile(false);
                  }}
                >
                  <XMarkIcon className="w-7 h-7 text-gray-900 font-bold" />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}

      <div className="w-[313px] h-fit border border-[rgba(12, 35, 2, 0.15)] rounded-[13px] sm:block hidden">
        <div className=" justify-between mx-2 mt-[20px] flex">
          <div className="space-x-2 items-center flex">
            <div className="w-[52px] h-[52px] bg-Primary bg-opacity-[0.15] rounded-lg relative flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#3ED400"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                />
              </svg>
            </div>
            <div>
              <p>Price</p>
              <p className="font-PoppinsBold text-Primary">
                RS {data?.price && data.price}{" "}
                <span>{data?.price_type && data.price_type}</span>
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            {/* hide when implement will show this */}
            <div className="hidden">
              <button>
                <ShareIcon />
              </button>
            </div>
            <div>
              <svg
                onClick={(e) => {
                  e.preventDefault();
                  if (!isAuth) {
                    navigate("/auth/login");
                  }
                  const target = e.currentTarget;

                  const response =
                    isAuth &&
                    mutateAddtoFavList({
                      ad_id: data?.id,
                      key: localStorage.getItem("key"),
                    });

                  Promise.resolve(response).then((res) => {
                    if (
                      res.web_status_code === 101 ||
                      res.web_status_code === 102 ||
                      res.web_status_code === 103
                    ) {
                      localStorage.removeItem("key");
                      setIsAuth(false);
                      setUser(null);
                      navigate("/auth/login");
                    }
                    if (res?.code === 200 || res?.code === 300) {
                      target.classList.add("animate-pulse");
                      target.classList.toggle("fill-[#FE251B]");
                      target.classList.toggle("stroke-[#FE251B]");
                      setTimeout(() => {
                        target.classList.remove("animate-pulse");
                      }, 1000);
                    }
                    if (res?.code === 201) {
                      alert(res?.message);
                    }
                  });
                }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke={"black"}
                className={`w-6 h-6 cursor-pointer ${
                  String(data?.is_fav) === "1" &&
                  "fill-[#FE251B] stroke-[#FE251B]"
                }  `}
                fill="none"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center mt-[20px] space-y-2">
          {/* checking if user is logged in or not */}
          {isAuth ? (
            localStorage.getItem("DoNotShowBuyTerms") === "true" ? (
              <a
                href={`https://api.whatsapp.com/send?phone=${
                  data?.ad_whatsapp && "92" + data.ad_whatsapp
                }`}
                target={"_blank"}
                rel="noreferrer"
                className="w-[285px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
              >
                <AdWhatsappIcon />
                <span className="ml-[65px]">Whatsapp</span>
              </a>
            ) : (
              <div>
                <button
                  onClick={() => {
                    setShowModal(true);
                    setMakeContinueButtonWhastapp(true);
                  }}
                  className="w-[285px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
                >
                  <AdWhatsappIcon />
                  <span className="ml-[65px]">Whatsapp</span>
                </button>
              </div>
            )
          ) : (
            <div>
              <button
                onClick={() => {
                  navigate("/auth/login");
                }}
                className="w-[285px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
              >
                <AdWhatsappIcon />
                <span className="ml-[65px]">Whatsapp</span>
              </button>
            </div>
          )}

          {/* <div>
            <a
              href={`sms://${
                data?.ad_sms_number && data.ad_sms_number
              };?&body=farmGhar%20Ad ID : ${data?.ref_no && data.ref_no}%20${
                data?.ad_title && data.ad_title
              }%20 Price: ${data?.price && data.price}%20`}
              className="w-[285px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
            >
              <AdMessageIcon />
              <span className="ml-12">Send Message</span>
            </a>
          </div> */}

          {/* checking if user is logged in or not */}
          {isAuth ? (
            localStorage.getItem("DoNotShowBuyTerms") === "true" ? (
              <div>
                <button
                  onClick={() => setShowCustomerNumber(true)}
                  className="w-[285px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
                >
                  <AdCallIcon />
                  <span className="ml-14">
                    {showCustomerNumber
                      ? data?.ad_user_phone && data.ad_user_phone
                      : "Show number"}
                  </span>
                </button>
              </div>
            ) : (
              <div>
                <button
                  onClick={() => setShowModal(true)}
                  className="w-[285px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
                >
                  <AdCallIcon />
                  <span className="ml-14">
                    {showCustomerNumber
                      ? data?.ad_user_phone && data.ad_user_phone
                      : "Show number"}
                  </span>
                </button>
              </div>
            )
          ) : (
            <div>
              <button
                onClick={() => navigate("/auth/login")}
                className="w-[285px] h-[42px] rounded-[8px] border border-[#39342F] flex items-center pl-3"
              >
                <AdCallIcon />

                <span className="ml-14">
                  {showCustomerNumber
                    ? data?.ad_user_phone && data.ad_user_phone
                    : "Show number"}
                </span>
              </button>
            </div>
          )}
        </div>
        <div className="flex flex-col items-center space-y-2 mt-10 mb-5 max-w-[285px] mx-auto">
          <PaymentConfirmation ad_detail_list={data} ad_id={ad_id} />
          {/* <div className="w-[285px] h-[42px] rounded-[8px]  bg-Primary flex justify-center items-center">
            <button
              // href={`sms://${
              //   data?.ad_sms_number && data.ad_sms_number
              // };?&body=Ad ID : ${
              //   data?.id && data.id
              // }%20 I want to buy, please provide more information.`}
              onClick={() => {
                setShowModalBuyWithPaymentGuarantee(true);
              }}
              className="text-center text-white w-full h-full text-[12px]"
            >
              {data?.managed_by_farmghar === "1"
                ? "farmGhar Payment Guarantee"
                : "Buy with payment guarantee "}
            </button>
          </div> */}

          {/* {data?.is_verified === "1" && (
            <div className="w-[285px] h-[42px] rounded-[8px]   bg-Primary  flex justify-center items-center">
              <a
                href={`tel:${data?.ad_user_phone && data.ad_user_phone}`}
                className=" text-white w-full text-center"
              >
                Buy now
              </a>
            </div>
          )} */}
        </div>
      </div>

      <div className="w-full  border border-[rgba(12, 35, 2, 0.15)] rounded-[13px] sm:hidden block p-4 fixed bottom-0 bg-white z-0">
        <div className="items-center justify-between flex">
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#3ED400"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
              />
            </svg>
            <p className="font-PoppinsBold text-[#B6B6B6] text-[10px]">
              Price{" "}
              <span className="text-Primary text-[12px]">
                RS {data?.price && data.price}{" "}
                {data?.price_type && data.price_type}
              </span>
            </p>
          </div>
          <div className="flex space-x-2">
            {" "}
            {/* checking if user is logged in or not */}
            {isAuth ? (
              localStorage.getItem("DoNotShowBuyTerms") === "true" ? (
                <>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${
                      data?.ad_whatsapp && "92" + data.ad_whatsapp
                    }`}
                    target={"_blank"}
                    rel="noreferrer"
                    className=" h-[36px] rounded-[8px] border border-[#39342F] flex items-center  p-[2px]"
                  >
                    <AdWhatsappIcon />
                  </a>
                  <a
                    href={`sms://${
                      data?.ad_sms_number && data.ad_sms_number
                    };?&body=farmGhar%20Ad ID : ${
                      data?.ref_no && data.ref_no
                    }%20${data?.ad_title && data.ad_title}%20 Price: ${
                      data?.price && data.price
                    }%20`}
                    className="h-[36px] rounded-[8px] border border-[#39342F] flex items-center p-[2px]"
                  >
                    <AdMessageIcon />
                  </a>
                  <a
                    href={`tel:${data?.ad_user_phone && data.ad_user_phone}`}
                    className=" h-[36px] rounded-[8px] border border-[#39342F] flex items-center p-[2px]"
                  >
                    <AdCallIcon />
                  </a>{" "}
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      setShowModal(true);
                      setMakeContinueButtonWhastapp(true);
                    }}
                    className=" h-[36px] rounded-[8px] border border-[#39342F] flex items-center  p-[2px]"
                  >
                    <AdWhatsappIcon />
                  </button>
                  <button
                    onClick={() => {
                      setShowModal(true);
                      setMakeContinueButtonSMS(true);
                    }}
                    className="h-[36px] rounded-[8px] border border-[#39342F] flex items-center p-[2px]"
                  >
                    <AdMessageIcon />
                  </button>
                  <button
                    onClick={() => {
                      setShowModal(true);
                      setMakeContinueButtonCall(true);
                    }}
                    className=" h-[36px] rounded-[8px] border border-[#39342F] flex items-center p-[2px]"
                  >
                    <AdCallIcon />
                  </button>{" "}
                </>
              )
            ) : (
              <>
                <button
                  onClick={() => {
                    navigate("/auth/login");
                  }}
                  className=" h-[36px] rounded-[8px] border border-[#39342F] flex items-center  p-[2px]"
                >
                  <AdWhatsappIcon />
                </button>
                <button
                  onClick={() => {
                    navigate("/auth/login");
                  }}
                  className="h-[36px] rounded-[8px] border border-[#39342F] flex items-center p-[2px]"
                >
                  <AdMessageIcon />
                </button>
                <button
                  onClick={() => {
                    navigate("/auth/login");
                  }}
                  className=" h-[36px] rounded-[8px] border border-[#39342F] flex items-center p-[2px]"
                >
                  <AdCallIcon />
                </button>{" "}
              </>
            )}
          </div>
        </div>
        <div
          // className={`flex  items-center ${
          //   data?.is_verified === "1" ? "justify-between" : "justify-center"
          // }  mt-4`}
          className={`flex  items-center justify-center mt-4`}
        >
          <div className="w-[300px] h-[42px] rounded-[8px]  bg-Primary flex justify-center items-center">
            {/* <a
              href={`sms://${
                data?.ad_sms_number && data.ad_sms_number
              };?&body=Ad ID : ${
                data?.id && data.id
              }%20 I want to buy, please provide more information.`}
              className="text-center text-white w-full text-[12px]"
            >
              Buy with payment guarantee
            </a> */}
            <PaymentConfirmation ad_detail_list={data} ad_id={ad_id} />

            {/* <button
              onClick={() => {
                setModalBuyWithPaymentGuaranteeMobile(true);
              }}
              className="text-center text-white w-full text-[12px]"
            >
              {data?.managed_by_farmghar === "1"
                ? "farmGhar Payment Guarantee"
                : "Buy with payment guarantee"}
            </button> */}
          </div>
          {/* {data?.is_verified === "1" && (
          <div className="w-[130px] h-[42px] rounded-[8px]   bg-Primary  flex justify-center items-center">
            <a
              href={`tel:${data?.ad_user_phone && data.ad_user_phone}`}
              className=" text-white w-full text-center text-[12px]"
            >
              Buy now
            </a>
          </div>
          )} */}
        </div>
      </div>
    </>
  );
}
