import { ReactComponent as BankIcon } from "../../assets/icons/Bank.svg";
export default function QurbaniPaymentHelpAreUs({ formik }) {
  return (
    <div className="flex justify-center items-center flex-col h-full my-10 w-full">
      <p className="font-PoppinsBold text-sm mt-8">Mode Of Payments</p>
      <div className=" space-y-2 mt-4">
        <label
          className={`QurbaniFormRadioBtnLabel w-full text-center   ${
            formik.values.PaymentMethodHelpAreUs === "other"
              ? "bg-Primary bg-opacity-[0.1] text-Primary"
              : "bg-[#f2f2f2] text-TextColor"
          } rounded-lg py-1 px-2 inline-flex items-center justify-center text-[12px]`}
        >
          <input
            type="radio"
            className="QurbaniFormRadioBtn h-5 w-5 text-gray-600"
            name="PaymentMethod"
            value="other"
            checked={formik.values.PaymentMethodHelpAreUs === "other"}
            onChange={(e) => {
              formik.setFieldValue("PaymentMethodHelpAreUs", e.target.value);
            }}
          />
          <span className="whitespace-nowrap">
            <div>
              <svg
                className="mr-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 19V22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19Z"
                  fill="black"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M7 11H5V18H7V11Z" fill="black" />
                <path d="M11 11H9V18H11V11Z" fill="black" />
                <path d="M15 11H13V18H15V11Z" fill="black" />
                <path d="M19 11H17V18H19V11Z" fill="black" />
                <path
                  d="M23 22.75H1C0.59 22.75 0.25 22.41 0.25 22C0.25 21.59 0.59 21.25 1 21.25H23C23.41 21.25 23.75 21.59 23.75 22C23.75 22.41 23.41 22.75 23 22.75Z"
                  fill="black"
                />
                <path
                  d="M21.37 5.75L12.37 2.15C12.17 2.07 11.83 2.07 11.63 2.15L2.63 5.75C2.28 5.89 2 6.3 2 6.68V10C2 10.55 2.45 11 3 11H21C21.55 11 22 10.55 22 10V6.68C22 6.3 21.72 5.89 21.37 5.75ZM12 8.5C11.17 8.5 10.5 7.83 10.5 7C10.5 6.17 11.17 5.5 12 5.5C12.83 5.5 13.5 6.17 13.5 7C13.5 7.83 12.83 8.5 12 8.5Z"
                  fill="black"
                />
              </svg>
            </div>
            Bank Transfer/Cash
          </span>
        </label>
        <label
          className={`QurbaniFormRadioBtnLabel w-full    ${
            formik.values.PaymentMethodHelpAreUs === "Card"
              ? "bg-Primary bg-opacity-[0.1] text-Primary"
              : "bg-[#f2f2f2] text-TextColor"
          } rounded-lg py-1 px-2 inline-flex items-center justify-center text-[12px]`}
        >
          <input
            type="radio"
            className="QurbaniFormRadioBtn h-5 w-5 text-gray-600"
            name="PaymentMethod"
            value="Card"
            checked={formik.values.PaymentMethodHelpAreUs === "Card"}
            onChange={(e) => {
              formik.setFieldValue("PaymentMethodHelpAreUs", e.target.value);
            }}
          />
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 7.54999C22 8.20999 21.46 8.74999 20.8 8.74999H3.2C2.54 8.74999 2 8.20999 2 7.54999V7.53999C2 5.24999 3.85 3.39999 6.14 3.39999H17.85C20.14 3.39999 22 5.25999 22 7.54999Z"
                fill="black"
              />
              <path
                d="M2 11.45V16.46C2 18.75 3.85 20.6 6.14 20.6H17.85C20.14 20.6 22 18.74 22 16.45V11.45C22 10.79 21.46 10.25 20.8 10.25H3.2C2.54 10.25 2 10.79 2 11.45ZM8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25ZM14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z"
                fill="black"
              />
            </svg>
            Credit/Debit Card
          </span>
        </label>

        {formik.values.PaymentMethodHelpAreUs === "Card" && (
          <div className="pt-10 w-full flex justify-center items-center flex-col">
            <p className="">
              Helpareus is an official donation partner of farmGhar.
            </p>
            <p className="font-PoppinsBold py-4">
              Note:{" "}
              <span className="font-PoppinsRegular">
                Your payment is fully secure with us.
              </span>
            </p>
            <a
              href="https://helpareus.com/category/zabiha"
              target="_blank"
              rel="noreferrer"
              className="rounded-lg font-PoppinsBold text-sm p-2 text-white bg-Primary text-center w-[250px] h-[50px] flex justify-center items-center"
            >
              Pay with debit and credit card
            </a>
          </div>
        )}
        {formik.values.PaymentMethodHelpAreUs === "other" && (
          <div className="col-span-1 mt-6">
            <p className="font-PoppinsBold py-4">For payment</p>
            <div className="flex items-center mb-4">
              <BankIcon className="" />
              <h3 className="font-PoppinsBold text-lg mt-2 pl-4 text-center">
                Bank Account
              </h3>
            </div>
            <div className="flex  md:pr-4 items-center justify-between">
              <h3 className="font-PoppinsBold text-[13px]">Bank Name</h3>
              <p className="font-PoppinsRegular text-[12px]">MCB Bank</p>
            </div>
            <div className="flex md:pr-4 items-center justify-between">
              <h3 className="font-PoppinsBold text-[13px]">Account Title</h3>
              <p className="font-PoppinsRegular text-[12px]">
                Farmghar Private Limited
              </p>
            </div>
            <div className="flex  md:pr-4 items-center justify-between">
              <h3 className="font-PoppinsBold text-[13px]">Account IBAN</h3>
              <p className="font-PoppinsRegular text-[12px]">
                PK66MUCB1206098601010134
              </p>
            </div>
            <div className="flex  md:pr-4 items-center justify-between">
              <h3 className="font-PoppinsBold text-[13px]">Account Number</h3>
              <p className="font-PoppinsRegular text-[12px]">
                1206098601010134
              </p>
            </div>

            <div className="flex  md:pr-4 items-center justify-between">
              <h3 className="font-PoppinsBold text-[13px]">Branch Code</h3>
              <p className="font-PoppinsRegular text-[12px]">0477</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
