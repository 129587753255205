import { useContext, useEffect, useState } from "react";
import { CartContext } from "../../Context/CartContext";
import QuantitySelector from "./QuantitySelector";
import { XMarkIcon } from "@heroicons/react/24/outline";
import AddMorePackages from "./AddMorePackages";
import { ReactComponent as BankIcon } from "../../assets/icons/Bank.svg";
export default function QurbaniCart({
  QurbaniPackages,
  GettingPackages,
  pickUpAddress,
  formik,
  handleAddToCart,
  setToastMessage,
  handleShowToast,
  refetch,
}) {
  const [showAddMorePackages, setShowAddMorePackages] = useState(false);
  const { cartItems, removeFromCart, updateQuantity, totalPrice } =
    useContext(CartContext);

  // const getShortName = (name) => {
  //   let str;

  //   if (name?.length > 14) {
  //     str = name?.substring(0, 14) + "...";
  //   } else {
  //     str = name ?? "";
  //   }
  //   return str;
  // };
  let BookingAmount = 0;
  // let AmountLeft = 0;
  let DeliveryPrice = 0;

  useEffect(() => {}, [cartItems]);
  return (
    <div className="pt-4 pl-2 w-full">
      {showAddMorePackages && (
        <AddMorePackages
          setToastMessage={setToastMessage}
          GettingPackages={GettingPackages}
          QurbaniPackages={QurbaniPackages}
          setShowAddMorePackages={setShowAddMorePackages}
          handleAddToCart={handleAddToCart}
          cartItems={cartItems}
          handleShowToast={handleShowToast}
        />
      )}
      <h3 className="font-PoppinsBold">Order Summery</h3>
      {cartItems.map((item) => {
        // 20% of the total price of the package will be charged as booking amount. The remaining amount will be paid at the time of delivery.
        if (
          pickUpAddress?.data2[0]?.qurbani_advance_payment === "100" ||
          formik.values.PaymentOption === "FullPayment"
        ) {
          BookingAmount += item.price * item.quantity;
          // AmountLeft += item.price * item.quantity;
        } else {
          BookingAmount += (item.price * item.quantity) / 5;
          // AmountLeft += item.price * item.quantity - BookingAmount;
        }

        if (
          pickUpAddress?.data2[0]?.is_delivery === "1" &&
          formik.values.DeliveryOption === "HomeDelivery"
        ) {
          DeliveryPrice += item.deliveryPrice * item.quantity;
        }
        return (
          <div key={item.id} className="flex justify-between items-center mt-4">
            <div className="space-x-3 flex">
              <img
                className={`w-[59px] h-[59px] rounded-[10px]`}
                src={item.image}
                alt={item.name}
              />
              <div className="">
                <p className="text-sm">{item.name}</p>
                <p className="font-PoppinsBold">Rs {item.price}</p>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex justify-end pb-3">
                <button
                  onClick={() => {
                    removeFromCart(item.id);
                    setToastMessage("Item Removed from Cart.");
                    handleShowToast();
                  }}
                  className=""
                  title="Remove Item from Cart"
                >
                  <XMarkIcon className="w-5 h-5 text-gray-500" />
                </button>
              </div>
              <QuantitySelector
                id={item.id}
                quantity={item.quantity}
                updateQuantity={updateQuantity}
                max={item.availableQuantity}
                setToastMessage={setToastMessage}
                handleShowToast={handleShowToast}
              />
            </div>
          </div>
        );
      })}
      <button
        className="flex items-center font-PoppinsBold my-4"
        onClick={() => {
          setShowAddMorePackages(true);
          refetch();
        }}
        type="button"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          className="mr-2"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0ZM14 10.75H10.75V14C10.75 14.41 10.41 14.75 10 14.75C9.59 14.75 9.25 14.41 9.25 14V10.75H6C5.59 10.75 5.25 10.41 5.25 10C5.25 9.59 5.59 9.25 6 9.25H9.25V6C9.25 5.59 9.59 5.25 10 5.25C10.41 5.25 10.75 5.59 10.75 6V9.25H14C14.41 9.25 14.75 9.59 14.75 10C14.75 10.41 14.41 10.75 14 10.75Z"
            fill="#3ED400"
          />
        </svg>
        Add more Packages
      </button>
      <div className="space-y-4 mt-4">
        <section className="flex justify-between items-center">
          <div className="text-base">Total Order Value</div>
          <div className="font-PoppinsBold text-base">RS {totalPrice}</div>
        </section>
        {/* <section className="flex justify-between items-center">
        <div className="text-base">Delivery Charges</div>
        <div className="font-PoppinsBold text-base">RS {item.deliveryPrice}</div>
      </section> */}
        <section className="flex justify-between items-center">
          <div className="text-base">Booking Amount</div>
          <div className="font-PoppinsBold text-base">RS {BookingAmount}</div>
        </section>
        {pickUpAddress?.data2[0]?.is_delivery === "1" && (
          <section className="flex justify-between items-center">
            <div className="text-base">Delivery Charges</div>
            <div className="font-PoppinsBold text-base">RS {DeliveryPrice}</div>
          </section>
        )}
        {/* <section className="flex justify-between items-center">
          <div className="text-base">Balance Amount</div>
          <div className="font-PoppinsBold text-base">
            RS{" "}
            {formik?.values?.PaymentOption === "FullPayment"
              ? 0
              : totalPrice - BookingAmount + DeliveryPrice}
          </div>
        </section> */}
      </div>
      <section className="flex justify-between items-center mt-4 border-t-2 border-gray-200 pt-2">
        <div className="text-base">Total Payable</div>
        <div className="font-PoppinsBold text-base">
          RS{" "}
          {pickUpAddress?.data2[0]?.is_delivery === "1" &&
          formik?.values?.DeliveryOption === "HomeDelivery" &&
          (pickUpAddress?.data2[0]?.qurbani_advance_payment === "100" ||
            formik?.values?.PaymentOption === "FullPayment")
            ? BookingAmount + DeliveryPrice
            : BookingAmount}
        </div>
      </section>

      <div className="col-span-1 mt-6">
        <p className="font-PoppinsBold py-4">For payment</p>
        <div className="flex items-center mb-4">
          <BankIcon className="" />
          <h3 className="font-PoppinsBold text-lg mt-2 pl-4 text-center">
            Bank Account
          </h3>
        </div>
        <div className="flex  md:pr-4 items-center justify-between">
          <h3 className="font-PoppinsBold text-[13px]">Bank Name</h3>
          <p className="font-PoppinsRegular text-[12px]">MCB Bank</p>
        </div>
        <div className="flex md:pr-4 items-center justify-between">
          <h3 className="font-PoppinsBold text-[13px]">Account Title</h3>
          <p className="font-PoppinsRegular text-[12px]">
            Farmghar Private Limited
          </p>
        </div>
        <div className="flex  md:pr-4 items-center justify-between">
          <h3 className="font-PoppinsBold text-[13px]">Account IBAN</h3>
          <p className="font-PoppinsRegular text-[12px]">
            PK66MUCB1206098601010134
          </p>
        </div>
        <div className="flex  md:pr-4 items-center justify-between">
          <h3 className="font-PoppinsBold text-[13px]">Account Number</h3>
          <p className="font-PoppinsRegular text-[12px]">1206098601010134</p>
        </div>

        <div className="flex  md:pr-4 items-center justify-between">
          <h3 className="font-PoppinsBold text-[13px]">Branch Code</h3>
          <p className="font-PoppinsRegular text-[12px]">0477</p>
        </div>
      </div>
    </div>
  );
}
