import QurbaniCart from "../Components/QurbaniComponents/QurbaniCart";
import LogoCut from "../assets/icons/LogoCut.svg";
import { ReactComponent as Logo } from "../assets/logo.svg";
import BannerTop from "../Components/HomePageComponents/BannerTop";
import QurbaniOrder from "../Components/QurbaniComponents/QurbaniOrder";
import { useContext } from "react";
import { CartContext } from "../Context/CartContext";
import UpdateMetaTags from "../Utils/UpdateMetaTags";
import {
  useMutateQurbaniAbandonedCart,
  useFetchQurbaniPackages,
  useFetchQurbaniSupportInfo,
  useFetchQurbaniPickUpAddress,
  useMutateOrderQurbaniPackage,
} from "../Middlewares/Api";
import { useFormik } from "formik";
import * as Yup from "yup";
import Toast from "../Utils/Toast";
import { useState, useEffect } from "react";
export default function QurbaniCheckout() {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "Item added to cart successfully."
  );
  const {
    data: QurbaniPackages,
    isLoading: GettingPackages,
    refetch,
  } = useFetchQurbaniPackages({ package_category_id: "0" });
  const { cartItems, addToCart } = useContext(CartContext);
  const { mutateAsync: OrderQurbaniPackage, isLoading: OrderRequestInProcess } =
    useMutateOrderQurbaniPackage();

  const { data: pickUpAddress } = useFetchQurbaniPickUpAddress();
  const { data: supportInfo } = useFetchQurbaniSupportInfo();
  const { mutateAsync: AbandonedCart } = useMutateQurbaniAbandonedCart();
  const handleCloseToast = () => {
    setShowToast(false);
  };
  const [validationSchema, setValidationSchema] = useState({
    FullName: Yup.string().required("Required"),
    PaymentMethodHelpAreUs: Yup.string().required("Required"),
    ContactNumber: Yup.string()
      .required("Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(11, "Must be exactly 11 digits")
      .max(11, "Must be exactly 11 digits"),
    AlternateContactNumber: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(11, "Must be exactly 11 digits")
      .max(11, "Must be exactly 11 digits"),
    HouseNumber: Yup.string().required("Required"),
    Block: Yup.string(),
    Society: Yup.string(),
    Cnic: Yup.string().matches(/^\d{5}-\d{7}-\d{1}$/, "Invalid cnic"),
    DeliveryOption: Yup.string().required("Required"),
    PaymentOption: Yup.string().required("Required"),
    PickupPoint: Yup.object().when("DeliveryOption", {
      is: "SelfPickUp",
      then: Yup.object().required("Required"),
    }),
    PickupDay: Yup.object().required("Required"),
    City: Yup.object().required("Required"),
    Email: Yup.string().email("Invalid email address"),
    PaymentMethod: Yup.string().required("Required"),
    JazzCashNumber: Yup.string().when("PaymentMethod", {
      is: "JazzCash",
      then: Yup.string()
        .required("Required")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(11, "Must be exactly 11 digits")
        .max(11, "Must be exactly 11 digits"),
    }),
    CardNumber: Yup.string().when("PaymentMethod", {
      is: "Card",
      then: Yup.string()
        .required("Required")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(16, "Must be exactly 16 digits")
        .max(16, "Must be exactly 16 digits"),
    }),
    CardExpiry: Yup.string().when("PaymentMethod", {
      is: "Card",
      then: Yup.string()
        .required("Required")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(4, "Must be exactly 4 digits")
        .max(4, "Must be exactly 4 digits"),
    }),
    CardCVV: Yup.string().when("PaymentMethod", {
      is: "Card",
      then: Yup.string()
        .required("Required")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(3, "Must be exactly 3 digits")
        .max(3, "Must be exactly 3 digits"),
    }),
    FullNameOnCard: Yup.string().when("PaymentMethod", {
      is: "Card",
      then: Yup.string().required("Required"),
    }),
  });
  const handleShowToast = () => {
    setShowToast(true);
  };
  const formik = useFormik({
    initialValues: {
      FullName: "",
      ContactNumber: "",
      AlternateContactNumber: "",
      HouseNumber: "",
      Block: "",
      Society: "",
      DeliveryOption: "SelfPickUp",
      PaymentOption: "",
      PickupPoint: "",
      PickupDay: "",
      City: { label: "Lahore", value: "1" },
      Email: "",
      PaymentMethod: "Cash",
      PaymentMethodHelpAreUs: "other",
      Cnic: "",
      JazzCashNumber: "",
      CardNumber: "",
      CardExpiry: "",
      CardCVV: "",
      FullNameOnCard: "",
    },
    validationSchema: Yup.object(validationSchema),
  });

  const handleAddToCart = (item) => {
    addToCart(
      {
        id: parseInt(item.package_id),
        name: item.package_name,
        price: parseInt(item?.price?.replace(/,/g, "")),
        catId: parseInt(item?.cat_id),
        totalQuantity: parseInt(item?.total_quantity),
        availableQuantity: parseInt(item?.available_quantity),
        packageCatName: item?.package_cat_name,
        deliveryPrice: parseInt(item?.delivery_price?.replace(/,/g, "")),
        description: item?.description,
        image: item?.image,
        SelectedEidDay: item.SelectedEidDay,
      },
      1
    );
    let cartsI = 0;
    const found = cartItems?.find(
      (element) => element.id === parseInt(item.package_id)
    );
    if (found) {
      cartsI = 0;
    } else {
      cartsI = 1;
    }

    cartItems?.forEach((element) => {
      cartsI = cartsI + element.quantity;
    });
    setToastMessage(
      `${cartsI} ${cartsI > 1 ? "items" : "item"} added to cart successfully.`
    );
    handleShowToast();
  };
  useEffect(() => {
    const itm = [
      { label: "1st day of Eid", value: "1" },
      { label: "2nd day of Eid", value: "2" },
    ].filter((element) => element.value === cartItems[0]?.SelectedEidDay);
    if (cartItems?.length > 0) {
      formik.setFieldValue("PickupDay", itm[0] ?? "");
    }
    const handleBeforeUnload = (event) => {
      formik.validateForm().then((errors) => {
        !errors["ContactNumber"] &&
          formik.values.ContactNumber !== "" &&
          cartItems?.length > 0 &&
          AbandonedCart({
            mobileNumber: formik.values.ContactNumber,
            name: formik.values.FullName,
            package_id: cartItems?.map((item) => item.id),
            quantity: cartItems?.map((item) => item.quantity),
          });
      });
      if (event) {
        event.preventDefault();
        event.returnValue = ""; // Chrome requires this line
      }
    };
    // Execute the handleBeforeUnload function on component mount
    // handleBeforeUnload();

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, formik.values.ContactNumber]);

  useEffect(() => {
    const value =
      pickUpAddress?.data2[0]?.qurbani_advance_payment === "100"
        ? "FullPayment"
        : "BookingPayment";
    formik.setFieldValue("PaymentOption", value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickUpAddress?.data2]);

  return (
    <>
      <UpdateMetaTags data={{ title: "Qurbani | Checkout" }} />
      {showToast && (
        <Toast
          message={toastMessage}
          duration={3000}
          onClose={handleCloseToast}
        />
      )}
      <div className="relative min-w-screen min-h-screen bg-[#F4F4F4] font-PoppinsRegular">
        <img
          src={LogoCut}
          alt="Logo"
          className="absolute bottom-0 right-0 z-0 "
        />
        <div className="bg-white">
          <div className="font-PoppinsRegular max-w-[1280px] mx-auto">
            <div className="flex justify-between items-center  px-5 py-3">
              <a href="/" className=" h-8 w-28">
                <Logo />
              </a>
              <a
                href="/"
                className="w-[110px]  text-center sm:w-[203px] flex-shrink h-[42px] rounded-[50px] bg-[#EFA922] flex justify-center items-center text-white text-[14px]"
              >
                Go Back To homepage
              </a>
            </div>
          </div>
        </div>
        <div className="bg-[#F4F4F4] relative font-PoppinsRegular max-w-[1280px] xl:px-20 pb-6 lg:px-16 md:px-8 px-5 mx-auto mt-[54px] md:mt-0 space-y-4">
          <h2 className="font-PoppinsBold text-center mt-4">
            Book Your Qurbani Package
          </h2>
          <div className="pt-3 ">
            <BannerTop />
          </div>
          {/* Contact Us  */}
          {supportInfo && (
            <div>
              <h3 className="font-PoppinsBold">Contact Us</h3>
              <div className="flex flex-wrap flex-row justify-around max-w-[500px] w-full">
                <div className="flex m-1 items-center">
                  <svg
                    className="w-7 h-7 mr-1"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.6 6.31999C16.8669 5.58141 15.9943 4.99596 15.033 4.59767C14.0716 4.19938 13.0406 3.99622 12 3.99999C10.6089 4.00135 9.24248 4.36819 8.03771 5.06377C6.83294 5.75935 5.83208 6.75926 5.13534 7.96335C4.4386 9.16745 4.07046 10.5335 4.06776 11.9246C4.06507 13.3158 4.42793 14.6832 5.12 15.89L4 20L8.2 18.9C9.35975 19.5452 10.6629 19.8891 11.99 19.9C14.0997 19.9001 16.124 19.0668 17.6222 17.5816C19.1205 16.0965 19.9715 14.0796 19.99 11.97C19.983 10.9173 19.7682 9.87634 19.3581 8.9068C18.948 7.93725 18.3505 7.05819 17.6 6.31999ZM12 18.53C10.8177 18.5308 9.65701 18.213 8.64 17.61L8.4 17.46L5.91 18.12L6.57 15.69L6.41 15.44C5.55925 14.0667 5.24174 12.429 5.51762 10.8372C5.7935 9.24545 6.64361 7.81015 7.9069 6.80322C9.1702 5.79628 10.7589 5.28765 12.3721 5.37368C13.9853 5.4597 15.511 6.13441 16.66 7.26999C17.916 8.49818 18.635 10.1735 18.66 11.93C18.6442 13.6859 17.9355 15.3645 16.6882 16.6006C15.441 17.8366 13.756 18.5301 12 18.53ZM15.61 13.59C15.41 13.49 14.44 13.01 14.26 12.95C14.08 12.89 13.94 12.85 13.81 13.05C13.6144 13.3181 13.404 13.5751 13.18 13.82C13.07 13.96 12.95 13.97 12.75 13.82C11.6097 13.3694 10.6597 12.5394 10.06 11.47C9.85 11.12 10.26 11.14 10.64 10.39C10.6681 10.3359 10.6827 10.2759 10.6827 10.215C10.6827 10.1541 10.6681 10.0941 10.64 10.04C10.64 9.93999 10.19 8.95999 10.03 8.56999C9.87 8.17999 9.71 8.23999 9.58 8.22999H9.19C9.08895 8.23154 8.9894 8.25465 8.898 8.29776C8.8066 8.34087 8.72546 8.403 8.66 8.47999C8.43562 8.69817 8.26061 8.96191 8.14676 9.25343C8.03291 9.54495 7.98287 9.85749 8 10.17C8.0627 10.9181 8.34443 11.6311 8.81 12.22C9.6622 13.4958 10.8301 14.5293 12.2 15.22C12.9185 15.6394 13.7535 15.8148 14.58 15.72C14.8552 15.6654 15.1159 15.5535 15.345 15.3915C15.5742 15.2296 15.7667 15.0212 15.91 14.78C16.0428 14.4856 16.0846 14.1583 16.03 13.84C15.94 13.74 15.81 13.69 15.61 13.59Z"
                      fill="#000000"
                    />
                  </svg>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${
                      supportInfo?.data?.whatsapp_number &&
                      "92" +
                        supportInfo?.data?.whatsapp_number.replace(/-/g, "")
                    }`}
                    target={"_blank"}
                    rel="noreferrer"
                    className=" hover:underline"
                  >
                    {supportInfo?.data?.whatsapp_number}
                  </a>
                </div>
                <div className="flex m-1 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-5 h-5 mr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                    />
                  </svg>
                  <a
                    href={`tel:${supportInfo?.data?.phone_number.replace(
                      /-/g,
                      ""
                    )}`}
                    className="block md:hidden hover:underline"
                  >
                    {supportInfo?.data?.phone_number}
                  </a>
                  <p className="hidden md:block">
                    {supportInfo?.data?.phone_number}
                  </p>
                </div>
                <div className="flex m-1 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-5 h-5 mr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                    />
                  </svg>
                  <a
                    href={`sms://${
                      supportInfo?.data?.sms_number &&
                      supportInfo?.data?.sms_number.replace(/-/g, "")
                    };?&body=I want to order Qurbani package, please provide more information.`}
                    className="block md:hidden hover:underline"
                  >
                    {supportInfo?.data?.sms_number}
                  </a>
                  <p className="hidden md:block">
                    {supportInfo?.data?.sms_number}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="flex  flex-col xl:flex-row pt-4 ">
            {cartItems.length > 0 ? (
              <>
                <div className="w-full xl:w-[65%]  mr-[25px] flex items-center flex-col xl:block bg-white rounded-[15px] p-2">
                  <QurbaniOrder
                    refetch={refetch}
                    Yup={Yup}
                    AbandonedCart={AbandonedCart}
                    setValidationSchema={setValidationSchema}
                    pickUpAddress={pickUpAddress}
                    OrderQurbaniPackage={OrderQurbaniPackage}
                    OrderRequestInProcess={OrderRequestInProcess}
                    formik={formik}
                  />
                </div>
                <div className="w-full xl:w-[35%] space-y-4 pt-10 xl:pt-0 flex items-center flex-col xl:block bg-white rounded-[15px] p-2 mt-2 xl:mt-0">
                  <QurbaniCart
                    pickUpAddress={pickUpAddress}
                    formik={formik}
                    handleAddToCart={handleAddToCart}
                    setToastMessage={setToastMessage}
                    handleShowToast={handleShowToast}
                    QurbaniPackages={QurbaniPackages}
                    GettingPackages={GettingPackages}
                    refetch={refetch}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="w-full text-center font-PoppinsBold justify-center  xl:w-[65%] mr-[25px] space-y-4 pt-10  flex items-center flex-col  bg-white rounded-[15px] p-2 mt-2 xl:mt-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-20 h-20 mx-auto"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                    />
                  </svg>
                  Please add items to cart.
                </div>
                <div className="w-full xl:w-[35%] space-y-4 pt-10 xl:pt-0 flex items-center flex-col xl:block bg-white rounded-[15px] p-2 mt-2 xl:mt-0">
                  <QurbaniCart
                    pickUpAddress={pickUpAddress}
                    formik={formik}
                    handleAddToCart={handleAddToCart}
                    setToastMessage={setToastMessage}
                    handleShowToast={handleShowToast}
                    QurbaniPackages={QurbaniPackages}
                    GettingPackages={GettingPackages}
                    refetch={refetch}
                  />
                </div>
              </>
            )}
          </div>
          <p className="text-center text-[14px] text-TextColor pt-2">
            Copyright {new Date().getFullYear()} farmGhar. All Rights Reserved
          </p>
        </div>
      </div>
    </>
  );
}
