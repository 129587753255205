import React, { useEffect, useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";

export const getCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (error) => {
          reject(error);
        }
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

export const getAddress = (latitude, longitude, FromHeader = false) => {
  return new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        location: { lat: latitude, lng: longitude },
      },
      (results, status) => {
        if (status === "OK" && results.length > 0) {
          if (FromHeader) {
            resolve(results[0].address_components);
          }
          resolve(results[0].formatted_address);
        } else {
          reject("Reverse geocoding failed");
        }
      }
    );
  });
};

function MyComponent({
  autoComplete2,
  setAutoComplete2,
  HandleChangeLatLong,
  locationStates,
  setLocationStates,
  formikInitialValues,
}) {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const autocompleteInputRef = useRef(null);

  useEffect(() => {
    // Reverse geocode when the selected place changes
    if (locationStates.selectedPlace !== null) {
      getAddress(
        locationStates.selectedPlace.geometry.location.lat(),
        locationStates.selectedPlace.geometry.location.lng()
      )
        .then((address) => {
          setLocationStates((prevState) => ({
            ...prevState,
            locality: null,
            address: address,
          }));
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationStates.selectedPlace]);

  useEffect(() => {
    // Reverse geocode when the current location changes
    if (locationStates.currentLocation !== null) {
      getAddress(
        locationStates.currentLocation.lat,
        locationStates.currentLocation.lng
      )
        .then((address) => {
          // localStorage.setItem("address", address);
          setLocationStates((prevState) => ({
            ...prevState,
            locality: null,
            address: address,
          }));

          if (autocompleteInputRef.current) {
            autocompleteInputRef.current.value = address;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationStates.currentLocation]);

  const onPlaceChanged = () => {
    if (autoComplete2) {
      const place = autoComplete2.getPlace();
      if (place.geometry && place.geometry.location) {
        setLocationStates((prevState) => ({
          ...prevState,
          locality: null,
          selectedPlace: place,
        }));
        HandleChangeLatLong(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
      }
    }
  };
  const getLocation = async () => {
    const position = await getCurrentLocation();
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    HandleChangeLatLong(latitude, longitude);
    getAddress(latitude, longitude)
      .then((address) => {
        setLocationStates((prevState) => ({
          ...prevState,
          // locality: null,
          address: address,
          latitude: latitude,
          longitude: longitude,
        }));
        if (autocompleteInputRef.current) {
          autocompleteInputRef.current.value = address;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    if (
      (formikInitialValues?.District ?? []).length === 0 &&
      Object.keys(formikInitialValues?.Tehsil ?? {})?.length === 0
    ) {
      getLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="w-full">
        <div>
          <label
            htmlFor="autocomplete"
            className="block text-sm font-medium text-TextColor"
          >
            Location
          </label>
        </div>
        {window.google.maps.places && (
          <Autocomplete
            onLoad={(autocomplete) => {
              setAutoComplete2(autocomplete);
              autocomplete.setComponentRestrictions({
                country: ["pk"],
              });
            }}
            onPlaceChanged={onPlaceChanged}
          >
            <div className="relative">
              <input
                ref={autocompleteInputRef}
                type="text"
                name="autocomplete"
                id="autocomplete"
                onKeyDown={handleKeyDown}
                placeholder="Search for a location"
                value={locationStates.address}
                onChange={(e) =>
                  setLocationStates((prevState) => ({
                    ...prevState,
                    locality: null,
                    address: e.target.value,
                  }))
                }
                className="mt-1 block w-full SearchLocationInput border-[rgba(0, 0, 0, 0.16)] py-2 pl-3 pr-10 text-base  sm:text-sm border-2 rounded-[10px] focus:outline-none"
              />
            </div>
          </Autocomplete>
        )}

        <div className="flex gap-1 justify-start items-center mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="#3ED400"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
            />
          </svg>

          <button
            onClick={getLocation}
            type="button"
            className="block text-sm font-medium text-Primary"
          >
            Use Current Location
          </button>
        </div>
      </div>
    </>
  );
}

export default React.memo(MyComponent);
